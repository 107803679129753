import { Budget } from '../../../common/models/workspace.models';
import { CellPrimaryValue, CellSecondaryValue } from '../../workspaces/workspaces.styles';
import { TableHeadCell } from '../../../components/Table/TableHead';
import { get12HourFormat, getDate } from '../../../common/utils/date-formatter';
import React from 'react';

export const headCells: TableHeadCell<Budget>[] = [
  { id: 'name', label: 'Name' },
  { id: 'budgetLimit', label: 'Budget' },
  { id: 'notifications', label: 'Notification Type' },
  { id: 'createdAt', label: 'Created date' },
  { id: 'createdBy', label: 'Created by' },
  { id: 'editable', type: 'editable' },
];

export const renderCell = (colName: keyof Budget | string, _rowIndex: number, item: Budget): React.ReactNode => {
  switch (colName) {
    case 'budgetLimit': {
      return <CellPrimaryValue>US$ {item[colName]}</CellPrimaryValue>;
    }
    case 'notifications': {
      return (
        <CellPrimaryValue>
          {item[colName].map((notification) => notification.notificationType).join(', ')}
        </CellPrimaryValue>
      );
    }
    case 'createdAt': {
      const d = new Date(item.createdAt);
      return (
        <>
          <CellPrimaryValue>{getDate(d)}</CellPrimaryValue>
          <CellSecondaryValue className="time">{get12HourFormat(d)}</CellSecondaryValue>
        </>
      );
    }
    default: {
      return <CellPrimaryValue>{item[colName]}</CellPrimaryValue>;
    }
  }
};
