import { Toast } from './toast/toast';
import { ToasterContainer } from './toaster.styles';
import { useSelector } from '../../store/configure-store';
import React, { useEffect, useState } from 'react';

export const Toaster: React.FC = () => {
  const toasters = useSelector((state) => state.toasters.toasters);
  const [deleteToasterId, setDeleteToasterId] = useState('');
  useEffect(() => {
    const interval = setInterval(() => {
      if (toasters.length) {
        setDeleteToasterId(toasters[0].id);
      }
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [toasters]);

  return (
    <ToasterContainer>
      {toasters
        .filter((value) => value.message)
        .map((toaster) => (
          <Toast {...toaster} deleteToasterId={deleteToasterId} key={toaster.id} />
        ))}
    </ToasterContainer>
  );
};
