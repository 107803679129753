import { GET_RESOURCES, GET_RESOURCE_BY_ID, SET_OPENED, SET_REQUIRED_RESOURCE, SET_RESOURCE } from './action-types';
import { Resource, ResourceType } from '../../common/models/resource.model';
import { TDynamicSelectorAction } from './actions';

export interface DynamicSelectorState {
  resources: Resource[] | null;
  selectedResource: Resource | null;
  opened: boolean;
  requiredResource?: ResourceType[] | null | undefined;
  selectedResourceDomain: string | null;
}

const initialSelectedResource: Resource = JSON.parse(localStorage?.getItem('selectedResource') || '{}') || null;
const initialRequiredResource: ResourceType[] | null =
  (localStorage?.getItem('requiredResource')?.split(',') as ResourceType[]) || null;

const initialDynamicSelectorState: DynamicSelectorState = {
  resources: null,
  selectedResource: Object.keys(initialSelectedResource).length !== 0 ? initialSelectedResource : null,
  opened: false,
  requiredResource: initialRequiredResource,
  selectedResourceDomain: null,
};

const dynamicSelectorReducer = (state = initialDynamicSelectorState, action: TDynamicSelectorAction) => {
  switch (action.type) {
    case GET_RESOURCES:
      return {
        ...state,
        resources: action.payload,
      };
    case SET_RESOURCE:
      return {
        ...state,
        selectedResource: action.payload,
      };
    case SET_OPENED:
      return {
        ...state,
        opened: action.payload,
      };
    case SET_REQUIRED_RESOURCE:
      return {
        ...state,
        requiredResource: action.payload,
      };
    case GET_RESOURCE_BY_ID:
      return {
        ...state,
        selectedResource: action.payload,
      };
    default:
      return state;
  }
};

export default dynamicSelectorReducer;
