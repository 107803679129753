import { MPMRocChart } from '../../../common/models/interfaces';
import React, { Suspense, lazy } from 'react';
const DynamicPlotlyChart = lazy(() => import('../../DynamicPlotlyChart'));

interface TRocCurveProps {
  chart: MPMRocChart;
}

function RocCurve({ chart }: TRocCurveProps) {
  const { tpr, fpr, class_names, roc_auc_score } = chart;

  const finalData =
    tpr &&
    class_names &&
    fpr &&
    tpr.map((tprArray, tprIndex) => {
      return {
        x: fpr[tprIndex],
        y: tprArray,
        mode: 'lines',
        name: `${class_names.length > 1 ? class_names[tprIndex] : ''} (AUC=${
          Math.round((roc_auc_score[tprIndex] + Number.EPSILON) * 100) / 100
        })`,
      };
    });

  if (!chart) return null;

  return (
    <div style={{ width: '50%' }}>
      <Suspense fallback={<>Loading...</>}>
        <DynamicPlotlyChart
          data={finalData}
          layout={{
            shapes: [{ type: 'line', line: { dash: 'dash' }, x0: 0, x1: 1, y0: 0, y1: 1 }],
            title: '',
            xaxis: {
              title: 'False Positive Rate',
              constrain: 'domain',
            },
            yaxis: {
              title: 'True Positive Rate',
              scaleanchor: 'x',
              scaleratio: 1,
            },
            height: 380,
            autosize: true,
            margin: { t: 0, r: 0, b: 40, l: 40 },
            showlegend: true,
            legend: {
              x: 1,
              xanchor: 'auto',
              y: 1,
            },
          }}
          config={{ displaylogo: false, modeBarButtons: false, responsive: false }}
        />
      </Suspense>
    </div>
  );
}

export default RocCurve;
