import { CanvasContainer, LoadingSpinnerContainer } from './monthly-cost-report.styles';
import { Line } from 'react-chartjs-2';
import { LoadingSpinner } from '../../../components/loading-spinner/loading-spinner';
import { SummaryReport } from '../summary-report/summary-report';
import React from 'react';

interface MonthlyCostReportProps {
  data: number[];
  budgetLimit: number | null;
  isLoading: boolean;
}

export const MonthlyCostReport: React.FC<MonthlyCostReportProps> = (props) => {
  const calculateActualSpending = (): number | null => {
    return props.data[props.data.length - 1] ?? null;
  };

  const calculateSpendingRatio = (): number | null => {
    const actualSpending = calculateActualSpending();
    if (typeof actualSpending === 'number' && typeof props.budgetLimit === 'number') {
      return Math.round((actualSpending / props.budgetLimit) * 100);
    }

    return null;
  };

  return (
    <>
      <CanvasContainer>
        {props.isLoading && (
          <LoadingSpinnerContainer>
            <LoadingSpinner />
          </LoadingSpinnerContainer>
        )}
        <Line
          data={{
            labels: Array.from({ length: 31 }, (_, i) => `${i + 1}`),
            datasets: [
              {
                label: 'Monthly Cost Report',
                data: props.data,
                borderColor: '#8CAC9C',
                borderWidth: 3,
                fill: false,
              },
            ],
          }}
          options={{
            maintainAspectRatio: false,

            plugins: {
              legend: {
                display: false,
              },
            },
            scales: {
              y: {
                beginAtZero: true,
              },
              x: {
                grid: {
                  drawOnChartArea: false,
                },
              },
            },
          }}
        />
      </CanvasContainer>
      <SummaryReport
        totalBudget={props.budgetLimit}
        actualSpending={calculateActualSpending()}
        spendingRatio={calculateSpendingRatio()}
      />
    </>
  );
};
