import { Button, Card } from '@material-ui/core';
import { CardContainer } from '../../common/styles/common.styles';
import { Label, NotFoundContainer } from './not-found.styles';
import { path } from '../../routes/path';
import { useNavigate } from 'react-router-dom';
import React from 'react';

export const NotFound: React.FC = () => {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(path.reports());
  };

  return (
    <CardContainer>
      <Card>
        <NotFoundContainer>
          <Label>404 - Not Found</Label>
          <Button variant="contained" color="primary" onClick={onClick}>
            go back to Reports
          </Button>
        </NotFoundContainer>
      </Card>
    </CardContainer>
  );
};
