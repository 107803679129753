import { Autocomplete } from '@material-ui/lab';
import { Button, CircularProgress, TextField } from '@material-ui/core';
import { EnvironmentType, ToastType } from '../../common/models/enums';
import { Project, Repository } from '../../common/models/workspace.models';
import { createToasterNotification } from '../../common/utils/toaster-notification';
import { getWorkspaceById } from '../../store/workspaces/actions';
import { path } from '../../routes/path';
import { urls } from '../../common/utils/urls';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useSelector } from '../../store/configure-store';
import React, { useCallback, useEffect, useState } from 'react';
import api from '../../common/utils/api';

function DeployPipeline() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const workspaceDetails = useSelector((state) => state.workspaces.selectedWorkspace);
  const selectedResource = useSelector((state) => state.dynamicSelector.selectedResource);
  const [projects, setProjects] = useState<Project[]>([]);
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);
  const [workspaceId, setWorkspaceId] = useState<string>('');
  const [provisionsLoading, setProvisionsLoading] = useState(false);

  useEffect(() => {
    if (workspaceDetails) {
      setProjects(workspaceDetails?.projects);
      setWorkspaceId(workspaceDetails?.id);
      setSelectedProject(null);
    }
  }, [workspaceDetails]);

  const handleOnSelectProject = useCallback((project: Project | null) => {
    setSelectedProject(project);
  }, []);

  function environmentType(envType: EnvironmentType) {
    return envType?.charAt(0).toUpperCase() + envType?.slice(1).toLowerCase();
  }

  function getOptionLabel(option: Project) {
    return [environmentType(option.environmentType), ' (', option.name, ')'].join('');
  }

  const onDeploy = () => {
    setProvisionsLoading(true);
    api
      .post<Repository>(urls.provision(workspaceId, selectedProject?.projectId))
      .then(() => {
        setProvisionsLoading(false);
        navigate(path.provisioning());
        dispatch(createToasterNotification('Deploy has been successfully started', ToastType.SUCCESS));
      })
      .catch((e) => {
        setProvisionsLoading(false);
        dispatch(createToasterNotification(e));
      });
  };

  useEffect(() => {
    selectedResource && dispatch(getWorkspaceById(selectedResource.id));
  }, [selectedResource, dispatch]);

  useEffect(() => {
    if (projects && projects.length && !selectedProject) {
      handleOnSelectProject(projects[0]);
    }
  }, [projects, handleOnSelectProject, selectedProject]);

  return (
    <div style={{ display: 'flex', gap: '12px' }}>
      <Autocomplete
        value={selectedProject}
        options={projects ?? []}
        renderInput={(params) => <TextField {...params} label="Select environment" variant="outlined" fullWidth />}
        getOptionLabel={getOptionLabel}
        onChange={(event, project) => handleOnSelectProject(project ?? null)}
        style={{ width: '300px' }}
        getOptionSelected={(option, value) => option.projectId === value.projectId}
        size="small"
      />
      <Button
        variant="contained"
        color="primary"
        disabled={!selectedProject || !selectedProject.provisioningEnabled || provisionsLoading}
        onClick={() => onDeploy()}
      >
        {provisionsLoading && (
          <CircularProgress style={{ width: '25px', height: '25px', marginRight: '5px' }} color="primary" />
        )}
        Deploy
      </Button>
    </div>
  );
}

export default DeployPipeline;
