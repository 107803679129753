import { AppDrawer } from '../containers';
import { AppState } from '../common/models/interfaces';
import { BlueprintDashboard } from '../pages/blueprint-dashboard/blueprint-dashboard';
import { LoginButton } from '../components';
import { NotFound } from '../pages/not-found/not-found';
import { Route, Routes } from 'react-router-dom';
import { Toaster } from '../components/toaster/toaster';
import { path } from './path';
import { useSelector } from '../store/configure-store';
import React from 'react';
import Sign from '../pages/sign/sign';
import TrialSignUp from '../pages/TrialSignUp/TrialSignUp';
import routeConfig from './config';

export const Routing = () => {
  const isLoggedInByGoogle = useSelector(({ auth: { data } }: AppState) => !!data);
  const currentUserState = useSelector((state) => state.currentUser);

  const isAdmin = true; // TODO remove this later

  return (
    <>
      <Toaster />
      {isLoggedInByGoogle && !!currentUserState.me.id ? (
        <AppDrawer>
          <div style={{ display: 'none' }}>
            <LoginButton keepSignIn={true} />
          </div>
          <Routes>
            {routeConfig.map((route, index) => {
              const { component: Component } = route;
              if (route.precondition) {
                if (route.precondition(isAdmin)) {
                  return (
                    <Route
                      key={index}
                      path={route.path}
                      element={
                        <Component
                          requiredResource={route?.requiredResource}
                          titleAction={route?.titleAction}
                          title={route?.title}
                        />
                      }
                    />
                  );
                } else {
                  return null;
                }
              } else {
                return (
                  <Route
                    key={index}
                    path={route.path}
                    element={
                      <Component
                        requiredResource={route?.requiredResource}
                        titleAction={route?.titleAction}
                        title={route?.title}
                      />
                    }
                  />
                );
              }
            })}

            <Route path="/" element={<BlueprintDashboard />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </AppDrawer>
      ) : (
        <Routes>
          <Route path={path.root()} element={<Sign />} />
          <Route path={path.rootSignUp()} element={<Sign />} />
          <Route path={path.rootTrialSignUp()} element={<TrialSignUp />} />
        </Routes>
      )}
    </>
  );
};
