import * as S from './styles';
import { ModelPlotTypes } from '../../common/models/enums';
import ModelPerformanceChart from '../ModelPerformanceChart';
import ModelPerformanceContext from '../../pages/ModelPerformance/ModelPerformanceContext';
import NoModelSelected from '../NoModelSelected/NoModelSelected';
import PlotDateSelector from '../PlotDateSelector';
import PlotTypeSelector from '../PlotTypeSelector';
import React, { useContext, useState } from 'react';

function ModelPerformanceTab() {
  const { modelId } = useContext(ModelPerformanceContext);
  const [selectedPlotType, setSelectedPlotType] = useState<ModelPlotTypes>(ModelPlotTypes.ROC_CURVE);
  const [selectedDate, setSelectedDate] = useState<string>();
  const [selectedReferenceDate, setSelectedReferenceDate] = useState<string>();

  if (!modelId) return <NoModelSelected />;

  return (
    <S.MainWrapper>
      <S.SelectorsSection>
        <PlotTypeSelector selectedPlotType={selectedPlotType} setSelectedPlotType={setSelectedPlotType} />
        <PlotDateSelector
          selectedPlotType={selectedPlotType}
          setSelectedDate={setSelectedDate}
          selectedDate={selectedDate}
          isReferenceDate={false}
          label="Date"
        />
        <PlotDateSelector
          selectedPlotType={selectedPlotType}
          setSelectedDate={setSelectedReferenceDate}
          selectedDate={selectedReferenceDate}
          isReferenceDate={true}
          label="Reference Date"
        />
      </S.SelectorsSection>
      <S.ChartsSection>
        <ModelPerformanceChart
          selectedDate={selectedDate}
          selectedPlotType={selectedPlotType}
          selectedReferenceDate={selectedReferenceDate}
        />
      </S.ChartsSection>
    </S.MainWrapper>
  );
}

export default ModelPerformanceTab;
