import * as S from './styles';
import { AlertIncident, AlertIncidentTableCell } from '../../common/models/interfaces';
import { AlertIncidentStatuses } from '../../common/models/enums';
import { TableHeadCellType } from '../Table/TableHead/interfaces';
import { capitalize } from '../../common/utils/string';
import { getDate } from '../../common/utils/date-formatter';
import { getIncidents } from './requests';
import { resolveMetricTypeLable } from '../AlertPolicies/dictionary';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import React, { useCallback, useEffect, useState } from 'react';
import Table from '../Table/Table';

interface TAlertIncidentsProps {
  domainId: string | undefined;
  modelId: string;
}

type TableHeadCell<T> = {
  id: keyof T | string;
  label?: string;
  type?: TableHeadCellType;
  [property: string]: unknown;
};

const headCells: TableHeadCell<AlertIncidentTableCell>[] = [
  { id: 'status', label: 'Status' },
  { id: 'createdAt', label: 'Date' },
  { id: 'policyName', label: 'Policy Name' },
  { id: 'actualValue', label: 'Value' },
  { id: 'severity', label: 'Alert Severity' },
  { id: 'metricType', label: 'Alert Type' },
];

const renderCell = (
  colName: keyof AlertIncidentTableCell | string,
  _rowIndex: number,
  item: AlertIncidentTableCell,
): React.ReactNode => {
  switch (colName) {
    case 'createdAt':
      return getDate(new Date(item[colName]));
    case 'status':
      switch (item[colName]) {
        case AlertIncidentStatuses.ACTIVE:
          return <ErrorOutlineIcon style={{ fill: '#F7C220' }} />;
        case AlertIncidentStatuses.RESOLVED:
          return <CheckCircleIcon style={{ fill: '#37D083' }} />;
        default:
          return item[colName];
      }
    case 'severity':
      return capitalize(item[colName]);
    case 'metricType':
      return resolveMetricTypeLable(item[colName]);
    default:
      return item[colName];
  }
};

const AlertIncidents: React.FC<TAlertIncidentsProps> = ({ domainId, modelId }) => {
  const handleGetIncidents = useCallback(getIncidents, []);
  const [incidents, setIncidents] = useState<AlertIncident[]>([]);
  const [isIncidentLoading, setIsIncidentLoading] = useState(false);

  useEffect(() => {
    if (domainId && modelId)
      handleGetIncidents(
        domainId,
        modelId,
        (incidentList) => {
          setIncidents(incidentList);
          setIsIncidentLoading(false);
        },
        () => setIsIncidentLoading(true),
      );
  }, [handleGetIncidents, domainId, modelId]);

  return (
    <S.IncidentsWrapper>
      <S.IncidentTitle>Incidents ({incidents.length})</S.IncidentTitle>
      <Table
        headCells={headCells}
        renderCell={renderCell}
        data={incidents as AlertIncidentTableCell[]}
        rowKey="mpmAlertIncidentId"
        isLoading={isIncidentLoading}
        refresh={() => {
          if (domainId && modelId)
            handleGetIncidents(
              domainId,
              modelId,
              (incidentList) => {
                setIncidents(incidentList);
                setIsIncidentLoading(false);
              },
              () => setIsIncidentLoading(true),
            );
        }}
        withTopActions={true}
      />
    </S.IncidentsWrapper>
  );
};

export default AlertIncidents;
