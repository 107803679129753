import { CardContainer } from '../../common/styles/common.styles';
import { ResourceTypes } from '../../common/models/resource.model';
import { Step } from 'react-joyride';
import { Template } from '../../common/models/workspace.models';
import { TemplateSearch } from '../../components/template-search/template-search';
import { Title, TitleAction } from '../../routes/config';
import { path } from '../../routes/path';
import { setRequiredResource } from '../../store/dynamic-selector/actions';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import RouteWithHeader from '../../containers/route-with-header/route-with-header';
import Tour from '../../components/Tour';

export const BlueprintDashboard: React.FC<{ title?: Title; titleAction?: TitleAction }> = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const goToTemplateDetails = (template: Template): void => {
    navigate(path.blueprintDetail(template.id));
  };

  const newInstance = (): void => {
    navigate(path.blueprintDashboardNewInstance());
  };

  const steps = [
    {
      target: '.tour',
      disableBeacon: true,
      placement: 'left-start',
      showSkipButton: true,
      content: 'Here you can find the list of blueprints',
    },
    {
      target: '.tour2',
      showSkipButton: true,
      content: 'You can create new instance by click here',
    },
    {
      target: '.upload-blurpint-btn',
      showSkipButton: true,
      content: 'You can upload your custom blueprint by click here.',
    },
  ];

  useEffect(() => {
    dispatch(setRequiredResource([ResourceTypes.DOMAIN, ResourceTypes.WORKSPACE]));
  }, [dispatch]);

  return (
    <RouteWithHeader titleAction={props.titleAction} title={props?.title}>
      <Tour type={'BLUEPRINT_WIZARD_DASHBOARD'} steps={steps as Step[]} />
      <CardContainer>
        <TemplateSearch
          buttonText={'details'}
          showNewInstance={true}
          onNewInstanceClick={() => newInstance()}
          onNewInstanceCardClick={() => newInstance()}
          onActionClick={goToTemplateDetails}
          onCardClick={goToTemplateDetails}
        />
      </CardContainer>
    </RouteWithHeader>
  );
};
