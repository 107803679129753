import { AlertLogsResponse } from './models/alert-log.models';
import { Card } from '@material-ui/core';
import { CardContainer } from '../../common/styles/common.styles';
import { Table } from '../../components';
import { createToasterNotification } from '../../common/utils/toaster-notification';
import { getWorkspaceById } from '../../store/workspaces/actions';
import { headCells, renderCell } from './table-config/table-config';
import { urls } from '../../common/utils/urls';
import { useDispatch } from 'react-redux';
import { useQuery } from 'react-query';
import { useSelector } from '../../store/configure-store';
import React, { useCallback, useEffect } from 'react';
import api from '../../common/utils/api';
import withResources from '../../components/withResources/withResources';

export const AlertLogComp: React.FC = () => {
  const selectedResource = useSelector((state) => state.dynamicSelector.selectedResource);
  const workspaceDetails = useSelector((state) => state.workspaces.selectedWorkspace);
  const dispatch = useDispatch();

  const alertLogsQuery = useQuery<AlertLogsResponse>(
    `${!selectedResource?.id ? 'show-all' : selectedResource?.id}-alert-logs`,
    () => {
      if (selectedResource?.id && selectedResource?.id !== 'show-all') {
        return api.get(urls.getAlertLogsByWorkspaceId(selectedResource?.id));
      }
      return api.get(urls.getAlertLogs(workspaceDetails?.createdById || ''));
    },
    { cacheTime: 0 },
  );

  const handleFetchAlertLogs = useCallback(alertLogsQuery.refetch, []);

  useEffect(() => {
    if (alertLogsQuery.error) {
      dispatch(createToasterNotification(alertLogsQuery.error));
    }
  }, [alertLogsQuery.error, dispatch]);

  useEffect(() => {
    handleFetchAlertLogs();
  }, [selectedResource, handleFetchAlertLogs]);

  useEffect(() => {
    selectedResource && dispatch(getWorkspaceById(selectedResource.id));
  }, [selectedResource, dispatch]);

  return (
    <CardContainer>
      <Card>
        <Table
          headCells={headCells}
          data={alertLogsQuery.data?.content || []}
          refresh={handleFetchAlertLogs}
          renderCell={renderCell}
          isLoading={alertLogsQuery.isLoading}
          isSearchEnabled={false}
        />
      </Card>
    </CardContainer>
  );
};

export const AlertLog = withResources(AlertLogComp);
