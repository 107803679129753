import { TEditBlueprint } from '../../common/models/interfaces';
import { Template } from '../../common/models/workspace.models';
import { urls } from '../../common/utils/urls';
import api from '../../common/utils/api';

interface Options {
  cb?: (editableBlueprint: TEditBlueprint) => void;
}

interface SaveOptions {
  cb?: () => void;
}

async function getBlueprintById(templateId: string, options?: Options) {
  try {
    const editableTemplate = await api.get<Template>(urls.getTemplate(templateId));
    options?.cb?.({
      name: editableTemplate.name,
      description: editableTemplate.description,
      versions: editableTemplate.versions || [],
      terraformScriptDirectory: editableTemplate.terraformScriptDirectory,
      terraformEnvDirectory: editableTemplate.terraformEnvDirectory,
      templateCategories: editableTemplate.templateCategories,
    });
  } catch (e) {
    console.error(e);
  }
}

async function saveTemplate(payload: TEditBlueprint, templateId: string, options?: SaveOptions) {
  try {
    const resolvedPayload = {
      ...payload,
      templateCategories: payload.templateCategories.map((cat) => cat.id),
    };
    console.log({ resolvedPayload });
    await api.put(urls.getTemplate(templateId), resolvedPayload);
    options?.cb?.();
  } catch (e) {
    console.error(e);
  }
}

export { getBlueprintById, saveTemplate };
