import * as S from './styles';
import { AlertPolicy, AlertPolicyDTO } from '../../common/models/interfaces';
import { AlertPolicyConditions } from 'src/common/models/enums';
import { Button } from '@material-ui/core';
import { Table } from '../../components';
import { TableHeadCellType } from '../Table/TableHead/interfaces';
import { TableHeaderCtaContainer } from '../../pages/workspaces/workspaces.styles';
import { createPolicy, deletePolicy, editPolicy, getPolicies } from './requests';
import { resolveConditionLabel, resolveMetricTypeLable } from './dictionary';
import AlertPolicyForm from '../Forms/AlertPolicyForm';
import GeneralModal from '../GeneralModal';
import GeneralModalContext, { initialContextData } from '../GeneralModal/GeneralModalContext';
import React, { useCallback, useEffect, useState } from 'react';

interface TAlertPolicies {
  domainId: string | undefined;
  modelId: string;
}

type TableHeadCell<T> = {
  id: keyof T | string;
  label?: string;
  type?: TableHeadCellType;
  [property: string]: unknown;
};

const headCells: TableHeadCell<AlertPolicy>[] = [
  { id: 'policyName', label: 'Name' },
  { id: 'value', label: 'Value' },
  { id: 'metricType', label: 'Metric Type' },
  { id: 'condition', label: 'Condition' },
  { id: 'conditionWindow', label: 'Condition Window' },
  { id: 'conditionWindowSize', label: 'Condition Window Size' },
  { id: 'menu', type: 'menu' },
];

const hasPolicyExtraParams = (condition: AlertPolicyConditions) => {
  const hasArray = ['INCREASED_BY', 'DECREASED_BY', 'CHANGED_BY'];
  if (hasArray.includes(condition)) {
    return true;
  }
  return false;
};

const AlertPolicies: React.FC<TAlertPolicies> = ({ domainId, modelId }) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [gridData, setGridData] = useState<AlertPolicy[]>([]);
  const [selectedRowId, setSelectedRowId] = useState<string | null>(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [policy, setPolicy] = useState<AlertPolicyDTO>();
  const [isGridLoading, setIsGridLoading] = useState(false);

  const renderCell = (colName: keyof AlertPolicy | string, _rowIndex: number, item: AlertPolicy): React.ReactNode => {
    switch (colName) {
      case 'metricType':
        return resolveMetricTypeLable(item[colName]);
      case 'condition':
        return resolveConditionLabel(item[colName]);
      default:
        return item[colName];
    }
  };

  const handleGetPolicies = useCallback(getPolicies, []);

  useEffect(() => {
    if (domainId && modelId) {
      handleGetPolicies(
        domainId,
        modelId,
        (policies) => {
          setGridData(policies);
          setIsGridLoading(false);
        },
        () => setIsGridLoading(true),
      );
    }
  }, [handleGetPolicies, domainId, modelId]);

  const createPolicyAction = () => {
    if (domainId && policy) {
      const originalPolicy = { ...policy };

      if (!hasPolicyExtraParams(policy.condition)) {
        delete originalPolicy?.conditionWindow;
        delete originalPolicy?.conditionWindowSize;
      }

      createPolicy(domainId, modelId, originalPolicy, () => {
        setModalIsOpen(false);
        handleGetPolicies(
          domainId,
          modelId,
          (policies) => {
            setGridData(policies);
            setIsGridLoading(false);
          },
          () => setIsGridLoading(true),
        );
      });
    }
  };

  const editPolicyAction = () => {
    if (domainId && policy && selectedRowId)
      editPolicy(domainId, modelId, { ...policy, mpmAlertPolicyId: selectedRowId }, () => {
        setModalIsOpen(false);
        handleGetPolicies(
          domainId,
          modelId,
          (policies) => {
            setGridData(policies);
            setIsGridLoading(false);
          },
          () => setIsGridLoading(true),
        );
      });
  };

  const deletePolicyAction = () => {
    if (domainId && selectedRowId)
      deletePolicy(
        domainId,
        modelId,
        selectedRowId,
        () => {
          setIsDeleteModalOpen(false);
          handleGetPolicies(
            domainId,
            modelId,
            (policies) => {
              setGridData(policies);
              setIsGridLoading(false);
            },
            () => setIsGridLoading(true),
          );
        },
        () => setIsGridLoading(true),
      );
  };

  const deleteContext = {
    ...initialContextData,
    modalIsOpen: Boolean(isDeleteModalOpen && selectedRowId),
    setModalIsOpen: setIsDeleteModalOpen,
    primaryText: 'Delete',
    title: 'Delete policy',
    onCloseCb: () => {
      setSelectedRowId(null);
    },
    onSubmit: () => {
      deletePolicyAction();
    },
  };

  const createAndEditContext = {
    ...initialContextData,
    modalIsOpen,
    setModalIsOpen,
    title: `${selectedRowId ? 'Edit' : 'Create'} Alert Policy`,
    onCloseCb: () => {
      setTimeout(() => setSelectedRowId(null), 500);
    },
    onSubmit: () => {
      if (!selectedRowId) {
        createPolicyAction();
      } else {
        editPolicyAction();
      }
    },
    isForm: true,
    primaryText: selectedRowId ? 'Edit' : 'Save',
  };

  return (
    <S.ActionPoliciesWrapper>
      <S.PolicyTitle>Alert Policies ({gridData.length})</S.PolicyTitle>
      <Table
        headCells={headCells}
        renderCell={renderCell}
        data={gridData}
        rowKey="mpmAlertPolicyId"
        isLoading={isGridLoading}
        refresh={() => {
          if (domainId && modelId)
            handleGetPolicies(
              domainId,
              modelId,
              (policies) => {
                setGridData(policies);
                setIsGridLoading(false);
              },
              () => {
                setIsGridLoading(true);
              },
            );
        }}
        topButton={
          <TableHeaderCtaContainer>
            <Button onClick={() => setModalIsOpen(true)} color="primary" variant="contained">
              Create new policy
            </Button>
          </TableHeaderCtaContainer>
        }
        withTopActions={true}
        tableMenuActions={[
          {
            label: 'Edit',
            onClick: (id) => {
              setSelectedRowId(id);
              setModalIsOpen(true);
            },
          },
          {
            label: 'Delete',
            onClick: (id) => {
              setSelectedRowId(id);
              setIsDeleteModalOpen(true);
            },
          },
        ]}
      />

      <GeneralModalContext.Provider value={createAndEditContext}>
        <GeneralModal>
          <AlertPolicyForm
            edit={gridData.find((data) => data.mpmAlertPolicyId === selectedRowId)}
            onChange={(newPolicy) => setPolicy(newPolicy)}
          />
        </GeneralModal>
      </GeneralModalContext.Provider>

      <GeneralModalContext.Provider value={deleteContext}>
        <GeneralModal>
          Are you sure you want do delete this policy? <br />
          <div style={{ fontWeight: 'bold', marginTop: '6px' }}>
            {gridData.find((data) => data.mpmAlertPolicyId === selectedRowId)?.policyName}
          </div>
        </GeneralModal>
      </GeneralModalContext.Provider>
    </S.ActionPoliciesWrapper>
  );
};

export default AlertPolicies;
