import { AnyAction, Dispatch } from 'redux';
import { AppState } from '../../common/models/interfaces';
import { DeleteWorkspaceDto, DetailedWorkspace } from '../../common/models/workspace.models';
import { ThunkDispatch } from 'redux-thunk';
import { ToastType } from '../../common/models/enums';
import { createToasterNotification } from '../../common/utils/toaster-notification';
import { getResources, setResource } from '../dynamic-selector/actions';
import { urls } from '../../common/utils/urls';
import api from '../../common/utils/api';
export const GET_WORKSPACE_BY_ID = 'GET_WORKSPACE_BY_DOMAIN_ID';
export const GET_WORKSPACES = 'GET_WORKSPACES';
export const SET_SELECTED_WORKSPACE = 'SET_SELECTED_WORKSPACE';
export const SET_DELETE_IN_PROGRESS_WORKSPACE = 'SET_DELETE_IN_PROGRESS_WORKSPACE';
export const SET_WORKSPACES_IN_PROGRESS = 'SET_WORKSPACES_IN_PROGRESS';

export const getWorkspaceById = (workspaceId: string) => async (dispatch: Dispatch) => {
  try {
    const result = await api.get(urls.workspace(workspaceId));
    dispatch({
      type: GET_WORKSPACE_BY_ID,
      payload: result,
    });
  } catch (e) {
    console.log(e);
  }
};

export const setSelectedWorkspace = (workspace: DetailedWorkspace) => async (dispatch: Dispatch) => {
  try {
    dispatch({
      type: SET_SELECTED_WORKSPACE,
      payload: workspace,
    });
  } catch (e) {
    console.log(e);
  }
};

export const setWorkspacesInProgress = (status: boolean) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_WORKSPACES_IN_PROGRESS,
    payload: status,
  });
};

export const getWorkspaces = (domainId: string) => async (dispatch: ThunkDispatch<AppState, unknown, AnyAction>) => {
  try {
    dispatch(setWorkspacesInProgress(true));
    const result = await api.get(urls.workspaces(domainId));
    dispatch({
      type: GET_WORKSPACES,
      payload: result,
    });
    dispatch(setWorkspacesInProgress(false));
  } catch (e) {
    console.log(e);
    dispatch(setWorkspacesInProgress(false));
  }
};

export const setDeleteInProgressWorkspace = (status: boolean) => async (dispatch: Dispatch) => {
  dispatch({
    type: SET_DELETE_IN_PROGRESS_WORKSPACE,
    payload: status,
  });
};

export const deleteWorkspace = (workspaceId: string | null, payload: DeleteWorkspaceDto | null) => async (
  dispatch: ThunkDispatch<AppState, unknown, AnyAction>,
  getState: () => AppState,
) => {
  if (!workspaceId || !payload) return false;
  try {
    dispatch(setDeleteInProgressWorkspace(true));
    await api.del(urls.deleteWorkspace(workspaceId), { data: payload });
    dispatch(setDeleteInProgressWorkspace(false));
    dispatch(createToasterNotification('Successfully deleted the workspace', ToastType.SUCCESS));
    dispatch(getResources());
    const selectedResource = getState().dynamicSelector.selectedResource;
    if (workspaceId === selectedResource?.id) {
      dispatch(setResource(null));
    }
    dispatch(getWorkspaces(selectedResource?.domainId || ''));
  } catch (e) {
    dispatch(createToasterNotification(e));
    dispatch(setDeleteInProgressWorkspace(false));
  }
};

interface GetWorkspacesByDomainId {
  type: typeof GET_WORKSPACE_BY_ID;
  payload: DetailedWorkspace;
}

interface GetWorkspaces {
  type: typeof GET_WORKSPACES;
  payload: any;
}

interface SetSelectedWorkspace {
  type: typeof SET_SELECTED_WORKSPACE;
  payload: DetailedWorkspace;
}

interface SetDeleteInProgressWorkspace {
  type: typeof SET_DELETE_IN_PROGRESS_WORKSPACE;
  payload: boolean;
}

interface SetWorkspacesInProgress {
  type: typeof SET_WORKSPACES_IN_PROGRESS;
  payload: boolean;
}

export type TWorkspacesActions =
  | GetWorkspacesByDomainId
  | GetWorkspaces
  | SetSelectedWorkspace
  | SetDeleteInProgressWorkspace
  | SetWorkspacesInProgress;
