import { Button, TextField } from '@material-ui/core';
import { DetailedWorkspace } from '../../../common/models/workspace.models';
import {
  InformationContainer,
  InputContainer,
  Label,
  SaveChangesContainer,
  WorkspaceInformationContainer,
} from './workspace-details-information.styles';
import { Resource } from '../../../common/models/resource.model';
import { ToastType } from '../../../common/models/enums';
import { createToasterNotification } from '../../../common/utils/toaster-notification';
import { getResourceById, getResources, setResource } from '../../../store/dynamic-selector/actions';
import { getWorkspaceById } from '../../../store/workspaces/actions';
import { path } from '../../../routes/path';
import { urls } from '../../../common/utils/urls';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useSelector } from '../../../store/configure-store';
import React, { useEffect, useState } from 'react';
import api from '../../../common/utils/api';

interface WorkspaceDetailsInformation {
  isNewWorkspace: boolean;
  workspace: DetailedWorkspace | null;
  editable: boolean;
  showCancel: boolean;
  onCancel: () => void;
}

export const WorkspaceDetailsInformation: React.FC<WorkspaceDetailsInformation> = (props) => {
  const [workspaceName, setWorkspaceName] = useState(props.workspace?.name ?? '');
  const [workspaceDescription, setWorkspaceDescription] = useState(props.workspace?.description ?? '');
  const selectedResource = useSelector((state) => state.dynamicSelector.selectedResource);

  const domainId = selectedResource?.domainId || '';

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    setWorkspaceName(props.workspace?.name ?? '');
    setWorkspaceDescription(props.workspace?.description ?? '');
  }, [props.workspace]);

  const onSave = () => {
    const payload = {
      id: props.workspace?.id,
      name: workspaceName,
      description: workspaceDescription,
    };

    api
      .post<Resource>(urls.workspaceBaseData(domainId), payload)
      .then((resource) => {
        resource &&
          dispatch(
            setResource({
              id: resource.id,
              name: resource.name,
              type: resource.type,
              children: resource.children,
              domainId: resource.domainId,
            }),
          );
        if (props.isNewWorkspace) {
          dispatch(createToasterNotification('Successfully created a new workspace', ToastType.SUCCESS));
          navigate(path.workspaces());
        } else {
          dispatch(createToasterNotification('Successfully updated the workspace', ToastType.SUCCESS));

          if (payload?.id === selectedResource?.id) {
            dispatch(getResourceById(payload?.id || null));
          }

          selectedResource && dispatch(getWorkspaceById(selectedResource.id));

          if (!props.showCancel) {
            navigate(path.workspaces());
          } else {
            props.onCancel();
          }
        }
        dispatch(getResources());
      })
      .catch((e) => {
        dispatch(createToasterNotification(e));
      });
  };

  return (
    <>
      <InformationContainer>
        <InputContainer>
          <Label>Basic information</Label>
          <WorkspaceInformationContainer>
            <TextField
              label="Workspace name"
              variant="outlined"
              color="primary"
              disabled={!props.editable}
              size="small"
              value={workspaceName}
              onChange={(e) => setWorkspaceName(e.target.value)}
            />
            <TextField
              label="Workspace description"
              variant="outlined"
              rows={10}
              disabled={!props.editable}
              color="primary"
              size="small"
              multiline={true}
              value={workspaceDescription}
              onChange={(e) => setWorkspaceDescription(e.target.value)}
            />
          </WorkspaceInformationContainer>
        </InputContainer>
      </InformationContainer>
      {props.editable && (
        <SaveChangesContainer>
          {props.showCancel && (
            <Button color="inherit" className={'default-color'} onClick={() => props.onCancel()}>
              Cancel
            </Button>
          )}
          <Button color="primary" onClick={() => onSave()}>
            Save changes
          </Button>
        </SaveChangesContainer>
      )}
    </>
  );
};
