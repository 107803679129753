import { MPMPrecisionRecallChart } from '../../../common/models/interfaces';
import React, { Suspense, lazy } from 'react';
const DynamicPlotlyChart = lazy(() => import('../../DynamicPlotlyChart'));

interface TRocCurveProps {
  chart: MPMPrecisionRecallChart;
}

function PrecisionRecallCurve({ chart }: TRocCurveProps) {
  const { precision, recall, class_names, average_precision_score } = chart;

  const finalData =
    precision &&
    class_names &&
    recall &&
    precision.map((precisionArray, precIndex) => {
      return {
        x: recall[precIndex],
        y: precisionArray,
        mode: 'lines',
        name: `${class_names.length > 1 ? class_names[precIndex] : ''} (AP=${
          Math.round((average_precision_score[precIndex] + Number.EPSILON) * 100) / 100
        })`,
      };
    });

  if (!chart) return null;

  return (
    <div style={{ width: '50%' }}>
      <Suspense fallback={<div>Loading...</div>}>
        <DynamicPlotlyChart
          data={finalData}
          layout={{
            shapes: [{ type: 'line', line: { dash: 'dash' }, x0: 0, x1: 1, y0: 1, y1: 0 }],
            title: '',
            xaxis: {
              title: 'Recall',
              constrain: 'domain',
            },
            yaxis: {
              title: 'Precision',
              scaleanchor: 'x',
              scaleratio: 1,
            },
            height: 380,
            autosize: true,
            margin: { t: 0, r: 0, b: 40, l: 40 },
            showlegend: true,
            legend: {
              x: 1,
              xanchor: 'auto',
              y: 1,
            },
          }}
          config={{ displaylogo: false, modeBarButtons: false, responsive: false }}
        />
      </Suspense>
    </div>
  );
}

export default PrecisionRecallCurve;
