import { Button, Card } from '@material-ui/core';
import { HalfCardContainer } from '../../common/styles/common.styles';
import { Label } from './information/workspace-details-information.styles';
import { LabelRow, WorkspaceDetailsContainer } from './workspace-details.styles';
import { WorkspaceDetailsInformation } from './information/workspace-details-information';
import { getWorkspaceById } from '../../store/workspaces/actions';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useSelector } from '../../store/configure-store';
import React, { useEffect, useState } from 'react';
import withResources from '../../components/withResources/withResources';

export const WorkspaceDetailsComp: React.FC<{ force: boolean }> = (props) => {
  const selectedResource = useSelector((state) => state.dynamicSelector.selectedResource);
  const workspaceDetails = useSelector((state) => state.workspaces.selectedWorkspace);
  const dispatch = useDispatch();
  const isNewWorkspace = props?.force;
  const { workspaceId } = useParams() as any;
  const [editable, setEditable] = useState(Boolean(!Boolean(isNewWorkspace) && workspaceId));

  useEffect(() => {
    selectedResource && dispatch(getWorkspaceById(selectedResource.id));
  }, [selectedResource, dispatch]);

  useEffect(() => {
    if (props.hasOwnProperty('force')) {
      setEditable(!!props?.force);
    }
  }, [props.force, props]);

  useEffect(() => {
    if (workspaceId) dispatch(getWorkspaceById(workspaceId));
  }, [dispatch, workspaceId]);

  return (
    <WorkspaceDetailsContainer>
      <HalfCardContainer>
        <Card>
          <LabelRow>
            <Label>Basic information</Label>
            {!isNewWorkspace && selectedResource && !editable && (
              <Button onClick={() => setEditable(true)} color="primary" autoFocus>
                edit
              </Button>
            )}
          </LabelRow>
          <WorkspaceDetailsInformation
            editable={editable}
            showCancel={!isNewWorkspace && !workspaceId}
            onCancel={() => setEditable(false)}
            isNewWorkspace={isNewWorkspace && !workspaceDetails}
            workspace={!isNewWorkspace ? workspaceDetails : null}
          />
        </Card>
      </HalfCardContainer>
    </WorkspaceDetailsContainer>
  );
};

export const WorkspaceDetails = withResources(WorkspaceDetailsComp);
