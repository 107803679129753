import { CellPrimaryValue, CellSecondaryValue } from '../workspaces.styles';
import { TableHeadCell } from '../../../components/Table/TableHead';
import { Workspace } from '../models/workspaces.models';
import { get12HourFormat, getDate } from '../../../common/utils/date-formatter';
import React from 'react';

export const headCells: TableHeadCell<Workspace>[] = [
  { id: 'name', label: 'Name' },
  { id: 'description', label: 'Description' },
  { id: 'createdAt', label: 'Created' },
  { id: 'menu', type: 'menu' },
];

export const renderCell = (colName: keyof Workspace | string, _rowIndex: number, item: Workspace): React.ReactNode => {
  switch (colName) {
    case 'description': {
      const fullDescription = item[colName];
      const truncatedDescription =
        fullDescription?.length > 100 ? `${fullDescription.slice(0, 100).trim()}...` : fullDescription;
      return <CellPrimaryValue title={fullDescription}>{truncatedDescription}</CellPrimaryValue>;
    }
    case 'createdAt': {
      const d = new Date(item.createdAt);
      return (
        <>
          <CellPrimaryValue>{getDate(d)}</CellPrimaryValue>
          <CellSecondaryValue className="time">{get12HourFormat(d)}</CellSecondaryValue>
        </>
      );
    }
    default: {
      return <CellPrimaryValue>{item[colName]}</CellPrimaryValue>;
    }
  }
};
