import { ArrowBack } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import {
  BuildList,
  InformationContainer,
  InputContainer,
  InputRow,
  ProjectDetailRow,
  ProvisionDetailsContainer,
  RefreshContainer,
} from './provisioning.styles';
import { Button, Card, CircularProgress, TextField } from '@material-ui/core';
import { CardContainer, CardContent, Label, Row } from '../../common/styles/common.styles';
import { EnvironmentType, ToastType } from '../../common/models/enums';
import { Links } from '../../common/styles/common.styles';
import { LoadingSpinner } from '../../components/loading-spinner/loading-spinner';
import { Project, Provision, Repository } from '../../common/models/workspace.models';
import { ProvisioningDetails } from './provisioning-details/provisioning-details';
import { Refresh } from '../../assets';
import { createToasterNotification } from '../../common/utils/toaster-notification';
import { getWorkspaceById } from '../../store/workspaces/actions';
import { urls } from '../../common/utils/urls';
import { useDispatch } from 'react-redux';
import { useSelector } from '../../store/configure-store';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import ProvisioningRow from '../../components/ProvisioningRow/ProvisioningRow';
import React, { useCallback, useEffect, useState } from 'react';
import Tour from '../../components/Tour';
import api from '../../common/utils/api';
import withResources from '../../components/withResources/withResources';

const ProvisioningComp: React.FC = () => {
  const dispatch = useDispatch();
  const selectedResource = useSelector((state) => state.dynamicSelector.selectedResource);
  const workspaceDetails = useSelector((state) => state.workspaces.selectedWorkspace);
  const search = window.location.search;
  const sParams = new URLSearchParams(search);
  const provisioningId = sParams.get('provisioningId');
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);
  const [selectedProvisionId, setSelectedProvisionId] = useState<string | null | undefined>(provisioningId);
  const [provisions, setProvisions] = useState<Provision[] | null>([]);

  const [provisionsLoading, setProvisionsLoading] = useState(false);
  const [projects, setProjects] = useState<Project[]>([]);
  const [workspaceId, setWorkspaceId] = useState<string>('');

  const handleGetProvisions = useCallback(
    (project: Project | null) => {
      setProvisions(null);
      api
        .get<Provision[]>(urls.provision(workspaceId, project?.projectId))
        .then((response: Provision[]) => {
          setProvisions(response);
        })
        .catch((e) => {
          dispatch(createToasterNotification(e));
        });
    },
    [dispatch, workspaceId],
  );

  const handleOnSelectProject = useCallback(
    (project: Project | null) => {
      setSelectedProject(project);
      if (project?.provisioningEnabled) {
        handleGetProvisions(project);
      }
    },
    [handleGetProvisions],
  );

  const enableProvisioning = () => {
    setProvisionsLoading(true);
    api
      .post<Project>(urls.enableProvisioning(workspaceId, selectedProject?.projectId))
      .then((response: Project) => {
        workspaceDetails?.id && dispatch(getWorkspaceById(workspaceDetails?.id));

        const copiedProject: Project = { ...(selectedProject as Project) };
        copiedProject.provisioningEnabled = true;
        copiedProject.credential = response.credential;
        setSelectedProject(copiedProject);
        const project = projects.filter((value) => value.projectId === selectedProject?.projectId)[0];
        project.credential = response.credential;
        project.provisioningEnabled = true;

        setProvisionsLoading(false);
        handleGetProvisions(selectedProject);
        dispatch(createToasterNotification('Provisioning has been enabled successfully', ToastType.SUCCESS));
      })
      .catch((e) => {
        setProvisionsLoading(false);
        dispatch(createToasterNotification(e));
      });
  };

  const onDeploy = () => {
    setProvisionsLoading(true);
    api
      .post<Repository>(urls.provision(workspaceId, selectedProject?.projectId))
      .then(() => {
        setProvisionsLoading(false);
        dispatch(createToasterNotification('Deploy has been successfully started', ToastType.SUCCESS));
        handleGetProvisions(selectedProject);
      })
      .catch((e) => {
        setProvisionsLoading(false);
        dispatch(createToasterNotification(e));
      });
  };

  const detailsClickHandler = (provision: Provision | null) => {
    setSelectedProvisionId(provision?.provisionId);
  };

  useEffect(() => {
    if (workspaceDetails) {
      setProjects(workspaceDetails?.projects);
      setWorkspaceId(workspaceDetails?.id);
      setSelectedProject(null);
    }
  }, [workspaceDetails, workspaceId]);

  function getOptionLabel(option: Project) {
    return [environmentType(option.environmentType), ' (', option.name, ')'].join('');
  }

  function environmentType(envType: EnvironmentType) {
    return envType?.charAt(0).toUpperCase() + envType?.slice(1).toLowerCase();
  }

  useEffect(() => {
    selectedResource && dispatch(getWorkspaceById(selectedResource.id));
  }, [selectedResource, dispatch]);

  useEffect(() => {
    if (projects && projects.length && !selectedProject) {
      handleOnSelectProject(projects[0]);
    }
  }, [projects, handleOnSelectProject, selectedProject]);

  return (
    <>
      {!selectedProvisionId && (
        <CardContainer>
          <Card>
            <CardContent>
              <InformationContainer>
                <InputContainer>
                  <InputRow>
                    <Autocomplete
                      value={selectedProject}
                      options={projects ?? []}
                      renderInput={(params) => (
                        <TextField {...params} label="Select environment" variant="outlined" fullWidth />
                      )}
                      getOptionLabel={getOptionLabel}
                      onChange={(event, project) => handleOnSelectProject(project ?? null)}
                      style={{ width: '100%', maxWidth: 400 }}
                      getOptionSelected={(option, value) => option.projectId === value.projectId}
                      size="small"
                      className="select-project"
                    />
                    {selectedProject && selectedProject.provisioningEnabled && (
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={!selectedProject || provisionsLoading}
                        onClick={() => onDeploy()}
                      >
                        {provisionsLoading && (
                          <CircularProgress
                            style={{ width: '25px', height: '25px', marginRight: '5px' }}
                            color="primary"
                          />
                        )}
                        Deploy
                      </Button>
                    )}
                    {selectedProject && !selectedProject.provisioningEnabled && (
                      <>
                        <Tour
                          type="PROVISIONING_INIT"
                          steps={[
                            {
                              content:
                                'Select an environment, first enable the provisioning and after that you can deploy it. ',
                              target: '.select-project',
                              disableBeacon: true,
                              showSkipButton: true,
                              placement: 'left-start',
                            },
                          ]}
                        />
                        <Button
                          variant="contained"
                          color="primary"
                          className="enable-button"
                          disabled={!selectedProject || provisionsLoading}
                          onClick={() => enableProvisioning()}
                        >
                          {provisionsLoading && (
                            <CircularProgress
                              style={{ width: '25px', height: '25px', marginRight: '5px' }}
                              color="primary"
                            />
                          )}
                          Enable provisioning
                        </Button>
                      </>
                    )}
                  </InputRow>

                  {selectedProject && selectedProject.provisioningEnabled && (
                    <BuildList>
                      <RefreshContainer>
                        <Label className={'label'} style={{ fontSize: '1.3rem', marginTop: '1.2em' }}>
                          Provisions
                        </Label>
                        <Button
                          color="primary"
                          disabled={!selectedProject}
                          onClick={() => handleGetProvisions(selectedProject)}
                        >
                          <Refresh />
                        </Button>
                      </RefreshContainer>
                      {selectedProject && (
                        <ProjectDetailRow>
                          <p>
                            <Links
                              className={'withIcon'}
                              href={`https://console.cloud.google.com/home/dashboard?project=${selectedProject.projectId}`}
                              underline={'none'}
                              target="_blank"
                              rel="noopener"
                            >
                              {selectedProject?.projectId}
                              <OpenInNewIcon />
                            </Links>
                          </p>
                          <p>
                            Project number: <span>{selectedProject.projectNumber}</span>
                          </p>
                          <p>
                            Environment: <span>{environmentType(selectedProject.environmentType)}</span>
                          </p>
                        </ProjectDetailRow>
                      )}
                      {provisions?.map((provision: Provision) => (
                        <ProvisioningRow
                          key={provision.provisionId}
                          provision={provision}
                          workspaceId={workspaceId}
                          detailsClickHandler={detailsClickHandler}
                        />
                      ))}
                      {!provisions && <LoadingSpinner />}
                    </BuildList>
                  )}
                </InputContainer>
              </InformationContainer>
            </CardContent>
          </Card>
        </CardContainer>
      )}
      {selectedProvisionId && (
        <ProvisionDetailsContainer>
          <Row>
            <ArrowBack className={'back-btn'} onClick={() => detailsClickHandler(null)} />
            <Label className={'details-title'}>Build: {selectedProvisionId}</Label>
          </Row>
          <ProvisioningDetails provisionId={selectedProvisionId} workspaceId={workspaceId} />
        </ProvisionDetailsContainer>
      )}
    </>
  );
};

export const Provisioning = withResources(ProvisioningComp);
