import { AccessDenied } from '../pages/access-denied/access-denied';
import { AlertLog } from '../pages/alert-log/alert-log';
import { AppState } from '../common/models/interfaces';
import { BluePrintDetail } from '../pages/blueprint-dashboard/blueprint-detail/blue-print-detail';
import { BlueprintDashboard } from '../pages/blueprint-dashboard/blueprint-dashboard';
import { BudgetForm } from '../pages/budget-rules/budget-form/budget-form';
import { BudgetRules } from '../pages/budget-rules/budget-rules';
import { Configuration } from '../pages/configuration/configuration';
import { DeployBlueprints } from '../pages/deploy-blueprints/deploy-blueprints';
import { GcpProjects } from '../pages/gcp-projects/gcp-projects';
import { Iam } from '../pages/iam/iam';
import { Kubeflow } from '../pages/kubeflow/kubeflow';
import { Mlflow } from '../pages/mlflow/mlflow';
import { ModelServing } from '../pages/model-serving/model-serving';
import { MyTasks } from '../pages/mytasks/my-tasks';
import { NewInstance } from '../pages/blueprint-dashboard/new-instance/new-instance';
import { NotFound } from '../pages/not-found/not-found';
import { Provisioning } from '../pages/provisioning/provisioning';
import { Reports } from '../pages/reports/reports';
import { ResourceType, ResourceTypes } from '../common/models/resource.model';
import { Support } from '../pages/support/support';
import { WorkspaceDetails } from '../pages/workspace-details/workspace-details';
import { Workspaces } from '../pages/workspaces/workspaces';
import { path } from './path';
import EditBlueprint from '../pages/EditBlueprint';
import ManagedServiceAccess from '../pages/ManagedServiceAccess';
import ModelPerformance from '../pages/ModelPerformance/ModelPerformance';
import PipelineEditor from '../pages/PipelineEditor';
import React from 'react';
import Settings from '../pages/Settings';
import UpgradeBlueprint from '../pages/UpgradeBlueprint/upgradeBlueprint';
import UploadBlueprint from '../pages/UploadBlueprint/UploadBlueprint';

export type Title = string | TitleFunction;
type TitleFunction = ({ state, location }: { state?: AppState; location?: any }) => string;

export interface TitleAction {
  id: string;
  component: React.FC<any>;
}

interface RouteItem {
  title: Title;
  path: string;
  requiredResource?: ResourceType[];
  component: React.FC<any>;
  titleAction?: TitleAction;
  precondition?: (condition: boolean) => boolean;
}

const routes: RouteItem[] = [
  {
    title: 'Admin / IAM',
    path: path.users(),
    requiredResource: [ResourceTypes.DOMAIN, ResourceTypes.WORKSPACE],
    component: Iam,

    precondition: (condition: boolean) => condition,
  },
  {
    title: '',
    path: path.blueprintUsers(),
    requiredResource: [ResourceTypes.DOMAIN, ResourceTypes.WORKSPACE],
    component: Iam,
  },
  {
    title: 'Reports',
    path: path.reports(),
    requiredResource: [ResourceTypes.WORKSPACE],
    component: Reports,
  },
  {
    title: 'Alert log',
    path: path.alertLogs(),
    requiredResource: [ResourceTypes.WORKSPACE],
    component: AlertLog,
  },
  {
    title: 'Model Performance',
    path: path.modelPerformance(),
    requiredResource: [ResourceTypes.DOMAIN, ResourceTypes.WORKSPACE],
    component: ModelPerformance,
  },
  {
    title: 'Manage Workspaces',
    path: path.workspaces(),
    requiredResource: [ResourceTypes.DOMAIN, ResourceTypes.WORKSPACE],
    component: Workspaces,
  },
  {
    title: ({ location }) => {
      const params = (location?.search && new URLSearchParams(location?.search)) || null;
      return params?.get('force') ? 'Create workspace' : 'Workspace info';
    },
    path: path.createWorkspace(),
    requiredResource: [ResourceTypes.DOMAIN, ResourceTypes.WORKSPACE],
    component: WorkspaceDetails,
  },
  {
    title: 'Edit Workspace',
    path: path.editWorkspace(),
    requiredResource: [ResourceTypes.WORKSPACE],
    component: WorkspaceDetails,
  },
  {
    title: 'Create budget rule',
    path: path.createBudgetRule(),
    requiredResource: [ResourceTypes.WORKSPACE],
    component: BudgetForm,
  },
  {
    title: 'Edit budget',
    path: path.editBudgetRule(),
    requiredResource: [ResourceTypes.WORKSPACE],
    component: BudgetForm,
  },
  {
    title: 'Support',
    path: path.support(),
    component: Support,
  },
  {
    title: 'Not Found',
    path: path.notFound(),
    component: NotFound,
  },
  {
    title: 'My Tasks',
    path: path.myTask(),
    requiredResource: [ResourceTypes.WORKSPACE],
    component: MyTasks,
  },
  {
    title: 'Provisioning',
    path: path.provisioning(),
    requiredResource: [ResourceTypes.WORKSPACE],
    component: Provisioning,
  },
  {
    title: 'Configuration',
    path: path.configuration(),
    requiredResource: [ResourceTypes.WORKSPACE],
    component: Configuration,
  },
  {
    title: 'Budget rules',
    path: path.budgetRules(),
    requiredResource: [ResourceTypes.WORKSPACE],
    component: BudgetRules,
  },
  {
    title: 'Deploy Blueprints',
    path: path.deployBlueprints(),
    requiredResource: [ResourceTypes.WORKSPACE],
    component: DeployBlueprints,
  },
  {
    title: 'Upgrade Blueprint',
    path: path.upgradeBlueprint(),
    requiredResource: [ResourceTypes.WORKSPACE],
    component: UpgradeBlueprint,
  },
  {
    title: 'GCP Projects',
    path: path.gcpProjects(),
    requiredResource: [ResourceTypes.WORKSPACE],
    component: GcpProjects,
  },
  {
    title: 'Blueprint Dashboard',
    path: path.blueprintDashboard(),
    requiredResource: [ResourceTypes.DOMAIN, ResourceTypes.WORKSPACE],
    component: BlueprintDashboard,
  },
  {
    title: 'Blueprint dashboard / Create new instance',
    path: path.blueprintDashboardNewInstance(),
    requiredResource: [ResourceTypes.DOMAIN, ResourceTypes.WORKSPACE],
    component: NewInstance,
  },
  {
    title: 'Blueprint dashboard / Detail',
    path: path.blueprintDetail(),
    requiredResource: [ResourceTypes.DOMAIN, ResourceTypes.WORKSPACE],
    component: BluePrintDetail,
  },
  {
    title: 'Upload Blueprint',
    path: path.uploadBlueprint(),
    requiredResource: [ResourceTypes.DOMAIN, ResourceTypes.WORKSPACE],
    component: UploadBlueprint,
  },
  {
    title: 'Edit Blueprint',
    path: path.editBlueprint(),
    requiredResource: [ResourceTypes.DOMAIN, ResourceTypes.WORKSPACE],
    component: EditBlueprint,
  },
  {
    title: 'Experiments',
    path: path.mlflow(),
    requiredResource: [ResourceTypes.DOMAIN, ResourceTypes.WORKSPACE],
    component: Mlflow,
  },
  {
    title: 'Pipelines',
    path: path.kubeflow(),
    requiredResource: [ResourceTypes.DOMAIN, ResourceTypes.WORKSPACE],
    component: Kubeflow,
  },
  {
    title: '',
    path: path.managedServiceAccess(),
    requiredResource: [ResourceTypes.DOMAIN, ResourceTypes.WORKSPACE],
    component: ManagedServiceAccess,
  },
  {
    title: 'Model Serving',
    path: path.modelServing(),
    requiredResource: [ResourceTypes.DOMAIN, ResourceTypes.WORKSPACE],
    component: ModelServing,
  },
  {
    title: 'Settings',
    path: path.settings(),
    component: Settings,
  },
  {
    title: 'Pipeline Editor',
    path: path.pipelineEditor(),
    component: PipelineEditor,
    requiredResource: [ResourceTypes.WORKSPACE],
  },
  {
    title: 'Access Denied',
    path: path.accessDenied(),
    component: AccessDenied,
  },
];

export default routes;
