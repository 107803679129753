import {
  MPMCharts,
  MPMHistoryChartBase,
  MPMKSStatistic,
  MPMLiftPlot,
  MPMPrecisionRecallChart,
  MPMRocChart,
} from '../../common/models/interfaces';
import { ModelPlotTypes } from '../../common/models/enums';
import { Skeleton } from '@material-ui/lab';
import { Typography } from '@material-ui/core';
import { getChartByPlotType, getHistoryChart } from './requests';
import History from './charts/History';
import KSStatistic from './charts/KSStatistic';
import LiftHistory from './charts/LiftHistory';
import LiftPlot from './charts/LiftPlot';
import LiftTable from './charts/LiftTable';
import ModelPerformanceContext from '../../pages/ModelPerformance/ModelPerformanceContext';
import PrecisionRecallCurve from './charts/PrecisionRecallCurve';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import RocCurve from './charts/RocCurve';

interface TModelPerformanceChartProps {
  selectedDate: string | undefined;
  selectedReferenceDate: string | undefined;
  selectedPlotType: ModelPlotTypes;
}

function resolveChartByType(chartType: ModelPlotTypes, chartData: MPMCharts | undefined) {
  if (!chartData) return null;
  switch (chartType) {
    case ModelPlotTypes.ROC_CURVE:
      return <RocCurve chart={chartData as MPMRocChart} />;
    case ModelPlotTypes.PRECISION_RECALL_CURVE:
      return <PrecisionRecallCurve chart={chartData as MPMPrecisionRecallChart} />;
    case ModelPlotTypes.KOLMOGOROV_SMIRNOV_PLOT:
      return <KSStatistic chart={chartData as MPMKSStatistic} />;
    case ModelPlotTypes.LIFT_PLOT:
      return <LiftPlot chart={chartData as MPMLiftPlot} />;
    case ModelPlotTypes.LIFT_TABLE:
      return <LiftTable chart={chartData as MPMLiftPlot} />;
    default:
      return null;
  }
}

function ModelPerformanceChart({ selectedDate, selectedPlotType, selectedReferenceDate }: TModelPerformanceChartProps) {
  const { modelId, domainId } = useContext(ModelPerformanceContext);
  const handleGetChartByPlotType = useCallback(getChartByPlotType, [selectedPlotType, selectedDate, modelId, domainId]);
  const handleGetReferenceChartByPlotType = useCallback(getChartByPlotType, [
    selectedPlotType,
    selectedDate,
    modelId,
    domainId,
  ]);
  const [chartData, setChartData] = useState<MPMCharts>();
  const [historyChartData, setHistoryChartData] = useState<MPMHistoryChartBase[]>();
  const [referenceChartData, setReferenceChartData] = useState<MPMCharts>();
  const [isFetchingChart, setIsFetchingChart] = useState(false);
  const [isFetchingHistoryChart, setIsFetchingHistoryChart] = useState(false);
  const [isFetchingReferenceChart, setIsFetchingReferenceChart] = useState(false);

  const getChartByPlotTypeCallback = (newChartData: MPMCharts) => {
    setChartData(newChartData);
    setIsFetchingChart(false);
  };

  const getReferenceChartByPlotTypeCallback = (newChartData: MPMCharts) => {
    setReferenceChartData(newChartData);
    setIsFetchingReferenceChart(false);
  };

  const getHistoryChartCallback = (chart: MPMHistoryChartBase[]) => {
    chart && setHistoryChartData(chart);
    setIsFetchingHistoryChart(false);
  };

  const handleGetHistoryChart = useCallback(getHistoryChart, [domainId, modelId, selectedPlotType]);

  useEffect(() => {
    if (domainId && modelId && selectedPlotType)
      handleGetHistoryChart(domainId, modelId, selectedPlotType, {
        cb: getHistoryChartCallback,
        beforeAction: () => {
          setIsFetchingHistoryChart(true);
        },
      });
  }, [handleGetHistoryChart, domainId, modelId, selectedPlotType]);

  useEffect(() => {
    if (selectedPlotType && selectedDate && modelId && domainId) {
      handleGetChartByPlotType(domainId, modelId, selectedPlotType, selectedDate, {
        cb: getChartByPlotTypeCallback,
        beforeAction: () => setIsFetchingChart(true),
      });
    }
  }, [selectedPlotType, selectedDate, modelId, domainId, handleGetChartByPlotType]);

  useEffect(() => {
    if (selectedPlotType && selectedReferenceDate && modelId && domainId) {
      handleGetReferenceChartByPlotType(domainId, modelId, selectedPlotType, selectedReferenceDate, {
        cb: getReferenceChartByPlotTypeCallback,
        beforeAction: () => setIsFetchingReferenceChart(true),
      });
    }
  }, [selectedPlotType, selectedReferenceDate, modelId, domainId, handleGetReferenceChartByPlotType]);

  if (isFetchingChart || isFetchingReferenceChart)
    return <Skeleton style={{ borderRadius: '5px' }} variant="rect" width={500} height={380} />;

  const isPlotLiftTyped =
    selectedPlotType === ModelPlotTypes.LIFT_PLOT || selectedPlotType === ModelPlotTypes.LIFT_TABLE;

  return (
    <>
      {resolveChartByType(selectedPlotType, chartData)}
      {selectedReferenceDate && referenceChartData && (
        <>
          <Typography variant="h6">Reference</Typography>
          {resolveChartByType(selectedPlotType, referenceChartData)}
        </>
      )}
      {!isPlotLiftTyped && historyChartData && !isFetchingHistoryChart && <History chart={historyChartData} />}
      {isPlotLiftTyped && historyChartData && !isFetchingHistoryChart && <LiftHistory chart={historyChartData} />}
    </>
  );
}

export default ModelPerformanceChart;
