import { Card, CardContent, CardMedia, Typography } from '@material-ui/core';
import { CardVerticalRoot } from './selected-template-card.styles';
import React from 'react';

import { BlueprintVersion } from '../../../common/models/interfaces';
import { ProvidedByAlizImage, ProvidedByAlizPicture } from '../../blueprint-dashboard/blueprint-dashboard.styles';
import { ProvidedByUserImage } from '../../../components/template-search/template-search.styles';
import { Template } from '../../../common/models/workspace.models';
import blueprintThumbnails from '../../../common/utils/blueprintThumbnails';

interface SelectedTemplateCardProps {
  template: Template;
  templateVersion?: BlueprintVersion;
}

export const SelectedTemplateCard: React.FC<SelectedTemplateCardProps> = ({ template }) => {
  return (
    <>
      <CardVerticalRoot>
        <Card className={'card'}>
          <CardMedia
            className={'media'}
            image={template && blueprintThumbnails(template.image)}
            src={template && blueprintThumbnails(template.image)}
            title="Contemplative Reptile"
            style={{ backgroundSize: 'contain' }}
          >
            <ProvidedByAlizPicture
              src={template?.providedByAliz ? ProvidedByAlizImage.attrs[0][`src`] : ProvidedByUserImage.attrs[0][`src`]}
            />
          </CardMedia>
          <div className={'card-details'}>
            <CardContent className={'content'}>
              <Typography className={'title'} gutterBottom color="primary" variant="body2" component="p">
                {template?.name?.substring(0, 50) + (template?.name?.length > 50 ? '...' : '')}
              </Typography>
              <Typography className={'description'} variant="body2" color="textSecondary" component="p">
                {template?.description?.substring(0, 250) + (template?.description?.length > 250 ? '...' : '')}
              </Typography>
            </CardContent>
          </div>
        </Card>
      </CardVerticalRoot>
    </>
  );
};
