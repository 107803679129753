import { Autocomplete } from '@material-ui/lab';
import { NewInstanceProps } from '../new-instance';
import { QueryResult } from 'react-query/types/core/types';
import { SelectWorkspaceRoot, WorkspaceFromContainer } from '../new-instance.styles';
import { TextField, Typography } from '@material-ui/core';
import { Workspace } from '../../../workspaces/models/workspaces.models';
import { WorkspacesWithoutRepoQuery } from '../../../../common/utils/query';
import React, { useCallback, useEffect, useState } from 'react';

export const SelectWorkspace: React.FC<NewInstanceProps> = (props) => {
  const createItem = {
    id: '',
    name: '',
    description: '',
    createdAt: '',
    createdById: '',
    lastModifiedAt: '',
    lastModifiedById: '',
  };
  const [workspaces, setWorkspaces] = useState<Workspace[] | undefined>([{ ...createItem }]);
  const [selectedWorkspace, setSelectedWorkspace] = useState<Workspace | null>(
    props.selectedWorkspace ? props.selectedWorkspace : null,
  );
  const [newWorkspace, setNewWorkspace] = useState<Workspace | null>(
    props.selectedWorkspace ? props.selectedWorkspace : null,
  );
  const workspacesQuery: QueryResult<Workspace[]> = WorkspacesWithoutRepoQuery('-new-instance-selector');

  const onSelectWorkspace = (workspace: Workspace | null) => {
    if (workspace?.id === '') {
      setNewWorkspace({ ...workspace });
      setSelectedWorkspace(workspace);
      if (props.onSelectWorkspace) {
        props.onSelectWorkspace(null);
      }
    } else {
      setSelectedWorkspace(workspace);
      setNewWorkspace(null);
      if (props.onSelectWorkspace) {
        props.onSelectWorkspace(workspace);
      }
    }
  };

  const setWorkspaceName = (name: string) => {
    if (newWorkspace) {
      newWorkspace.name = name;
      setNewWorkspace({ ...newWorkspace });
    }
    if (props.onSelectWorkspace) {
      props.onSelectWorkspace(name !== '' ? newWorkspace : null);
    }
  };

  const setWorkspaceDesc = (desc: string) => {
    if (newWorkspace) {
      newWorkspace.description = desc;
      setNewWorkspace({ ...newWorkspace });
    }
    if (props.onSelectWorkspace) {
      props.onSelectWorkspace(newWorkspace?.name !== '' ? newWorkspace : null);
    }
  };

  const handleReInit = useCallback(() => {
    // on load if we selected previously a workspace trigger parent to enable finish button
    if (selectedWorkspace && props.onSelectWorkspace) {
      props.onSelectWorkspace(selectedWorkspace.id === '' ? newWorkspace : selectedWorkspace);
    }
  }, [selectedWorkspace, newWorkspace, props]);

  useEffect(() => {
    if (workspacesQuery.data && workspacesQuery.data.length > 0) {
      if (workspacesQuery.data[0].id !== '') {
        workspacesQuery.data?.unshift({ ...createItem });
      }
      setWorkspaces(workspacesQuery.data);
    }
    handleReInit();
  }, [workspacesQuery.data, handleReInit, createItem]);

  return (
    <SelectWorkspaceRoot>
      <div className={'center-container'}>
        <Autocomplete
          options={workspaces ?? []}
          renderOption={(option) => (
            <Typography className={option.name === '' ? 'create-option' : ''} noWrap>
              {option.name === '' ? '+ NEW WORKSPACE' : option.name}
            </Typography>
          )}
          renderInput={(params) => <TextField {...params} label="Workspace" variant="outlined" fullWidth />}
          getOptionLabel={(option) => (option.name === '' ? 'New workspace' : option.name)}
          onChange={(event, workspace) => onSelectWorkspace(workspace ?? null)}
          value={selectedWorkspace}
          getOptionSelected={(option, value) => option.id === value.id}
          size="small"
          className="workspace-selector"
        />
        {newWorkspace && newWorkspace?.id === '' && (
          <WorkspaceFromContainer>
            <TextField
              label="Workspace name"
              variant="outlined"
              color="primary"
              size="small"
              value={newWorkspace.name}
              onChange={(e) => setWorkspaceName(e.target.value)}
            />
            <TextField
              label="Workspace description"
              variant="outlined"
              rows={10}
              color="primary"
              size="small"
              multiline={true}
              value={newWorkspace.description}
              onChange={(e) => setWorkspaceDesc(e.target.value)}
            />
          </WorkspaceFromContainer>
        )}
      </div>
    </SelectWorkspaceRoot>
  );
};
