import { Row } from '../../common/styles/common.styles';
import styled from 'styled-components';

export const InformationContainer = styled.div`
  padding: 20px;
`;

export const InputContainer = styled.div`
  margin: 30px 0;

  &:first-of-type {
    margin-top: 0;
  }
`;

export const Label = styled.div`
  padding-bottom: 20px;
  font-size: 16px;
  line-height: 24px;
  color: ${(p) => p.theme.colors.transparentDarkGreen};
`;

export const WorkspaceInformationContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
`;

export const ProjectInformationContainer = styled.div`
  display: grid;
  grid-template-columns: 300px 300px 200px;
  grid-column-gap: 20px;
  grid-row-gap: 10px;
  &.one-column {
    grid-template-columns: 500px;
  }
`;

export const ErrorMessage = styled.div<{ isVisible?: boolean }>`
  font-size: 12px;
  line-height: 16px;
  margin-top: 4px;
  color: ${(p) => p.theme.colors.othersError};
  visibility: ${(p) => !p.isVisible && 'hidden'};
`;

export const SaveChangesContainer = styled(Row)`
  justify-content: flex-end;
  padding: 15px;
`;
