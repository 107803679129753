import { AnyAction, Dispatch } from 'redux';
import { AppState } from '../../common/models/interfaces';
import { Payload } from '../../pages/UploadBlueprint/UploadBlueprint';
import { Template, TemplateVariables } from '../../common/models/workspace.models';
import { ThunkDispatch } from 'redux-thunk';
import { path } from '../../routes/path';
import { urls } from '../../common/utils/urls';
import api from '../../common/utils/api';

export const GET_TEMPLATES_LIST = 'GET_TEMPLATES_LIST';
export const GET_TERRAFORM_VERSIONS = 'GET_TERRAFORM_VERSIONS';
export const GET_BASE_TEMPLATE = 'GET_BASE_TEMPLATE';
export const SET_IS_BASE_TEMPLATE_FETCHING = 'SET_IS_BASE_TEMPLATE_FETCHING';
export const SET_CREATE_TEMPLATE_IN_PROGRESS = 'SET_CREATE_TEMPLATE_IN_PROGRESS';

export const getTemplatesList = () => async (dispatch: ThunkDispatch<AppState, unknown, AnyAction>) => {
  try {
    const list = await api.get(urls.getTemplates());
    dispatch({
      type: GET_TEMPLATES_LIST,
      payload: list,
    });
  } catch (e) {
    console.log(e);
  }
};

export const getTerraformVersions = () => async (dispatch: ThunkDispatch<AppState, unknown, AnyAction>) => {
  try {
    const terraformVersions = await api.get<string[]>(urls.getTerraformVersions());
    dispatch({
      type: GET_TERRAFORM_VERSIONS,
      payload: terraformVersions,
    });
  } catch (e) {
    console.log(e);
  }
};

export const createTemplate =
  (payload: Payload, navigate: any, domainId: string, setErrors?: any) =>
  async (dispatch: ThunkDispatch<AppState, unknown, AnyAction>) => {
    if (!domainId) {
      console.log('Need to define domainId!');
      return;
    }
    dispatch(setCreateTemplateInProgress(true));
    try {
      await api.post(urls.createTemplate(domainId), payload);
      navigate(path.blueprintDashboard());
      dispatch(setCreateTemplateInProgress(false));
    } catch (e) {
      if (setErrors) {
        setErrors(e);
      }
      dispatch(setCreateTemplateInProgress(false));
    }
  };

export const getBaseTemplate = () => async (dispatch: ThunkDispatch<AppState, unknown, AnyAction>) => {
  try {
    dispatch(setIsBaseTemplateFetching(true));
    const template = await api.get(urls.getBaseTemplate());
    dispatch({
      type: GET_BASE_TEMPLATE,
      payload: template,
    });
    dispatch(setIsBaseTemplateFetching(false));
  } catch (e) {
    console.log(e);
    dispatch(setIsBaseTemplateFetching(false));
  }
};

export const setIsBaseTemplateFetching = (state: boolean) => async (dispatch: Dispatch) => {
  dispatch({
    type: SET_IS_BASE_TEMPLATE_FETCHING,
    payload: state,
  });
};

export const setCreateTemplateInProgress = (state: boolean) => async (dispatch: Dispatch) => {
  dispatch({
    type: SET_CREATE_TEMPLATE_IN_PROGRESS,
    payload: state,
  });
};

interface GetTemplatesList {
  type: typeof GET_TEMPLATES_LIST;
  payload: Template[];
}

interface GetTerraformVersions {
  type: typeof GET_TERRAFORM_VERSIONS;
  payload: string[];
}

interface GetBaseTemplate {
  type: typeof GET_BASE_TEMPLATE;
  payload: TemplateVariables;
}

interface SetIsBaseTemplateFetching {
  type: typeof SET_IS_BASE_TEMPLATE_FETCHING;
  payload: boolean;
}

interface SetCreateTemplateInProgress {
  type: typeof SET_CREATE_TEMPLATE_IN_PROGRESS;
  payload: boolean;
}

export type TemplatesActionsT =
  | GetTemplatesList
  | GetBaseTemplate
  | SetIsBaseTemplateFetching
  | SetCreateTemplateInProgress
  | GetTerraformVersions;
