import { AuthorizationDto, RoleDto } from '../../common/models/workspace.models';
import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select, TextField } from '@material-ui/core';
import { Dialog } from '../../containers';
import { IDialogProps } from '../../containers/Dialog';
import { PrincipalType } from '../../common/models/enums';
import { ResourceType } from '../../common/models/resource.model';
import { RoleSelectorContainer, UserInfoRow, useDialogStyles } from './styles';
import { createToasterNotification } from '../../common/utils/toaster-notification';
import { urls } from '../../common/utils/urls';
import { useDispatch } from 'react-redux';
import { useQuery } from 'react-query';
import { validateEmails } from '../../common/utils/validations';
import React, { useEffect, useState } from 'react';
import api from '../../common/utils/api';

type DialogPropNames = 'open' | 'title' | 'subtitle' | 'onCancel';

interface IProps extends Pick<IDialogProps, DialogPropNames> {
  principal: AuthorizationDto | null;
  onSave: (authorizationDto: AuthorizationDto) => void;
  resourceType: ResourceType | null;
}

export const PrincipalDetailsDialog = ({ principal, onSave, resourceType, ...dialogProps }: IProps) => {
  const [selectedRoles, setSelectedRoles] = useState<RoleDto[]>(principal ? principal?.roles : []);
  const [domainLevelRoles, setDomainLevelRoles] = useState<RoleDto[]>([]);
  const [principalName, setPrincipalName] = useState<string | null>(principal ? principal?.principalId : null);
  const [isPrincipalEmail, setIsPrincipalEmail] = useState<boolean>(
    principal ? validateEmails(principal?.principalId) : false,
  );
  const classes = useDialogStyles();

  const domainLevelRolesQuery = useQuery(`domain-level-roles`, () => {
    return api
      .get<RoleDto[]>(urls.domainLevelRoles(resourceType || ''))
      .then((data) => setDomainLevelRoles(data))
      .catch((e) => {
        dispatch(createToasterNotification(e));
      });
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (domainLevelRolesQuery.data) {
      setDomainLevelRoles(domainLevelRolesQuery.data);
    }
  }, [domainLevelRolesQuery.data]);

  const handleSelect = (domainLevelRole: RoleDto) => {
    let newSelectedRoles = [...selectedRoles];
    const isRoleAlreadyAdded = selectedRoles.some((selectedRole) => selectedRole.name === domainLevelRole.name);
    if (isRoleAlreadyAdded) {
      newSelectedRoles = newSelectedRoles.filter((newSelectedRole) => newSelectedRole.name !== domainLevelRole.name);
    } else {
      newSelectedRoles.push(domainLevelRole);
    }

    setSelectedRoles(newSelectedRoles);
  };

  const handleSave = () => {
    onSave({
      id: principal ? principal.id : '',
      principalId: principalName as string,
      roles: selectedRoles,
      principalType: isPrincipalEmail ? PrincipalType.EMAIL : PrincipalType.DOMAIN,
    });
  };

  return (
    <>
      <Dialog
        {...dialogProps}
        disabled={() => selectedRoles.length === 0 || !principalName}
        onPrimaryAction={() => handleSave()}
        classes={{ paper: classes.dialog }}
      >
        <UserInfoRow>
          <TextField
            className={'principal'}
            label="Principal"
            variant="outlined"
            color="primary"
            size="small"
            placeholder={'Email or Domain'}
            value={principalName}
            onChange={(e) => {
              setPrincipalName(e.target.value);
              setIsPrincipalEmail(validateEmails(e.target.value));
            }}
          />
        </UserInfoRow>
        <RoleSelectorContainer>
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel id="roles">Roles</InputLabel>
            <Select
              labelId="roles"
              label="Roles"
              multiple
              value={selectedRoles}
              renderValue={(values) => (Array.isArray(values) ? values.map((value) => value.name).join(', ') : '')}
              fullWidth
            >
              {domainLevelRoles.map((domainLevelRole) => (
                <MenuItem
                  key={domainLevelRole.id}
                  value={domainLevelRole.id}
                  onClick={() => handleSelect(domainLevelRole)}
                >
                  <Checkbox checked={selectedRoles.some((selectedRole) => selectedRole.id === domainLevelRole.id)} />
                  <ListItemText primary={domainLevelRole.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </RoleSelectorContainer>
      </Dialog>
    </>
  );
};
