import styled from 'styled-components';

export const Page = styled.div`
  background-color: ${(p) => p.theme.colors.white};
  padding-top: 18px;
`;

export const Cell = styled.div`
  font-family: Didact Gothic, sans-serif;
  font-size: 14px;
  line-height: 20px;
`;

export const CellPrimaryValue = styled(Cell)<{ color?: string }>`
  color: ${(p) => (p.color ? p.theme.colors[p.color] : p.theme.colors.darkGreen)};
`;

export const CellSecondaryValue = styled(Cell)`
  color: ${(p) => p.theme.colors.transparentDarkGreen};
`;

export const TableHeaderCtaContainer = styled.div`
  margin-left: auto;
  margin-right: 20px;
  min-width: 150px;
  white-space: nowrap;
`;
