import styled from 'styled-components';

const OverviewWrapper = styled.div`
  display: grid;
  grid-template-columns: 400px 1fr;
  padding: 1em;
`;

const MetaColumn = styled.div``;

export { OverviewWrapper, MetaColumn };
