import { AccessDeniedRoot } from './access-denied.styles';
import { Button } from '@material-ui/core';
import { RolesLg } from '../../assets';
import { useNavigate } from 'react-router-dom';
import React from 'react';

export const AccessDenied = () => {
  const navigate = useNavigate();
  return (
    <div>
      <AccessDeniedRoot>
        <RolesLg />
        <h1 className={'error-text'}>Access denied</h1>
        <p className={'error-desc-text'}>The page you&apos;re trying to access has restricted access.</p>
        <p className={'error-desc-text'}>Please refer to your system administrator.</p>
        <Button
          className={'go-back-btn'}
          variant="contained"
          color="primary"
          onClick={() => {
            navigate(-1);
          }}
        >
          Go back
        </Button>
      </AccessDeniedRoot>
    </div>
  );
};
