import { Appbar, NavigationList, NavigationListItem } from '../../components';
import {
  BoltIcon,
  ConfigIcon,
  DesktopIcon,
  DraftingCompassIcon,
  ExperimentIcon,
  FileCloudIcon,
  Grid,
  InfoIcon,
  PerformanceIcon,
  PipelineIcon,
  Support,
  Task,
  Task2Icon,
  User,
} from '../../assets';
import { Copyright, CopyrightText } from '../../components/NavigationList/styles';
import { INavigationListItem } from '../../components/NavigationListItem';
import { path } from '../../routes/path';
import React from 'react';
import useStyles, { Drawer, DrawerContainer, Main, Root } from './styles';

const mainMenuItems: INavigationListItem[] = [
  { name: 'Blueprint dashboard', logo: DraftingCompassIcon, path: path.blueprintDashboard() },
];

/* const budgetMenuItems: INavigationListItem[] = [
  { name: 'Reports', logo: LineChartIcon, path: path.reports() },
  { name: 'Alert log', logo: BullHornIcon, path: path.alertLogs() },
]; */

const staticMenuItems: INavigationListItem[] = [
  { name: 'Info', logo: InfoIcon, path: path.createWorkspace(), alternativePath: path.editWorkspaceWithoutParam() },
  { name: 'GCP Projects', logo: Grid, path: path.gcpProjects() },
  { name: 'Deploy blueprints', logo: FileCloudIcon, path: path.deployBlueprints() },
  { name: 'Provisioning', logo: Task2Icon, path: path.provisioning() },
  /* { name: 'Budget rules', logo: WalletIcon, path: path.budgetRules() }, */
  { name: 'Configuration', logo: ConfigIcon, path: path.configuration() },
  { name: 'My tasks', logo: Task, path: path.myTask() },
];

const adminMenuItems: INavigationListItem[] = [
  { name: 'IAM', logo: User, path: path.users() },
  { name: 'Workspaces', logo: DesktopIcon, path: path.workspaces() },
];

const mlopsMenuItems: INavigationListItem[] = [
  { name: 'Experiments', logo: ExperimentIcon, path: path.mlflow() },
  { name: 'Pipeline', logo: PipelineIcon, path: path.pipelineEditor() },
  /* { name: 'Pipelines', logo: PipelineIcon, path: path.kubeflow() },
  {
    name: 'Model Serving',
    logo: BoltIcon,
    path: path.modelServing(),
  }, */
  {
    name: 'Model Performance',
    logo: PerformanceIcon,
    path: path.modelPerformance(),
  },
];

const AppDrawer: React.FC = ({ children }) => {
  const classes = useStyles();
  const isAdmin = true; // TODO remove this later

  return (
    <Root>
      <Appbar />
      <Drawer
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <DrawerContainer>
          <div>
            <NavigationList items={mainMenuItems} />
            {/* <NavigationList items={budgetMenuItems} label="Budget" /> */}
            <NavigationList items={staticMenuItems} label="Workspace" />
            <NavigationList items={mlopsMenuItems} label="MLOPS" />
            {isAdmin && <NavigationList items={adminMenuItems} label="Admin" />}
          </div>
          <div>
            <NavigationListItem name="Support" path={path.support()} logo={Support} />
            <Copyright>
              <CopyrightText>Copyright © 2021 Aliz Ltd. All rights reserved.</CopyrightText>
            </Copyright>
          </div>
        </DrawerContainer>
      </Drawer>
      <Main>{children}</Main>
    </Root>
  );
};

export default AppDrawer;
