import { AppBar, Toolbar } from '@material-ui/core';
import styled from 'styled-components';

export const StyledAppbar = styled(AppBar)`
  z-index: 10;
  height: ${(p) => p.theme.appbarHeight}px;
  background-color: ${(p) => p.theme.colors.primary};
  justify-content: center;
  &.relative {
    position: relative;
  }
`;

export const StyledToolbar = styled(Toolbar)`
  padding-left: 16px;
  justify-content: space-between;
`;

export const ToolbarContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
    border-color: rgb(255 255 255 / 26%);
  }
  & .workspace-dropdown {
    min-width: 250px;
    margin-left: 20px;
    & input {
      color: white;
    }
    & .MuiFormLabel-root,
    & .MuiButtonBase-root {
      color: white;
    }
    & .MuiOutlinedInput-notchedOutline {
      border-color: white;
    }
    &:hover .MuiOutlinedInput-notchedOutline {
      border-color: white;
    }
    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: white;
    }
    &.disabled label {
      color: rgb(255 255 255 / 26%) !important;
    }
    & .MuiButtonBase-root.Mui-disabled {
      color: rgb(255 255 255 / 26%) !important;
    }
    & .MuiOutlinedInput-root.Mui-disabled {
      color: rgb(255 255 255 / 26%) !important;
      & input {
        color: rgb(255 255 255 / 26%) !important;
      }
      & .MuiOutlinedInput-notchedOutline {
        border-color: rgb(255 255 255 / 26%) !important;
      }
      &:hover .MuiOutlinedInput-notchedOutline {
        border-color: rgb(255 255 255 / 26%) !important;
      }
      &.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: rgb(255 255 255 / 26%) !important;
      }
    }
  }
`;

export const IconWrapper = styled.div`
  margin-left: 16px;
  cursor: pointer;
`;

export const UserAvatar = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
`;

export const BrandLogo = styled.img`
  height: 24px;
  margin-left: 35px;
  &.no-margin {
    margin-left: 0px;
  }
`;
