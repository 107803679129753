import {
  GenerateRepository,
  ManagedInstanceDto,
  TemplateVariables,
  TemplateVariablesItem,
} from '../../../../common/models/workspace.models';
import React, { useCallback, useEffect, useState } from 'react';

import { Autocomplete } from '@material-ui/lab';
import { BlueprintDependency } from '../../../../common/models/interfaces';
import { CookieCutterVariables } from '../../../../components/cookie-cutter-variables/cookie-cutter-variables';
import { DependecyRow } from '../../../deploy-blueprints/template-adder/template-adder.styles';
import { EnvironmentVariablesRoot } from '../new-instance.styles';
import { InfoCircleIcon } from '../../../../assets';
import { ManagedServiceType } from '../../../../common/models/enums';
import { NewInstanceProps } from '../new-instance';
import { Paper, TextField, Tooltip } from '@material-ui/core';
import { createToasterNotification } from '../../../../common/utils/toaster-notification';
import { urls } from '../../../../common/utils/urls';
import { useDispatch } from 'react-redux';
import { useQuery } from 'react-query';
import { useSelector } from '../../../../store/configure-store';
import BlueprintDependencyRow from '../../../../components/BlueprintDependencyRow/BlueprintDependencyRow';
import VersionSelector from '../../../../components/VersionSelector/VersionSelector';
import api from '../../../../common/utils/api';

export const SetEnvironmentVariables: React.FC<NewInstanceProps> = (props) => {
  const onChangeBlueprintDependency = props?.onChangeBlueprintDependency;
  const [templateVariables, setTemplateVariables] = useState({
    items: [] as TemplateVariablesItem[],
    originalContent: '',
  });
  const selectedVersion = useSelector((state) => state.version.selectedVersion);

  const [selectedRepo, setSelectedRepo] = useState<GenerateRepository>(
    props.selectedGenerateRepository
      ? { ...props.selectedGenerateRepository, templateVersionId: selectedVersion?.id }
      : {
          newRepository: false,
          cookieCutterContent: {},
          templateVersionId: selectedVersion?.id,
        },
  );
  const [generatedRepo, setGeneratedRepo] = useState<GenerateRepository | null>(null);
  const domainId = useSelector((state) => state.dynamicSelector.selectedResource?.domainId);

  const [selectedPipeline, setSelectedPipeline] = useState<ManagedInstanceDto | null>(null);
  const [selectedMlflow, setSelectedMlflow] = useState<ManagedInstanceDto | null>(null);
  const [hasMlflow, setHasMlflow] = useState<boolean>(false);
  const [hasKubeflow, setHasKubeflow] = useState<boolean>(false);
  const [hasBlueprintDependency, setHasBlueprintDependency] = useState<boolean>(false);
  const [blueprintDependencies, setBlueprintDependencies] = useState<BlueprintDependency[]>();
  const [blueprintDependencyData, setblueprintDependencyData] = useState<{ [index: string]: string }>();
  const dispatch = useDispatch();

  const templateVariablesQuery = useQuery(
    [`templateVariables`, selectedVersion?.id],
    (_, version: string) => {
      if (!version) {
        return;
      }

      return api
        .get<TemplateVariables>(urls.getTemplateVariables(props.selectedTemplate?.id as string, version || ''))
        .then((data) => {
          //Init and prepare for the blueprint dependencies
          if (data.blueprintDependencies) {
            setBlueprintDependencies(data.blueprintDependencies);
            const initBlueprintData = {};
            data.blueprintDependencies.forEach((dependency) => {
              initBlueprintData[dependency.id] = null;
            });
            setblueprintDependencyData(initBlueprintData);
          }
          ////////////////////////////////////////////////////////

          if (Object.keys(selectedRepo.cookieCutterContent).length) {
            data.cookieCutterContent.items.forEach((value) => {
              value.value = selectedRepo.cookieCutterContent[value.key];
            });
          }
          setTemplateVariables(data.cookieCutterContent);
          if (!Object.keys(selectedRepo.cookieCutterContent).length) {
            const templateVar = {};
            data.cookieCutterContent.items.forEach((value) => {
              templateVar[value.key] = value.value;
            });
            const copyOfRepo = { ...selectedRepo, templateVersionId: version };
            copyOfRepo.cookieCutterContent = templateVar;
            setSelectedRepo(copyOfRepo);
          }

          setHasMlflow(data.managedServiceDependencies.indexOf(ManagedServiceType.MLFLOW) !== -1);
          setHasKubeflow(data.managedServiceDependencies.indexOf(ManagedServiceType.KUBEFLOW) !== -1);
          setHasBlueprintDependency(data?.blueprintDependencies?.length > 0);
        })
        .catch((e) => {
          dispatch(createToasterNotification(e));
        });
    },
    { enabled: true, refetchOnWindowFocus: false },
  );

  useEffect(() => {
    if (props.onSelectGenerateRepository) {
      if (generatedRepo) {
        generatedRepo.managedServiceDependencies = {};
        if (hasMlflow && selectedMlflow) {
          generatedRepo.managedServiceDependencies[ManagedServiceType.MLFLOW] = selectedMlflow.id;
        } else if (hasMlflow) {
          props.onSelectGenerateRepository(null);
          return;
        }
        if (hasKubeflow && selectedPipeline) {
          generatedRepo.managedServiceDependencies[ManagedServiceType.KUBEFLOW] = selectedPipeline.id;
        } else if (hasKubeflow) {
          props.onSelectGenerateRepository(null);
          return;
        }
        if (hasBlueprintDependency) {
          generatedRepo.blueprintDependencies = blueprintDependencyData;
        }
        props.onSelectGenerateRepository(generatedRepo);
      } else {
        props.onSelectGenerateRepository(null);
      }
    }
  }, [
    generatedRepo,
    selectedPipeline,
    selectedMlflow,
    hasMlflow,
    hasKubeflow,
    props,
    hasBlueprintDependency,
    blueprintDependencyData,
  ]);

  const fetchKubeflows = useQuery(
    `fetch-kubeflow-for-variables`,
    () => {
      return api
        .get<ManagedInstanceDto[]>(urls.getKubeflows(domainId || ''), { params: { state: 'DEPLOY_SUCCESS' } })
        .then((data) => {
          if (!selectedPipeline && data && !selectedRepo.managedServiceDependencies) {
            setSelectedPipeline(data[0]);
          } else if (selectedRepo.managedServiceDependencies?.KUBEFLOW) {
            const kubeflow = data.find((value) => value.id === selectedRepo.managedServiceDependencies?.KUBEFLOW);
            if (kubeflow) {
              setSelectedPipeline(kubeflow);
            }
          }
          return data;
        })
        .catch((e) => {
          dispatch(createToasterNotification(e));
        });
    },
    { enabled: true, refetchOnWindowFocus: false },
  );

  const fetchMlflows = useQuery(
    `fetch-mlflows-for-variables`,
    () => {
      return api
        .get<ManagedInstanceDto[]>(urls.getMlflows(domainId || ''), { params: { state: 'DEPLOY_SUCCESS' } })
        .then((data) => {
          if (!selectedMlflow && data && !selectedRepo.managedServiceDependencies) {
            setSelectedMlflow(data[0]);
          } else if (selectedRepo.managedServiceDependencies?.MLFLOW) {
            const mlflow = data.find((value) => value.id === selectedRepo.managedServiceDependencies?.MLFLOW);
            if (mlflow) {
              setSelectedMlflow(mlflow);
            }
          }
          return data;
        })
        .catch((e) => {
          dispatch(createToasterNotification(e));
        });
    },
    { enabled: true, refetchOnWindowFocus: false },
  );

  const templateVariablesQueryCB = useCallback(templateVariablesQuery.refetch, [selectedVersion?.id]);

  useEffect(() => {
    templateVariablesQueryCB();
    setSelectedRepo((prevSelectedRepo) => ({
      ...prevSelectedRepo,
      templateVersionId: selectedVersion?.id,
    }));
    setGeneratedRepo(
      (prevGeneratedRepo) =>
        ({
          ...prevGeneratedRepo,
          templateVersionId: selectedVersion?.id,
        } as GenerateRepository),
    );
  }, [selectedVersion, templateVariablesQueryCB]);

  useEffect(() => {
    if (onChangeBlueprintDependency) {
      onChangeBlueprintDependency(blueprintDependencyData);
    }
  }, [blueprintDependencyData, onChangeBlueprintDependency]);

  return (
    <EnvironmentVariablesRoot>
      <div style={{ width: '500px', margin: '0 auto' }}>
        <VersionSelector
          styles={{ marginLeft: '34px', marginBottom: '16px' }}
          initSelectedVersion={props?.selectedVersionCache || null}
          template={props?.selectedTemplate}
        />
      </div>

      <CookieCutterVariables
        validationError={props.validationError}
        isFetching={templateVariablesQuery.isFetching}
        templateVariables={templateVariables.items}
        data={selectedRepo}
        onDataChanged={(data) => {
          if (props.onSelectGenerateRepository) {
            setGeneratedRepo({ ...data, templateVersionId: selectedVersion?.id } as GenerateRepository);
          }
        }}
      />

      {(hasMlflow || hasKubeflow || hasBlueprintDependency) && (
        <Paper className="deps" elevation={1} style={{ padding: '1em', maxWidth: '550px', margin: '0 auto' }}>
          <h2 style={{ margin: 0, marginBottom: '1em', textAlign: 'center' }}>Dependencies</h2>
          {hasMlflow && (
            <DependecyRow style={{ marginLeft: 'auto', marginRight: 'auto' }}>
              <div style={{ alignSelf: 'center' }}>
                <Tooltip title={'Experiment instance for experiment tracking'} aria-label="add">
                  <InfoCircleIcon />
                </Tooltip>
              </div>
              <Autocomplete
                disableClearable={true}
                options={fetchMlflows.data ? fetchMlflows.data : []}
                renderInput={(params) => <TextField {...params} label="Experiments" variant="outlined" fullWidth />}
                getOptionLabel={(option) => option.name}
                onChange={(event, mlflow) => {
                  setSelectedMlflow(mlflow);
                }}
                value={
                  selectedMlflow
                    ? selectedMlflow
                    : fetchMlflows.data
                    ? fetchMlflows.data[0]
                    : (null as unknown as ManagedInstanceDto)
                }
                size="small"
              />
            </DependecyRow>
          )}
          {hasKubeflow && (
            <DependecyRow style={{ marginLeft: 'auto', marginRight: 'auto' }}>
              <div style={{ alignSelf: 'center' }}>
                <Tooltip title={'Pipeline instance for pipeline handling'} aria-label="add">
                  <InfoCircleIcon />
                </Tooltip>
              </div>
              <Autocomplete
                disableClearable={true}
                options={fetchKubeflows.data ? fetchKubeflows.data : []}
                renderInput={(params) => <TextField {...params} label="Pipelines" variant="outlined" fullWidth />}
                getOptionLabel={(option) => option.name}
                onChange={(event, pipeline) => {
                  setSelectedPipeline(pipeline);
                }}
                value={
                  selectedPipeline
                    ? selectedPipeline
                    : fetchKubeflows.data
                    ? fetchKubeflows.data[0]
                    : (null as unknown as ManagedInstanceDto)
                }
                size="small"
              />
            </DependecyRow>
          )}

          {hasBlueprintDependency &&
            blueprintDependencies?.map((dependency) => (
              <DependecyRow style={{ marginLeft: 'auto', marginRight: 'auto' }} key={dependency.id}>
                <div style={{ alignSelf: 'top', marginTop: '.5em' }}>
                  <Tooltip title="Select the workspace where the following blueprint is deployed." aria-label="add">
                    <InfoCircleIcon />
                  </Tooltip>
                </div>
                <BlueprintDependencyRow
                  blueprintDependencyData={blueprintDependencyData}
                  onChangeAction={setblueprintDependencyData}
                  dependency={dependency}
                />
              </DependecyRow>
            ))}
        </Paper>
      )}
    </EnvironmentVariablesRoot>
  );
};
