import { MPMHistoryChartBase } from 'src/common/models/interfaces';
import { Typography } from '@material-ui/core';
import React, { Suspense, lazy } from 'react';
const DynamicPlotlyChart = lazy(() => import('../../DynamicPlotlyChart'));

interface HistoryProps {
  chart: MPMHistoryChartBase[];
}

function History({ chart }: HistoryProps) {
  if (!chart || !chart.length) return null;

  const names = chart[0].classNames;
  const dates = chart.map((chartItem) => chartItem.date);

  const finalData: {
    name: string;
    x: string[];
    y: number[];
  }[] = [];

  names?.forEach((name, nameIndex) => {
    const yAxis: number[] = [];

    chart.forEach((chartItem) => {
      const { plotDataItems } = chartItem;
      const precisionScore = plotDataItems?.average_precision_score;
      const rocAucScore = plotDataItems?.roc_auc_score;
      const ksstatistic = plotDataItems?.ks_statistic;
      if (precisionScore) {
        yAxis.push(precisionScore[nameIndex]);
      }
      if (rocAucScore) {
        yAxis.push(rocAucScore[nameIndex]);
      }
      if (ksstatistic) {
        yAxis.push(ksstatistic[nameIndex]);
      }
    });

    finalData.push({
      name,
      x: dates,
      y: yAxis,
    });
  });

  return (
    <>
      <Typography variant="h6">History</Typography>
      <Suspense fallback={<div>Loading...</div>}>
        {finalData && (
          <DynamicPlotlyChart
            data={finalData}
            layout={{
              height: 380,
              autosize: true,
              margin: { t: 40, r: 0, b: 40, l: 40 },
              showlegend: names && names.length > 1 ? true : false,
            }}
            config={{ displaylogo: false, modeBarButtons: false, responsive: false }}
          />
        )}
      </Suspense>
    </>
  );
}

export default History;
