import { ChartTitle } from '../../common/styles/common.styles';
import { ModelVS } from '../../common/models/model.model';
import React, { Suspense, lazy } from 'react';
const DynamicPlotlyChart = lazy(() => import('../DynamicPlotlyChart'));

interface TVSPredictionChartProps {
  data: ModelVS;
}

const VSPredictionChart: React.FC<TVSPredictionChartProps> = ({ data }) => {
  return (
    <div>
      <ChartTitle>Predicted vs. Actual</ChartTitle>
      <Suspense fallback={<>Loading...</>}>
        <DynamicPlotlyChart
          data={[
            {
              type: 'bar',
              mode: 'lines+markers',
              marker: { color: '#E5002A' },
              x: data.actual.map((item) => item.xvalue),
              y: data.actual.map((item) => item.yvalue),
              name: 'Actual (count)',
            },
            {
              type: 'bar',
              mode: 'lines+markers',
              marker: { color: '#2F80ED' },
              x: data.pred.map((item) => item.xvalue),
              y: data.pred.map((item) => item.yvalue),
              name: 'Predicted (count)',
            },
          ]}
          layout={{
            title: '',
            height: 380,
            autosize: true,
            margin: { t: 0, r: 0, b: 40, l: 40 },
          }}
          config={{ displaylogo: false, modeBarButtons: false, responsive: true }}
        />
      </Suspense>
    </div>
  );
};

export default VSPredictionChart;
