import React from 'react';

import { Button } from '@material-ui/core';
import { Column } from '../../common/styles/common.styles';
import styled from 'styled-components';

export const NotFoundContainer = styled(Column)`
  width: 100%;
  height: 400px;
  align-items: center;
  justify-content: center;
`;

export const Label = styled.div`
  font-size: 32px;
  margin: 12px 0;
  color: ${(p) => p.theme.colors.primary};
`;

export const StyledButton = styled(({ color, ...other }) => <Button classes={{ label: 'labels' }} {...other} />)`
  background-color: #6772e5;
`;
