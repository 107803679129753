import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { Model } from '../../common/models/model.model';
import { Skeleton } from '@material-ui/lab';
import { urls } from '../../common/utils/urls';
import { useSelector } from '../../store/configure-store';
import React, { useCallback, useEffect, useState } from 'react';
import api from '../../common/utils/api';

interface TModelSelectorProps {
  onChangeModel: (modelId: string) => void;
}

const ModelSelector: React.FC<TModelSelectorProps> = ({ onChangeModel }) => {
  const [models, setModels] = useState<Model[]>();
  const [selectedModel, setSelectedModel] = useState<Model | string>('');
  const domainId = useSelector((state) => state.dynamicSelector.selectedResource?.domainId);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const fetchModels = async () => {
    try {
      setIsFetching(true);
      const modelsData = await api.get<Model[]>(urls.getModels(domainId || ''));
      if (modelsData) {
        setModels(modelsData);
      }
      setIsFetching(false);
    } catch (e) {
      console.log('ModelSelector-->fetchModels', e);
      setIsFetching(false);
    }
  };

  const handleFetchModels = useCallback(fetchModels, [domainId]);

  useEffect(() => {
    handleFetchModels();
  }, [handleFetchModels]);

  const onSelectModel = (e: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedModel(e.target.value as string);
    if (onChangeModel) {
      onChangeModel(e.target.value as string);
    }
  };

  if (!domainId) return null;

  if (isFetching) {
    return <Skeleton variant="rect" width={210} height={50} />;
  }

  return (
    <FormControl size="small" variant="outlined">
      <InputLabel shrink id="model-label" htmlFor="model">
        Model
      </InputLabel>
      <Select
        style={{ minWidth: '320px' }}
        displayEmpty
        id="model"
        labelId="model-label"
        onChange={onSelectModel}
        name="model"
        value={selectedModel}
        label="Model"
      >
        <MenuItem value="" disabled>
          Select model
        </MenuItem>
        {models &&
          models.map((model) => (
            <MenuItem value={model.modelId} key={model.modelId}>
              {model.modelName}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default ModelSelector;
