import { IconButton, Menu, MenuItem, makeStyles } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { path } from '../../routes/path';
import { useNavigate } from 'react-router-dom';
import GeneralModalContext from '../GeneralModal/GeneralModalContext';
import React, { useContext, useState } from 'react';

interface TTemplateVerticalMenuProps {
  templateId: string | null | undefined;
  setTemplateForDelete: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const useStyle = makeStyles({
  iconButtonRoot: {
    position: 'absolute',
    right: '0.5em',
    bottom: '0.5em',
  },
});

const TemplateVerticalMenu: React.FC<TTemplateVerticalMenuProps> = ({ templateId, setTemplateForDelete }) => {
  const navigate = useNavigate();
  const { setModalIsOpen } = useContext(GeneralModalContext);
  const classes = useStyle();
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);

  if (!templateId) return null;

  return (
    <>
      <IconButton
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
          setAnchorEl(event.currentTarget);
        }}
        classes={{ root: classes.iconButtonRoot }}
      >
        <MoreVert aria-label="more" aria-controls="long-menu" aria-haspopup="true" />
      </IconButton>

      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        PaperProps={{
          elevation: 1,
        }}
      >
        <MenuItem onClick={() => navigate(path.editBlueprint(templateId))}>Edit</MenuItem>
        <MenuItem onClick={() => navigate(path.blueprintUsers(templateId))}>IAM</MenuItem>
        <MenuItem
          onClick={() => {
            setTemplateForDelete(templateId);
            setModalIsOpen?.(true);
          }}
        >
          Delete
        </MenuItem>
      </Menu>
    </>
  );
};

export default TemplateVerticalMenu;
