import { BrowserRouter } from 'react-router-dom';
import { QueryCache, ReactQueryCacheProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query-devtools';
import { Routing } from './routes/Routes';
import { WithProviders } from './containers';
import React from 'react';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';

const queryCache = new QueryCache({ defaultConfig: { queries: { retry: false } } });
TimeAgo.addDefaultLocale(en);

export const App = () => {
  return (
    <BrowserRouter>
      <ReactQueryCacheProvider queryCache={queryCache}>
        <WithProviders>
          <Routing />
        </WithProviders>
        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      </ReactQueryCacheProvider>
    </BrowserRouter>
  );
};
