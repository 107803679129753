import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import React from 'react';

export interface AlertDialogProps {
  isOpen: boolean;
  title: string;
  description: string;
  cancelButtonText: string;
  okButtonText: string;
  onClose: () => void;
  onOk: () => void;
  isLoading?: boolean;
}

export const AlertDialog: React.FC<AlertDialogProps> = (props) => {
  return (
    <Dialog
      open={props.isOpen}
      onClose={props?.onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{props.description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props?.onClose} color="primary">
          {props.cancelButtonText.toUpperCase()}
        </Button>
        <Button onClick={props?.onOk} variant="contained" color="primary" autoFocus disabled={props.isLoading}>
          {props.isLoading && <CircularProgress style={{ marginRight: '3px' }} size={14} />}
          {props.okButtonText.toUpperCase()}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
