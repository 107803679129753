import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { appStore, persistor } from '../../store/configure-store';
import React, { ReactNode } from 'react';

const WithRedux = ({ children }: { children: ReactNode }) => {
  return (
    <Provider store={appStore}>
      <PersistGate persistor={persistor} loading={null}>
        {children}
      </PersistGate>
    </Provider>
  );
};

export default WithRedux;
