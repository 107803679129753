import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { NavigationLinkContainer, StyledLink } from './styles';
import { useLocation } from 'react-router-dom';
import React from 'react';

export interface INavigationListItem {
  name: string;
  logo: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  path: string;
  alternativePath?: string;
}

const NavigationListItem = ({ path, name, logo: Logo, alternativePath }: INavigationListItem) => {
  const location = useLocation();
  return (
    <>
      <NavigationLinkContainer
        isActive={
          location.pathname === path || (alternativePath ? location.pathname.startsWith(alternativePath) : false)
        }
      >
        <StyledLink to={`${path}`}>
          <ListItem button={true} style={{ borderRadius: '4px' }}>
            <ListItemIcon>
              <Logo />
            </ListItemIcon>
            <ListItemText primary={name} />
          </ListItem>
        </StyledLink>
      </NavigationLinkContainer>
    </>
  );
};

export default NavigationListItem;
