import { ToastConfig } from '../../store/toaster/models/toastr.models';
import { ToastType } from '../models/enums';
import { addToasterNotification } from '../../store/toaster/reducers/toaster.reducers';
import { generateSimpleId } from './id-generator';
import { getMessage } from './response-message';

export const createToasterNotification = (message: unknown, type: ToastType = ToastType.DANGER) => {
  const toastConfig: ToastConfig = {
    id: generateSimpleId(),
    type,
    message: message ? getMessage(message) : '',
  };

  return addToasterNotification(toastConfig);
};
