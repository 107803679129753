import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ToastConfig } from '../models/toastr.models';

export interface ToasterState {
  toasters: ToastConfig[];
}

const initialState: ToasterState = {
  toasters: [],
};

const toasterSlice = createSlice({
  name: 'toaster',
  initialState,
  reducers: {
    addToasterNotification(state, action: PayloadAction<ToastConfig>) {
      state.toasters = [...state.toasters, action.payload];
    },
    removeToasterNotificationByIndex(state, action: PayloadAction<string>) {
      state.toasters = state.toasters.filter((toaster) => toaster.id !== action.payload);
    },
  },
});

export const { addToasterNotification, removeToasterNotificationByIndex } = toasterSlice.actions;

export const toasterReducer = toasterSlice.reducer;
