import { Button } from '@material-ui/core';
import { DarkGreenPlusIcon } from '../../assets';
import { PlusIconContainer, Text } from '../../common/styles/common.styles';
import React from 'react';

export interface AddButtonProps {
  label: string;
  onClick: () => void;
}

export const AddButton: React.FC<AddButtonProps> = (props) => {
  return (
    <div>
      <Button onClick={props.onClick}>
        <PlusIconContainer>
          <DarkGreenPlusIcon />
        </PlusIconContainer>
        <Text>{props.label}</Text>
      </Button>
    </div>
  );
};
