import { LoadingSpinnerContainer } from '../loading-spinner/loading-spinner.styles';
import styled from 'styled-components';

export const LoadingSpinnerContainerWbg = styled(LoadingSpinnerContainer)`
  background-color: rgb(255 255 255 / 70%);
  position: absolute;
  z-index: 10;
  min-height: auto;
`;

export const ManagedServiceVariablesContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
`;

export const ManagedServiceVersionSelectorContainer = styled.div`
  width: 230px;
  margin: 0 auto;
  margin-bottom: 16px;
  display: flex;
  justify-content: flex-end;
`;
