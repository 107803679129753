import styled from 'styled-components';

export const Label = styled.span`
  color: rgba(5, 33, 32, 0.6);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  margin-left: 16px;
`;

export const Copyright = styled.div`
  display: flex;
  padding: 16px 54px;
  border: 1px solid rgba(5, 33, 32, 0.12);
`;

export const CopyrightText = styled.span`
  font-size: 12px;
  line-height: 16px;
  color: #828282;
  text-align: center;
`;
