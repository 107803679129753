import { Card } from '@material-ui/core';
import styled from 'styled-components';

const ProvisioningRow = styled(Card)`
  & + & {
    margin-top: 1.5em;
  }
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0.5em;
`;

const CardContent = styled.div`
  border-bottom: 1px solid #e0e0e0;
`;

const CardContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5em;
`;

const Middle = styled.div``;

const Info = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1em;
  font-size: 14px;
  color: rgba(5, 33, 32, 0.6);
`;

const Commit = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  &:after {
    content: '|';
    margin-left: 1em;
  }
`;

const CommitID = styled.div`
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
`;

const BuildName = styled.p`
  margin: 0;
  margin-bottom: 0.5em;
`;

const BuildNumber = styled.span`
  color: rgba(5, 33, 32, 0.6);
`;

const InfoItem = styled.div`
  &:after {
    content: '|';
    margin-left: 1em;
  }
  &:last-child:after {
    content: '';
  }
`;

export {
  Footer,
  CardContent,
  CardContentWrapper,
  Middle,
  Info,
  Commit,
  CommitID,
  BuildName,
  BuildNumber,
  InfoItem,
  ProvisioningRow,
};
