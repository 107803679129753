import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { ReportsQueryParam } from '../models/interfaces';
import { addZeroBeforeNumber } from './number-formatter';

export const getDate = (d: Date) => {
  const localD = convertUTCDateToLocalDate(d);
  let month = localD.toLocaleString('en-US', { month: 'long' }).slice(0, 3);
  month = month[0].toUpperCase() + month.slice(1);
  const day = localD.getDate();
  const year = localD.getFullYear();

  return `${month} ${day}, ${year}`;
};

export const get12HourFormat = (d: Date) => {
  const localD = convertUTCDateToLocalDate(d);
  return localD.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });
};

export const getMonthAndYear = (d: Date) => {
  const localD = convertUTCDateToLocalDate(d);
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const month = months[localD.getMonth()];
  const year = localD.getFullYear();

  return `${month} ${year}`;
};

export const setQueryParamForReports = (date: Date, direction: number): ReportsQueryParam => {
  const from = `${date.getFullYear()}-${addZeroBeforeNumber(date.getMonth() + 1)}-01`;
  const untilDate = new Date(date.getFullYear(), date.getMonth() + Math.abs(direction));
  const until = `${untilDate.getFullYear()}-${addZeroBeforeNumber(untilDate.getMonth() + 1)}-01`;

  return { from, until };
};

export const convertUTCDateToLocalDate = (date: Date): Date => {
  return new Date(
    Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
    ),
  );
};

export const formatMUIDateToString = (date?: MaterialUiPickersDate | Date): string => {
  const convertedDate = new Date(date?.toString() || '');
  return `${convertedDate?.getFullYear()}-${('0' + (convertedDate?.getMonth() + 1)).slice(-2)}-${(
    '0' + convertedDate?.getDate()
  ).slice(-2)}`;
};
