import { Build, ProvisionDetails, Step } from '../../../common/models/workspace.models';
import { Button, Card } from '@material-ui/core';
import { CardContainer, CardContent } from '../../../common/styles/common.styles';
import { LoadingSpinner } from '../../../components/loading-spinner/loading-spinner';
import { ProvisioningDetailsRow } from './provisioning-details-row/provisioning-details-row';
import { Refresh } from '../../../assets';
import { RefreshContainer } from '../provisioning.styles';
import { Task } from '../../mytasks/models/tasks.model';
import { WorkspaceStatusBlock } from '../status-block/workspace-status-block';
import { createToasterNotification } from '../../../common/utils/toaster-notification';
import { urls } from '../../../common/utils/urls';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useSelector } from '../../../store/configure-store';
import React, { useState } from 'react';
import api from '../../../common/utils/api';

interface ProvisioningDetailsProps {
  provisionId: string;
  workspaceId: string;
}

const stepNames: Map<string, string> = new Map([
  ['terraform-init', 'Terraform Init'],
  ['terraform-plan', 'Terraform Plan'],
  ['terraform-apply', 'Terraform Apply'],
  ['git-clone', 'Git Clone'],
  ['git-checkout-commit-id', 'Checkout Specific Commit'],
]);

export const ProvisioningDetails: React.FC<ProvisioningDetailsProps> = (props) => {
  const dispatch = useDispatch();
  const [firstBuildStepCount, setFirstBuildStepCount] = useState<number>(0);
  const [steps, setSteps] = useState<Step[]>([]);
  const currentUserState = useSelector((state) => state.currentUser);
  const workspaceId = useSelector((state) => state.workspaces.selectedWorkspace);

  let { provisioningId } = useParams() as any;
  if (!provisioningId) {
    provisioningId = props.provisionId;
  }
  const detailsQuery = useQuery(
    `provisioning_details_${provisioningId}`,
    () => {
      return api
        .get<ProvisionDetails>(urls.provisionLogs(workspaceId?.id as string, provisioningId))
        .then((response: ProvisionDetails) => {
          if (response.builds.length > 0) {
            setFirstBuildStepCount(response.builds[0].steps.length);
            setSteps(response.builds.flatMap((value: Build) => value.steps));
          }
          return response;
        })
        .catch((e) => {
          dispatch(createToasterNotification(e));
        });
    },
    { enabled: workspaceId, refetchOnWindowFocus: false },
  );

  const taskQuery = useQuery(
    `task_query_${provisioningId}`,
    () => {
      return api
        .get<Task>(urls.getTaskByReferenceId(workspaceId?.id as string, provisioningId))
        .then((response: Task) => {
          return response;
        })
        .catch((e) => {
          dispatch(createToasterNotification(e));
        });
    },
    { enabled: workspaceId, refetchOnWindowFocus: false },
  );

  const refresh = () => {
    detailsQuery.refetch();
    taskQuery.refetch();
  };

  const isWaitForApprove = (index: number): boolean | null => {
    if (taskQuery.data && detailsQuery.data && index === firstBuildStepCount - 1) {
      return (
        !taskQuery.data.done &&
        taskQuery.data.assignedUsers != null &&
        taskQuery.data.assignedUsers.indexOf(currentUserState.me.email) > -1 &&
        detailsQuery.data.provisioning.status === 'WAITING_FOR_APPROVAL'
      );
    }
    return null;
  };

  return (
    <>
      <CardContainer>
        <Card>
          <CardContent>
            <div>
              <RefreshContainer>
                {!detailsQuery.isFetching && detailsQuery.data && (
                  <WorkspaceStatusBlock status={detailsQuery.data.provisioning.status} />
                )}
                <span />
                <Button color="primary" onClick={() => refresh()}>
                  <Refresh />
                </Button>
              </RefreshContainer>
              {detailsQuery.isFetching && <LoadingSpinner />}
              {!detailsQuery.isFetching && (
                <div>
                  {steps?.map((step: Step, index) => (
                    <ProvisioningDetailsRow
                      refreshCallback={() => {
                        refresh();
                      }}
                      task={taskQuery.data ?? null}
                      isWaitForApprove={isWaitForApprove(index)}
                      key={index}
                      step={step}
                      workspaceId={workspaceId?.id as string}
                      name={
                        stepNames.has(step.buildStepId) ? (stepNames.get(step.buildStepId) as string) : 'Unknown Step'
                      }
                    />
                  ))}
                </div>
              )}
            </div>
          </CardContent>
        </Card>
      </CardContainer>
    </>
  );
};
