import { Label } from './styles';
import List from '@material-ui/core/List';
import NavigationListItem, { INavigationListItem } from '../NavigationListItem';
import React from 'react';

interface IProps {
  items: INavigationListItem[];
  label?: string;
}

const NavigationList = ({ items, label }: IProps) => {
  return (
    <>
      {label && <Label>{label}</Label>}
      <List>
        {items.map((navItem: INavigationListItem) => (
          <NavigationListItem key={navItem.path} {...navItem} />
        ))}
      </List>
    </>
  );
};

export default NavigationList;
