import { AnyAction } from 'redux';
import { AppState, BlueprintVersion } from '../../common/models/interfaces';
import { ThunkDispatch } from 'redux-thunk';
import { Version } from './reducer';

const SET_SELECTED_VERSION = 'SET_SELECTED_VERSION';

const setSelectedVersion =
  (version: BlueprintVersion | null) => async (dispatch: ThunkDispatch<AppState, unknown, AnyAction>) => {
    dispatch({
      type: SET_SELECTED_VERSION,
      payload: version,
    });
  };

interface TSetSelectedVersion {
  type: typeof SET_SELECTED_VERSION;
  payload: Version;
}

export type TVersionActions = TSetSelectedVersion;

export { SET_SELECTED_VERSION, setSelectedVersion };
