import AlertIncidents from '../AlertIncidents';
import AlertPolicies from '../AlertPolicies';
import ModelPerformanceContext from '../../pages/ModelPerformance/ModelPerformanceContext';
import NoModelSelected from '../NoModelSelected/NoModelSelected';
import React, { useContext } from 'react';

const AlertsTab = () => {
  const { domainId, modelId } = useContext(ModelPerformanceContext);
  if (!modelId) return <NoModelSelected />;

  return (
    <>
      <AlertIncidents domainId={domainId} modelId={modelId} />
      <AlertPolicies domainId={domainId} modelId={modelId} />
    </>
  );
};

export default AlertsTab;
