import { Button, Card } from '@material-ui/core';
import { CardContent, Label } from '../../common/styles/common.styles';
import { FirstTab } from '../blueprint-dashboard/blueprint-detail/blue-print-detail.styles';
import { GenerateRepository, Repository, Template } from '../../common/models/workspace.models';
import { InformationContainer } from './deploy-blueprints.styles';
import { Link } from 'react-router-dom';
import { LoadingSpinner } from '../../components/loading-spinner/loading-spinner';
import { LoadingSpinnerContainerWbg } from './template-adder/template-adder.styles';
import { SelectedTemplateCard } from './selected-template-card/selected-template-card';
import { TemplateAdder } from './template-adder/template-adder';
import { ToastType } from '../../common/models/enums';
import { createToasterNotification } from '../../common/utils/toaster-notification';
import { path } from '../../routes/path';
import { urls } from '../../common/utils/urls';
import { useDispatch } from 'react-redux';
import { useQuery } from 'react-query';
import { useSelector } from '../../store/configure-store';
import React, { useCallback, useEffect, useState } from 'react';
import Tour from '../../components/Tour';
import api from '../../common/utils/api';
import withResources from '../../components/withResources/withResources';

const DeployBlueprintsComp: React.FC = () => {
  const dispatch = useDispatch();
  const [templates, setTemplates] = useState<Template[]>([]);
  const [isDeploying, setIsDeploying] = useState<boolean>(false);
  const selectedResource = useSelector((state) => state.dynamicSelector.selectedResource);

  useQuery(
    `templates`,
    () => {
      return api
        .get<Template[]>(urls.getTemplates())
        .then((data) => {
          setTemplates(data);
          return data;
        })
        .catch((e) => {
          dispatch(createToasterNotification(e));
        });
    },
    { enabled: true, refetchOnWindowFocus: false },
  );
  const repositoryQuery = useQuery(
    [`repository`, selectedResource?.id],
    (_, selectedResourceParam: string) => {
      if (!selectedResourceParam) return;
      return api.get<Repository>(urls.getRepository(selectedResourceParam || '')).then((data) => {
        return data;
      });
    },
    { enabled: false, refetchOnWindowFocus: false },
  );
  const repositoryQueryFetch = useCallback(repositoryQuery.refetch, []);

  useEffect(() => {
    if (selectedResource) {
      repositoryQueryFetch();
    }
  }, [selectedResource, repositoryQueryFetch]);

  const onSave = (templateId: string, generateRepository?: GenerateRepository): Promise<Repository> => {
    setIsDeploying(true);
    return api
      .put<Repository>(urls.generateCodeFromTemplate(templateId, selectedResource?.id || ''), generateRepository)
      .then((response: Repository) => {
        dispatch(createToasterNotification('Successfully created a new repository', ToastType.SUCCESS));
        repositoryQueryFetch();
        setIsDeploying(false);
        return response;
      });
  };

  const latestVersionReleaseDate = repositoryQuery.data?.template.versions
    ? repositoryQuery.data?.template.versions[0].releaseDate
    : null;

  const currentVersionReleaseDate = repositoryQuery.data?.templateVersion?.releaseDate || null;

  const isUpgradeable = (
    latestVersionReleaseDateParam: Date | null,
    currentVersionReleaseDateParam: Date | null,
  ): boolean => {
    if (!latestVersionReleaseDateParam || !currentVersionReleaseDateParam) return false;
    const latestVersionReleaseDateTime = new Date(latestVersionReleaseDateParam).getTime();
    const currentVersionReleaseDateTime = new Date(currentVersionReleaseDateParam).getTime();
    if (latestVersionReleaseDateTime > currentVersionReleaseDateTime) {
      return true;
    }
    return false;
  };

  return (
    <>
      {!repositoryQuery.isError && !repositoryQuery.isFetching && repositoryQuery.data && (
        <>
          <Tour
            type="DEPLOY_BLUEPRINTS"
            steps={[
              {
                content: 'You can find the link of the github repo here.',
                target: '.repo-url',
                disableBeacon: true,
                showSkipButton: true,
                placement: 'left-start',
              },
            ]}
          />
          <Card>
            <CardContent style={{ position: 'relative' }}>
              {(repositoryQuery.isFetching || isDeploying) && (
                <LoadingSpinnerContainerWbg>
                  <LoadingSpinner />
                </LoadingSpinnerContainerWbg>
              )}
              <InformationContainer>
                <>
                  <SelectedTemplateCard
                    templateVersion={repositoryQuery.data?.templateVersion}
                    template={repositoryQuery.data?.template as Template}
                  />
                  <CardContent className="content">
                    <Label>Generated repository</Label>
                    <FirstTab style={{ marginTop: 0 }}>
                      <label>Repostiory url</label>
                      <span>
                        <a
                          className="repo-url"
                          target="_blank"
                          rel="noopener noreferrer"
                          href={repositoryQuery.data?.repositoryUrl}
                        >
                          {repositoryQuery.data?.repositoryUrl}
                        </a>
                      </span>
                      <label>Current version</label>
                      <span>{repositoryQuery.data?.templateVersion.version}</span>
                      {repositoryQuery.data.pullRequestUrl && (
                        <>
                          <label>Pull Request</label>
                          <span>
                            <a href={repositoryQuery.data.pullRequestUrl} target="_blank" rel="noopener noreferrer">
                              Click here
                            </a>
                          </span>
                        </>
                      )}
                    </FirstTab>
                  </CardContent>
                  <Button variant="contained" disabled color="primary">
                    Deploy
                  </Button>
                  <Button
                    component={Link}
                    disabled={!isUpgradeable(latestVersionReleaseDate, currentVersionReleaseDate) || isDeploying}
                    style={{ marginLeft: '1em' }}
                    variant="contained"
                    color="primary"
                    to={path.upgradeBlueprint(repositoryQuery.data.template.id)}
                  >
                    Upgrade
                  </Button>
                </>
              </InformationContainer>
            </CardContent>
          </Card>
        </>
      )}
      {repositoryQuery.isError && !repositoryQuery.isFetching && selectedResource && (
        <TemplateAdder
          disabled={selectedResource == null}
          selectableTemplates={templates}
          onSave={onSave}
          hasRepository={!repositoryQuery.isError}
        />
      )}
    </>
  );
};

export const DeployBlueprints = withResources(DeployBlueprintsComp);
