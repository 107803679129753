import { Row } from '../../common/styles/common.styles';
import styled from 'styled-components';

export const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 18px;
  & :not(:last-child) {
    margin-right: 8px;
  }
`;

export const FilterRow = styled(Row)`
  min-width: 470px;
  & automcomplete {
    margin-left: 20px;
  }
  & .completedSwitch {
    margin: 0px;
  }
`;
export const AutocompleteContainer = styled.div`
  padding-left: 20px;
  width: 100%;
`;
