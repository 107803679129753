import { Button, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import GeneralModalContext from './GeneralModalContext';
import React, { useContext } from 'react';

const GeneralModalContent: React.FC = ({ children }) => {
  const { setModalIsOpen, title, onSubmit, isForm, primaryText, secondaryText, onCloseCb } =
    useContext(GeneralModalContext);
  return (
    <>
      {title && <DialogTitle id="general-modal-title">{title}</DialogTitle>}
      {children && <DialogContent>{children}</DialogContent>}
      <DialogActions>
        <Button
          onClick={async () => {
            setModalIsOpen && (await setModalIsOpen(false));
            onCloseCb && onCloseCb();
          }}
          color="primary"
        >
          {secondaryText}
        </Button>
        {isForm && (
          <Button type="submit" color="primary">
            {primaryText}
          </Button>
        )}
        {!isForm && (
          <Button onClick={onSubmit} color="primary">
            {primaryText}
          </Button>
        )}
      </DialogActions>
    </>
  );
};

export default GeneralModalContent;
