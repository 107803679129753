import { BlueprintVersion } from '../../common/models/interfaces';
import { SET_SELECTED_VERSION, TVersionActions } from './actions';

export interface Version {
  id: string;
  version: string;
  releaseDate: string;
}

export interface TVersionReducer {
  selectedVersion: BlueprintVersion | null;
}

const initialState: TVersionReducer = {
  selectedVersion: null,
};

const versionReducer = (state = initialState, action: TVersionActions) => {
  switch (action.type) {
    case SET_SELECTED_VERSION:
      return {
        ...state,
        selectedVersion: action.payload,
      };
    default:
      return state;
  }
};

export default versionReducer;
