import { ArrowUp2, LogoDark } from '../../assets';
import {
  BottomContainer,
  ContentContainer,
  GcLogo,
  Href,
  LandingImage,
  LeftContainer,
  LinearProgressContainer,
  LogoContainer,
  Page,
  Panel,
  RightContainer,
  SmallText,
  Text,
  Title,
  Title2,
} from './sign.styles';
import { Button, Checkbox, FormControlLabel, LinearProgress, TextField } from '@material-ui/core';
import { Column, Row } from '../../common/styles/common.styles';
import { LoginButton } from '../../components';
import { path } from '../../routes/path';
import { useNavigate } from 'react-router-dom';
import { useSelector } from '../../store/configure-store';
import React, { useState } from 'react';

const Sign: React.FC = () => {
  const currentUserState = useSelector((state) => state.currentUser);
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const signUpParam = Boolean(params.get('signup')) as boolean;
  const [isSignUp, setIsSignUp] = useState<boolean>(signUpParam);
  const navigate = useNavigate();

  return (
    <Page>
      <LinearProgressContainer isVisible={currentUserState?.loadMeta?.isLoading}>
        <LinearProgress />
      </LinearProgressContainer>
      <LogoContainer>
        <LogoDark />
      </LogoContainer>
      <ContentContainer>
        <LeftContainer>
          <LandingImage />
          <Title>Aliz Intelligence Platform</Title>
          <Text style={{ marginTop: '40px' }}>New to our service?</Text>
          <Title2>Sign up for 30 days free trial</Title2>
          <Text style={{ marginTop: '20px' }}>
            Get a hands-on experience on how you can orchestrate your business and technology flow. Exapnd capabilities
            of your organization by solving tech challenges out of the box yet remain highly transparent and agile.
          </Text>
          <Text style={{ marginTop: '20px' }}>Our free trial gives you unlimited access for most features of AIP.</Text>
          <Text className={'primary'} style={{ fontFamily: 'Lato', marginTop: '40px', fontSize: '18px' }}>
            Integrated with
          </Text>
          <GcLogo />
        </LeftContainer>
        <RightContainer>
          {!isSignUp && (
            <Panel>
              <Column style={{ alignItems: 'center' }}>
                <Row style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                  <Text className={'primary'} style={{ fontSize: '20px' }}>
                    Sign in
                  </Text>
                  <div style={{ display: 'flex' }}>
                    <Text style={{ marginRight: '20px', fontSize: '16px' }}>OR</Text>
                    <Href
                      className={'primary'}
                      style={{ fontSize: '16px' }}
                      onClick={() => {
                        setIsSignUp(true);
                        navigate(path.rootTrialSignUp());
                      }}
                    >
                      SIGN UP
                    </Href>
                  </div>
                </Row>
                <Row style={{ alignItems: 'center', marginTop: '10px', justifyContent: 'flex-end' }}>
                  <Text style={{ fontSize: '12px' }}>Don’t have an acount yet? Try for free!</Text>
                  <ArrowUp2 />
                </Row>
                {/* <Column style={{ alignItems: 'center' }}>
                  <TextField
                    style={{ marginTop: '40px' }}
                    size="small"
                    label="Email adress"
                    variant="outlined"
                    fullWidth
                  />
                  <TextField
                    style={{ marginTop: '20px', marginBottom: '20px' }}
                    size="small"
                    label="Password"
                    variant="outlined"
                    fullWidth
                  />
                </Column> */}
                <LoginButton
                  keepSignIn={false}
                  render={(buttonProps) => (
                    <Button
                      style={{ marginTop: '3em', marginBottom: '3em' }}
                      {...buttonProps}
                      variant="contained"
                      color="primary"
                    >
                      Sign in with google
                    </Button>
                  )}
                />
              </Column>
              <Row style={{ justifyContent: 'center' }}>
                <Text>Have a problem signing in? &nbsp;</Text>
                <Href className={'primary underline'}>Contact our team.</Href>
              </Row>
            </Panel>
          )}
          {isSignUp && (
            <Panel className={'sign-up'}>
              <Column style={{ alignItems: 'center' }}>
                <Row style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                  <Text className={'primary'} style={{ fontSize: '20px' }}>
                    Sign up
                  </Text>
                  <div style={{ display: 'flex' }}>
                    <Text style={{ marginRight: '20px', fontSize: '16px' }}>OR</Text>
                    <Href
                      className={'primary'}
                      style={{ fontSize: '16px' }}
                      onClick={() => {
                        setIsSignUp(false);
                        navigate(path.root());
                      }}
                    >
                      SIGN IN
                    </Href>
                  </div>
                </Row>
                <Column style={{ alignItems: 'center' }}>
                  <TextField style={{ marginTop: '40px' }} size="small" label="Name" variant="outlined" fullWidth />
                  <TextField
                    style={{ marginTop: '20px' }}
                    size="small"
                    label="Email adress"
                    variant="outlined"
                    fullWidth
                  />
                  <TextField style={{ marginTop: '20px' }} size="small" label="Company" variant="outlined" fullWidth />
                  <TextField
                    style={{ marginTop: '20px', marginBottom: '20px' }}
                    size="small"
                    label="Phone"
                    variant="outlined"
                    fullWidth
                  />
                  <div style={{ width: '100%' }}>
                    <FormControlLabel
                      control={<Checkbox color="primary" />}
                      label={
                        <Row>
                          <label>I accept &nbsp;</label> <Href className={'primary underline'}>privacy policy</Href>
                        </Row>
                      }
                    />
                  </div>
                  <div style={{ width: '100%', marginBottom: '10px' }}>
                    <FormControlLabel
                      control={<Checkbox color="primary" />}
                      label={
                        <Row>
                          <label>I accept &nbsp;</label>{' '}
                          <Href className={'primary underline'}>terms and conditions</Href>
                        </Row>
                      }
                    />
                  </div>
                </Column>
                <LoginButton
                  keepSignIn={false}
                  render={(buttonProps) => (
                    <Button {...buttonProps} variant="contained" color="primary">
                      Submit
                    </Button>
                  )}
                />
              </Column>
              <Row style={{ justifyContent: 'center' }}>
                <Text>Have a problem signing in? &nbsp;</Text>
                <Href className={'primary underline'}>Contact our team.</Href>
              </Row>
            </Panel>
          )}
        </RightContainer>
      </ContentContainer>
      <BottomContainer>
        <Row style={{ justifyContent: 'center', alignItems: 'center' }}>
          <SmallText className={'primary'} style={{ marginRight: '10px' }}>
            TERMS OF SERVICE
          </SmallText>
          <SmallText className={'primary'} style={{ marginLeft: '10px' }}>
            PRIVACY POLICY
          </SmallText>
        </Row>
        <Row style={{ justifyContent: 'center', alignItems: 'center', marginTop: '10px' }}>
          <Text style={{ marginRight: '15px' }}>Copyright © 2021 Aliz Tech Ltd. All rights reserved.</Text>
          <div style={{ marginLeft: '15px' }}>
            <Text style={{ display: 'inline-block' }}>Get in touch: &nbsp;</Text>{' '}
            <Href style={{ display: 'inline-block' }} className={'primary'}>
              hello@aliz.ai
            </Href>
          </div>
        </Row>
      </BottomContainer>
    </Page>
  );
};

export default Sign;
