import { BlueprintVersion } from '../../common/models/interfaces';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { Template } from '../../common/models/workspace.models';
import { Version } from '../../store/version/reducer';
import { setSelectedVersion } from '../../store/version/actions';
import { useDispatch } from 'react-redux';
import { useSelector } from '../../store/configure-store';
import React, { useEffect } from 'react';

interface TVersionSelectorProps {
  template: Template | null | undefined;
  initSelectedVersion?: BlueprintVersion | null;
  styles?: React.CSSProperties;
}

const VersionSelector: React.FC<TVersionSelectorProps> = ({ template, initSelectedVersion, styles }) => {
  const dispatch = useDispatch();
  const selectedVersion = useSelector((state) => state.version.selectedVersion);

  useEffect(() => {
    //If we need to select a version by default
    if (initSelectedVersion) {
      dispatch(setSelectedVersion(initSelectedVersion));
    } else {
      const initVersion = template?.versions ? template.versions[0] : null;
      dispatch(setSelectedVersion(initVersion));
    }
    return () => {
      dispatch(setSelectedVersion(null));
    };
  }, [template, initSelectedVersion, dispatch]);

  if (!template) return null;

  const onChangeVersion = (e: React.ChangeEvent<{ value: unknown }>) => {
    const versionData = template.versions
      ? template.versions.find((version) => version.id === e.target.value) || null
      : null;
    dispatch(setSelectedVersion(versionData));
  };

  return (
    <FormControl className="version-selector" variant="outlined" fullWidth>
      {template.versions && (
        <div {...(styles && { style: styles })}>
          <FormControl size="small" fullWidth variant="outlined">
            <InputLabel id="select-version-label">Version</InputLabel>
            <Select
              labelId="select-version-label"
              onChange={onChangeVersion}
              label="Version"
              value={selectedVersion?.id || ''}
              name="version-selector"
            >
              {template.versions.map((version) => (
                <MenuItem value={version.id} key={version.id}>
                  {version.version}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      )}
    </FormControl>
  );
};

export default VersionSelector;
