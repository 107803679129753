import { urls } from '../../common/utils/urls';
import Joyride, { Step } from 'react-joyride';
import React, { useCallback, useEffect, useState } from 'react';
import api from '../../common/utils/api';

export interface TourTypes {
  BLUEPRINT_WIZARD_DASHBOARD: 'BLUEPRINT_WIZARD_DASHBOARD';
  BLUEPRINT_WIZARD_SELECT_BLUEPRINT: 'BLUEPRINT_WIZARD_SELECT_BLUEPRINT';
  BLUEPRINT_WIZARD_ENV_VARS: 'BLUEPRINT_WIZARD_ENV_VARS';
  BLUEPRINT_WIZARD_WORKSPACE: ' BLUEPRINT_WIZARD_WORKSPACE';
  BLUEPRINT_WIZARD_PROJECT: 'BLUEPRINT_WIZARD_PROJECT';
  PROVISIONING_INIT: 'PROVISIONING_INIT';
  PROVISIONING_LIST: ' PROVISIONING_LIST';
  DEPLOY_BLUEPRINTS: 'DEPLOY_BLUEPRINTS';
}

export type TourType = keyof TourTypes;

interface TTourProps {
  type: TourType;
  run?: boolean;
  steps: Step[];
  options?: {
    continuous?: boolean;
    showSkipButton?: boolean;
    showProgress?: boolean;
  };
}

const Tour: React.FC<TTourProps> = ({ steps, options, run, type }) => {
  const [showTour, setShowTour] = useState(false);

  const getTourStatusByType = async () => {
    const tour = await api.get<{ showUserTour: { [Property in keyof TourTypes]: boolean } }>(urls.userTour());
    if (tour) {
      const { showUserTour } = tour;

      const isCurrentTourFinished = showUserTour[type];

      if (!isCurrentTourFinished) {
        setShowTour(true);
      } else {
        setShowTour(false);
      }
    }
  };

  const finishTour = async () => {
    const showUserTour = {};
    showUserTour[type] = true;
    await api.post(urls.userTour(), {
      showUserTour,
    });
  };

  const handleGetTourStatusByType = useCallback(getTourStatusByType, [type]);
  const handleFinishTour = useCallback(finishTour, [type]);

  const handleCallback = useCallback(
    ({ action, controlled, index, lifecycle, size, step, status }) => {
      if (lifecycle === 'complete' && (status === 'skipped' || status === 'finished')) {
        handleFinishTour();
      }
    },
    [handleFinishTour],
  );

  useEffect(() => {
    if (type) {
      handleGetTourStatusByType();
    }
    return () => {
      setShowTour(false);
    };
  }, [type, handleGetTourStatusByType]);

  if (!showTour) {
    return null;
  }

  return (
    <Joyride
      run={run}
      styles={{
        options: {
          primaryColor: '#02394D',
        },
      }}
      callback={handleCallback}
      locale={{
        last: 'Close',
      }}
      steps={steps as Step[]}
      {...(options?.continuous ? { continuous: options.continuous } : { continuous: true })}
      {...(options?.showSkipButton ? { showSkipButton: options.showSkipButton } : { showSkipButton: true })}
      {...(options?.showProgress ? { showProgress: options.showProgress } : { showProgress: true })}
    />
  );
};

export default Tour;
