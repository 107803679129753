import * as S from './styles';
import { ArrowDown, ArrowUp } from '../../assets';
import { Button, Collapse, Tooltip } from '@material-ui/core';
import { CloudIcon } from '../../assets';
import { Provision } from '../../common/models/workspace.models';
import { ProvisioningDetails } from '../../pages/provisioning/provisioning-row/provisioning-details/provisioning-details';
import { WorkspaceStatusBlock } from '../../pages/provisioning/status-block/workspace-status-block';
import { convertUTCDateToLocalDate } from '../../common/utils/date-formatter';
import React, { useState } from 'react';
import TimeAgo from 'javascript-time-ago';
import Tour from '../Tour';

interface TProvisioningRowProps {
  provision: Provision;
  detailsClickHandler: (provision: Provision) => void;
  workspaceId: string;
}

const ProvisioningRow: React.FC<TProvisioningRowProps> = ({ provision, detailsClickHandler, workspaceId }) => {
  const timeAgo = new TimeAgo('en-US');
  const [expandedTab, setExpandedTab] = useState(-1);

  const handleExpandClick = (index: number): void => {
    if (index === expandedTab) {
      setExpandedTab(-1);
    } else {
      setExpandedTab(index);
    }
  };

  const formatTime = (time: string) => {
    return timeAgo.format(convertUTCDateToLocalDate(new Date(time)), 'round');
  };

  return (
    <>
      <Tour
        type="PROVISIONING_LIST"
        steps={[
          {
            content: 'You can check the full log here.',
            target: '.full-log',
            disableBeacon: true,
            showSkipButton: true,
            placement: 'left-start',
          },
          {
            content: 'You can check the outputs here.',
            target: '.outputs',
            disableBeacon: true,
            showSkipButton: true,
            placement: 'left-start',
          },
          {
            content: 'You can check the details here.',
            target: '.details',
            disableBeacon: true,
            showSkipButton: true,
            placement: 'left-start',
          },
        ]}
      />
      <S.ProvisioningRow elevation={2}>
        <S.CardContent>
          <div style={{ margin: '0.8em' }}>
            <S.CardContentWrapper>
              <CloudIcon style={{ flexShrink: 0 }} />
              <S.Middle style={{ flexShrink: 1 }}>
                <S.BuildName>
                  Build: <S.BuildNumber>{provision.provisionId}</S.BuildNumber>
                </S.BuildName>
                <S.Info>
                  <S.InfoItem>
                    Created by: <span>{provision.createdBy}</span>
                  </S.InfoItem>

                  <Tooltip title={provision.createdAt}>
                    <S.InfoItem>
                      <>
                        Created: <span>{formatTime(provision.createdAt)}</span>
                      </>
                    </S.InfoItem>
                  </Tooltip>

                  <S.Commit>
                    Commit id: <S.CommitID>{provision.commitId}</S.CommitID>
                  </S.Commit>

                  <S.InfoItem>
                    Branch: <span>{provision.branch}</span>
                  </S.InfoItem>
                </S.Info>
              </S.Middle>

              <div style={{ marginLeft: 'auto', flexShrink: 0 }}>
                <WorkspaceStatusBlock status={provision.status} />
              </div>
            </S.CardContentWrapper>
          </div>
        </S.CardContent>
        <S.Footer>
          <Button className="full-log" color="primary" onClick={() => handleExpandClick(0)}>
            Full log
            {expandedTab === 0 ? <ArrowUp /> : <ArrowDown />}
          </Button>
          <Button className="outputs" color="primary" onClick={() => handleExpandClick(1)}>
            Outputs
            {expandedTab === 1 ? <ArrowUp /> : <ArrowDown />}
          </Button>
          <Button
            className="details"
            style={{ marginLeft: 'auto' }}
            color="primary"
            onClick={() => detailsClickHandler(provision)}
          >
            Details
          </Button>
        </S.Footer>
        <Collapse in={expandedTab === 0} timeout="auto" unmountOnExit>
          <ProvisioningDetails showLogs={true} provisioningId={provision.provisionId} workspaceId={workspaceId} />
        </Collapse>
        <Collapse in={expandedTab === 1} timeout="auto" unmountOnExit>
          <ProvisioningDetails showLogs={false} provisioningId={provision.provisionId} workspaceId={workspaceId} />
        </Collapse>
      </S.ProvisioningRow>
    </>
  );
};

export default ProvisioningRow;
