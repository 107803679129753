import * as AuthTypes from '../types';
import { AppState } from '../../../common/models/interfaces';
import { Dispatch } from 'redux';
import { IGoogleLogoutFailure, IGoogleLogoutSuccess } from '../models';
import { clearMeState } from '../../current-user/reducers/currentUser.reducer';
import { createToasterNotification } from '../../../common/utils/toaster-notification';
import { logout } from '../../../services';

export const googleLogoutSuccess = (): IGoogleLogoutSuccess => ({
  type: AuthTypes.GOOGLE_LOGOUT_SUCCESS,
});

export const googleLogoutFailure = (error: string): IGoogleLogoutFailure => ({
  type: AuthTypes.GOOGLE_LOGOUT_FAILURE,
  error,
});

export const googleLogout = () => async (dispatch: Dispatch, getState: () => AppState) => {
  const { auth } = getState();
  try {
    await logout(auth.data?.accessToken || '');
    dispatch(clearMeState());
    dispatch(googleLogoutSuccess());
    localStorage.removeItem('selectedResource');
    localStorage.removeItem('requiredResource');
    window.location.replace('/');
  } catch (e) {
    dispatch(googleLogoutFailure(e as any));
    dispatch(createToasterNotification(e));
  }
};
