import { BaseDataItem, OrderType, TableMenuAction } from './interfaces';
import { ChipInput } from '../index';
import { EditPencilIcon, Refresh, TrashIcon, VerticalMenu } from '../../assets';
import {
  EditPencilIconContainer,
  EditableContainer,
  RefreshIconWrapper,
  TableMenuButton,
  TableTopActions,
  TrashIconContainer,
} from './styles';
import {
  FormControlLabel,
  IconButton,
  LinearProgress,
  Menu,
  MenuItem,
  Table as MuiTable,
  Radio,
  RadioGroup,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import { getComparator, stableSort } from './utils';
import Checkbox from '@material-ui/core/Checkbox';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import React from 'react';
import TableHead, { TableHeadCell } from './TableHead';

interface IState {
  order: OrderType;
  orderBy: string;
  selected: string[];
  open: string[];
  menuAnchor?: HTMLDivElement;
  openMenuRowId: string;
  openMenuRowName: string;
  filter: string[];
}

interface IProps<T> {
  data: T[];
  rowKey?: string;
  renderCell?: (columnName: keyof T | string, rowIndex: number, cell: T) => React.ReactNode;
  headCells?: TableHeadCell<T>[];
  tableMenuActions?: TableMenuAction[];
  showMenu?: (id: string) => boolean;
  refresh?: () => void;
  select?: boolean;
  singleSelect?: boolean;
  withTopActions?: boolean;
  isLoading?: boolean;
  topButton?: React.ReactNode;
  isSearchEnabled?: boolean;
  isRefreshEnabled?: boolean;
  handleDelete?: (id: string) => void;
  handleEdit?: (id: string) => void;
  onSelectionChanged?: (selected: []) => void;
  getCollapseRow?: (item: T, isOpen: boolean) => JSX.Element;
}

class Table<DataItem extends BaseDataItem> extends React.Component<IProps<DataItem>, IState> {
  constructor(props: IProps<DataItem>) {
    super(props);
    this.state = {
      order: 'asc',
      orderBy: '',
      selected: [],
      open: [],
      openMenuRowId: '',
      openMenuRowName: '',
      filter: [],
    };
  }

  handleRequestSort = (_event: React.MouseEvent, property: keyof DataItem) => {
    const { order, orderBy } = this.state;
    const isAsc = orderBy === property && order === 'asc';
    this.setState({ order: isAsc ? 'desc' : 'asc', orderBy: String(property) });
  };

  handleClick = (_event: React.MouseEvent, rowId: string) => {
    const { selected } = this.state;
    let newSelected: string[] = [];

    if (this.props.singleSelect) {
      selected.splice(0, selected.length);
      selected.push(rowId);
      this.setState({ selected });
      if (this.props.onSelectionChanged) {
        this.props.onSelectionChanged(selected as []);
      }
      return;
    }

    const selectedIndex = selected.indexOf(rowId);
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, rowId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    this.setState({ selected: newSelected });
    if (this.props.onSelectionChanged) {
      this.props.onSelectionChanged(newSelected as []);
    }
  };

  handleOpenClick = (_event: React.MouseEvent, rowId: string) => {
    const { open } = this.state;
    let newSelected: string[] = [];

    if (this.props.singleSelect) {
      open.splice(0, open.length);
      open.push(rowId);
      this.setState({ open });
      if (this.props.onSelectionChanged) {
        this.props.onSelectionChanged(open as []);
      }
      return;
    }

    const selectedIndex = open.indexOf(rowId);
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(open, rowId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(open.slice(1));
    } else if (selectedIndex === open.length - 1) {
      newSelected = newSelected.concat(open.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(open.slice(0, selectedIndex), open.slice(selectedIndex + 1));
    }
    this.setState({ open: newSelected });
  };

  handleMenuClose = () => this.setState({ menuAnchor: undefined });

  handleMenuItemClick = (menuAction: TableMenuAction) => {
    const { openMenuRowId, openMenuRowName } = this.state;
    menuAction.onClick(openMenuRowId, openMenuRowName);
    this.handleMenuClose();
  };

  handleMenuShow = (row: DataItem): boolean => {
    if (this.props.showMenu) {
      return this.props.showMenu(String(row[this.props.rowKey || 'id']));
    }
    return true;
  };

  handleMenuItemHidden = (menuAction: TableMenuAction): boolean => {
    if (menuAction.hidden) {
      const { openMenuRowId } = this.state;
      return menuAction.hidden(openMenuRowId);
    }
    return false;
  };

  handleRefresh = () => {
    if (this.props.refresh) {
      this.props.refresh();
    }
  };

  handleFilterChange = (newFilter: string[]) => {
    this.setState({ filter: newFilter });
  };

  handleRowDelete = (row: DataItem) => {
    this.props.handleDelete?.(String(row[this.props.rowKey || 'id']));
  };

  handleRowEdit = (row: DataItem) => {
    this.props.handleEdit?.(String(row[this.props.rowKey || 'id']));
  };

  filterData = (headers: TableHeadCell<DataItem>[]): DataItem[] => {
    const { filter } = this.state;
    const dataHeader = headers.filter((h) => h.type !== 'menu' && h.label !== '');
    const { data } = this.props;
    if (filter.length === 0) {
      return data;
    }

    const itemFilter = (item: DataItem): boolean => {
      return !!dataHeader.find((header) => {
        return filter.find((filt) => {
          return item[header.id as string] && item[header.id as string].length
            ? item[header.id as string]?.toLowerCase().includes(filt?.toLowerCase())
            : false;
        });
      });
    };

    return data.filter(itemFilter);
  };

  public render() {
    const { selected, open, order, orderBy, menuAnchor, filter } = this.state;

    const { data, headCells, tableMenuActions = [], select, withTopActions = true } = this.props;

    const rowKey = this.props.rowKey || 'id';

    const isSelected = (rowId: string) => selected.indexOf(rowId) !== -1;
    const isOpen = (rowId: string) => open.indexOf(rowId) !== -1;

    const headerCells: TableHeadCell<DataItem>[] =
      headCells ||
      (data.length === 0
        ? []
        : Object.keys(data[0])
            .filter((key) => key !== rowKey)
            .map((keyName: string) => ({ id: keyName, label: keyName })));

    const filteredData = this.filterData(headerCells);

    return (
      <>
        {withTopActions && (
          <TableTopActions>
            {this.props.isRefreshEnabled !== false && (
              <RefreshIconWrapper>
                <Refresh onClick={this.handleRefresh} />
              </RefreshIconWrapper>
            )}
            {this.props.isSearchEnabled !== false && <ChipInput value={filter} onChange={this.handleFilterChange} />}
            {this.props.topButton && this.props.topButton}
          </TableTopActions>
        )}
        {this.props.isLoading && <LinearProgress />}
        <TableContainer>
          <RadioGroup style={{ display: 'block' }} aria-label="row" name="row">
            <MuiTable>
              <TableHead
                select={select}
                headCells={headerCells}
                order={order}
                orderBy={orderBy}
                onRequestSort={this.handleRequestSort}
              />
              <TableBody>
                {stableSort(filteredData, getComparator(order, orderBy)).map((row: DataItem, rowIndex: number) => {
                  const rowIdString = String(row[this.props.rowKey || 'id']);
                  const isItemSelected = isSelected(rowIdString);
                  const isOpenElem: boolean = isOpen(rowIdString);

                  return (
                    <React.Fragment key={rowIdString}>
                      <TableRow
                        hover={true}
                        onClick={(e: React.MouseEvent) => this.handleClick(e, rowIdString)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        selected={isItemSelected}
                      >
                        {this.props.getCollapseRow && (
                          <TableCell padding="checkbox">
                            <IconButton
                              aria-label="expand row"
                              size="small"
                              onClick={(e: React.MouseEvent) => this.handleOpenClick(e, rowIdString)}
                            >
                              {isOpenElem ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                          </TableCell>
                        )}

                        {select && !this.props.singleSelect && (
                          <TableCell padding="checkbox">
                            <Checkbox checked={isItemSelected} />
                          </TableCell>
                        )}

                        {select && this.props.singleSelect && (
                          <TableCell padding="checkbox">
                            <FormControlLabel
                              checked={isItemSelected}
                              color="primary"
                              value={rowIdString}
                              control={<Radio color="primary" />}
                              label=""
                            />
                          </TableCell>
                        )}

                        {headerCells
                          .filter((value) => value.label !== '')
                          .map(({ id, type, ...headerCell }) => (
                            <TableCell
                              {...headerCell}
                              key={String(id)}
                              align={['menu', 'editable'].includes(type as string) ? 'right' : 'inherit'}
                              component="th"
                            >
                              {type === 'menu' && this.renderMenuCell(row)}
                              {type === 'editable' && this.renderEditableCell(row)}
                              {type !== 'menu' &&
                                type !== 'editable' &&
                                this.renderDefaultCell(
                                  {
                                    id,
                                    type,
                                  },
                                  rowIndex,
                                  row,
                                )}
                            </TableCell>
                          ))}
                      </TableRow>
                      {this.props.getCollapseRow && this.props.getCollapseRow(row, isOpenElem)}
                    </React.Fragment>
                  );
                })}
              </TableBody>
            </MuiTable>
          </RadioGroup>
        </TableContainer>
        <Menu
          id="simple-menu"
          anchorEl={menuAnchor}
          keepMounted={true}
          open={Boolean(menuAnchor)}
          onClose={this.handleMenuClose}
        >
          {tableMenuActions.map(
            (menuAction) =>
              !this.handleMenuItemHidden(menuAction) && (
                <MenuItem key={menuAction.label} onClick={() => this.handleMenuItemClick(menuAction)}>
                  {menuAction.label}
                </MenuItem>
              ),
          )}
        </Menu>
      </>
    );
  }

  private renderDefaultCell = (headerCell: TableHeadCell<DataItem>, rowIndex: number, row: DataItem) => {
    const { renderCell } = this.props;
    return renderCell ? renderCell(headerCell.id, rowIndex, row) : String(row[headerCell.id as keyof DataItem]);
  };

  private renderMenuCell = (row: DataItem) => {
    return (
      this.handleMenuShow(row) && (
        <TableMenuButton
          onClick={(e) => {
            e.stopPropagation();
            this.setState({
              menuAnchor: e.currentTarget,
              openMenuRowId: String(row[this.props.rowKey || 'id']),
              openMenuRowName: String(row?.name),
            });
          }}
        >
          <VerticalMenu />
        </TableMenuButton>
      )
    );
  };

  private renderEditableCell = (row: DataItem) => {
    return (
      <EditableContainer>
        {this.props.handleEdit && (
          <EditPencilIconContainer onClick={() => this.handleRowEdit(row)}>
            <EditPencilIcon />
          </EditPencilIconContainer>
        )}
        {this.props.handleDelete && (
          <TrashIconContainer onClick={() => this.handleRowDelete(row)}>
            <TrashIcon />
          </TrashIconContainer>
        )}
      </EditableContainer>
    );
  };
}

export default Table;
