import { ErrorIcon, FinishedIcon, RunningIcon, WarnIcon } from '../../../assets';
import { StatusBlock } from './workspace-status-block.styles';
import { replaceUnderScoreToCamel } from '../../../common/utils/string';
import React from 'react';

interface WorkspaceStatusBlockProps {
  status: string;
}

export const WorkspaceStatusBlock: React.FC<WorkspaceStatusBlockProps> = (props) => {
  const getIcon = (status: string) => {
    switch (status) {
      case 'RUNNING': {
        return <RunningIcon className={'icon'} />;
      }
      case 'FINISHED': {
        return <FinishedIcon className={'icon'} />;
      }
      case 'WAITING_FOR_APPROVAL': {
        return <WarnIcon className={'icon'} />;
      }
      case 'REJECTED': {
        return <ErrorIcon className={'icon'} />;
      }
      case 'ERROR': {
        return <ErrorIcon className={'icon'} />;
      }
    }
  };

  return (
    <StatusBlock className={props.status.toLowerCase()}>
      {getIcon(props.status)}
      {replaceUnderScoreToCamel(props.status)}
    </StatusBlock>
  );
};
