import * as S from './styles';
import { PipelineStep } from '../../common/models/interfaces';
import React from 'react';

interface TPipelineStepElementProps {
  step: PipelineStep;
  setSelectedStep: React.Dispatch<React.SetStateAction<PipelineStep | undefined>>;
  setSteps: React.Dispatch<React.SetStateAction<PipelineStep[]>>;
}

function PipelineStepElement({ step, setSelectedStep, setSteps }: TPipelineStepElementProps) {
  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      {step.type !== '+ Custom Python component' && <S.ExtensionIcon />}
      {step.type === '+ Custom Python component' && <S.CodeIcon />}
      <S.Description>
        <S.StepName>{step?.name}</S.StepName>
        <br />
        {step.type}
      </S.Description>

      <S.EditIcon
        onClick={() => {
          setSelectedStep(step);
        }}
        fontSize="small"
      />

      <S.DeleteIcon
        onClick={() => {
          setSteps((prevSteps) => {
            const lines =
              prevSteps
                .filter(
                  (lineStep) =>
                    lineStep.type === 'straight' && (lineStep?.source === step.id || lineStep?.target === step.id),
                )
                .map((lineStep) => lineStep.id) || [];

            return prevSteps.filter((element) => element.id !== step?.id && !lines.includes(element.id));
          });
        }}
        fontSize="small"
      />
    </div>
  );
}

export default PipelineStepElement;
