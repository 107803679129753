import { PageActionWrapper, PageTitle, PageTitleWrapper, RoutePageWrapper } from './router-with-header.styles';
import { Title, TitleAction } from '../../routes/config';
import { useLocation } from 'react-router-dom';
import { useSelector } from '../../store/configure-store';
import React from 'react';

const RouteWithHeader: React.FC<{ title?: Title; titleAction?: TitleAction }> = ({ children, title, titleAction }) => {
  const state = useSelector((storeState) => storeState);
  const location = useLocation();
  const resolvedTitle = typeof title === 'function' ? title({ state, location }) : title;
  let ActionComponent;

  if (titleAction) {
    ActionComponent = titleAction.component;
  }

  return (
    <>
      {resolvedTitle && (
        <PageTitleWrapper>
          <PageTitle>{resolvedTitle}</PageTitle>
          {ActionComponent && (
            <PageActionWrapper>
              <ActionComponent />
            </PageActionWrapper>
          )}
        </PageTitleWrapper>
      )}
      <RoutePageWrapper width={'100%'}>{children}</RoutePageWrapper>
    </>
  );
};

export default RouteWithHeader;
