import { MPMCharts, MPMHistoryChartBase } from '../../common/models/interfaces';
import { urls } from '../../common/utils/urls';
import api from '../../common/utils/api';

interface TGetChartByPlotTypeOptions {
  cb?: (chartData: MPMCharts) => void;
  beforeAction?: () => void;
}

interface TGetHistoryChartOptions {
  cb?: (chartData: MPMHistoryChartBase[]) => void;
  beforeAction?: () => void;
}

async function getChartByPlotType(
  domainId: string,
  modelId: string,
  selectedPlotType: string,
  selectedDate: string,
  options?: TGetChartByPlotTypeOptions,
) {
  try {
    options?.beforeAction?.();
    const { data } = await api.get<{ data: MPMCharts }>(
      urls.getPlotChart(domainId, modelId, selectedPlotType, selectedDate),
    );
    options?.cb?.(data);
  } catch (e) {
    console.error(e);
  }
}

async function getHistoryChart(domainId: string, modelId: string, plotType: string, options?: TGetHistoryChartOptions) {
  try {
    const historyCharts = await api.get<MPMHistoryChartBase[]>(urls.getHistoryChart(domainId, modelId, plotType));
    options?.cb?.(historyCharts);
  } catch (e) {
    console.error(e);
  }
}

export { getChartByPlotType, getHistoryChart };
