import React, { ReactNode } from 'react';
import WithRedux from './WithRedux';
import WithTheme from './WithTheme';

const WithProviders = ({ children }: { children: ReactNode }) => {
  return (
    <WithRedux>
      <WithTheme>{children}</WithTheme>
    </WithRedux>
  );
};

export default WithProviders;
