import { FormControlLabel, Switch } from '@material-ui/core';
import { TourItem, getTours, saveTour } from './requests';
import React, { useCallback, useEffect, useState } from 'react';

const TourSettings: React.FC = () => {
  const [tours, setTours] = useState<TourItem[]>();
  const handleGetTours = useCallback(getTours, []);

  useEffect(() => {
    handleGetTours(setTours);
  }, [handleGetTours]);

  return (
    <>
      {tours &&
        tours.map((tour) => (
          <div key={tour.id}>
            <FormControlLabel
              control={
                <>
                  <Switch
                    checked={!tour.finished}
                    onChange={(_, checked) => {
                      saveTour(tour.id, checked, setTours);
                    }}
                    name={tour.label}
                  />
                </>
              }
              label={tour.label}
            />
          </div>
        ))}
    </>
  );
};

export default TourSettings;
