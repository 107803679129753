import { Button } from '@material-ui/core';
import { GoogleLogout } from 'react-google-login';
import { environmentVariable } from '../../common/utils/environment-variable';
import { googleLogout, googleLogoutFailure } from '../../store/auth/actions';
import { persistor } from '../../store/configure-store';
import { useDispatch } from 'react-redux';
import React, { useCallback } from 'react';

const LogoutButton = () => {
  const dispatch = useDispatch();
  const handleLogoutSuccess = useCallback(() => {
    dispatch(googleLogout());
    persistor.purge();
  }, [dispatch]);

  const handleLogoutFailure = useCallback(() => {
    dispatch(googleLogoutFailure('Logout error'));
  }, [dispatch]);

  return (
    <>
      <GoogleLogout
        render={(props) => {
          return (
            <Button {...props} variant="outlined" onClick={handleLogoutSuccess}>
              Logout
            </Button>
          );
        }}
        onLogoutSuccess={handleLogoutSuccess}
        onFailure={handleLogoutFailure}
        clientId={environmentVariable.googleClientId}
      />
    </>
  );
};

export default LogoutButton;
