import { LoadingSpinnerContainer } from '../../../components/loading-spinner/loading-spinner.styles';
import styled from 'styled-components';

export const CardRoot = styled.div`
  display: inline-block;
  padding: 10px;
  & .card {
    max-width: 200px;
    padding: 8px;
    background-color: #fcfcfc;
    & .content {
      height: 150px;
      & .title {
      }
      & .description {
        overflow: hidden;
      }
    }
    & .media {
      height: 100px;
      background-size: contain;
      background-color: white;
    }

    & .actions {
      align-items: center;
      flex-direction: column;
    }
  }
`;

export const TemplateSelectContainer = styled.div`
  margin-bottom: 15px;
`;

export const SelectedTemplateContainer = styled.div`
  & .change-template-container {
    align-items: flex-end;
  }
`;

export const TemplateVariablesContainer = styled.div`
  margin-bottom: 25px;
`;

export const ContentContainer = styled.div`
  position: relative;
`;

export const LoadingSpinnerContainerWbg = styled(LoadingSpinnerContainer)`
  background-color: rgb(255 255 255 / 70%);
  position: absolute;
  z-index: 10;
  min-height: auto;
`;

export const DependecyRow = styled.div`
  display: grid;
  grid-template-columns: 24px 1fr;
  grid-column-gap: 10px;
  max-width: 500px;
  margin-bottom: 25px;
`;
