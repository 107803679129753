import styled from 'styled-components';
import theme from '../../../theme';

export const StatusBlock = styled.div`
  width: auto;
  height: auto;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 10px;
  padding-right: 10px;
  color: white;
  font-size: 14px;
  border-radius: 2px;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  & .icon {
    margin-right: 5px;
  }
  &.running {
    background-color: ${() => theme.colors.plain};
  }
  &.waiting_for_approval {
    background-color: ${() => theme.colors.warning};
  }
  &.rejected,
  &.error {
    background-color: ${() => theme.colors.othersError};
  }
  &.finished {
    background-color: ${() => theme.colors.success};
  }
`;
