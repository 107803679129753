import { AlertPolicyConditionWindows, AlertPolicyConditions, AlertPolicyMetrics } from '../../common/models/enums';
import { AlertPolicyDTO } from '../../common/models/interfaces';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { TextValidator } from 'react-material-ui-form-validator';
import {
  resolveConditionLabel,
  resolveConditionWindowLable,
  resolveMetricTypeLable,
} from '../AlertPolicies/dictionary';
import React, { useEffect, useState } from 'react';

interface TAlertPolicyFormProps {
  onChange?: (policy: AlertPolicyDTO) => void;
  edit?: AlertPolicyDTO;
}

const hasPolicyExtraParams = (condition: AlertPolicyConditions) => {
  const hasArray = ['INCREASED_BY', 'DECREASED_BY', 'CHANGED_BY'];
  if (hasArray.includes(condition)) {
    return true;
  }
  return false;
};

const AlertPolicyForm: React.FC<TAlertPolicyFormProps> = ({ onChange, edit }) => {
  const conditions = Object.keys(AlertPolicyConditions);
  const conditionWindows = Object.keys(AlertPolicyConditionWindows);
  const metricTypes = Object.keys(AlertPolicyMetrics);
  const initialPolicy: AlertPolicyDTO = {
    condition: conditions[0] as AlertPolicyConditions,
    metricType: metricTypes[0] as AlertPolicyMetrics,
    policyName: '',
    value: 0,
    conditionWindow: conditionWindows[0] as AlertPolicyConditionWindows,
    conditionWindowSize: 0,
  };

  const [policy, setPolicy] = useState<AlertPolicyDTO>(edit ? edit : initialPolicy);

  useEffect(() => {
    onChange && onChange(policy);
  }, [policy, onChange]);

  useEffect(() => {
    if (policy.condition && hasPolicyExtraParams(policy.condition)) {
    }
  }, [policy.condition]);

  return (
    <>
      <TextValidator
        validators={['required']}
        errorMessages={['This field is required!']}
        name="policyName"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setPolicy((prevPolicyState) => ({
            ...prevPolicyState,
            policyName: e.target.value,
          }))
        }
        type="text"
        variant="outlined"
        autoFocus
        margin="dense"
        id="plocyName"
        label="Policy name"
        fullWidth
        value={policy.policyName}
      />

      <TextValidator
        errorMessages={['This field is required!', 'You need to specific number above 0!']}
        validators={['required', 'minNumber:0']}
        name="value"
        variant="outlined"
        style={{ marginTop: '12px' }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setPolicy((prevPolicyState) => ({
            ...prevPolicyState,
            value: parseFloat(e.target.value),
          }))
        }
        inputProps={{
          step: '0.1',
        }}
        type="number"
        autoFocus
        margin="dense"
        id="value"
        label="Value"
        fullWidth
        value={policy.value.toString()}
      />
      <FormControl style={{ marginTop: '12px' }} size="small" fullWidth variant="outlined">
        <InputLabel htmlFor="metric-type">Metric Type</InputLabel>
        <Select
          style={{ marginBottom: '1em', width: '100%' }}
          labelId="metric-type"
          value={policy.metricType}
          inputProps={{
            name: 'metric-type',
            id: 'metric-type',
          }}
          label="Metric Type"
          variant="outlined"
          onChange={(e) => {
            setPolicy((prevPolicyState) => ({ ...prevPolicyState, metricType: e.target.value as AlertPolicyMetrics }));
          }}
        >
          {metricTypes.map((metricType) => (
            <MenuItem key={metricType} value={metricType}>
              {resolveMetricTypeLable(metricType)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl style={{ marginTop: '12px' }} size="small" fullWidth variant="outlined">
        <InputLabel htmlFor="condition">Condition</InputLabel>
        <Select
          style={{ marginBottom: '1em', width: '100%' }}
          labelId="condition"
          value={policy.condition}
          inputProps={{
            name: 'condition',
            id: 'condition',
          }}
          label="Condition"
          variant="outlined"
          onChange={(e) => {
            setPolicy((prevPolicyState) => ({
              ...prevPolicyState,
              condition: e.target.value as AlertPolicyConditions,
            }));
          }}
        >
          {conditions.map((condition) => (
            <MenuItem key={condition} value={condition}>
              {resolveConditionLabel(condition)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {hasPolicyExtraParams(policy.condition) && (
        <FormControl style={{ marginTop: '12px' }} size="small" fullWidth variant="outlined">
          <InputLabel htmlFor="condition-window">Condition Window</InputLabel>
          <Select
            style={{ marginBottom: '1em', width: '100%' }}
            labelId="condition-window"
            value={policy.conditionWindow || conditionWindows[0]}
            inputProps={{
              name: 'condition-window',
              id: 'condition-window',
            }}
            label="Condition Window"
            variant="outlined"
            onChange={(e) => {
              setPolicy((prevPolicyState) => ({
                ...prevPolicyState,
                conditionWindow: e.target.value as AlertPolicyConditionWindows,
              }));
            }}
          >
            {conditionWindows.map((conditionWindow) => (
              <MenuItem key={conditionWindow} value={conditionWindow}>
                {resolveConditionWindowLable(conditionWindow)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      {hasPolicyExtraParams(policy.condition) && (
        <TextValidator
          errorMessages={['This field is required!', 'You need to specific number above 0!']}
          validators={['required', 'minNumber:1']}
          name="window-size"
          variant="outlined"
          style={{ marginTop: '12px' }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setPolicy((prevPolicyState) => ({
              ...prevPolicyState,
              conditionWindowSize: parseFloat(e.target.value),
            }))
          }
          inputProps={{
            step: '1',
          }}
          type="number"
          autoFocus
          margin="dense"
          id="window-size"
          label="Condition Window Size"
          fullWidth
          value={policy?.conditionWindowSize?.toString()}
        />
      )}
    </>
  );
};

export default AlertPolicyForm;
