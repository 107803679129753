// tslint:disable-next-line:no-any
export function getErrorMessage(error: any): string {
  return error.response?.data?.message ?? error.message ?? error;
}

// tslint:disable-next-line:no-any
export function getMessage(data: any): string {
  if (typeof data === 'string') {
    return data;
  }
  return data.message || 'Something went wrong';
}
