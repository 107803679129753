import { Column, Row } from '../../common/styles/common.styles';
import styled from 'styled-components';

export const WorkspaceDetailsContainer = styled(Column)`
  height: 100%;
  & .tabs {
    margin-bottom: 10px;
  }
`;

export const TabContent = styled.div<{ isVisible: boolean }>`
  ${(p) => !p.isVisible && 'display: none'};
`;

export const LabelRow = styled(Row)`
  display: flex;
  padding: 20px;
  padding-bottom: 0px;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: center;
`;
