import { setOpened, setRequiredResource } from '../../store/dynamic-selector/actions';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useSelector } from '../../store/configure-store';
import React, { useEffect, useMemo, useState } from 'react';
import RouteWithHeader from '../../containers/route-with-header/route-with-header';

const withResources = (Component: React.FC<any>, Wrapper = RouteWithHeader) => {
  const WrappedComponent = (props: any) => {
    const [renderable, setRenderable] = useState(false);
    const dispatch = useDispatch();
    const selectedResource = useSelector((state) => state.dynamicSelector.selectedResource);
    const { search } = useLocation();
    const params = useMemo(() => new URLSearchParams(search), [search]);

    useEffect(() => {
      dispatch(setRequiredResource(props?.requiredResource));
      if (props?.requiredResource && !params.get('force')) {
        if (props.requiredResource.includes(selectedResource?.type)) {
          setRenderable(true);
        } else {
          dispatch(setOpened({ opened: true, requiredResource: props?.requiredResource }));
        }
      } else {
        setRenderable(true);
      }
    }, [selectedResource, dispatch, props, params]);

    if (renderable) {
      const finalProps = {
        ...props,
        ...(params.get('force') && { force: params.get('force') }),
      };

      return (
        <Wrapper title={props.title}>
          <Component {...finalProps} />
        </Wrapper>
      );
    } else {
      return null;
    }
  };

  return WrappedComponent;
};

export default withResources;
