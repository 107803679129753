import { AnyAction, Dispatch } from 'redux';
import { AppState } from '../../common/models/interfaces';
import { GET_RESOURCES, GET_RESOURCE_BY_ID, SET_OPENED, SET_REQUIRED_RESOURCE, SET_RESOURCE } from './action-types';
import { Resource, ResourceType } from '../../common/models/resource.model';
import { ThunkDispatch } from 'redux-thunk';
import { urls } from '../../common/utils/urls';
import api from '../../common/utils/api';

export const setResource = (resource: Resource | null) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_RESOURCE,
    payload: resource,
  });
  if (resource === null) {
    localStorage.removeItem('selectedResource');
  } else {
    localStorage.setItem('selectedResource', JSON.stringify(resource));
  }
};

export const setRequiredResource = (type: ResourceType[] | null) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_REQUIRED_RESOURCE,
    payload: type,
  });
  localStorage.setItem('requiredResource', type?.join() || '');
};

export const setOpened = ({
  opened,
  requiredResource,
}: {
  opened: boolean;
  requiredResource: ResourceType[] | null | undefined;
}) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_REQUIRED_RESOURCE,
    payload: requiredResource,
  });
  dispatch({
    type: SET_OPENED,
    payload: opened,
  });
  localStorage.setItem('requiredResource', requiredResource?.join() || '');
};

export const getResources = () => async (dispatch: Dispatch) => {
  try {
    const resources = await api.get(urls.resources());
    dispatch({
      type: GET_RESOURCES,
      payload: resources,
    });
  } catch (e) {
    console.log(e);
  }
};

export const getResourceById = (workspaceId: string | null) => async (
  dispatch: ThunkDispatch<AppState, unknown, AnyAction>,
) => {
  try {
    const resource: Resource = await api.get(urls.getResourceById(workspaceId));
    dispatch(setResource(resource));
  } catch (e) {
    console.log(e);
  }
};

interface SetResource {
  type: typeof SET_RESOURCE;
  payload: Resource;
}

interface GetResources {
  type: typeof GET_RESOURCES;
  payload: Resource[];
}

interface SetOpened {
  type: typeof SET_OPENED;
  payload: {
    opened: boolean;
    requiredResoure?: ResourceType | null;
  };
}

interface SetRequiredResource {
  type: typeof SET_REQUIRED_RESOURCE;
  payload: ResourceType | null;
}

interface GetResourceById {
  type: typeof GET_RESOURCE_BY_ID;
  payload: Resource;
}

export type TDynamicSelectorAction = SetResource | GetResources | SetOpened | SetRequiredResource | GetResourceById;
