import { Autocomplete } from '@material-ui/lab';
import { BlueprintDependency } from '../../common/models/interfaces';
import { Button, CardContent, CircularProgress, Paper, TextField, Tooltip } from '@material-ui/core';
import { CookieCutterVariables } from '../../components/cookie-cutter-variables/cookie-cutter-variables';
import { DependecyRow } from '../deploy-blueprints/template-adder/template-adder.styles';
import {
  GenerateRepository,
  ManagedInstanceDto,
  Template,
  TemplateVariables,
} from '../../common/models/workspace.models';
import { HalfCardContainer } from '../../common/styles/common.styles';
import { InfoCircleIcon } from '../../assets';
import { ManagedServiceType } from '../../common/models/enums';
import { path } from '../../routes/path';
import { urls } from '../../common/utils/urls';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from '../../store/configure-store';
import BlueprintDependencyRow from '../../components/BlueprintDependencyRow/BlueprintDependencyRow';
import React, { useCallback, useEffect, useState } from 'react';
import VersionSelector from '../../components/VersionSelector/VersionSelector';
import api from '../../common/utils/api';
import withResources from '../../components/withResources/withResources';

const UpgradeBlueprintComp = () => {
  const selectedResource = useSelector((state) => state.dynamicSelector.selectedResource);
  const selectedVersion = useSelector((state) => state.version.selectedVersion);
  const [initialRepoData, setInitialRepoData] = useState<GenerateRepository | null>({
    newRepository: false,
    cookieCutterContent: {},
  });
  const { templateId } = useParams() as { templateId: string };
  const [templateVariables, setTemplateVariables] = useState<TemplateVariables>();
  const [template, setTemplate] = useState<Template>();
  const [isTemplateVariablesFething, setIsTemplateVariablesFething] = useState<boolean>(false);
  const [generateRepository, setGenerateRepository] = useState<GenerateRepository | null>();
  const [hasMlflow, setHasMlflow] = useState<boolean>(false);
  const [hasKubeflow, setHasKubeflow] = useState<boolean>(false);
  const [hasBlueprintDependency, setHasBlueprintDependency] = useState<boolean>(false);
  const [selectedMlflow, setSelectedMlflow] = useState<ManagedInstanceDto | null>(null);
  const [mlFlows, setMlflows] = useState<ManagedInstanceDto[]>();
  const [selectedPipeline, setSelectedPipeline] = useState<ManagedInstanceDto | null>(null);
  const [pipelines, setPipelines] = useState<ManagedInstanceDto[]>();
  const navigate = useNavigate();
  const [isUpgradeInProgress, setIsUpgradeInProgress] = useState<boolean>(false);
  const [blueprintDependencies, setBlueprintDependencies] = useState<BlueprintDependency[]>();
  const [blueprintDependencyData, setblueprintDependencyData] = useState<{ [index: string]: string }>();

  const fetchTemplateVariables = async () => {
    try {
      setIsTemplateVariablesFething(true);
      const version = selectedVersion?.id || null;

      if (version && templateId && selectedResource?.id) {
        const variables = await api.get<TemplateVariables>(
          urls.getTemplateVariablesActual(templateId, version, selectedResource?.id),
        );

        //Init and prepare for the blueprint dependencies
        if (variables.blueprintDependencies) {
          setBlueprintDependencies(variables.blueprintDependencies);
          const initBlueprintData = {};
          variables.blueprintDependencies.forEach((dependency) => {
            initBlueprintData[dependency.id] = null;
          });
          setblueprintDependencyData(initBlueprintData);
        }
        ////////////////////////////////////////////////////////

        setTemplateVariables(variables as TemplateVariables);

        const templateVar = {};
        variables.cookieCutterContent.items.forEach((value) => {
          templateVar[value.key] = value.value;
        });
        setInitialRepoData(
          (prevRepoData) => ({ ...prevRepoData, cookieCutterContent: templateVar } as GenerateRepository),
        );

        setIsTemplateVariablesFething(false);

        setHasMlflow(variables.managedServiceDependencies.indexOf(ManagedServiceType.MLFLOW) !== -1);
        setHasKubeflow(variables.managedServiceDependencies.indexOf(ManagedServiceType.KUBEFLOW) !== -1);
        setHasBlueprintDependency(variables?.blueprintDependencies?.length > 0);
      }
    } catch (e) {
      console.log('fetchTemplateVariables', e);
      setIsTemplateVariablesFething(false);
    }
  };

  const fetchTemplate = async () => {
    try {
      const templateData = await api.get(urls.getTemplate(templateId));
      setTemplate(templateData as Template);
    } catch (e) {
      console.log('fetchTemplate', e);
    }
  };

  const fetchMlflows = async () => {
    try {
      const mlFlowsData = await api.get<ManagedInstanceDto[]>(urls.getMlflows(selectedResource?.domainId || ''), {
        params: { state: 'DEPLOY_SUCCESS' },
      });
      setMlflows(mlFlowsData);
    } catch (e) {
      console.log('fetchMlflows', e);
    }
  };

  const fetchKubeFlows = async () => {
    try {
      const kubeFlows = await api.get<ManagedInstanceDto[]>(urls.getKubeflows(selectedResource?.domainId || ''), {
        params: { state: 'DEPLOY_SUCCESS' },
      });
      if (kubeFlows) {
        setPipelines(kubeFlows);
      }
    } catch (e) {
      console.log('fetchMlflows', e);
    }
  };

  const fetchMlflowsCB = useCallback(fetchMlflows, [selectedResource?.domainId]);
  const fetchKubeFlowsCB = useCallback(fetchKubeFlows, [selectedResource?.domainId]);
  const fetchTemplateCB = useCallback(fetchTemplate, [templateId]);
  const fetchTemplateVariablesCB = useCallback(fetchTemplateVariables, [selectedVersion, selectedResource, templateId]);

  useEffect(() => {
    fetchMlflowsCB();
    fetchKubeFlowsCB();
    fetchTemplateCB();
  }, [templateId, fetchMlflowsCB, fetchKubeFlowsCB, fetchTemplateCB]);

  useEffect(() => {
    fetchTemplateVariablesCB();
  }, [template, fetchTemplateVariablesCB, selectedVersion]);

  const onUpgrade = async () => {
    try {
      setIsUpgradeInProgress(true);

      //--Add Manged Service Object
      const managedServiceDependencies = {};
      if (selectedMlflow) managedServiceDependencies['MLFLOW'] = selectedMlflow.id;
      if (selectedPipeline) managedServiceDependencies['KUBEFLOW'] = selectedPipeline.id;
      //--Add Manged Service Object END

      await api.put(urls.upgradeTemplate(selectedResource?.id || null, template?.id || null), {
        ...generateRepository,
        templateVersionId: selectedVersion?.id || '',
        ...generateRepository,
        managedServiceDependencies,
        blueprintDependencies: blueprintDependencyData,
      });
      navigate(path.deployBlueprints());
    } catch (e) {
      setIsUpgradeInProgress(false);
      console.log('onUpgrade', e);
    }
  };

  useEffect(() => {
    if (!selectedMlflow && mlFlows) {
      setSelectedMlflow(mlFlows[0]);
    }
  }, [mlFlows, selectedMlflow]);

  useEffect(() => {
    if (!selectedPipeline && pipelines) {
      setSelectedPipeline(pipelines[0]);
    }
  }, [pipelines, selectedPipeline]);

  return (
    <HalfCardContainer>
      <CardContent>
        <div style={{ width: '455px', margin: '0 auto' }}>
          <VersionSelector styles={{ marginLeft: '-12px', marginBottom: '16px' }} template={template} />
        </div>
        <CookieCutterVariables
          hideCheckbox
          hideRepositoryName
          validationError={null}
          templateVariables={templateVariables?.cookieCutterContent.items || []}
          data={initialRepoData}
          isFetching={isTemplateVariablesFething}
          onDataChanged={(data) => {
            setGenerateRepository(data);
          }}
        />

        {(hasMlflow || hasKubeflow || hasBlueprintDependency) && (
          <Paper className="deps" elevation={1} style={{ padding: '1em', maxWidth: '550px', margin: '0 auto' }}>
            <h2 style={{ margin: 0, marginBottom: '1em', textAlign: 'center' }}>Dependencies</h2>
            {hasMlflow && (
              <DependecyRow>
                <div>
                  <Tooltip title={'Experiment instance for experiment tracking'} aria-label="add">
                    <InfoCircleIcon />
                  </Tooltip>
                </div>
                <Autocomplete
                  disableClearable={true}
                  options={mlFlows || []}
                  renderInput={(params) => <TextField {...params} label="Experiments" variant="outlined" fullWidth />}
                  getOptionLabel={(option) => option.name}
                  onChange={(_, mlflow) => {
                    setSelectedMlflow(mlflow);
                  }}
                  value={
                    selectedMlflow ? selectedMlflow : mlFlows ? mlFlows[0] : (null as unknown as ManagedInstanceDto)
                  }
                  size="small"
                />
              </DependecyRow>
            )}
            {hasKubeflow && (
              <DependecyRow>
                <div>
                  <Tooltip title={'Pipeline instance for pipeline handling'} aria-label="add">
                    <InfoCircleIcon />
                  </Tooltip>
                </div>
                <Autocomplete
                  disableClearable={true}
                  options={pipelines || []}
                  renderInput={(params) => <TextField {...params} label="Pipelines" variant="outlined" fullWidth />}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, pipeline) => {
                    setSelectedPipeline(pipeline);
                  }}
                  value={
                    selectedPipeline
                      ? selectedPipeline
                      : pipelines
                      ? pipelines[0]
                      : (null as unknown as ManagedInstanceDto)
                  }
                  size="small"
                />
              </DependecyRow>
            )}
            {hasBlueprintDependency &&
              blueprintDependencies?.map((dependency) => (
                <DependecyRow key={dependency.id}>
                  <div style={{ alignSelf: 'top', marginTop: '.5em' }}>
                    <Tooltip title="Select the workspace where the following blueprint is deployed." aria-label="add">
                      <InfoCircleIcon />
                    </Tooltip>
                  </div>
                  <BlueprintDependencyRow
                    blueprintDependencyData={blueprintDependencyData}
                    onChangeAction={setblueprintDependencyData}
                    dependency={dependency}
                  />
                </DependecyRow>
              ))}
          </Paper>
        )}

        <Button style={{ marginTop: '12px' }} disabled={isUpgradeInProgress} onClick={onUpgrade}>
          {isUpgradeInProgress && <CircularProgress style={{ marginRight: '6px' }} size={14} />}
          Upgrade
        </Button>
      </CardContent>
    </HalfCardContainer>
  );
};

export default withResources(UpgradeBlueprintComp);
