import {
  GET_BASE_TEMPLATE,
  GET_TEMPLATES_LIST,
  GET_TERRAFORM_VERSIONS,
  SET_CREATE_TEMPLATE_IN_PROGRESS,
  SET_IS_BASE_TEMPLATE_FETCHING,
  TemplatesActionsT,
} from './actions';
import { Template, TemplateVariables } from '../../common/models/workspace.models';

export interface InitialTemplatesState {
  templates: Template[] | null;
  baseTemplate: TemplateVariables | null;
  isBaseTemplateFetching: boolean;
  createTemplateInProgress: boolean;
  terraformVersions: string[] | null;
}

const initialTemplatesState: InitialTemplatesState = {
  templates: null,
  baseTemplate: null,
  isBaseTemplateFetching: false,
  createTemplateInProgress: false,
  terraformVersions: null,
};

const templatesReducer = (state = initialTemplatesState, action: TemplatesActionsT) => {
  switch (action.type) {
    case GET_TEMPLATES_LIST:
      return {
        ...state,
        templates: action.payload,
      };
    case GET_BASE_TEMPLATE:
      return {
        ...state,
        baseTemplate: action.payload,
      };
    case SET_IS_BASE_TEMPLATE_FETCHING:
      return {
        ...state,
        isBaseTemplateFetching: action.payload,
      };
    case SET_CREATE_TEMPLATE_IN_PROGRESS:
      return {
        ...state,
        createTemplateInProgress: action.payload,
      };
    case GET_TERRAFORM_VERSIONS:
      return {
        ...state,
        terraformVersions: action.payload,
      };
    default:
      return state;
  }
};

export default templatesReducer;
