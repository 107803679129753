import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemIcon,
  TextField,
} from '@material-ui/core';
import { Resource, ResourceTypes } from '../../common/models/resource.model';
import { ToastType } from '../../common/models/enums';
import { createToasterNotification } from '../../common/utils/toaster-notification';
import { getResources, setOpened, setResource } from '../../store/dynamic-selector/actions';
import { makeStyles } from '@material-ui/core/styles';
import { path } from '../../routes/path';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useSelector } from '../../store/configure-store';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import DesktopWindow from '@material-ui/icons/DesktopWindows';
import FolderIcon from '@material-ui/icons/Folder';
import LanguageIcon from '@material-ui/icons/Language';
import React, { useEffect, useState } from 'react';
import TreeItem from '@material-ui/lab/TreeItem';
import TreeView from '@material-ui/lab/TreeView';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  paper: { minWidth: '500px', minHeight: '438px' },
  root: {
    height: 264,
    flexGrow: 1,
  },
  container: {
    minHeight: '438px',
  },
});

const useTreeItemStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    '&:hover > $content': {},
    '&:focus > $content, &$selected > $content': {
      color: 'var(--tree-view-color)',
    },
    '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {},
  },
  content: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '$expanded > &': {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  group: {
    marginLeft: 0,
    '& $content': {
      paddingLeft: theme.spacing(2),
    },
  },
  expanded: {},
  selected: {},
  label: {
    fontWeight: 'inherit',
    color: 'inherit',
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0),
  },
  labelIcon: {
    marginRight: theme.spacing(1),
  },
  labelText: {
    fontWeight: 'inherit',
    flexGrow: 1,
  },
}));

const flatResources = (resource: Resource) => {
  const value = {
    ...resource,
  };
  delete value.children;
  return value;
};

const resolveFlatResource = (resourcesParam: Resource[] | null): Resource[] => {
  return (
    resourcesParam
      ?.map((resource) => {
        return resource ? [flatResources(resource as Resource), ...resolveFlatResource(resource.children ?? [])] : [];
      })
      .flat() ?? []
  );
};

const DynamicSelector = () => {
  const dispatch = useDispatch();
  const opened = useSelector((state) => state.dynamicSelector.opened);
  const resources = useSelector((state) => state.dynamicSelector.resources);

  const requiredResource = useSelector((state) => state.dynamicSelector.requiredResource);
  const previousSelectedResource = useSelector((state) => state.dynamicSelector.selectedResource);
  //We need this because, we would like to open the first domain by default
  const firstResourceId = resources ? resources[0]?.id : '';
  const firstResource = resources ? resources[0] : null;
  const [selectedResource, setSelectedResource] = useState(previousSelectedResource || firstResource);
  const navigate = useNavigate();
  const [search, setSearch] = useState<string>('');
  const [flatResourcesArray, setFlatResourcesArray] = useState<Resource[]>();

  const classes = useStyles();

  useEffect(() => {
    dispatch(getResources());
  }, [dispatch]);

  function resolveTitle(requiredResourceParam: ResourceTypes[] | null | undefined): string {
    if (requiredResourceParam?.length === 1) {
      if (requiredResourceParam.includes(ResourceTypes.DOMAIN)) {
        return 'Select a domain!';
      }
      if (requiredResourceParam.includes(ResourceTypes.WORKSPACE)) {
        return 'Select a workspace!';
      }
    } else {
      if (
        requiredResourceParam?.includes(ResourceTypes.DOMAIN) &&
        requiredResourceParam?.includes(ResourceTypes.WORKSPACE)
      ) {
        return 'Select a domain or a workspace';
      }
    }

    return 'Select a resource';
  }

  useEffect(() => {
    resources && setFlatResourcesArray(resolveFlatResource(resources));
  }, [resources, setFlatResourcesArray]);

  return (
    <Dialog
      open={opened}
      classes={{ paper: classes.paper }}
      onClose={() => {
        navigate(path.blueprintDashboard());
        dispatch(setOpened({ opened: false, requiredResource }));
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{resolveTitle(requiredResource)}</DialogTitle>
      <DialogContent className="tr-cursor-pointer">
        <TextField
          fullWidth
          placeholder="Search resource"
          value={search}
          autoFocus
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value as string)}
          style={{ marginBottom: '16px' }}
        />

        {search && flatResourcesArray && (
          <List>
            {flatResourcesArray
              .filter((resource) => resource.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
              .map((resource) => {
                return (
                  <ListItem
                    key={resource.id}
                    button
                    style={{ fontSize: '0.9rem' }}
                    dense
                    onClick={() => {
                      if (requiredResource && resource && requiredResource.includes(resource.type)) {
                        dispatch(setResource(resource));
                        dispatch(setOpened({ opened: false, requiredResource }));
                        setSearch('');
                      } else {
                        dispatch(
                          createToasterNotification(
                            `You need to select a ${requiredResource?.join().toLocaleLowerCase()}!`,
                            ToastType.WARNING,
                          ),
                        );
                      }
                    }}
                  >
                    <ListItemIcon style={{ fontSize: '0.9rem' }}>{resolveIcon(resource.type, true)}</ListItemIcon>
                    {resource.name}
                  </ListItem>
                );
              })}
          </List>
        )}

        {!search && (
          <TreeView
            className={classes.root}
            defaultExpanded={[firstResourceId]}
            defaultCollapseIcon={<ArrowDropDownIcon />}
            defaultExpandIcon={<ArrowRightIcon />}
            defaultEndIcon={<div style={{ width: 24 }} />}
          >
            {resources?.map((resource) => {
              return (
                <StyledTreeItem
                  key={resource?.id}
                  nodeId={resource?.id}
                  labelText={resource?.name}
                  labelIcon={LanguageIcon}
                  labelInfo={resource?.children?.length}
                  childrenelements={resource?.children}
                  nodeInfo={resource}
                  onClickEvent={setSelectedResource}
                ></StyledTreeItem>
              );
            })}
          </TreeView>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            if (requiredResource && selectedResource && requiredResource.includes(selectedResource.type)) {
              dispatch(setOpened({ opened: false, requiredResource }));
            } else {
              navigate(path.blueprintDashboard());
              dispatch(setOpened({ opened: false, requiredResource }));
            }
          }}
        >
          CANCEL
        </Button>
        <Button
          onClick={() => {
            if (requiredResource && selectedResource && requiredResource.includes(selectedResource.type)) {
              dispatch(setResource(selectedResource));
              dispatch(setOpened({ opened: false, requiredResource }));
              setSearch('');
            } else {
              dispatch(
                createToasterNotification(
                  `You need to select a ${requiredResource?.join().toLocaleLowerCase()}!`,
                  ToastType.WARNING,
                ),
              );
            }
          }}
          color="primary"
        >
          SELECT
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export function resolveIcon(type: string, asComponent?: boolean) {
  switch (type) {
    case ResourceTypes.WORKSPACE:
      return asComponent ? <DesktopWindow /> : DesktopWindow;
    case ResourceTypes.DOMAIN:
      return asComponent ? <LanguageIcon /> : LanguageIcon;
    case ResourceTypes.FOLDER:
      return asComponent ? <FolderIcon /> : FolderIcon;
    default:
      return asComponent ? <LanguageIcon /> : LanguageIcon;
  }
}

function StyledTreeItem(props: any) {
  const classes = useTreeItemStyles();
  const { labelText, labelIcon: LabelIcon, labelInfo, color, bgColor, onClickEvent, nodeInfo, ...other } = props;

  return (
    <TreeItem
      label={
        <div className={classes.labelRoot}>
          <LabelIcon color="inherit" className={classes.labelIcon} />
          <Typography variant="body2" className={classes.labelText}>
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        </div>
      }
      onLabelClick={() => {
        const selectedResource: Resource = {
          ...props.nodeInfo,
        };
        onClickEvent(selectedResource || undefined);
      }}
      style={{
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor,
      }}
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
      }}
      {...other}
    >
      {props?.childrenelements?.map((child: Resource) => (
        <StyledTreeItem
          key={child?.id}
          nodeId={child?.id}
          labelText={child?.name}
          labelIcon={resolveIcon(child.type)}
          labelInfo={child?.children?.length}
          childrenelements={child?.children}
          nodeInfo={child}
          onClickEvent={onClickEvent}
        />
      ))}
    </TreeItem>
  );
}

export default DynamicSelector;
