import styled from 'styled-components';

export const TemplateVariablesContainer = styled.div`
  margin-bottom: 25px;
`;

export const RepositoryRow = styled.div`
  display: grid;
  grid-template-columns: 1.3fr 0.7fr;
  grid-column-gap: 20px;
  margin-left: 34px;
  max-width: calc(500px - 34px);

  &.one-column {
    grid-template-columns: 1fr !important;
  }
`;

export const TemplateVariableItem = styled.div`
  display: grid;
  grid-template-columns: 24px 1fr;
  grid-column-gap: 10px;
  max-width: 500px;
  margin-bottom: 25px;
  align-items: center;
`;
