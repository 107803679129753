import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import React, { ChangeEvent, useState } from 'react';

interface FilterDropdownProps {
  label: string;
  options: { itemName: string; value: string }[];
  handleChange: (value: string) => void;
  selectedValue?: string | null;
}

export const FilterDropdown: React.FC<FilterDropdownProps> = (props) => {
  const [selectedValue, setSelectedValue] = useState(props.selectedValue);

  const onChange = (event: ChangeEvent<{ value: unknown }>) => {
    const newValue = `${event.target.value}`;
    setSelectedValue(newValue);
    props.handleChange?.(newValue);
  };

  return (
    <FormControl variant="outlined" size="small" fullWidth>
      <InputLabel id={props.label}>{props.label}</InputLabel>
      <Select
        labelId={props.label}
        label={props.label}
        value={props.selectedValue ? props.selectedValue : selectedValue}
        onChange={onChange}
      >
        {props.options.map((option, index) => (
          <MenuItem value={option.value} key={`${option.value}-${index}`}>
            {option.itemName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
