import { Card, Typography } from '@material-ui/core';
import { CardContainer } from '../../common/styles/common.styles';
import React from 'react';
import RouteWithHeader from '../../containers/route-with-header/route-with-header';

export const Support: React.FC = () => {
  return (
    <RouteWithHeader title="Support">
      <CardContainer>
        <Card>
          <Typography style={{ margin: '16px' }}>
            In case of any issue or if you have any questions please let us know in the following e-mail:&nbsp;
            <a href="mailto:aip-support@aliz.ai" target="_blank" rel="noreferrer">
              aip-support@aliz.ai.
            </a>
          </Typography>
        </Card>
      </CardContainer>
    </RouteWithHeader>
  );
};
