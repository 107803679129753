export enum UserStatus {
  EMPTY = '',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  DELETED = 'DELETED',
}

export interface User {
  id: string;
  name: string;
  email: string;
  domain: string;
  status: UserStatus;
  picture: string;
}

export interface UsersState {
  data: User[];
  isFetching: boolean;
  error?: string;
}
