import styled from 'styled-components';

export const Label = styled.div`
  padding-bottom: 20px;
  font-size: 16px;
  line-height: 24px;
  color: ${(p) => p.theme.colors.transparentDarkGreen};
`;

export const HeadLabel = styled.div`
  padding-bottom: 20px;
  font-size: 17px;
  line-height: 24px;
  color: ${(p) => p.theme.colors.transparentDarkGreen};
`;

export const ChipContainer = styled.div`
  & .chip {
    padding: 0px;
    margin-right: 8px;
    background: linear-gradient(0deg, rgba(2, 57, 77, 0.08), rgba(2, 57, 77, 0.08)), #ffffff;
    margin-bottom: 10px;
    &.invalid-chip {
      background: rgba(253, 143, 130, 0.12);
    }
  }
  & .MuiChip-label {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.5;
    color: ${(p) => p.theme.colors.darkGreen};
  }
`;
