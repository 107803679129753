import { AppState } from '../../common/models/interfaces';
import { BrandLogo, IconWrapper, StyledAppbar, StyledToolbar, ToolbarContent, UserAvatar } from './styles';
import { Button, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { setOpened } from '../../store/dynamic-selector/actions';
import { useDispatch } from 'react-redux';
import { useSelector } from '../../store/configure-store';
import AddIcon from '@material-ui/icons/Add';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import DynamicSelector, { resolveIcon } from '../dynamic-selector/dynamic-selector';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationIcon from '@material-ui/icons/NotificationsOutlined';
import React, { useCallback, useState } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import UserAccountMenu from '../UserAccountMenu';
import images from '../../assets';
import logo from '../../assets/aliz_logo_lightmint.png';

const useStyles = makeStyles(
  (theme) => ({
    buttonRoot: {
      width: 250,
      marginLeft: theme.spacing(13),
      color: theme.palette.white,
      borderColor: theme.palette.white,
      '&:hover': {
        borderColor: theme.palette.white,
        backgroundColor: '#fff4',
      },
    },
  }),
  { name: 'Appbar' },
);

const Appbar: React.FC = () => {
  const profile = useSelector(({ auth }: AppState) => auth.data?.profile);
  const requiredResource = useSelector((state) => state.dynamicSelector.requiredResource);
  const classes = useStyles({});

  const [menuAnchor, setMenuAnchor] = useState<HTMLDivElement | null>(null);

  const handleIconClick = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    setMenuAnchor(e.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setMenuAnchor(null);
  }, []);

  const dispatch = useDispatch();
  const selectedResource = useSelector((state) => state.dynamicSelector.selectedResource);

  return (
    <>
      <StyledAppbar position="fixed">
        <StyledToolbar>
          <ToolbarContent>
            <MenuIcon />
            <BrandLogo src={logo} />
            <Button
              variant="outlined"
              color="secondary"
              classes={{ root: classes.buttonRoot }}
              startIcon={selectedResource ? resolveIcon(selectedResource.type, true) : <AddIcon />}
              endIcon={<ArrowDropDownIcon />}
              onClick={() => dispatch(setOpened({ opened: true, requiredResource }))}
            >
              <Tooltip title={selectedResource ? selectedResource.name : 'Select domain / workspace'}>
                <div
                  style={{
                    width: '100%',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    textAlign: 'left',
                  }}
                >
                  {selectedResource ? selectedResource.name : 'Select domain / workspace'}
                </div>
              </Tooltip>
            </Button>

            <DynamicSelector />
          </ToolbarContent>
          <ToolbarContent>
            <IconWrapper>
              <SearchIcon />
            </IconWrapper>
            <IconWrapper>
              <NotificationIcon />
            </IconWrapper>
            <IconWrapper onClick={handleIconClick}>
              <UserAvatar src={profile?.imageUrl || images.User} />
            </IconWrapper>
          </ToolbarContent>
        </StyledToolbar>
      </StyledAppbar>
      {profile && <UserAccountMenu profile={profile} anchorEl={menuAnchor} onClose={handleClose} />}
    </>
  );
};

export default Appbar;
