import { ChartTitle } from '../../common/styles/common.styles';
import { ModelMean } from '../../common/models/model.model';
import React, { Suspense, lazy } from 'react';
const DynamicPlotlyChart = lazy(() => import('../DynamicPlotlyChart'));

interface TMainPredictionChartProps {
  data: ModelMean;
}

const MainPredictionChart: React.FC<TMainPredictionChartProps> = ({ data }) => {
  return (
    <div>
      <ChartTitle>Prediction and Actual AVG </ChartTitle>
      <Suspense fallback={<>Loading...</>}>
        <DynamicPlotlyChart
          data={[
            {
              x: data.pred.avg.map((item) => item.xvalue),
              y: data.pred.avg.map((item) => item.yvalue),
              type: 'scatter',
              mode: 'lines',
              name: 'Predictions AVG',

              marker: { color: '#2F80ED' },
            },
            {
              x: data.actual.avg.map((item) => item.xvalue),
              y: data.actual.avg.map((item) => item.yvalue),
              type: 'scatter',
              mode: 'lines',
              name: 'Actual AVG',
              marker: { color: '#36D083' },
            },
          ]}
          layout={{
            title: '',
            height: 380,
            autosize: true,
            margin: { t: 0, r: 0, b: 40, l: 40 },
            showlegend: true,
            legend: {
              orientation: 'h',
              xanchor: 'center',
              yanchor: 'top',
              x: 0.5,
              y: 1,
            },
          }}
          config={{ displaylogo: false, modeBarButtons: false, responsive: true }}
        />
      </Suspense>
    </div>
  );
};

export default MainPredictionChart;
