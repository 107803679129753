import { Button, Checkbox, CircularProgress, FormControlLabel, LinearProgress } from '@material-ui/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { LogoDark } from '../../assets';
import { useSelector } from '../../store/configure-store';
import {
  BottomContainer,
  ContentContainer,
  GcLogo,
  Href,
  LandingImage,
  LeftContainer,
  LinearProgressContainer,
  LogoContainer,
  Page,
  Panel,
  RightContainer,
  SmallText,
  Text,
  Title,
  Title2,
} from './sign.styles';
import { Column, Row } from '../../common/styles/common.styles';
import { GoogleLoginResponse, GoogleLoginResponseOffline, useGoogleLogin } from 'react-google-login';
import { ReCaptcha, loadReCaptcha } from 'react-recaptcha-v3';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { createToasterNotification } from '../../common/utils/toaster-notification';
import { environmentVariable } from '../../common/utils/environment-variable';
import { googleLogin, googleLoginFailure, googleLoginSuccess } from '../../store/auth/actions';
import { fetchMe } from '../../store/current-user/reducers/currentUser.reducer';
import { path } from '../../routes/path';
import { urls } from '../../common/utils/urls';
import { useDispatch } from 'react-redux';
import api from '../../common/utils/api';

const TrialSignUp: React.FC = () => {
  const currentUserState = useSelector((state) => state.currentUser);
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const [data, setData] = useState({ name: '', github: '', company: '', phone: '', reason: '' });
  const [privacy, setPrivacy] = useState(false);
  const [tac, setTac] = useState(false);
  const [onSaveInProgress, setOnSaveInProgress] = useState(false);
  const recaptchaRef = useRef<ReCaptcha | null>(null);
  const [recaptchaToken, setRecaptchaToken] = useState<string>();

  const getReCaptchaKeyByEnvironment = () => {
    return environmentVariable.googleCaptchaKey;
  };

  getReCaptchaKeyByEnvironment();

  const sendDataToApi = async (recaptchaTokenParam: string) => {
    try {
      await api.post(
        urls.trialSignUp(),
        {
          companyName: data.company,
          githubUser: data.github,
          name: data.name,
          phone: data.phone,
          reasonOfTrial: data.reason,
        },
        {
          'g-recaptcha-response': recaptchaTokenParam,
        },
      );
    } catch (e) {
      throw e;
    }
  };

  const handleSendDataToApi = useCallback(sendDataToApi, [recaptchaToken, data]);

  const handleLoginSuccess = useCallback(
    async (res: GoogleLoginResponse | GoogleLoginResponseOffline) => {
      try {
        if ('tokenId' in res) {
          setOnSaveInProgress(true);
          const { profileObj, accessToken, tokenId } = res;
          await dispatch(googleLoginSuccess({ accessToken, profile: profileObj, tokenId, tokenObj: res.tokenObj }));
          recaptchaToken && (await handleSendDataToApi(recaptchaToken));
          await dispatch(googleLogin(res as GoogleLoginResponse));
          await dispatch(fetchMe());
          setOnSaveInProgress(false);
        }
      } catch (e) {
        console.log('TrialSignUp-->handleLoginSuccess', e);
        recaptchaRef.current?.execute();
        dispatch(createToasterNotification(e));
        setOnSaveInProgress(false);
      }
    },
    [dispatch, handleSendDataToApi, recaptchaToken],
  );

  const handleLoginError = useCallback(
    (error: string) => {
      dispatch(googleLoginFailure(error));
    },
    [dispatch],
  );

  const { signIn } = useGoogleLogin({
    scope: 'https://www.googleapis.com/auth/plus.login',
    responseType: 'token',
    cookiePolicy: 'single_host_origin',
    clientId: environmentVariable.googleClientId,
    onSuccess: handleLoginSuccess,
    onFailure: handleLoginError,
  });

  const onSignUp = async () => {
    signIn();
  };

  const verifyCallback = (recaptchaTokenParam: string) => {
    setRecaptchaToken(recaptchaTokenParam);
  };

  useEffect(() => {
    loadReCaptcha(environmentVariable.googleCaptchaKey);
  }, []);

  return (
    <Page>
      <ReCaptcha
        ref={(ref) => (recaptchaRef.current = ref)}
        sitekey={environmentVariable.googleCaptchaKey}
        verifyCallback={verifyCallback}
        action=""
      />

      <LinearProgressContainer isVisible={currentUserState?.loadMeta?.isLoading}>
        <LinearProgress />
      </LinearProgressContainer>
      <LogoContainer>
        <LogoDark />
      </LogoContainer>
      <ContentContainer>
        <LeftContainer>
          <LandingImage />
          <Title>Aliz Intelligence Platform</Title>
          <Text style={{ marginTop: '40px' }}>New to our service?</Text>
          <Title2>Sign up for 30 days free trial</Title2>
          <Text style={{ marginTop: '20px' }}>
            Get a hands-on experience on how you can orchestrate your business and technology flow. Exapnd capabilities
            of your organization by solving tech challenges out of the box yet remain highly transparent and agile.
          </Text>
          <Text style={{ marginTop: '20px' }}>Our free trial gives you unlimited access for most features of AIP.</Text>
          <Text className={'primary'} style={{ fontFamily: 'Lato', marginTop: '40px', fontSize: '18px' }}>
            Integrated with
          </Text>
          <GcLogo />
        </LeftContainer>
        <RightContainer>
          <ValidatorForm ref={formRef} onSubmit={onSignUp}>
            <Panel className={'sign-up'}>
              <Column style={{ alignItems: 'center' }}>
                <Row style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                  <Text className={'primary'} style={{ fontSize: '20px' }}>
                    Sign up
                  </Text>
                  <div style={{ display: 'flex' }}>
                    <Text style={{ marginRight: '20px', fontSize: '16px' }}>OR</Text>
                    <Href
                      className={'primary'}
                      style={{ fontSize: '16px' }}
                      onClick={() => {
                        window.location.replace(path.root());
                      }}
                    >
                      SIGN IN
                    </Href>
                  </div>
                </Row>
                <Column>
                  <TextValidator
                    errorMessages={['This field is required']}
                    style={{ marginTop: '40px' }}
                    size="small"
                    label="Name"
                    name="name"
                    value={data.name}
                    variant="outlined"
                    onChange={(e: any) => setData({ ...data, name: e.target.value })}
                    fullWidth
                    validators={['required']}
                  />
                  <TextValidator
                    style={{ marginTop: '20px' }}
                    size="small"
                    name="github"
                    label="GitHub username"
                    variant="outlined"
                    value={data.github}
                    fullWidth
                    onChange={(e: any) => setData({ ...data, github: e.target.value })}
                    errorMessages={['This field is required']}
                    validators={['required']}
                  />
                  <TextValidator
                    style={{ marginTop: '20px' }}
                    size="small"
                    value={data.company}
                    label="Company"
                    variant="outlined"
                    name="company"
                    fullWidth
                    onChange={(e: any) => setData({ ...data, company: e.target.value })}
                    errorMessages={['This field is required']}
                    validators={['required']}
                  />
                  <TextValidator
                    style={{ marginTop: '20px' }}
                    size="small"
                    label="Phone"
                    name="phone"
                    variant="outlined"
                    fullWidth
                    value={data.phone}
                    onChange={(e: any) => setData({ ...data, phone: e.target.value })}
                    errorMessages={['This field is required']}
                    validators={['required']}
                  />
                  <TextValidator
                    style={{ marginTop: '20px', marginBottom: '20px' }}
                    size="small"
                    name="reason"
                    value={data.reason}
                    label="Why would you like to try?"
                    variant="outlined"
                    fullWidth
                    onChange={(e: any) => setData({ ...data, reason: e.target.value })}
                    errorMessages={['This field is required']}
                    validators={['required']}
                  />
                  <div style={{ width: '100%' }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={privacy}
                          color="primary"
                          onClick={() => setPrivacy((prevPrivacy) => !prevPrivacy)}
                        />
                      }
                      label={
                        <Row>
                          <label>I accept &nbsp;</label>{' '}
                          <Href href="https://aliz.ai/privacy-policy/" target="_blank" className={'primary underline'}>
                            privacy policy
                          </Href>
                        </Row>
                      }
                    />
                  </div>
                  <div style={{ width: '100%', marginBottom: '10px' }}>
                    <FormControlLabel
                      control={<Checkbox value={tac} color="primary" onClick={() => setTac((prevTac) => !prevTac)} />}
                      label={
                        <Row>
                          <label>I accept &nbsp;</label>{' '}
                          <Href className={'primary underline'}>terms and conditions</Href>
                        </Row>
                      }
                    />
                  </div>
                </Column>
                <Button
                  disabled={!privacy || !tac || onSaveInProgress}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  {onSaveInProgress && <CircularProgress style={{ marginRight: '6px' }} size={14} />}
                  Sign up
                </Button>
              </Column>
              <Row style={{ justifyContent: 'center' }}>
                <Text>Have a problem signing in? &nbsp;</Text>
                <Href className={'primary underline'}>Contact our team.</Href>
              </Row>
            </Panel>
          </ValidatorForm>
        </RightContainer>
      </ContentContainer>
      <BottomContainer>
        <Row style={{ justifyContent: 'center', alignItems: 'center' }}>
          <SmallText className={'primary'} style={{ marginRight: '10px' }}>
            TERMS OF SERVICE
          </SmallText>
          <SmallText className={'primary'} style={{ marginLeft: '10px' }}>
            PRIVACY POLICY
          </SmallText>
        </Row>
        <Row style={{ justifyContent: 'center', alignItems: 'center', marginTop: '10px' }}>
          <Text style={{ marginRight: '15px' }}>Copyright © 2021 Aliz Tech Ltd. All rights reserved.</Text>
          <div style={{ marginLeft: '15px' }}>
            <Text style={{ display: 'inline-block' }}>Get in touch: &nbsp;</Text>{' '}
            <Href style={{ display: 'inline-block' }} className={'primary'}>
              hello@aliz.ai
            </Href>
          </div>
        </Row>
      </BottomContainer>
    </Page>
  );
};

export default TrialSignUp;
