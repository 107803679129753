import { ManagedServiceTypes } from '../../common/models/enums';
import { Version } from '../../common/models/interfaces';
import { urls } from '../../common/utils/urls';
import api from '../../common/utils/api';

async function getVersions(
  setter: React.Dispatch<React.SetStateAction<Version[] | undefined>>,
  serviceType: ManagedServiceTypes,
) {
  try {
    const url = serviceType === ManagedServiceTypes.MLFLOW ? urls.getMlflowVersions() : urls.getKubeFlowVersions();
    const versions = await api.get<Version[]>(url);
    if (versions && typeof setter === 'function') {
      setter(versions);
    }
  } catch (e) {
    console.log('ManagedServiceVersionSelector-->requests-->getVersions', e);
  }
}

export { getVersions };
