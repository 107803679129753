import { Dialog } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import GeneralModalContent from './GeneralModalContent';
import GeneralModalContext from './GeneralModalContext';
import GeneralModalForm from './GeneralModalForm';
import React, { useContext } from 'react';

const useStyles = makeStyles({
  paper: { minWidth: '500px' },
});

const GeneralModal: React.FC = ({ children }) => {
  const classes = useStyles();
  const { modalIsOpen, isForm } = useContext(GeneralModalContext);
  return (
    <Dialog open={modalIsOpen} classes={{ paper: classes.paper }}>
      {isForm && (
        <GeneralModalForm>
          <GeneralModalContent>{children}</GeneralModalContent>
        </GeneralModalForm>
      )}
      {!isForm && <GeneralModalContent>{children}</GeneralModalContent>}
    </Dialog>
  );
};

export default GeneralModal;
