import * as S from './styles';
import { ModelPredictionsMetrics } from '../../common/models/model.model';
import React, { Suspense, lazy } from 'react';
const DynamicPlotlyChart = lazy(() => import('../DynamicPlotlyChart'));

interface TPredictionsMetricsProps {
  metrics: ModelPredictionsMetrics;
}

const PredictionsMetrics: React.FC<TPredictionsMetricsProps> = ({ metrics }) => {
  return (
    <div style={{ marginTop: '2em' }}>
      <S.Title>Prediction count</S.Title>
      <div style={{ minHeight: '300px' }}>
        <Suspense fallback={<>Loading...</>}>
          <DynamicPlotlyChart
            data={[
              {
                x: metrics.metrics.map((metric) => metric.xvalue),
                y: metrics.metrics.map((metric) => metric.yvalue),
                type: 'bar',
                marker: { color: '#56CCF2' },
              },
            ]}
            layout={{
              autosize: true,
              title: '',
              height: 300,
              margin: { t: 0, r: 0, b: 40, l: 40 },
            }}
            config={{ displaylogo: false, modeBarButtons: false, responsive: true }}
          />
        </Suspense>
      </div>
    </div>
  );
};

export default PredictionsMetrics;
