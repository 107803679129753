export const replaceUnderScoreToFirstCamel = (s: string) => {
  const replaced = s.split('_').join(' ');
  return replaced.charAt(0).toUpperCase() + replaced.slice(1).toLowerCase();
};

export const replaceUnderScoreToCamel = (s: string) => {
  const replaced = s.split('_').join(' ');
  return replaced.toUpperCase();
};

export function capitalize(word: string) {
  const lower = word.toLowerCase();
  return word.charAt(0).toUpperCase() + lower.slice(1);
}
