import { Column } from '../../../common/styles/common.styles';
import styled from 'styled-components';

export const DataContainer = styled(Column)`
  flex: 1;
  align-items: center;
  justify-content: center;
  border-right: 1px solid ${(p) => p.theme.colors.transparentGrey};

  &:last-of-type {
    border-right: none;
  }
`;
