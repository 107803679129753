import { Alert } from '@material-ui/lab';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import { ManagedServiceTypes } from '../../common/models/enums';
import { ValidationError } from '../../common/utils/api';
import { makeStyles } from '@material-ui/core/styles';
import { savePrincipal } from './requests';
import { useSelector } from '../../store/configure-store';
import React, { Dispatch, SetStateAction, useState } from 'react';

interface TManagedServiceAccessModalProps {
  serviceType: ManagedServiceTypes.KUBEFLOW | ManagedServiceTypes.MLFLOW;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  serviceId: string;
  refreshList: () => '' | Promise<void> | undefined;
  onError: (e: ValidationError | unknown) => void;
}

const useStyles = makeStyles({
  paper: { minWidth: '500px' },
});

const ManagedServiceAccessModal: React.FC<TManagedServiceAccessModalProps> = ({
  open,
  setOpen,
  serviceType,
  serviceId,
  refreshList,
  onError,
}) => {
  const classes = useStyles();
  const [principal, setPrincipal] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const selectedResource = useSelector((state) => state.dynamicSelector.selectedResource);

  const onSuccess = () => {
    refreshList && refreshList();
    setOpen(false);
  };

  const onSave = async () => {
    await savePrincipal(
      serviceType,
      selectedResource?.domainId || '',
      serviceId,
      principal,
      setIsLoading,
      onSuccess,
      onError,
    );
  };

  return (
    <Dialog open={open} classes={{ paper: classes.paper }}>
      <DialogTitle id="managed-service-access-modal-title">Add new principal</DialogTitle>
      <DialogContent>
        <Alert severity="warning">It might take a couple minutes for the access to be granted!</Alert>
        <TextField
          onChange={(e) => setPrincipal(e.target.value)}
          autoFocus
          margin="dense"
          id="principal"
          label="Principal"
          type="principal"
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setOpen(false);
          }}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            onSave();
          }}
          color="primary"
          disabled={!principal?.length || isLoading}
        >
          {isLoading && <CircularProgress style={{ marginRight: '3px' }} size={14} />}
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ManagedServiceAccessModal;
