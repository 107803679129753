import { ActionRow, CardContainer } from './iam.styles';
import { AlertDialog } from '../../components/alert-dialog/alert-dialog';
import { AuthorizationDto, RoleDto, Template } from '../../common/models/workspace.models';
import { Breadcrumbs, Button, Card, Link } from '@material-ui/core';
import { BreadcrumbsLink } from '../blueprint-dashboard/blueprint-detail/blue-print-detail.styles';
import { PlusIcon } from '../../assets';
import { PlusIconContainer } from '../../common/styles/common.styles';
import { PrincipalDetailsDialog } from '../../components/UserDetailsDialog/PrincipalDetailsDialog';
import { ResourceType, ResourceTypes } from '../../common/models/resource.model';
import { Table } from '../../components';
import { TableHeadCell } from '../../components/Table/TableHead';
import { ToastType } from '../../common/models/enums';
import { createToasterNotification } from '../../common/utils/toaster-notification';
import { getWorkspaceById } from '../../store/workspaces/actions';
import { path } from '../../routes/path';
import { urls } from '../../common/utils/urls';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useSelector } from '../../store/configure-store';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import React, { useCallback, useEffect, useState } from 'react';
import api from '../../common/utils/api';
import withResources from '../../components/withResources/withResources';

const headCells: TableHeadCell<AuthorizationDto>[] = [
  { id: 'principalId', label: 'Principal' },
  { id: 'principalType', label: 'Principal type' },
  { id: 'roles', label: 'Roles' },
  { id: 'editable', type: 'editable' },
];

export const IamComp: React.FC = () => {
  const selectedResource = useSelector((state) => state.dynamicSelector.selectedResource);

  const [isPrincipalDialogOpen, setIsPrincipalDialogOpen] = useState(false);

  const [gridData, setGridData] = useState<AuthorizationDto[]>([]);
  const [isBlueprintMembersFetching, setIsBlueprintMembersFetchingetGridData] = useState<boolean>(false);
  const [template, setTemplate] = useState<Template | null>(null);
  const [selectedGridRow, setSelectedGridRow] = useState<AuthorizationDto | null>(null);
  const domainId = selectedResource?.domainId || '';
  const [isAlertDialogForDeletionOpen, setIsAlertDialogForDeletionOpen] = useState(false);
  const dispatch = useDispatch();
  const { templateId } = useParams() as any;

  const fetchWorkspaceMembersQuery = useQuery(
    [`fetch-workspace-members`, selectedResource?.id],
    (_, id: string) => {
      return api
        .get<AuthorizationDto[]>(urls.fetchWorkspaceMembers(id || ''))
        .then((data) => {
          setGridData(data);
          return data;
        })
        .catch((e) => {
          dispatch(createToasterNotification(e));
        });
    },
    { enabled: false, refetchOnWindowFocus: false },
  );

  const handleWorkspaceFetchMembers = useCallback(fetchWorkspaceMembersQuery.refetch, [selectedResource?.id]);

  const fetchBlueprintMembersQuery = async () => {
    try {
      setIsBlueprintMembersFetchingetGridData(true);
      const data = await api.get<AuthorizationDto[]>(urls.fetchTemplateMembers(templateId || ''));
      setGridData(data);
      setIsBlueprintMembersFetchingetGridData(false);
    } catch (e) {
      console.log('iam-->fetchTemplateMembersQuery', e);
      setIsBlueprintMembersFetchingetGridData(false);
    }
  };

  const handleBlueprintFetchMembers = useCallback(fetchBlueprintMembersQuery, [templateId]);

  const fetchDomainMembersQuery = useQuery(
    [`fetch-domain-members`, domainId],
    (_, domainIdParam: string) => {
      return api
        .get<AuthorizationDto[]>(urls.fetchDomainMembers(domainIdParam))
        .then((data) => {
          setGridData(data);
          return data;
        })
        .catch((e) => {
          dispatch(createToasterNotification(e));
        });
    },
    { enabled: false, refetchOnWindowFocus: false },
  );

  const handleDomainFetchMembers = useCallback(fetchDomainMembersQuery.refetch, [domainId, selectedResource?.type]);

  const deleteDomainMember = (authorizationDto: AuthorizationDto) => {
    api
      .del(urls.deleteDomainMember(domainId, authorizationDto.id))
      .then(() => {
        handleDomainFetchMembers();
        handleCloseAlertDialog();
        dispatch(createToasterNotification('Principal has successfully been deleted', ToastType.SUCCESS));
      })
      .catch((e) => {
        dispatch(createToasterNotification(e));
      });
  };

  const deleteBlueprintMember = (authorizationDto: AuthorizationDto) => {
    api
      .del(urls.deleteBlueprintMember(templateId, authorizationDto.id))
      .then(() => {
        handleBlueprintFetchMembers();
        handleCloseAlertDialog();
        dispatch(createToasterNotification('Principal has successfully been deleted', ToastType.SUCCESS));
      })
      .catch((e) => {
        dispatch(createToasterNotification(e));
      });
  };

  const deleteWorkspaceMember = (authorizationDto: AuthorizationDto) => {
    api
      .del(urls.deleteWorkspaceMember(selectedResource?.id || '', authorizationDto.id))
      .then(() => {
        handleWorkspaceFetchMembers();
        handleCloseAlertDialog();
        dispatch(createToasterNotification('Principal has successfully been deleted', ToastType.SUCCESS));
      })
      .catch((e) => {
        dispatch(createToasterNotification(e));
      });
  };

  const saveOrUpdateDomainMember = (authorizationDto: AuthorizationDto) => {
    if (authorizationDto.id === '') {
      api
        .post<AuthorizationDto>(urls.createOrUpdateDomainMember(domainId), authorizationDto)
        .then((data) => {
          handleDomainFetchMembers();
          handleCloseDialog();
          dispatch(createToasterNotification('Principal has successfully been created', ToastType.SUCCESS));
        })
        .catch((e) => {
          dispatch(createToasterNotification(e));
        });
    } else {
      api
        .put<AuthorizationDto>(urls.createOrUpdateDomainMember(domainId), authorizationDto)
        .then((data) => {
          handleDomainFetchMembers();
          handleCloseDialog();
          dispatch(createToasterNotification('Principal has successfully been updated', ToastType.SUCCESS));
        })
        .catch((e) => {
          dispatch(createToasterNotification(e));
        });
    }
  };

  const saveOrUpdateBlueprintMember = (authorizationDto: AuthorizationDto) => {
    if (authorizationDto.id === '') {
      api
        .post<AuthorizationDto>(urls.createOrUpdateBlueprintMember(templateId), authorizationDto)
        .then((_) => {
          handleBlueprintFetchMembers();
          handleCloseDialog();
          dispatch(createToasterNotification('Principal has successfully been created', ToastType.SUCCESS));
        })
        .catch((e) => {
          dispatch(createToasterNotification(e));
        });
    } else {
      api
        .put<AuthorizationDto>(urls.createOrUpdateBlueprintMember(templateId), authorizationDto)
        .then((_) => {
          handleBlueprintFetchMembers();
          handleCloseDialog();
          dispatch(createToasterNotification('Principal has successfully been updated', ToastType.SUCCESS));
        })
        .catch((e) => {
          dispatch(createToasterNotification(e));
        });
    }
  };

  const saveOrUpdateWorkspaceMember = (authorizationDto: AuthorizationDto) => {
    if (authorizationDto.id === '') {
      api
        .post<AuthorizationDto>(urls.fetchWorkspaceMembers(selectedResource?.id || ''), authorizationDto)
        .then((data) => {
          handleWorkspaceFetchMembers();
          handleCloseDialog();
          dispatch(createToasterNotification('Principal has successfully been created', ToastType.SUCCESS));
        })
        .catch((e) => {
          dispatch(createToasterNotification(e));
        });
    } else {
      api
        .put<AuthorizationDto>(urls.fetchWorkspaceMembers(selectedResource?.id || ''), authorizationDto)
        .then((data) => {
          handleWorkspaceFetchMembers();
          handleCloseDialog();
          dispatch(createToasterNotification('Principal has successfully been updated', ToastType.SUCCESS));
        })
        .catch((e) => {
          dispatch(createToasterNotification(e));
        });
    }
  };

  const handlePrincipalCreateOrUpdate = (authorizationDto: AuthorizationDto) => {
    if (templateId) {
      saveOrUpdateBlueprintMember(authorizationDto);
    } else {
      if (selectedResource?.type === ResourceTypes.DOMAIN) {
        saveOrUpdateDomainMember(authorizationDto);
      }
      if (selectedResource?.type === ResourceTypes.WORKSPACE) {
        saveOrUpdateWorkspaceMember(authorizationDto);
      }
    }
  };

  const handlePrincipalDelete = () => {
    if (!selectedGridRow) {
      return;
    }

    if (templateId) {
      deleteBlueprintMember(selectedGridRow);
    } else {
      if (selectedResource?.type === ResourceTypes.DOMAIN) {
        deleteDomainMember(selectedGridRow);
      }
      if (selectedResource?.type === ResourceTypes.WORKSPACE) {
        deleteWorkspaceMember(selectedGridRow);
      }
    }
  };

  const renderCell = (
    colName: keyof AuthorizationDto | string,
    _rowIndex: number,
    item: AuthorizationDto,
  ): React.ReactNode => {
    if (colName === 'roles') {
      const roleNames: string[] = [];
      item.roles.forEach((value: RoleDto) => roleNames.push(value.name));
      return roleNames.join(', ');
    }
    return item[colName];
  };

  const onAddPrincipal = (): void => {
    setSelectedGridRow(null);
    setIsPrincipalDialogOpen(true);
  };

  useEffect(() => {
    if (templateId) {
      handleBlueprintFetchMembers();
    } else {
      if (selectedResource?.type) {
        switch (selectedResource.type) {
          case ResourceTypes.DOMAIN:
            handleDomainFetchMembers();
            break;
          case ResourceTypes.WORKSPACE:
            handleWorkspaceFetchMembers();
            break;
          default:
            setGridData([]);
            break;
        }
      }
    }
  }, [
    selectedResource,
    templateId,
    handleBlueprintFetchMembers,
    handleDomainFetchMembers,
    handleWorkspaceFetchMembers,
  ]);

  const confirmDeleteOpen = (principalId: string) => {
    setIsAlertDialogForDeletionOpen(true);
    const clickedPrincipal = gridData.find((principal) => principal.id === principalId);
    if (!clickedPrincipal) {
      return;
    }
    setSelectedGridRow(clickedPrincipal);
    setIsAlertDialogForDeletionOpen(true);
  };

  const handlePrincipalEdit = (principalId: string) => {
    const clickedPrincipal = gridData.find((principal) => principal.id === principalId);
    if (!clickedPrincipal) {
      return;
    }
    setIsPrincipalDialogOpen(true);
    setSelectedGridRow(clickedPrincipal);
  };

  const handleCloseDialog = () => {
    setIsPrincipalDialogOpen(false);
    setSelectedGridRow(null);
  };

  const handleCloseAlertDialog = () => {
    setIsAlertDialogForDeletionOpen(false);
    setSelectedGridRow(null);
  };

  useEffect(() => {
    selectedResource &&
      selectedResource.type === ResourceTypes.WORKSPACE &&
      dispatch(getWorkspaceById(selectedResource.id));
  }, [selectedResource, dispatch]);

  const resolveRefreshFunctionByType = () => {
    if (templateId) {
      return handleBlueprintFetchMembers;
    }

    switch (selectedResource?.type) {
      case ResourceTypes.WORKSPACE:
        return handleWorkspaceFetchMembers;
      case ResourceTypes.DOMAIN:
        return handleDomainFetchMembers;
      default:
        break;
    }
  };

  const resolveLoadingByType = (): boolean => {
    if (templateId) {
      return isBlueprintMembersFetching;
    }

    switch (selectedResource?.type) {
      case ResourceTypes.WORKSPACE:
        return fetchWorkspaceMembersQuery.isFetching;
      case ResourceTypes.DOMAIN:
        return fetchDomainMembersQuery.isFetching;
      default:
        return false;
    }
  };

  const fetchTemplateById = async () => {
    try {
      if (!templateId) return;
      const templateData = await api.get<Template>(urls.getTemplate(templateId));
      setTemplate(templateData);
    } catch (e) {
      console.log('iam-->fetchTemplateById', e);
    }
  };

  const handleFetchTemplateById = useCallback(fetchTemplateById, [templateId]);

  useEffect(() => {
    templateId && handleFetchTemplateById();
  }, [templateId, handleFetchTemplateById]);

  const resolveDialogRoles = (): ResourceType | null => {
    if (templateId) {
      return ResourceTypes.BLUEPRINT;
    }
    return selectedResource?.type || null;
  };

  return (
    <>
      {templateId && (
        <Breadcrumbs style={{ marginBottom: '1em' }}>
          <Link component={BreadcrumbsLink} to={path.blueprintDashboard()}>
            <ArrowBackIcon style={{ marginRight: '.5em' }} />
            Blueprint Dashboard
          </Link>
          <span>IAM</span>
          {template && <span>{template.name}</span>}
        </Breadcrumbs>
      )}
      <CardContainer>
        <Card className={'card'}>
          <ActionRow>
            <Button style={{ marginLeft: 'auto' }} variant="contained" color="primary" onClick={onAddPrincipal}>
              <PlusIconContainer>
                <PlusIcon />
              </PlusIconContainer>
              Add
            </Button>
          </ActionRow>
          <Table
            headCells={headCells}
            data={gridData}
            isLoading={resolveLoadingByType()}
            refresh={resolveRefreshFunctionByType()}
            renderCell={renderCell}
            handleDelete={confirmDeleteOpen}
            handleEdit={handlePrincipalEdit}
          />
          {isPrincipalDialogOpen && (
            <PrincipalDetailsDialog
              principal={selectedGridRow}
              resourceType={resolveDialogRoles()}
              open={isPrincipalDialogOpen}
              title="Principal details"
              onCancel={handleCloseDialog}
              onSave={handlePrincipalCreateOrUpdate}
            />
          )}
          {isAlertDialogForDeletionOpen && (
            <AlertDialog
              isOpen={isAlertDialogForDeletionOpen}
              title="Delete principal"
              description="Are you sure you want to delete this item?"
              cancelButtonText="Cancel"
              okButtonText="Delete"
              onClose={() => {
                setIsAlertDialogForDeletionOpen(false);
                setSelectedGridRow(null);
              }}
              onOk={handlePrincipalDelete}
            />
          )}
        </Card>
      </CardContainer>
    </>
  );
};

export const Iam = withResources(IamComp);
