import { Action, ThunkAction, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { AppState } from '../common/models/interfaces';
import { TypedUseSelectorHook, useSelector as useReduxSelector } from 'react-redux';
import { persistReducer, persistStore } from 'redux-persist';
import rootReducer, { RootState } from './root-reducer';
import storage from 'redux-persist/lib/storage';

const persistedReducer = persistReducer(
  {
    key: 'aip-persisted-store',
    storage,
    whitelist: ['auth', 'currentUser'],
  },
  rootReducer,
);

export const appStore = configureStore({
  reducer: persistedReducer,
  // One of the action on the redux-persist is not serializable, so this ignores it
  middleware: getDefaultMiddleware({ serializableCheck: false }),
});

export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;

export const persistor = persistStore(appStore);

export const useSelector: TypedUseSelectorHook<AppState> = useReduxSelector;
