export const path = {
  root: () => '/',
  settings: () => '/settings',
  pipelineEditor: () => '/pipeline-editor',
  rootSignUp: () => '/?signup=true',
  rootTrialSignUp: () => '/trial/sign-up',
  reports: () => '/reports',
  alertLogs: () => '/alert-logs',
  users: () => '/iam',
  modelPerformance: () => '/model-performance',
  blueprintUsers: (templateId = ':templateId') => `/iam/blueprint/${templateId}`,
  uploadBlueprint: () => `/upload-blueprint`,
  editBlueprint: (templateId = ':templateId') => `/edit-blueprint/${templateId}`,
  blueprintDetail: (templateId = ':templateId') => `/blueprint-detail/${templateId}`,
  blueprintDashboard: () => '/blueprint-dashboard',
  blueprintDashboardNewInstance: () => '/blueprint-dashboard/new-instance',
  myTask: () => '/mytasks',
  provisioning: () => '/provisioning',
  deployBlueprints: () => '/deploy-blueprints',
  upgradeBlueprint: (blueprintId = ':templateId') => `/upgrade-blueprint/${blueprintId}`,
  info: () => '/info',
  gcpProjects: () => '/gcp-projects',
  budgetRules: () => '/budget-rules',
  configuration: () => '/configuration',
  workspaces: () => '/workspaces',
  createWorkspace: () => '/workspace/create',
  createWorkspaceNavigation: () => '/workspace/create?force=true',
  provisioningDetails: (provisioningId = ':provisioningId') => `/provisioning?provisioningId=${provisioningId}`,
  editWorkspace: (workspaceId = ':workspaceId') => `/workspaces/edit/${workspaceId}`,
  editWorkspaceWithoutParam: () => `/workspaces/edit/`,
  editWorkspaceWithTab: (workspaceId = ':workspaceId', tabIndex = ':tabIndex', provisioningId = ':provisioningId') =>
    `/workspaces/edit/${workspaceId}?tabIndex=${tabIndex}&provisioningId=${provisioningId}`,
  createBudgetRule: (workspaceId = ':workspaceId') => `/workspaces/edit/${workspaceId}/budget/create`,
  editBudgetRule: (workspaceId = ':workspaceId', budgetId = ':budgetId') =>
    `/workspaces/edit/${workspaceId}/budget/${budgetId}/edit`,
  support: () => '/support',
  notFound: () => '/not-found',
  accessDenied: () => '/access-denied',
  mlflow: () => `/mlflow`,
  kubeflow: () => `/kubeflow`,
  modelServing: () => `/model-serving`,
  managedServiceAccess: (type = ':type', serviceId = ':serviceId') => `/managed-service-access/${type}/${serviceId}`,
};
