import { ModelPlotTypes } from '../../common/models/enums';
import { urls } from '../../common/utils/urls';
import api from '../../common/utils/api';

interface TGetPlotTypesOptions {
  cb?: (plotTypes: ModelPlotTypes[]) => void;
  beforeActions?: () => void;
}

async function getPlotTypes(domainId: string, modelId: string, options?: TGetPlotTypesOptions) {
  try {
    options?.beforeActions?.();
    const { plotTypes } = await api.get<{ plotTypes: ModelPlotTypes[] }>(urls.getPlotTypes(domainId, modelId));
    options?.cb?.(plotTypes);
  } catch (e) {
    console.error(e);
  }
}

export { getPlotTypes };
