import { Link } from '@material-ui/core';
import styled from 'styled-components';

export interface CommonProps {
  marginBottom?: number;
  fontSize?: number;
}

export const Row = styled.div`
  width: 100%;
  display: flex;
`;

export const Column = styled(Row)`
  flex-direction: column;
`;

export const VerticallyCenteredRow = styled(Row)`
  align-items: center;
`;

export const ProfilePicture = styled.img<{ size: number }>`
  width: ${(p) => p.size}px;
  height: ${(p) => p.size}px;
  border-radius: 50%;
`;

export const Title = styled.div<CommonProps>`
  font-size: 20px;
  line-height: 32px;
  color: ${(p) => p.theme.colors.darkGreen};
  margin-bottom: ${(p) => (p.marginBottom ? `${p.marginBottom}px` : 0)};
`;

export const TransparentText = styled.div<CommonProps>`
  font-size: ${(p) => (p.fontSize ? `${p.fontSize}px` : '16px')};
  line-height: 24px;
  color: ${(p) => p.theme.colors.transparentDarkGreen};
  margin-bottom: ${(p) => (p.marginBottom ? `${p.marginBottom}px` : 0)};
`;

export const Text = styled.div<CommonProps>`
  font-size: ${(p) => (p.fontSize ? `${p.fontSize}px` : '16px')};
  color: ${(p) => p.theme.colors.darkGreen};
  margin-bottom: ${(p) => (p.marginBottom ? `${p.marginBottom}px` : 0)};
`;

export const PlusIconContainer = styled.div`
  margin-right: 13px;
`;

export const CardContainer = styled.div`
  background: ${(p) => p.theme.colors.white};
  box-shadow: 0 0.1px 0.3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.2);
  margin-bottom: 16px;
`;

export const HalfCardContainer = styled(CardContainer)`
  max-width: 580px;
  width: 100%;
`;

export const CardContent = styled.div`
  padding: 16px;
`;

export const Margin = styled.div<{
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
}>`
  margin-top: ${(p) => (p.top ? `${p.top}px` : 0)};
  margin-right: ${(p) => (p.right ? `${p.right}px` : 0)};
  margin-bottom: ${(p) => (p.bottom ? `${p.bottom}px` : 0)};
  margin-left: ${(p) => (p.left ? `${p.left}px` : 0)};
`;

export const InformationContainer = styled.div`
  padding: 20px;
`;

export const Label = styled.div`
  padding-bottom: 20px;
  font-size: 16px;
  line-height: 24px;
  color: ${(p) => p.theme.colors.transparentDarkGreen};
`;

export const Links = styled(Link)`
  display: flex;
  align-items: center;
  &.disabled {
    color: #a7a7a7;
  }
  & .MuiSvgIcon-root {
    display: inline-block;
    height: 15px;
    width: 15px;
    margin-left: 5px;
  }
`;

export const ChartTitle = styled.p`
  color: #02394d;
  margin-left: 2em;
`;
