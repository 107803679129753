import { Row } from '../../../common/styles/common.styles';
import { ToastType } from '../../../common/models/enums';
import styled from 'styled-components';

export const ToastContainer = styled(Row)<{
  isVisible: boolean;
  type: ToastType;
}>`
  position: relative;
  width: 100%;
  min-height: 50px;
  transform: translateX(100%);
  align-items: center;
  margin-bottom: 10px;
  padding: 15px;
  color: ${(p) => p.theme.colors.white};
  border-radius: 4px;
  box-shadow: 0 0.8px 1.5px rgba(0, 0, 0, 0.1), 0 6px 12px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  word-break: break-word;
  transition: transform 500ms cubic-bezier(0.34, 1.56, 0.64, 1);

  ${(p) => p.isVisible && 'transform: translateX(-10px)'};
  ${(p) => p.type === ToastType.SUCCESS && `background: ${p.theme.colors.success}`};
  ${(p) => p.type === ToastType.WARNING && `background: ${p.theme.colors.warning}; color: ${p.theme.colors.black}`};
  ${(p) => p.type === ToastType.DANGER && `background: ${p.theme.colors.danger}`};
`;
