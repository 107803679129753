import { Search } from '../../assets';
import { Theme, createStyles } from '@material-ui/core';
import styled from 'styled-components';

export const Logo = styled.div`
  position: absolute;
  height: 24px;
  left: 16px;
  top: 12px;
`;

export const InputContainer = styled.div`
  position: relative;
`;

export const SearchIcon = styled(Search)`
  margin-left: 16px;
`;

export const chipInputStyles = (theme: Theme) =>
  createStyles({
    input: {
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: 8,
      padding: 8,
      paddingLeft: 56,
      width: 560,
    },
    chip: {
      padding: 0,
      marginRight: 8,
      backgroundColor: 'rgba(2, 57, 77, 0.08)',
    },
    chipText: {
      fontSize: 14,
      lineHeight: 20,
      letterSpacing: 0.5,
      color: theme.palette.black,
    },

    chipContainer: {
      minHeight: 0,
    },
  });
