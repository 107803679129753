import { EnvironmentType } from '../../../../common/models/enums';
import { NewInstanceProps } from '../new-instance';
import { Project } from '../../../../common/models/workspace.models';
import { ProjectToWorkspaceAdder } from '../../../../components/project-to-workspace-adder/project-to-workspace-adder';
import { SelectWorkspaceRoot } from '../new-instance.styles';
import { WorkspaceQuery } from '../../../../common/utils/query';
import { urls } from '../../../../common/utils/urls';
import { useSelector } from '../../../../store/configure-store';
import React, { useCallback, useEffect, useState } from 'react';
import api from '../../../../common/utils/api';

export const SelectProject: React.FC<NewInstanceProps> = (props) => {
  const [selectedWorkspaceProjects, setSelectedWorkspaceProjects] = useState<Project[] | null>(null);
  const [selectableProjects, setSelectableProjects] = useState<Project[] | null>([]);
  const selectedResource = useSelector((state) => state.dynamicSelector.selectedResource);
  const domainId = selectedResource?.domainId || '';
  const [originalSelectableProjects, setOriginalSelectableProjects] = useState<Project[]>();

  const projectsWithIds =
    props.selectedProjects?.map((project) => ({
      ...project,
      id: project?.projectId,
    })) || [];

  if (props.selectedWorkspace?.id) {
    const workspaceQuery = WorkspaceQuery(props.selectedWorkspace.id, 'select-project-workspace');
    if (workspaceQuery.data) {
      setSelectedWorkspaceProjects(workspaceQuery.data.projects);
    }
  }

  const onSave = (createProject: Project | Project[]) => {
    if (Array.isArray(createProject)) {
      return;
    }
    const projects = props.selectedProjects ? [...props.selectedProjects] : [];
    const updatedProjects = projects?.filter((pro) => pro.projectId === createProject.projectId);
    if (updatedProjects && updatedProjects.length > 0) {
      return;
    }
    projects.push(createProject);
    if (props.onSelectProject) {
      props.onSelectProject(projects);
    }
  };

  const onUpdateProject = (environmentType: EnvironmentType, item: Project) => {
    const projects = props.selectedProjects ? [...props.selectedProjects] : [];
    const updatedProjects = projects?.filter((pro) => pro.projectId === item.projectId);
    if (!updatedProjects || updatedProjects.length === 0) {
      return;
    }
    updatedProjects[0].environmentType = environmentType;
    if (props.onSelectProject) {
      props.onSelectProject(projects);
    }
  };

  const handleProjectDelete = (project: Project) => {
    const projects = props.selectedProjects ? [...props.selectedProjects] : [];
    const deleteProjectIndex = projects?.findIndex((pro) => pro.projectId === project.projectId);
    if (deleteProjectIndex === undefined) {
      return;
    }
    if (props.onSelectProject) {
      projects.splice(deleteProjectIndex, 1);
      props.onSelectProject(projects);
    }
  };

  useEffect(() => {
    if (props.selectedProjects && props.onSelectProject) {
      setSelectedWorkspaceProjects(props.selectedProjects);
      props.onSelectProject(props.selectedProjects);
    }
  }, [props.selectedProjects, props.onSelectProject, props]);

  useEffect(() => {
    if (props.selectedProjects && originalSelectableProjects) {
      const allProjects: Project[] = [...originalSelectableProjects];
      const onlySelectableProjects = allProjects.filter(
        (value) => !props.selectedProjects?.find((v) => v.projectId === value.projectId),
      );
      setSelectableProjects(onlySelectableProjects);
    } else if (originalSelectableProjects) {
      setSelectableProjects(originalSelectableProjects);
    }
  }, [props.selectedProjects, originalSelectableProjects]);

  const fetchSelectableProjects = async () => {
    try {
      const projects = await api.get<Project[]>(urls.projects(domainId || ''), { params: { onlyUnallocated: true } });
      if (projects) setOriginalSelectableProjects(projects);
    } catch (e) {
      console.log('gcp-projects-->fetchSelectableProjects', e);
    }
  };
  const handleFetchSelectableProjects = useCallback(fetchSelectableProjects, []);

  useEffect(() => {
    handleFetchSelectableProjects();
  }, [handleFetchSelectableProjects]);

  return (
    <SelectWorkspaceRoot>
      <div className={'center-container'}>
        <ProjectToWorkspaceAdder
          hideName={true}
          workspaceProjects={selectedWorkspaceProjects}
          onSave={onSave}
          tableProjects={projectsWithIds}
          tableOnEdit={onUpdateProject}
          tableOnDelete={handleProjectDelete}
          selectableProjects={selectableProjects as Project[]}
        />
      </div>
    </SelectWorkspaceRoot>
  );
};
