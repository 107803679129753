import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const TemplateVariablesContainer = styled.div`
  margin-bottom: 25px;
`;

export const SelectTemplateRoot = styled.div`
  & .select {
    position: absolute;
    z-index: 2;
  }
`;

export const WorkspaceFromContainer = styled.div`
  display: flex;
  max-width: 600px;
  flex-direction: column;
  grid-row-gap: 20px;
  margin-top: 20px;
  width: 100%;
`;

export const FirstTab = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 40px;
  grid-row-gap: 20px;
  grid-template-columns: max-content auto;
  & label {
    text-align: right;
    color: ${(p) => p.theme.colors.primary};
  }
  & span {
    color: black;
  }
  & a {
    color: ${(p) => p.theme.colors.primary};
  }
`;
export const SecondTab = styled.div`
  & a {
    color: ${(p) => p.theme.colors.primary};
  }
`;

export const BreadcrumbsLink = styled(Link)`
  font-size: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
