import { Breadcrumbs, Card, Link, Tab, Tabs } from '@material-ui/core';
import { BreadcrumbsLink, FirstTab, SecondTab } from './blue-print-detail.styles';
import { CardContent, HalfCardContainer } from '../../../common/styles/common.styles';
import { CellPrimaryValue } from '../../workspaces/workspaces.styles';
import { RepositoryDetail, TemplateDetail } from '../../../common/models/workspace.models';
import { SelectedTemplateCard } from '../../deploy-blueprints/selected-template-card/selected-template-card';
import { TableHeadCell } from '../../../components/Table/TableHead';
import { createToasterNotification } from '../../../common/utils/toaster-notification';
import { getDate } from '../../../common/utils/date-formatter';
import { path } from '../../../routes/path';
import { urls } from '../../../common/utils/urls';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import React, { useState } from 'react';
import Table from '../../../components/Table';
import api from '../../../common/utils/api';
import { TemplateCategory } from '../../../common/models/interfaces';

export const headCells: TableHeadCell<RepositoryDetail>[] = [
  { id: 'workspace', label: 'Workspace' },
  { id: 'workspaceId', label: 'Workspace ID' },
  { id: 'environment', label: 'Environment' },
  { id: 'version', label: 'Version' },
  { id: 'repository', label: 'Repository' },
];

export const renderCell = (
  colName: RepositoryDetail | string,
  _rowIndex: number,
  item: RepositoryDetail,
): React.ReactNode => {
  switch (colName) {
    case 'workspace': {
      return <CellPrimaryValue title={item.workspace.name}>{item.workspace.name}</CellPrimaryValue>;
    }
    case 'workspaceId': {
      return <CellPrimaryValue title={item.workspace.id}>{item.workspace.id}</CellPrimaryValue>;
    }
    case 'version': {
      return <CellPrimaryValue title={item.templateVersion.version}>{item.templateVersion.version}</CellPrimaryValue>;
    }
    case 'repository': {
      return (
        <CellPrimaryValue title={item.repositoryUrl}>
          <a color="primary" target="_blank" rel="noopener noreferrer" href={item.repositoryUrl}>
            {item.repositoryUrl}
          </a>
        </CellPrimaryValue>
      );
    }
    default: {
      return <CellPrimaryValue>{item[colName as string]}</CellPrimaryValue>;
    }
  }
};

export const BluePrintDetail: React.FC = () => {
  const dispatch = useDispatch();
  const { templateId } = useParams() as any;
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const templateQuery = useQuery(
    `template-detail`,
    () => {
      return api
        .get<TemplateDetail>(urls.getTemplate(templateId))
        .then((data) => data)
        .catch((e) => {
          dispatch(createToasterNotification(e));
        });
    },
    { enabled: true, refetchOnWindowFocus: false },
  );

  const resolveCategoryDisplay = (categories: TemplateCategory[]) => {
    if (categories && categories.length) {
      const displayNames = categories.map((category) => category.displayName);
      return displayNames.join(', ');
    } else {
      return '-';
    }
  };

  return (
    <>
      <Breadcrumbs style={{ marginBottom: '1em' }}>
        <Link component={BreadcrumbsLink} to={path.blueprintDashboard()}>
          <ArrowBackIcon style={{ marginRight: '.5em' }} />
          Blueprint Dashboard
        </Link>
        {templateQuery.data && <span>{templateQuery.data.name}</span>}
      </Breadcrumbs>
      <HalfCardContainer>
        <Card style={{ position: 'relative' }}>
          <CardContent className={'content'}>
            <SelectedTemplateCard template={templateQuery.data as TemplateDetail} />
            <Tabs
              className={'tabs'}
              indicatorColor="primary"
              value={activeTabIndex}
              onChange={(event, nextTabIndex) => setActiveTabIndex(nextTabIndex)}
            >
              <Tab label={'BLUEPRINT DETAILS'} key={`tab-label-1`} />
              <Tab label={'INSTANCE'} key={`tab-label-2`} />
            </Tabs>
            {activeTabIndex === 0 && templateQuery.data && (
              <FirstTab>
                <label>Current version</label>
                <span>{templateQuery.data.versions && templateQuery.data.versions[0].version}</span>
                <label>Last update</label>
                <span>{getDate(new Date(templateQuery.data.lastModifiedAt))}</span>
                <label>Template repository</label>
                <span>
                  <a
                    color="primary"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={'https://github.com/' + templateQuery.data.repositoryName}
                  >
                    {'https://github.com/' + templateQuery.data.repositoryName}
                  </a>
                </span>
                <label>Categories</label>
                <span>{resolveCategoryDisplay(templateQuery.data.templateCategories)}</span>
              </FirstTab>
            )}
            {activeTabIndex === 1 && templateQuery.data && (
              <SecondTab>
                <Table
                  withTopActions={false}
                  isLoading={templateQuery.isFetching}
                  headCells={headCells}
                  renderCell={renderCell}
                  data={templateQuery.data.repositories as RepositoryDetail[]}
                />
              </SecondTab>
            )}
          </CardContent>
        </Card>
      </HalfCardContainer>
    </>
  );
};
