import * as S from './styles';
import {
  ModelMeta as ModelMetaType,
  ModelPredictionsMetrics,
  ModelRegressionMetrics,
} from '../../common/models/model.model';
import { Skeleton } from '@material-ui/lab';
import { urls } from '../../common/utils/urls';
import ModelMeta from '../ModelMeta/ModelMeta';
import ModelPerformanceContext from '../../pages/ModelPerformance/ModelPerformanceContext';
import NoModelSelected from '../NoModelSelected/NoModelSelected';
import PredictionsMetrics from '../PredictionsMetrics/PredictionsMetrics';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import RegressionMetrics from '../RegressionMetrics/RegressionMetrics';
import api from '../../common/utils/api';

const ModelOverviewTab = () => {
  const { domainId, modelId } = useContext(ModelPerformanceContext);
  const [meta, setMeta] = useState<ModelMetaType>();
  const [isMetaLoading, setIsMetaLoading] = useState(false);
  const [regressionMetrics, setRegressionMetrics] = useState<ModelRegressionMetrics>();
  const [predictionsMetrics, setPredictionsMetrics] = useState<ModelPredictionsMetrics>();
  const evaluationMetric = meta?.evaluationMetric;

  const fetchMeta = async () => {
    try {
      setIsMetaLoading(true);
      const metaData = await api.get<ModelMetaType>(urls.getModelMeta(domainId || '', modelId || ''));
      metaData && setMeta(metaData);
      setIsMetaLoading(false);
    } catch (e) {
      console.log('ModelOverviewTab-->fetchMeta', e);
      setIsMetaLoading(false);
    }
  };

  const fetchRegressionMetrics = async () => {
    try {
      const metrics = await api.get<ModelRegressionMetrics>(
        urls.getRegressionMetrics(domainId || '', modelId || '', evaluationMetric || ''),
      );
      metrics && setRegressionMetrics(metrics);
    } catch (e) {
      console.log('ModelOverviewTab-->fetchRegressionMetrics', e);
    }
  };

  const fetchPredictionsMetrics = async () => {
    try {
      const predictions = await api.get<ModelPredictionsMetrics>(
        urls.getPredictionsMetrics(domainId || '', modelId || ''),
      );
      predictions && setPredictionsMetrics(predictions);
    } catch (e) {
      console.log('ModelOverviewTab-->fetchPredictionsMetrics', e);
    }
  };

  const handleFetchMeta = useCallback(fetchMeta, [domainId, modelId]);
  const handleFetchRegressionMetrics = useCallback(fetchRegressionMetrics, [domainId, modelId, evaluationMetric]);
  const handleFetchPredictionsMetrics = useCallback(fetchPredictionsMetrics, [domainId, modelId]);

  useEffect(() => {
    if (domainId && modelId) handleFetchMeta();
    if (domainId && modelId) handleFetchPredictionsMetrics();
  }, [domainId, modelId, handleFetchMeta, handleFetchPredictionsMetrics]);

  useEffect(() => {
    if (evaluationMetric && domainId && modelId) handleFetchRegressionMetrics();
  }, [handleFetchRegressionMetrics, evaluationMetric, domainId, modelId]);

  if (!modelId) return <NoModelSelected />;

  return (
    <S.OverviewWrapper>
      <S.MetaColumn>
        {isMetaLoading && <Skeleton style={{ borderRadius: '5px' }} variant="rect" width={'100%'} height={380} />}
        {meta && <ModelMeta meta={meta} />}
      </S.MetaColumn>
      <div>
        {regressionMetrics && <RegressionMetrics metrics={regressionMetrics} />}
        {predictionsMetrics && <PredictionsMetrics metrics={predictionsMetrics} />}
      </div>
    </S.OverviewWrapper>
  );
};

export default ModelOverviewTab;
