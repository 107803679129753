import { Button } from '@material-ui/core';
import { CloudUploadIcon } from '../../assets';
import { Link } from 'react-router-dom';
import { path } from '../../routes/path';
import React from 'react';

interface UploadBlueprintBtnPropsT {
  style?: React.CSSProperties;
}

const UploadBlueprintBtn: React.FC<UploadBlueprintBtnPropsT> = (props) => {
  return (
    <Button
      {...props}
      className="upload-blurpint-btn"
      variant="contained"
      color="primary"
      component={Link}
      to={path.uploadBlueprint()}
      startIcon={<CloudUploadIcon stroke="white" />}
    >
      Upload Blueprint
    </Button>
  );
};

export default UploadBlueprintBtn;
