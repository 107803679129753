import styled from 'styled-components';

const ChartGridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1em;
  margin-top: 1em;
`;

export { ChartGridWrapper };
