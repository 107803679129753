import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  InputAdornment,
  Table as MuiTable,
  OutlinedInput,
  TableFooter,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import {
  CardRoot,
  NewInstanceImage,
  ProvidedByAlizImage,
  ProvidedByAlizPicture,
  ProvidedByUserImage,
  SelectRadioButtonImage,
  TemplateSearchRoot,
  UnSelectRadioButtonImage,
} from './template-search.styles';
import { CellPrimaryValue, CellSecondaryValue } from '../../pages/workspaces/workspaces.styles';
import { GridIcon, ListIcon } from '../../assets';
import { TableHeadCell } from '../Table/TableHead';
import { Template, TemplateResponse } from '../../common/models/workspace.models';
import { TemplateCategory } from '../../common/models/interfaces';
import { createToasterNotification } from '../../common/utils/toaster-notification';
import { deleteBlueprint } from '../TemplateVerticalMenu/requests';
import { get12HourFormat, getDate } from '../../common/utils/date-formatter';
import { urls } from '../../common/utils/urls';
import { useDispatch } from 'react-redux';
import { useQuery } from 'react-query';
import CategoryFilter from '../CategoryFilter';
import GeneralModal from '../GeneralModal';
import GeneralModalContext from '../GeneralModal/GeneralModalContext';
import React, { useCallback, useEffect, useState } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import Table from '../Table';
import TemplateVerticalMenu from '../TemplateVerticalMenu/TemplateVerticalMenu';
import UploadBlueprintBtn from '../UploaBlueprintBtn/UploadBlueprintBtn';
import api from '../../common/utils/api';
import blueprintThumbnails from '../../common/utils/blueprintThumbnails';

export interface SelectTemplateProps {
  selectionEnabled?: boolean;
  selectedTemplate?: Template | null;
  onTemplateSelected?: (template: Template) => void;
  showNewInstance?: boolean;
  onNewInstanceClick?: () => void;
  onNewInstanceCardClick?: () => void;
  onCardClick?: (template: Template) => void;
  onActionClick?: (template: Template) => void;
  buttonText: string;
  templateApiUrl?: string;
}

export const headCells: TableHeadCell<Template>[] = [
  { id: 'blueprintName', label: 'Blueprint name' },
  { id: 'currentVersion', label: 'Current version' },
  { id: 'lastUpdate', label: 'Last update' },
  { id: 'instanceCount', label: 'Instances' },
  { id: 'action', label: ' ' },
];

// tslint:disable-next-line:no-any
export const debounce = <F extends (...args: any[]) => any>(func: F, waitFor: number) => {
  let timeout: ReturnType<typeof setTimeout> | null = null;

  const debounced = (...args: Parameters<F>) => {
    if (timeout !== null) {
      clearTimeout(timeout);
      timeout = null;
    }
    timeout = setTimeout(() => func(...args), waitFor);
  };

  return debounced as (...args: Parameters<F>) => ReturnType<F>;
};

export const TemplateSearch: React.FC<SelectTemplateProps> = (props) => {
  const dispatch = useDispatch();
  const [selectedTemplate, setSelectedTemplate] = useState<Template | null | undefined>(props.selectedTemplate);
  const [searchPage, setsearchPage] = useState<{ search: string; page: number }>({
    search: '',
    page: 0,
  });
  const [gridView, setGridView] = useState<boolean>(true);
  const [count, setCount] = useState<number>(0);
  const [selectedCategories, setSelectedCategories] = useState<TemplateCategory[]>();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [templateForDelete, setTemplateForDelete] = useState<string>();

  const templatesQuery = useQuery(
    [`templates-search`, selectedCategories],
    (_, categories: TemplateCategory[]) => {
      const data = { expression: searchPage.search, showGeneratable: true };

      if (categories && categories.length) {
        data['categories'] = categories?.map((category) => category.id);
      }

      return api
        .post<TemplateResponse>(
          props.templateApiUrl ? props.templateApiUrl : urls.getTemplatesPage({ page: searchPage.page, size: 20 }),
          data,
        )
        .then((dataResult) => {
          setCount(dataResult.totalElements);
          return dataResult;
        })
        .catch((e) => {
          dispatch(createToasterNotification(e));
        });
    },
    { refetchOnWindowFocus: false },
  );

  const getTemplates = (): Template[] => {
    if (templatesQuery.data) {
      return (templatesQuery.data.content as Template[]) ?? [];
    } else {
      return [];
    }
  };

  const onCardClick = (template: Template): void => {
    if (props.onCardClick) {
      props.onCardClick(template);
    }
    if (props.selectionEnabled) {
      setSelectedTemplate(template);
      if (props.onTemplateSelected) {
        props.onTemplateSelected(template as Template);
      }
    }
  };

  const onActionClick = (template: Template, e: React.MouseEvent): void => {
    e.stopPropagation();
    if (props.onActionClick) {
      props.onActionClick(template as Template);
    }
  };

  const onNewInstanceClick = (e: React.MouseEvent): void => {
    e.stopPropagation();
    if (props.onNewInstanceClick) {
      props.onNewInstanceClick();
    }
  };

  const onNewInstanceCardClick = (): void => {
    if (props.onNewInstanceCardClick) {
      props.onNewInstanceCardClick();
    }
  };

  const debounceSearch = debounce((value: string) => {
    const copy = {
      search: value,
      page: 0,
    };
    setsearchPage(copy);
  }, 450);

  const onSearch = (value: string): void => {
    debounceSearch(value);
  };

  const handleFetchTemplates = useCallback(templatesQuery.refetch, []);

  useEffect(() => {
    handleFetchTemplates();
  }, [searchPage, handleFetchTemplates]);

  const onViewChange = (): void => {
    setGridView(!gridView);
  };

  const onPaginationChanged = (p: number): void => {
    const copy = {
      search: searchPage.search,
      page: p,
    };
    setsearchPage(copy);
  };

  const renderCell = (colName: Template | string, _rowIndex: number, item: Template): React.ReactNode => {
    switch (colName) {
      case 'blueprintName': {
        return <CellPrimaryValue title={item.name}>{item.name}</CellPrimaryValue>;
      }
      case 'currentVersion': {
        return <CellPrimaryValue title={''}>{''}</CellPrimaryValue>;
      }
      case 'instanceCount': {
        return <CellPrimaryValue>{item.instanceCount} instances</CellPrimaryValue>;
      }
      case 'lastUpdate': {
        const d = new Date(item.lastModifiedAt as string);
        return (
          <>
            <CellPrimaryValue>{getDate(d)}</CellPrimaryValue>
            <CellSecondaryValue className="time">{get12HourFormat(d)}</CellSecondaryValue>
          </>
        );
      }
      case 'action': {
        return (
          <CellPrimaryValue>
            <Button onClick={(e) => onActionClick(item, e)}>{props.buttonText}</Button>
          </CellPrimaryValue>
        );
      }
      default: {
        return <CellPrimaryValue>{item[colName as string]}</CellPrimaryValue>;
      }
    }
  };

  return (
    <TemplateSearchRoot>
      <GeneralModalContext.Provider
        value={{
          onSubmit: () => {
            templateForDelete &&
              deleteBlueprint(templateForDelete, {
                cb: () => {
                  setIsDeleteModalOpen(false);
                  templatesQuery.refetch();
                },
              });
          },
          modalIsOpen: isDeleteModalOpen,
          setModalIsOpen: setIsDeleteModalOpen,
          primaryText: 'Delete',
          secondaryText: 'Cancel',
          title: 'Delete blueprint',
        }}
      >
        <div className={'search-container'}>
          <OutlinedInput
            className={'search-input'}
            placeholder={'Search blueprint'}
            onChange={(e) => onSearch(e.target.value)}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
          />
          {gridView ? (
            <ListIcon onClick={onViewChange} className={'view-changer'} />
          ) : (
            <GridIcon onClick={onViewChange} className={'view-changer'} />
          )}
          <UploadBlueprintBtn style={{ marginLeft: '1em' }} />
        </div>
        <CategoryFilter selectAllByDefault={true} onChangeAction={setSelectedCategories} />
        {gridView && (
          <div style={{ marginTop: '12px' }}>
            <div className="tour">
              {props.showNewInstance && (
                <CardRoot className="tour2">
                  <Card className={'card'} onClick={() => onNewInstanceCardClick()}>
                    <CardMedia
                      className={'media'}
                      image={NewInstanceImage.attrs[0][`src`]}
                      title="Contemplative Reptile"
                    />
                    <CardContent className={'content'}>
                      <Typography className={'title'} gutterBottom color="primary" variant="body2" component="p">
                        Add new instance
                      </Typography>
                      <Typography className={'description'} variant="body2" color="textSecondary" component="p">
                        Create new instance from templates available in the blueprint repository
                      </Typography>
                    </CardContent>
                    <CardActions className={'actions'}>
                      <Button size="small" color="primary" onClick={(e) => onNewInstanceClick(e)}>
                        NEW INSTANCE
                      </Button>
                    </CardActions>
                  </Card>
                </CardRoot>
              )}
              {getTemplates().map((template, index) => {
                return (
                  <CardRoot key={index}>
                    <Card
                      className={selectedTemplate?.id === template.id ? 'card selected' : 'card'}
                      onClick={() => onCardClick(template ?? null)}
                    >
                      {selectedTemplate?.id === template.id && props.selectionEnabled && (
                        <SelectRadioButtonImage className={'select'} />
                      )}
                      {selectedTemplate?.id !== template.id && props.selectionEnabled && (
                        <UnSelectRadioButtonImage className={'select'} />
                      )}

                      {
                        <CardMedia
                          className={'media external'}
                          component="div"
                          image={blueprintThumbnails(template?.image)}
                          src={blueprintThumbnails(template?.image)}
                          title="Contemplative Reptile"
                          style={{ backgroundSize: 'contain' }}
                        >
                          <ProvidedByAlizPicture
                            src={
                              template.providedByAliz
                                ? ProvidedByAlizImage.attrs[0][`src`]
                                : ProvidedByUserImage.attrs[0][`src`]
                            }
                          />
                        </CardMedia>
                      }
                      <CardContent className={'content'}>
                        <Typography className={'title'} gutterBottom color="primary" variant="body2" component="p">
                          {template.name.substring(0, 40) + (template.name.length > 40 ? '...' : '')}
                        </Typography>
                        <Typography className={'description'} variant="body2" color="textSecondary" component="p">
                          {template.description.substring(0, 75) + (template.description.length > 75 ? '...' : '')}
                        </Typography>
                      </CardContent>
                      <CardActions className={'actions'}>
                        <Button size="small" color="primary" onClick={(e) => onActionClick(template ?? null, e)}>
                          {props.buttonText}
                        </Button>
                      </CardActions>
                    </Card>
                    <TemplateVerticalMenu setTemplateForDelete={setTemplateForDelete} templateId={template?.id} />
                  </CardRoot>
                );
              })}
            </div>
          </div>
        )}
        {!gridView && (
          <Table
            singleSelect={props.selectionEnabled}
            select={props.selectionEnabled}
            onSelectionChanged={(x: string[]) => {
              onCardClick(getTemplates().filter((value) => value.id === x[0])[0]);
            }}
            withTopActions={false}
            isLoading={templatesQuery.isFetching}
            headCells={headCells}
            renderCell={renderCell}
            data={getTemplates()}
          />
        )}
        <MuiTable>
          <TableFooter>
            <TableRow>
              <TablePagination
                style={{ borderBottom: 'none', marginTop: '35px' }}
                page={searchPage.page}
                count={count}
                labelRowsPerPage={''}
                rowsPerPageOptions={[]}
                onPageChange={(event: React.MouseEvent | null, p: number) => {
                  onPaginationChanged(p);
                }}
                rowsPerPage={20}
              />
            </TableRow>
          </TableFooter>
        </MuiTable>

        <GeneralModal>
          Are you sure you want to delete this blueprint?
          <br />
          <div style={{ fontWeight: 'bold', marginTop: '6px' }}>
            {getTemplates()?.find((template) => template.id === templateForDelete)?.name}
          </div>
        </GeneralModal>
      </GeneralModalContext.Provider>
    </TemplateSearchRoot>
  );
};
