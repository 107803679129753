import styled from 'styled-components';

export const ProjectIdField = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;

  & .project-id-field {
    flex: 1;
  }
`;
