import {
  AlertTypeInputContainer,
  InfoIconContainer,
  LimitAutocompleteContainer,
  NotificationAutocompleteContainer,
  TrashIconContainer,
} from '../budget-form.styles';
import { Autocomplete, AutocompleteRenderInputParams } from '@material-ui/lab';
import { Column } from '../../../../common/styles/common.styles';
import { InfoIcon, TrashIcon } from '../../../../assets';
import { Notification } from '../../../../common/models/workspace.models';
import { NotificationAutocomplete, NotificationLimitAutocomplete } from '../../../../common/models/interfaces';
import { NotificationType } from '../../../../common/models/enums';
import { TextField, Tooltip } from '@material-ui/core';
import { limitOptions } from '../../../../common/utils/limit-options';
import React, { useEffect, useState } from 'react';

export interface AlertInputProps {
  index: number;
  notification: Notification;
  tooltipText?: string;
  handleNotificationUpdate: (value: NotificationType | null, index: number) => void;
  handleNotificationLimitUpdate: (value: number | null, index: number) => void;
  handleNotificationWebhookUrlUpdate: (value: string | null, index: number) => void;
  handleDeleteNotification: (index: number) => void;
}

const notificationOptions: { name: string; value: NotificationType }[] = [
  {
    name: 'Email',
    value: NotificationType.EMAIL,
  },
  {
    name: 'Webhook',
    value: NotificationType.WEBHOOK,
  },
];

export const AlertInput: React.FC<AlertInputProps> = (props) => {
  const [notificationValue, setNotificationValue] = useState<NotificationAutocomplete | null>(null);
  const [limitValue, setLimitValue] = useState<NotificationLimitAutocomplete | null>(null);
  const [webhookUrl, setWebhookUrl] = useState<string | null>(null);
  useEffect(() => {
    setNotificationValue(
      notificationOptions.find(
        (notificationOption) => notificationOption.value === props.notification.notificationType,
      ) ?? null,
    );
    setLimitValue(limitOptions.find((limitOption) => limitOption.value === props.notification.percent) ?? null);
    setWebhookUrl(props.notification.url ?? '');
  }, [props.notification]);

  const renderInput = (params: AutocompleteRenderInputParams, label: string) => {
    return <TextField {...params} label={label} variant="outlined" fullWidth />;
  };

  const handleNotificationUpdate = (selectedNotification: NotificationAutocomplete | null) => {
    setNotificationValue(selectedNotification);
    props.handleNotificationUpdate(selectedNotification?.value ?? null, props.index);
  };

  const handleNotificationLimitUpdate = (selectedLimit: NotificationLimitAutocomplete | null) => {
    setLimitValue(selectedLimit);
    props.handleNotificationLimitUpdate(selectedLimit?.value ?? null, props.index);
  };

  const handleNotificationWebhookUrlUpdate = (enteredUrl: string | null) => {
    setWebhookUrl(enteredUrl);
    props.handleNotificationWebhookUrlUpdate(enteredUrl, props.index);
  };

  const isWebhookNotification = notificationValue?.value === NotificationType.WEBHOOK;

  return (
    <Column>
      <AlertTypeInputContainer isWebhook={isWebhookNotification}>
        <NotificationAutocompleteContainer>
          <Autocomplete
            options={notificationOptions}
            renderInput={(params) => renderInput(params, 'Notification type')}
            getOptionLabel={(option) => option.name}
            onChange={(event, value) => handleNotificationUpdate(value)}
            value={notificationValue}
            getOptionSelected={(option) => option.value === notificationValue?.value}
            fullWidth
            size="small"
          />
        </NotificationAutocompleteContainer>
        <LimitAutocompleteContainer>
          <Autocomplete
            options={limitOptions}
            renderInput={(params) => renderInput(params, 'Limit')}
            getOptionLabel={(option) => option.name}
            onChange={(event, value) => handleNotificationLimitUpdate(value)}
            value={limitValue}
            getOptionSelected={(option) => option.value === limitValue?.value}
            fullWidth
            size="small"
          />
        </LimitAutocompleteContainer>
        <InfoIconContainer>
          <Tooltip title={props.tooltipText ? props.tooltipText : ''}>
            <InfoIcon />
          </Tooltip>
        </InfoIconContainer>
        <TrashIconContainer onClick={() => props?.handleDeleteNotification(props.index)}>
          <TrashIcon />
        </TrashIconContainer>
      </AlertTypeInputContainer>
      {isWebhookNotification && (
        <TextField
          label="URL"
          variant="outlined"
          color="primary"
          size="small"
          value={webhookUrl}
          fullWidth
          onChange={(event) => handleNotificationWebhookUrlUpdate(event.target.value)}
        />
      )}
    </Column>
  );
};
