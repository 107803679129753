import { Button, Card } from '@material-ui/core';
import { CardContainer, PlusIconContainer } from '../../common/styles/common.styles';
import { PlusIcon } from '../../assets';
import { Table } from '../../components';
import { TableHeaderCtaContainer } from '../workspaces/workspaces.styles';
import { ToastType } from '../../common/models/enums';
import { createToasterNotification } from '../../common/utils/toaster-notification';
import { getWorkspaceById } from '../../store/workspaces/actions';
import { headCells, renderCell } from './table-config/table-config';
import { path } from '../../routes/path';
import { urls } from '../../common/utils/urls';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useSelector } from '../../store/configure-store';
import React, { useEffect } from 'react';
import api from '../../common/utils/api';
import withResources from '../../components/withResources/withResources';

const BudgetRulesComp: React.FC = () => {
  const selectedResource = useSelector((state) => state.dynamicSelector.selectedResource);
  const workspace = useSelector((state) => state.workspaces.selectedWorkspace);
  const budgetsWithIds = workspace
    ? workspace?.budgets?.map((budget) => ({
        id: budget.budgetId,
        ...budget,
      }))
    : [];
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onCreateNewBudgetRule = () => {
    navigate(path.createBudgetRule(workspace?.id ?? ''));
  };

  const handleBudgetRuleEdit = (budgetId: string) => {
    navigate(path.editBudgetRule(workspace?.id ?? '', budgetId));
  };

  const handleDeleteBudgetRule = (budgetId: string) => {
    api.del(urls.deleteBudgetRule(workspace?.id ?? '', budgetId)).then(() => {
      selectedResource?.id && dispatch(getWorkspaceById(selectedResource?.id));
      dispatch(createToasterNotification('Successfully deleted the budget rule', ToastType.SUCCESS));
    });
  };

  useEffect(() => {
    selectedResource && dispatch(getWorkspaceById(selectedResource.id));
  }, [selectedResource, dispatch]);

  return (
    <CardContainer>
      <Card>
        <Table
          headCells={headCells}
          data={budgetsWithIds}
          renderCell={renderCell}
          handleEdit={handleBudgetRuleEdit}
          handleDelete={handleDeleteBudgetRule}
          topButton={
            <TableHeaderCtaContainer>
              {workspace && (
                <Button variant="contained" color="primary" onClick={onCreateNewBudgetRule}>
                  <PlusIconContainer>
                    <PlusIcon />
                  </PlusIconContainer>
                  Add Budget rule
                </Button>
              )}
            </TableHeaderCtaContainer>
          }
        />
      </Card>
    </CardContainer>
  );
};

export const BudgetRules = withResources(BudgetRulesComp);
