import * as AuthTypes from '../types';
import { AnyAction } from 'redux';
import { AppState } from '../../../common/models/interfaces';
import { GoogleLoginPayload, IGoogleLoginFailure, IGoogleLoginSuccess } from '../models';
import { GoogleLoginResponse } from 'react-google-login';
import { ThunkDispatch } from 'redux-thunk';
import { createToasterNotification } from '../../../common/utils/toaster-notification';

export const googleLoginSuccess = (payload: GoogleLoginPayload): IGoogleLoginSuccess => ({
  type: AuthTypes.GOOGLE_LOGIN_SUCCESS,
  payload,
});

export const googleLoginFailure = (error: string): IGoogleLoginFailure => ({
  type: AuthTypes.GOOGLE_LOGIN_FAILURE,
  error,
});

export const googleLogin = (loginRes: GoogleLoginResponse) => async (
  dispatch: ThunkDispatch<AppState, unknown, AnyAction>,
) => {
  try {
    const { profileObj, accessToken, tokenId } = loginRes;
    dispatch(refreshTokenSetup(loginRes));
    dispatch(googleLoginSuccess({ accessToken, profile: profileObj, tokenId, tokenObj: loginRes.tokenObj }));
  } catch (e) {
    dispatch(googleLoginFailure(e as any));
    dispatch(createToasterNotification(e));
  }
};

let refreshTokenTimeout: ReturnType<typeof setTimeout> | null = null;

export const refreshTokenSetup = (res: GoogleLoginResponse) => (dispatch: ThunkDispatch<AppState, unknown, AnyAction>) => {
  let refreshTiming = (res.tokenObj.expires_in || 3600 - 5 * 60) * 1000;

  const refreshToken = async () => {
    console.log('Try to refresh token');
    const newAuthRes = await res.reloadAuthResponse();
    const accessToken = newAuthRes.access_token;
    const idToken = newAuthRes.id_token;
    dispatch(googleLoginSuccess({ accessToken, profile: res.profileObj, tokenId: idToken, tokenObj: res.tokenObj }));
    refreshTiming = (newAuthRes.expires_in || 3600 - 5 * 60) * 1000;
    refreshTokenTimeout = setTimeout(refreshToken, refreshTiming);
  };

  if (refreshTokenTimeout != null) {
    clearTimeout(refreshTokenTimeout);
  }
  refreshTokenTimeout = setTimeout(refreshToken, refreshTiming);
};
