import { Card, CardContent, Tab, Tabs } from '@material-ui/core';
import { useSelector } from '../../store/configure-store';
import AlertsTab from '../../components/AlertsTab';
import ModelOverviewTab from '../../components/ModelOverviewTab/ModelOverviewTab';
import ModelPerformanceContext from './ModelPerformanceContext';
import ModelPerformanceTab from '../../components/ModelPerformanceTab';
import ModelPredictionTab from '../../components/ModelPredictionTab/ModelPredictionTab';
import ModelSelector from '../../components/ModelSelector/ModelSelector';
import React, { useState } from 'react';
import withResources from '../../components/withResources/withResources';

const ModelPerformanceComp = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [modelId, setModelId] = useState<string>();
  const domainId = useSelector((state) => state.dynamicSelector.selectedResource?.domainId);

  const tabs = [
    {
      id: 0,
      label: 'Overview',
    },
    {
      id: 1,
      label: 'Prediction Statistics',
    },
    {
      id: 2,
      label: 'Model Performance',
    },
    {
      id: 3,
      label: 'Alerts',
    },
  ];

  return (
    <Card>
      <CardContent>
        <ModelSelector
          onChangeModel={(selectedModelId) => {
            setModelId(selectedModelId);
          }}
        />
        <Tabs
          style={{ marginTop: '1.5em', borderBottom: '1px solid #E1E4E4' }}
          value={tabIndex}
          indicatorColor="primary"
          onChange={(_, nextIndex) => {
            setTabIndex(nextIndex);
          }}
        >
          {tabs.map((tab) => (
            <Tab label={tab.label} key={tab.id} />
          ))}
        </Tabs>
        <ModelPerformanceContext.Provider
          value={{
            domainId,
            modelId,
          }}
        >
          {tabIndex === 0 && <ModelOverviewTab />}
          {tabIndex === 1 && <ModelPredictionTab />}
          {tabIndex === 2 && <ModelPerformanceTab />}
          {tabIndex === 3 && <AlertsTab />}
        </ModelPerformanceContext.Provider>
      </CardContent>
    </Card>
  );
};

export default withResources(ModelPerformanceComp);
