import styled from 'styled-components';

const IntanceData = styled.div`
  display: grid;
  grid-template-columns: 120px auto;
  & + & {
    margin-top: 6px;
  }
  font-size: 0.8rem;
`;

export { IntanceData };
