import * as S from './styles';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { BlueprintVersion as TBlueprintVersion } from '../../common/models/interfaces';
import { TextValidator } from 'react-material-ui-form-validator';
import DateFnsUtils from '@date-io/date-fns';
import React from 'react';

function BlueprintVersion({
  version,
  index,
  terraformVersions,
  setter,
  remove,
}: {
  version: TBlueprintVersion;
  index: number;
  terraformVersions: string[];
  setter: (paramName: string, value: string | MaterialUiPickersDate, index: number) => void;
  remove?: (versionId?: string | number, isExist?: boolean) => void;
}) {
  const onChangeValueByField = (paramName: keyof TBlueprintVersion, value: string | MaterialUiPickersDate) => {
    setter(paramName, value, index);
  };

  return (
    <S.BlueprintVersion>
      <S.DeleteButton onClick={() => remove?.(version.id || index, !!version.id)}>
        <S.DeleteIcon fontSize="small" />
      </S.DeleteButton>
      <TextValidator
        validators={['required']}
        errorMessages={['This field is required']}
        size="small"
        label="Branch"
        variant="outlined"
        value={version.branch}
        name="branch"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          onChangeValueByField('branch', e.target.value as string);
        }}
      />
      <TextValidator
        validators={['required']}
        errorMessages={['This field is required']}
        size="small"
        label="Version"
        variant="outlined"
        value={version.version}
        name="version"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          onChangeValueByField('version', e.target.value as string);
        }}
      />
      <FormControl size="small" variant="outlined">
        <InputLabel shrink id="terraform-version">
          Terraform version
        </InputLabel>
        <Select
          label="Terraform version"
          labelId="terraform-version"
          value={version.terraformVersion ? version.terraformVersion : terraformVersions.at(-1)}
          variant="outlined"
          onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
            onChangeValueByField('terraformVersion', e.target.value as string);
          }}
        >
          {terraformVersions.map((currentVersion) => (
            <MenuItem key={currentVersion} value={currentVersion}>
              {currentVersion}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <S.ReleaseDateWrapper>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disableToolbar
            style={{ width: '100%' }}
            format="dd/MM/yyyy"
            margin="normal"
            initialFocusedDate={version.releaseDate}
            size="small"
            variant="dialog"
            inputVariant="outlined"
            id="date-picker-inline"
            label="Release date"
            value={version.releaseDate}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            onChange={(date) => {
              onChangeValueByField('releaseDate', date);
            }}
          />
        </MuiPickersUtilsProvider>
      </S.ReleaseDateWrapper>
    </S.BlueprintVersion>
  );
}

export default BlueprintVersion;
