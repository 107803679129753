import { ToastConfig } from '../../../store/toaster/models/toastr.models';
import { ToastContainer } from './toast.styles';
import { removeToasterNotificationByIndex } from '../../../store/toaster/reducers/toaster.reducers';
import { useDispatch } from 'react-redux';
import React, { useCallback, useEffect, useState } from 'react';

export interface ToasterProps extends ToastConfig {
  deleteToasterId: string;
}

export const Toast: React.FC<ToasterProps> = (props) => {
  const [isVisible, setIsVisible] = useState(false);
  const dispatch = useDispatch();

  const handleToastDelete = useCallback(() => {
    setIsVisible(false);
    setTimeout(() => {
      dispatch(removeToasterNotificationByIndex(props.id));
      // same as the transition duration of the toast
    }, 500);
  }, [dispatch, props.id]);

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, 100);
  }, []);

  useEffect(() => {
    if (props.deleteToasterId === props.id) {
      handleToastDelete();
    }
  }, [props.deleteToasterId, props.id, handleToastDelete]);

  return (
    <ToastContainer isVisible={isVisible} type={props.type} onClick={handleToastDelete}>
      {props.message}
    </ToastContainer>
  );
};
