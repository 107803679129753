import { BaseDataItem, OrderType } from './interfaces';

export const descendingComparator = <DataItem extends BaseDataItem>(a: DataItem, b: DataItem, orderBy: string) => {
  const propertyA = a[orderBy] as string;
  const propertyB = b[orderBy] as string;
  if (propertyB < propertyA) {
    return -1;
  }
  if (propertyB > propertyA) {
    return 1;
  }
  return 0;
};

export const getComparator = <DataItem extends BaseDataItem>(order: OrderType, orderBy: string) => {
  return order === 'desc'
    ? (a: DataItem, b: DataItem) => descendingComparator(a, b, orderBy)
    : (a: DataItem, b: DataItem) => -descendingComparator(a, b, orderBy);
};

export const stableSort = <DataItem extends BaseDataItem>(
  array: DataItem[],
  comparator: (a: DataItem, b: DataItem) => number,
) => {
  const stabilizedThis = array.map((el, index) => ({ item: el, index }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.item, b.item);
    if (order !== 0) {
      return order;
    }
    return a.index - b.index;
  });
  return stabilizedThis.map((el) => el.item);
};
