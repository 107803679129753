import {
  Button,
  DialogActions,
  DialogClassKey,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  Dialog as MuiDialog,
} from '@material-ui/core';
import React, { ReactNode } from 'react';

export interface IDialogProps {
  title: string;
  open?: boolean;
  subtitle?: string;
  children?: ReactNode;
  primaryButtonText?: string;
  onCancel?: () => void;
  onPrimaryAction?: () => void;
  classes?: Partial<Record<DialogClassKey, string>>;
  disabled?: () => boolean;
  loading?: boolean;
}

const Dialog = ({
  title,
  open,
  subtitle,
  children,
  primaryButtonText,
  onCancel,
  onPrimaryAction,
  classes,
  disabled,
  loading,
}: IDialogProps) => {
  return (
    <>
      <MuiDialog classes={classes} maxWidth="lg" open={!!open}>
        {loading && <LinearProgress />}
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{subtitle}</DialogContentText>
          {!loading && <div>{children}</div>}
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={onPrimaryAction} color="primary" disabled={disabled ? disabled() : false}>
            {primaryButtonText || 'Save'}
          </Button>
        </DialogActions>
      </MuiDialog>
    </>
  );
};

export default Dialog;
