import { AlertIncident, AlertIncidentWithPolicy } from '../../common/models/interfaces';
import { urls } from '../../common/utils/urls';
import api from '../../common/utils/api';

const getIncidents = async (
  domainId: string,
  modelId: string,
  cb?: (incidents: AlertIncident[]) => void,
  before?: () => void,
) => {
  try {
    before?.();
    const incidents = await api.get<AlertIncidentWithPolicy[]>(urls.getAlertIncidents(domainId, modelId));

    const flatIncidents = incidents.map((incident) => ({
      ...incident,
      metricType: incident.mpmAlertPolicy.metricType,
      policyName: incident.mpmAlertPolicy.policyName,
    }));

    cb?.(flatIncidents);
  } catch (e) {
    console.log(e);
  }
};

export { getIncidents };
