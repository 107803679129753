import { AlertPolicyConditionWindows, AlertPolicyConditions, AlertPolicyMetrics } from '../../common/models/enums';

const resolveConditionLabel = (condition: string) => {
  switch (condition) {
    case AlertPolicyConditions.EQUAL:
      return 'Equal';
    case AlertPolicyConditions.GREATER_THAN_OR_EQUAL:
      return 'Greater than or Equal';
    case AlertPolicyConditions.GREATER_THAN:
      return 'Greater';
    case AlertPolicyConditions.LESS_THAN_OR_EQUAL:
      return 'Less than or Equal ';
    case AlertPolicyConditions.LESS_THAN:
      return 'Less than';
    case AlertPolicyConditions.NOT_EQUAL:
      return 'Not Equal';
    case AlertPolicyConditions.INCREASED_BY:
      return 'Increased by';
    case AlertPolicyConditions.DECREASED_BY:
      return 'Decreased by';
    case AlertPolicyConditions.CHANGED_BY:
      return 'Changed by';
    default:
      return condition;
  }
};

const resolveMetricTypeLable = (metricType: string) => {
  switch (metricType) {
    case AlertPolicyMetrics.KS_STATISTICS:
      return 'Kolmogorov-Smirnov Statistics';
    case AlertPolicyMetrics.PR_CURVE_AP:
      return 'Precision-recall Curve (AP)';
    case AlertPolicyMetrics.ROC_CURVE_AUC:
      return 'Roc Curve (AUC)';
    default:
      return metricType;
  }
};

const resolveConditionWindowLable = (conditionWindow: string) => {
  switch (conditionWindow) {
    case AlertPolicyConditionWindows.COUNT:
      return 'Count';
    case AlertPolicyConditionWindows.DAY:
      return 'Day';
    case AlertPolicyConditionWindows.HOUR:
      return 'Hour';
    default:
      return conditionWindow;
  }
};

export { resolveConditionLabel, resolveMetricTypeLable, resolveConditionWindowLable };
