import { urls } from '../../common/utils/urls';
import api from '../../common/utils/api';

interface TGetPlotDatesOptions {
  cb?: (dateList: string[]) => void;
  beforeActions?: () => void;
}

async function getPlotDates(domainId: string, modelId: string, plotType: string, options?: TGetPlotDatesOptions) {
  try {
    options?.beforeActions?.();
    const { dates } = await api.get<{ dates: string[] }>(urls.getPlotDates(domainId, modelId, plotType));
    options?.cb?.(dates);
  } catch (e) {
    console.error(e);
  }
}

export { getPlotDates };
