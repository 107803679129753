interface EnvironmentVariable {
  apiUrl: string;
  googleClientId: string;
  googleCaptchaKey: string;
}

export const environmentVariable: EnvironmentVariable = {
  apiUrl: process.env.REACT_APP_API_URL || '',
  googleClientId: process.env.REACT_APP_GOOGLE_CLIENT_ID || '',
  googleCaptchaKey: process.env.REACT_APP_CAPTCHA || '',
};
