import { createContext } from 'react';

interface TGeneralModalContext {
  title?: string;
  modalIsOpen: boolean;
  setModalIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit: (event: React.FormEvent) => void;
  onCloseCb?: () => void;
  isForm?: boolean;
  primaryText?: string;
  secondaryText?: string;
}

export const initialContextData: TGeneralModalContext = {
  modalIsOpen: false,
  onSubmit: () => {
    return;
  },
  primaryText: 'Save',
  secondaryText: 'Cancel',
};

const GeneralModalContext = createContext(initialContextData);

export default GeneralModalContext;
