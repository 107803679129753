import { Row } from '../../common/styles/common.styles';
import { UserStatus } from '../../store/users/models';
import styled from 'styled-components';

export const CardContainer = styled(Row)`
  & .card {
    box-shadow: 0 2.1px 3px rgb(0 0 0 / 10%), 0px 3px 13px -8px rgb(0 0 0 / 22%);
    width: 100%;
    & .card-content {
      display: flex;
      flex: 1;
      & .icon-container {
      }
    }
  }
`;

export const Page = styled.div`
  background-color: white;
  padding-top: 18px;
`;

export const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 18px;
  & :not(:last-child) {
    margin-right: 8px;
  }
`;

export const StatusText = styled.span<{ status: UserStatus }>`
  color: ${(p) => {
    switch (p.status) {
      case UserStatus.ACTIVE:
        return p.theme.colors.success;
      case UserStatus.INACTIVE:
        return p.theme.colors.warning;
      case UserStatus.DELETED:
        return p.theme.colors.danger;
    }
  }};
`;

export const ActionRow = styled.div`
  display: flex;
  margin: 20px;
  justify-content: space-between;
`;

export const ResourceTypeDropdown = styled.div`
  max-width: 300px;
  width: 100%;
`;
