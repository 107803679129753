import { urls } from '../../common/utils/urls';
import api from '../../common/utils/api';

interface TDeleteBlueprintOptions {
  cb?: () => void;
}

async function deleteBlueprint(blueprintId: string, options?: TDeleteBlueprintOptions) {
  try {
    await api.del(urls.getTemplate(blueprintId));
    options?.cb?.();
  } catch (e) {
    console.error(e);
  }
}

export { deleteBlueprint };
