import { DataContainer } from './summary-reports.style';
import { Row, TransparentText } from '../../../common/styles/common.styles';
import React from 'react';

interface SummaryReportProps {
  totalBudget: number | null;
  actualSpending: number | null;
  spendingRatio: number | null;
}

export const SummaryReport: React.FC<SummaryReportProps> = (props) => {
  return (
    <Row>
      <DataContainer>
        <TransparentText marginBottom={20}>Total Budget</TransparentText>
        <TransparentText>{typeof props.totalBudget === 'number' ? `$${props.totalBudget}` : 'N/A'}</TransparentText>
      </DataContainer>
      <DataContainer>
        <TransparentText marginBottom={20}>Actual Spending</TransparentText>
        <TransparentText>
          {typeof props.actualSpending === 'number' ? `$${props.actualSpending}` : 'N/A'}
        </TransparentText>
      </DataContainer>
      <DataContainer>
        <TransparentText marginBottom={20}>Spending ratio</TransparentText>
        <TransparentText>{typeof props.spendingRatio === 'number' ? `${props.spendingRatio}%` : 'N/A'}</TransparentText>
      </DataContainer>
    </Row>
  );
};
