import * as S from './styles';
import { NoModelIcon } from '../../assets';
import React from 'react';

const NoModelSelected = () => {
  return (
    <S.Wrapper>
      <S.ContentWrapper>
        <NoModelIcon />
        <S.NoModelText>There is no selected model to display</S.NoModelText>
      </S.ContentWrapper>
    </S.Wrapper>
  );
};

export default NoModelSelected;
