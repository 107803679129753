import { appStore, persistor } from '../../store/configure-store';
import { getErrorMessage } from './response-message';
import { logout } from '../../services';
import { path } from '../../routes/path';
import axios, { AxiosRequestConfig } from 'axios';

export interface ErrorItem {
  message: string;
  errorCode?: string;
  field?: string;
  invalidValue?: string;
  requestId?: string;
  timestamp?: string;
}

export class ValidationError extends Error {
  public error: { errors: ErrorItem[] };

  constructor(m: { errors: ErrorItem[] }) {
    super('validation error');
    this.error = m;
    Object.setPrototypeOf(this, ValidationError.prototype);
  }
}

axios.interceptors.request.use(
  (config) => {
    const { auth: authState } = appStore.getState();
    const token: string = authState.data?.tokenId || '';

    if (config && config.headers) config.headers.Authorization = `Bearer ${token}`;

    return config;
  },
  (error) => {
    handleUnauthorized(error);
    return Promise.reject(error);
  },
);

async function get<T>(url: string, params?: AxiosRequestConfig | undefined): Promise<T> {
  try {
    const { data } = await axios.get<T>(url, params);
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error?.response?.status === 412) {
        throw new Error('Managed service is not initialized yet, please try it a few minutes later.');
      }
    }
    if (handleUnauthorized(error)) {
      throw new Error(getErrorMessage(error));
    } else {
      return Promise.reject();
    }
  }
}

async function post<T>(url: string, body?: any, headers?: any): Promise<T> {
  try {
    const { data } = await axios.post<T>(url, body, { headers });

    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error?.response?.status === 429) {
        throw new Error('Quota exceeded! Please contact support if you want to raise the limit!');
      }
    }
    if (axios.isAxiosError(error) && error.response && error.response.data) {
      throw new ValidationError(error.response.data as { errors: ErrorItem[] });
    }
    if (handleUnauthorized(error)) {
      throw new Error(getErrorMessage(error));
    } else {
      return Promise.reject();
    }
  }
}

async function put<T>(url: string, body?: any): Promise<T> {
  try {
    const { data } = await axios.put<T>(url, body);

    return data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response && error.response.data) {
      throw new ValidationError(error.response.data as { errors: ErrorItem[] });
    }
    if (handleUnauthorized(error)) {
      throw new Error(getErrorMessage(error));
    } else {
      return Promise.reject();
    }
  }
}

async function del<T>(url: string, params?: AxiosRequestConfig | undefined): Promise<T> {
  try {
    const { data } = await axios.delete<T>(url, params);

    return data;
  } catch (error) {
    if (handleUnauthorized(error)) {
      throw new Error(getErrorMessage(error));
    } else {
      return Promise.reject();
    }
  }
}

export default { get, post, put, del };

// tslint:disable-next-line: no-any
export const handleUnauthorized = (error: any): boolean => {
  if (error.response && error.response.status && error.response.status === 401) {
    const { auth: authState } = appStore.getState();
    const token: string = authState.data?.tokenId || '';
    token && logout(token);
    persistor.purge();
    document.location.href = '/';
  } else if (error.response && error.response.status && error.response.status === 403) {
    window.location.replace(path.accessDenied());
    return false;
  }
  return true;
};
