import thumb1 from '../../assets/thumb/thumb1.png';
import thumb10 from '../../assets/thumb/thumb10.png';
import thumb2 from '../../assets/thumb/thumb2.png';
import thumb3 from '../../assets/thumb/thumb3.png';
import thumb4 from '../../assets/thumb/thumb4.png';
import thumb5 from '../../assets/thumb/thumb5.png';
import thumb6 from '../../assets/thumb/thumb6.png';
import thumb7 from '../../assets/thumb/thumb7.png';
import thumb8 from '../../assets/thumb/thumb8.png';
import thumb9 from '../../assets/thumb/thumb9.png';
import thumbCustomer from '../../assets/thumb/thumb_customer1.png';

const thumbMap = {};
thumbMap['thumb1.png'] = thumb1;
thumbMap['thumb2.png'] = thumb2;
thumbMap['thumb3.png'] = thumb3;
thumbMap['thumb4.png'] = thumb4;
thumbMap['thumb5.png'] = thumb5;
thumbMap['thumb6.png'] = thumb6;
thumbMap['thumb7.png'] = thumb7;
thumbMap['thumb8.png'] = thumb8;
thumbMap['thumb9.png'] = thumb9;
thumbMap['thumb10.png'] = thumb10;
thumbMap['thumb_customer1.png'] = thumbCustomer;

function blueprintThumbnails(imageName: string | undefined) {
  if (!imageName) return null;

  if (thumbMap[imageName]) {
    return thumbMap[imageName];
  }
  return null;
}

export default blueprintThumbnails;
