import {
  CookieCutterContent,
  CreateManagedServiceDto,
  GenerateRepository,
  TemplateVariablesItem,
} from '../../common/models/workspace.models';
import { CookieCutterVariables } from '../../components/cookie-cutter-variables/cookie-cutter-variables';
import { Dialog } from '../../containers';
import { IDialogProps } from '../../containers/Dialog';
import { LoadingSpinner } from '../../components/loading-spinner/loading-spinner';
import {
  LoadingSpinnerContainerWbg,
  ManagedServiceVariablesContainer,
  ManagedServiceVersionSelectorContainer,
} from './managed-service-dialog.styles';
import { ManagedServiceTypes } from '../../common/models/enums';
import { createToasterNotification } from '../../common/utils/toaster-notification';
import { urls } from '../../common/utils/urls';
import { useDialogStyles } from '../../components/UserDetailsDialog/styles';
import { useDispatch } from 'react-redux';
import { useSelector } from '../../store/configure-store';
import ManagedServiceVersionSelector from '../ManagedServiceVersionSelector';
import React, { useCallback, useEffect, useState } from 'react';
import api, { ValidationError } from '../../common/utils/api';

type DialogPropNames = 'open' | 'title' | 'subtitle' | 'onCancel';

interface IProps extends Pick<IDialogProps, DialogPropNames> {
  onSave: (createManagedServiceDto: CreateManagedServiceDto) => Promise<unknown>;
  serviceType: ManagedServiceTypes;
  setter: React.Dispatch<React.SetStateAction<string>>;
}

export const ManagedServiceDialog = ({ onSave, setter, serviceType, ...dialogProps }: IProps) => {
  const classes = useDialogStyles();
  const selectedResource = useSelector((state) => state.dynamicSelector.selectedResource);
  const domainId = selectedResource?.domainId || '';
  const [initialRepoData, setInitialRepoData] = useState<GenerateRepository>({
    newRepository: false,
    cookieCutterContent: {},
  });
  const [generateRepository, setGenerateRepository] = useState<GenerateRepository | null>();
  const [templateVariables, setTemplateVariables] = useState({
    items: [] as TemplateVariablesItem[],
    originalContent: '',
  });
  const [showProgress, setShowProgress] = useState(false);
  const [variablesValidationError, setVariablesValidationError] = useState<ValidationError | null>(null);
  const dispatch = useDispatch();
  const [selectedVersionId, setSelectedVersionId] = useState<string>();

  const handleGetDataUrl = useCallback(() => {
    console.log('??:', selectedVersionId);
    switch (serviceType) {
      case ManagedServiceTypes.MLFLOW:
        return urls.getMlflowVariables(domainId, selectedVersionId || '');
      case ManagedServiceTypes.KUBEFLOW:
        return urls.getKubeflowVariables(domainId, selectedVersionId || '');
      default:
        return '';
    }
  }, [selectedVersionId, serviceType, domainId]);

  const handleSave = () => {
    if (generateRepository) {
      setShowProgress(true);
      setVariablesValidationError(null);
      onSave({
        cookieCutterContent: generateRepository.cookieCutterContent,
      })
        .then(() => {
          setShowProgress(false);
        })
        .catch((e: ValidationError | unknown) => {
          setShowProgress(false);
          if (e instanceof ValidationError && e.error.errors) {
            setVariablesValidationError(e);
          } else {
            dispatch(createToasterNotification(e));
          }
        });
    }
  };

  const fetchManagedServiceTemplateVariables = async () => {
    try {
      const variables = await api.get<CookieCutterContent>(handleGetDataUrl());
      if (variables) {
        setTemplateVariables(variables);
      }
    } catch (e) {
      console.log('managed-service-dialog-->fetchManagedServiceTemplateVariables', e);
      dispatch(createToasterNotification(e));
    }
  };

  useEffect(() => {
    if (templateVariables) {
      const templateVar = {};
      templateVariables.items.forEach((value) => {
        templateVar[value.key] = value.value;
      });

      setInitialRepoData((oldRepoData) => ({
        ...oldRepoData,
        cookieCutterContent: templateVar,
      }));
    }
  }, [templateVariables]);

  const handleFetchManagedServiceTemplateVariables = useCallback(fetchManagedServiceTemplateVariables, [
    dispatch,
    handleGetDataUrl,
  ]);

  useEffect(() => {
    if (selectedVersionId) {
      handleFetchManagedServiceTemplateVariables();
    }
  }, [handleFetchManagedServiceTemplateVariables, selectedVersionId]);

  useEffect(() => {
    if (selectedVersionId) {
      setter(selectedVersionId);
    }
  }, [selectedVersionId, setter]);

  return (
    <>
      <Dialog
        {...dialogProps}
        disabled={() => !generateRepository}
        onPrimaryAction={() => handleSave()}
        classes={{ paper: classes.dialog }}
      >
        {showProgress && (
          <LoadingSpinnerContainerWbg style={{ top: 0, left: 0 }}>
            <LoadingSpinner />
          </LoadingSpinnerContainerWbg>
        )}
        <ManagedServiceVersionSelectorContainer>
          <ManagedServiceVersionSelector
            setter={setSelectedVersionId}
            formControllStyle={{ width: '198px' }}
            serviceType={serviceType}
          />
        </ManagedServiceVersionSelectorContainer>
        <ManagedServiceVariablesContainer>
          <CookieCutterVariables
            validationError={variablesValidationError}
            hideCheckbox={true}
            hideRepositoryName={true}
            templateVariables={templateVariables.items}
            data={initialRepoData}
            onDataChanged={(data) => {
              setGenerateRepository(data);
            }}
          />
        </ManagedServiceVariablesContainer>
      </Dialog>
    </>
  );
};
