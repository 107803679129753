import { makeStyles } from '@material-ui/core';
import styled, { css } from 'styled-components';

export const Container = styled.div<{ border?: boolean }>`
  ${(p) =>
    p.border &&
    css`
      border-bottom: 1px solid rgba(5, 33, 32, 0.12);
    `};

  width: 356px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 20px;
`;

export const UserName = styled.div`
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.0015em;
  color: ${(p) => p.theme.colors.primary};
  margin-top: 24px;
`;

export const UserEmail = styled.div`
  font-family: Didact, Gothic, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: rgba(5, 33, 32, 0.6);
`;

export const UserAvatar = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 50%;
`;

export const useUserAccountMenuStyles = makeStyles({
  menu: {
    marginTop: 46,
    marginLeft: -10,
  },
});
