import { Build, ProvisionDetails, Step } from '../../../../common/models/workspace.models';
import { LinearProgress } from '@material-ui/core';
import { LogContainer, LogRow, OutputLink, OutputLinkIcon, OutputRow } from './provisioning-details.styles';
import { createToasterNotification } from '../../../../common/utils/toaster-notification';
import { urls } from '../../../../common/utils/urls';
import { useDispatch } from 'react-redux';
import { useQuery } from 'react-query';
import Convert from 'ansi-to-html';
import React, { useState } from 'react';
import api from '../../../../common/utils/api';
const convert = new Convert();

interface ProvisioningDetailsProps {
  provisioningId: string;
  workspaceId: string;
  showLogs: boolean;
}

export const ProvisioningDetails: React.FC<ProvisioningDetailsProps> = (props) => {
  const dispatch = useDispatch();

  const [workspaceId] = useState<string>(props.workspaceId);

  const logsQuery = useQuery(`provisioningLogs_${props.provisioningId}`, () => {
    return api
      .get<ProvisionDetails>(urls.provisionLogs(workspaceId, props.provisioningId))
      .then((response: ProvisionDetails) => response)
      .catch((e) => {
        dispatch(createToasterNotification(e));
      });
  });

  const getLogData = (): string[] => {
    const data: ProvisionDetails = logsQuery.data as ProvisionDetails;
    const logs: string[] = [];
    data.builds.flatMap((value: Build) => value.steps).forEach((value: Step) => logs.push(...value.logs));
    return logs;
  };

  const resolveEntry = (label: string, entry: string) => {
    if (entry.startsWith('http://') || entry.startsWith('https://')) {
      return (
        <OutputLink href={entry} target="_blank">
          {entry}
          <OutputLinkIcon style={{ fontSize: '1rem' }} />
        </OutputLink>
      );
    } else if (entry.startsWith('bq://')) {
      const paramteres = entry.substr(5).split('.');

      return (
        <OutputLink
          href={`https://console.cloud.google.com/bigquery?referrer=search&project=${paramteres[0]}&d=${paramteres[1]}&p=${paramteres[0]}&t=${paramteres[2]}&page=table`}
          target="_blank"
          rel="noopener"
        >
          {entry}
          <OutputLinkIcon style={{ fontSize: '1rem' }} />
        </OutputLink>
      );
    } else {
      return entry;
    }
  };

  return (
    <>
      {logsQuery.isFetching && <LinearProgress />}

      {props.showLogs && (
        <LogContainer>
          {/* {props.showLogs && (
            <LogRow>{logsQuery.isSuccess ? getLogData().map((log, index) => <p key={index}>{log}</p>) : ''}</LogRow>
          )} */}
          {props.showLogs && (
            <LogRow>
              {logsQuery.isSuccess
                ? getLogData().map((log, index) => {
                    return (
                      <p
                        key={index}
                        dangerouslySetInnerHTML={{
                          __html: convert.toHtml(log.replaceAll(' ', '&nbsp;')),
                        }}
                      ></p>
                    );
                  })
                : ''}
            </LogRow>
          )}
        </LogContainer>
      )}

      {!props.showLogs && (
        <>
          {!props.showLogs && logsQuery.data && (
            <div style={{ borderTop: '1px solid #e0e0e0', padding: '1em', height: '200px', overflowY: 'scroll' }}>
              {logsQuery.isSuccess
                ? Object.entries(logsQuery.data.outputs)
                    .reverse()
                    .map((entry, index) => {
                      return (
                        <OutputRow key={index}>
                          <span style={{ color: '#02394D', fontWeight: 'bold', textAlign: 'right', flexShrink: 0 }}>
                            {entry[0]}:
                          </span>{' '}
                          {resolveEntry(entry[0], entry[1])}
                        </OutputRow>
                      );
                    })
                : ''}
            </div>
          )}
        </>
      )}
    </>
  );
};
