import { TourTypes } from '../Tour';
import { urls } from '../../common/utils/urls';
import api from '../../common/utils/api';

export interface TourItem {
  id: string;
  finished: boolean;
  label: string;
}

function resolveLabel(tourId: string) {
  switch (tourId) {
    case 'BLUEPRINT_WIZARD_DASHBOARD':
      return 'Dashboard Tour';
    case 'BLUEPRINT_WIZARD_SELECT_BLUEPRINT':
      return 'Blueprint Wizard / Select Blueprint';
    case 'BLUEPRINT_WIZARD_ENV_VARS':
      return 'Blueprint Wizard / Environment Variables';
    case 'BLUEPRINT_WIZARD_PROJECT':
      return 'Blueprint Wizard / Project';
    case 'BLUEPRINT_WIZARD_WORKSPACE':
      return 'Blueprint Wizard / Workspace';
    case 'DEPLOY_BLUEPRINTS':
      return 'Deploy Blueprints';
    case 'PROVISIONING_INIT':
      return 'Enable Provisioning';
    case 'PROVISIONING_LIST':
      return 'Provisioning description';
    default:
      return `${tourId}`;
  }
}

async function getTours(setter: React.Dispatch<React.SetStateAction<TourItem[] | undefined>>) {
  try {
    const tours = await api.get<{ showUserTour: { [Property in keyof TourTypes]: boolean } }>(urls.userTour());

    if (tours) {
      const tourKeys = Object.keys(tours.showUserTour);

      const tourArray: TourItem[] = tourKeys.map((tour) => {
        return {
          id: tour,
          finished: tours.showUserTour[tour],
          label: resolveLabel(tour),
        };
      });

      if (typeof setter === 'function') {
        setter(tourArray);
      }
    }
  } catch (e) {
    console.log('TourSettings-->requests-->getTours', e);
  }
}

async function saveTour(
  tour: string,
  finished: boolean,
  cb: React.Dispatch<React.SetStateAction<TourItem[] | undefined>>,
  setLoader?: React.Dispatch<React.SetStateAction<string[]>>,
) {
  setLoader && setLoader((prevLoaders) => [...prevLoaders, tour]);
  const showUserTour = {};
  showUserTour[tour] = !finished;
  await api.post(urls.userTour(), {
    showUserTour,
  });
  setLoader && setLoader((prevLoaders) => prevLoaders.filter((loader) => loader !== tour));
  getTours(cb);
}

export { getTours, saveTour };
