import { Row } from '../../common/styles/common.styles';
import styled from 'styled-components';

export const InformationContainer = styled.div`
  padding: 20px;
`;

export const InputContainer = styled.div`
  margin: 30px 0;

  &:first-of-type {
    margin-top: 0;
  }
`;

export const Label = styled.div`
  padding-bottom: 20px;
  font-size: 16px;
  line-height: 24px;
  color: ${(p) => p.theme.colors.transparentDarkGreen};
`;

export const SaveChangesContainer = styled(Row)`
  justify-content: flex-end;
  padding: 15px;
`;

export const MlflowVariablesContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
`;

export const ActionRow = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;
