export type ResourceType =
  | ResourceTypes.WORKSPACE
  | ResourceTypes.DOMAIN
  | ResourceTypes.FOLDER
  | ResourceTypes.BLUEPRINT;

export enum ResourceTypes {
  'WORKSPACE' = 'WORKSPACE',
  'DOMAIN' = 'DOMAIN',
  'FOLDER' = 'FOLDER',
  'BLUEPRINT' = 'BLUEPRINT',
}

export interface Resource {
  id: string;
  name: string;
  children?: Resource[];
  type: ResourceType;
  domainId?: string;
}
