export enum ApiRequestMetaType {
  LOAD_META = 'loadMeta',
  SAVE_META = 'saveMeta',
  DELETE_META = 'deleteMeta',
}

export enum EnvironmentType {
  EMPTY = '',
  DEVELOP = 'DEVELOP',
  TEST = 'TEST',
  STATING = 'STAGING',
  UAT = 'UAT',
  PRODUCTION = 'PRODUCTION',
}

export enum NotificationType {
  EMPTY = '',
  EMAIL = 'EMAIL',
  WEBHOOK = 'WEBHOOK',
  HARD_LIMIT = 'HARD_LIMIT',
}

export enum PeriodType {
  MONTHLY = 'MONTHLY',
}

export enum ToastType {
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
  DANGER = 'DANGER',
}

export enum ToasterMessage {
  UPDATED_WORKSPACE = 'You have successfully updated the workspace.',
  CREATED_NEW_WORKSPACE = 'You have successfully created a new workspace.',
  ADDED_NEW_USERS_TO_WORKSPACE = 'You have successfully added new users to the workspace.',
  DUPLICATED_PROJECT = 'You have already added that project to your workspace.',
  DELETED_PROJECT = 'You have successfully deleted the project from your workspace.',
}

export enum DropdownValue {
  SHOW_ALL = 'Show All',
}

export enum ResourceType {
  WORKSPACE = 'WORKSPACE',
  DOMAIN = 'DOMAIN',
  BLUEPRINT = 'BLUEPRINT',
}

export enum PrincipalType {
  EMAIL = 'EMAIL',
  DOMAIN = 'DOMAIN',
  SERVICE_ACCOUNT = 'SERVICE_ACCOUNT',
  GROUP = 'GROUP',
}

export enum ManagedServiceType {
  MLFLOW = 'MLFLOW',
  KUBEFLOW = 'KUBEFLOW',
}

export enum ManagedServiceState {
  DEPLOY_PENDING = 'DEPLOY_PENDING',
  DEPLOY_SUCCESS = 'DEPLOY_SUCCESS',
  DEPLOY_ERROR = 'DEPLOY_ERROR',
  DESTROY_PENDING = 'DESTROY_PENDING',
  DESTROY_ERROR = 'DESTROY_ERROR',
}

export enum ServingModelInfrastructure {
  AIP = 'AIP',
  VERTEX_AI = 'VERTEX_AI',
}

export enum ServingModelStatus {
  PENDING = 'PENDING',
  READY = 'READY',
  FAILED = 'FAILED',
}

export enum BlueprintCreationType {
  EMPTY_BLUEPRINT = 'EMPTY_BLUEPRINT',
  BASE_BLUEPRINT = 'BASE_BLUEPRINT',
  CLONE_BLUEPRINT = 'CLONE_BLUEPRINT',
}

export enum ManagedServiceTypes {
  KUBEFLOW = 'kubeflow',
  MLFLOW = 'mlflow',
}

export enum AlertPolicyComparsions {
  COMPARISON_EQ = 'COMPARISON_EQ',
  COMPARISON_GE = 'COMPARISON_GE',
  COMPARISON_GT = 'COMPARISON_GT',
  COMPARISON_LE = 'COMPARISON_LE',
  COMPARISON_LT = 'COMPARISON_LT',
  COMPARISON_NE = 'COMPARISON_NE',
}

export enum AlertPolicyMetrics {
  ROC_CURVE_AUC = 'ROC_CURVE_AUC',
  KS_STATISTICS = 'KS_STATISTICS',
  PR_CURVE_AP = 'PR_CURVE_AP',
}

export enum AlertPolicyConditions {
  GREATER_THAN = 'GREATER_THAN',
  GREATER_THAN_OR_EQUAL = 'GREATER_THAN_OR_EQUAL',
  LESS_THAN = 'LESS_THAN',
  LESS_THAN_OR_EQUAL = 'LESS_THAN_OR_EQUAL',
  EQUAL = 'EQUAL',
  NOT_EQUAL = 'NOT_EQUAL',
  INCREASED_BY = 'INCREASED_BY',
  DECREASED_BY = 'DECREASED_BY',
  CHANGED_BY = 'CHANGED_BY',
}

export enum AlertPolicyConditionWindows {
  COUNT = 'COUNT',
  HOUR = 'HOUR',
  DAY = 'DAY',
}

export enum AlertIncidentStatuses {
  ACTIVE = 'ACTIVE',
  RESOLVED = 'RESOLVED',
}

export enum AlertIncidentSeverites {
  HIGH = 'HIGH',
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
}

export enum ModelPlotTypes {
  ROC_CURVE = 'ROC_CURVE',
  LIFT_PLOT = 'LIFT_PLOT',
  KOLMOGOROV_SMIRNOV_PLOT = 'KOLMOGOROV_SMIRNOV_PLOT',
  PRECISION_RECALL_CURVE = 'PRECISION_RECALL_CURVE',
  LIFT_TABLE = 'LIFT_TABLE',
}
