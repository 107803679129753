import { Column } from '../../common/styles/common.styles';
import styled from 'styled-components';

export const ToasterContainer = styled(Column)`
  position: fixed;
  top: 10px;
  right: 0;
  z-index: 2000;
  width: 350px;
`;
