import { ThemeProvider as MuiThemeProvider, createTheme } from '@material-ui/core';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import React, { ReactNode } from 'react';
import theme from '../../theme';

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    white: string;
  }

  interface PaletteOptions {
    white: string;
  }
}

// TODO: refactor to modules
const muiTheme = createTheme({
  palette: {
    black: theme.colors.black,
    white: '#ffffff',
    primary: { main: theme.colors.primary },
  },
  typography: {
    fontFamily: 'Lato, sans-serif',
  },
  overrides: {
    MuiButton: {
      text: { color: theme.colors.primary },
    },
    MuiTableRow: {
      root: {
        '&$selected': {
          backgroundColor: 'rgba(0, 0, 0, 0.04);',
        },
      },
    },
    MuiDialog: {
      paper: {
        borderRadius: 8,
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        color: 'rgba(5, 33, 32, 0.6)',
        '&$checked': {
          color: 'rgba(5, 33, 32, 0.6)',
        },
      },
    },
    MuiListItem: {
      root: {
        '&$selected': {
          backgroundColor: 'white',
        },
      },
    },
  },
});

const GlobalStyles = createGlobalStyle`
  body {
    font-family: 'Lato', sans-serif;
  }
`;

const WithTheme = ({ children }: { children: ReactNode }) => {
  return (
    <MuiThemeProvider theme={muiTheme}>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        {children}
      </ThemeProvider>
    </MuiThemeProvider>
  );
};

export default WithTheme;
