import { GoogleLogin, GoogleLoginProps, GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';
import { environmentVariable } from '../../common/utils/environment-variable';
import { fetchMe } from '../../store/current-user/reducers/currentUser.reducer';
import { googleLogin, googleLoginFailure } from '../../store/auth/actions';
import { useDispatch } from 'react-redux';
import React, { useCallback } from 'react';

interface IProps {
  render?: GoogleLoginProps['render'];
  keepSignIn: boolean;
}

const LoginButton = ({ render, keepSignIn }: IProps) => {
  const dispatch = useDispatch();

  const handleLoginSuccess = useCallback(
    (res: GoogleLoginResponse | GoogleLoginResponseOffline) => {
      dispatch(googleLogin(res as GoogleLoginResponse));
      dispatch(fetchMe());
    },
    [dispatch],
  );

  const handleLoginError = useCallback(
    (error: string) => {
      dispatch(googleLoginFailure(error));
    },
    [dispatch],
  );

  return (
    <>
      <GoogleLogin
        scope="https://www.googleapis.com/auth/plus.login"
        responseType="token"
        cookiePolicy="single_host_origin"
        onSuccess={handleLoginSuccess}
        onFailure={handleLoginError}
        clientId={environmentVariable.googleClientId}
        isSignedIn={keepSignIn}
        render={render}
      >
        {render ? undefined : 'Login'}
      </GoogleLogin>
    </>
  );
};

export default LoginButton;
