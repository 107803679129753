import { Autocomplete } from '@material-ui/lab';
import { GenerateRepository, TemplateVariablesItem } from '../../common/models/workspace.models';
import { InfoCircleIcon } from '../../assets';
import { LoadingSpinner } from '../loading-spinner/loading-spinner';
import { TemplateVariableItem, TemplateVariablesContainer } from './cookie-cutter-variables.style';
import { TextField, Tooltip } from '@material-ui/core';
import { ValidationError } from '../../common/utils/api';
import { validateGitRepositoryName } from '../../common/utils/validations';
import React, { useCallback, useEffect, useState } from 'react';
import RepositoryName from '../RepositoryName/RepositoryName';

interface CookieCutterVariablesProps {
  data?: GenerateRepository | null;
  templateVariables: TemplateVariablesItem[];
  onDataChanged?: (data: GenerateRepository | null) => void;
  hideCheckbox?: boolean;
  hideRepositoryName?: boolean;
  validationError?: ValidationError | null;
  style?: React.CSSProperties;
  isFetching?: boolean;
}

enum ErrorType {
  REQUIRED = 'REQUIRED',
  INVALID_FORMAT = 'INVALID_FORMAT',
  OTHER = 'OTHER',
}

export const CookieCutterVariables: React.FC<CookieCutterVariablesProps> = (props) => {
  const [formModel, setFormModel] = useState<GenerateRepository>(
    props.data
      ? props.data
      : {
          newRepository: false,
          cookieCutterContent: {},
        },
  );
  const [hasRepositoryNameError, setHasRepositoryNameError] = useState<boolean>(false);
  const [invalidFields, setInvalidFields] = useState<
    { key: string; errorType: ErrorType; message: string; invalidValue?: string }[]
  >([]);

  const onTemplateVariableChange = (key: string, value: string | string[]): void => {
    const copyOfRepo = { ...formModel };
    const templateVar = formModel.cookieCutterContent ? formModel.cookieCutterContent : {};
    if (value) {
      templateVar[key] = value;
    } else {
      delete templateVar[key];
    }
    copyOfRepo.cookieCutterContent = templateVar;
    setFormModel(copyOfRepo);
    validateFormModel(copyOfRepo);
  };

  const validateFormModel = (
    data: GenerateRepository,
    variables: TemplateVariablesItem[] = props.templateVariables,
  ): void => {
    setInvalidFields([]);
    for (const item of variables) {
      if (item.constraint && item.constraint.required && !data.cookieCutterContent[item.key]) {
        addInvalidField(item.key, ErrorType.REQUIRED);
        notifyParent(null);
        return;
      }
    }

    if (!props.hideRepositoryName) {
      if (!data.repositoryName) {
        notifyParent(null);
        return;
      }

      if (!validateGitRepositoryName(data.repositoryName)) {
        setHasRepositoryNameError(true);
        notifyParent(null);
        return;
      } else {
        setHasRepositoryNameError(false);
      }
    }

    notifyParent(data);
    return;
  };

  const notifyParent = (data: GenerateRepository | null): void => {
    if (props.onDataChanged) {
      props.onDataChanged(data);
    }
  };

  const handleValidateFromModel = useCallback(validateFormModel, []);

  useEffect(() => {
    if (props.data) {
      setFormModel(props.data);
      handleValidateFromModel(props.data);
    }
  }, [props.data, handleValidateFromModel]);

  const handleValidateTemplateVariables = useCallback(
    (variables: TemplateVariablesItem[]) => {
      handleValidateFromModel(formModel, variables);
    },
    [formModel, handleValidateFromModel],
  );

  useEffect(() => {
    if (props.templateVariables) {
      handleValidateTemplateVariables(props.templateVariables);
    }
  }, [props.templateVariables, handleValidateTemplateVariables]);

  const addInvalidField = (key: string, type: ErrorType) => {
    const invalidFieldsCopy = [...invalidFields];
    const index = invalidFieldsCopy.findIndex((value) => value.key === key);
    if (index > -1) {
      invalidFieldsCopy.splice(index, 1);
    }
    invalidFieldsCopy.push({ key, errorType: type, message: type });
    setInvalidFields(invalidFieldsCopy);
  };

  const handleAddInvalidField = useCallback(addInvalidField, []);

  useEffect(() => {
    if (props.validationError) {
      setInvalidFields(
        props.validationError.error.errors
          .filter((value) => value.field)
          .map((value) => {
            return {
              key: value.field as string,
              errorType: ErrorType[value.message.toUpperCase()]
                ? ErrorType[value.message.toUpperCase()]
                : ErrorType.OTHER,
              invalidValue: value.invalidValue,
              message: value.message,
            };
          }),
      );
    }
  }, [props.validationError, handleAddInvalidField]);

  const removeInvalidField = (key: string) => {
    const invalidFieldsCopy = [...invalidFields];
    const index = invalidFieldsCopy.findIndex((value) => value.key === key);
    if (index > -1) {
      invalidFieldsCopy.splice(index, 1);
    }
    setInvalidFields(invalidFieldsCopy);
  };

  const isInvalidField = (key: string) => {
    return invalidFields.findIndex((value) => value.key === key) !== -1;
  };

  const getErrorText = (key: string) => {
    const fieldError = invalidFields.find((value) => value.key === key);
    if (fieldError) {
      switch (fieldError.errorType) {
        case ErrorType.REQUIRED:
          return 'This field is required';
        case ErrorType.INVALID_FORMAT:
          return 'Invalid format, according to regex: ' + fieldError.invalidValue;
        case ErrorType.OTHER:
          return fieldError.message;
      }
    }
    return '';
  };

  const handleValueChange = (item: TemplateVariablesItem, value: string) => {
    removeInvalidField(item.key);
    if (item.constraint && item.constraint.required && !value) {
      addInvalidField(item.key, ErrorType.REQUIRED);
      onTemplateVariableChange(item.key, value);
      return;
    }
    if (item.constraint && item.constraint.pattern) {
      const reg = new RegExp(item.constraint.pattern);
      const isValid = reg.test(value);
      if (!isValid) {
        addInvalidField(item.key, ErrorType.INVALID_FORMAT);
        onTemplateVariableChange(item.key, value);
        return;
      }
    }
    onTemplateVariableChange(item.key, value);
  };

  return (
    <div style={props.style}>
      {formModel && (
        <TemplateVariablesContainer className={'variables'}>
          <RepositoryName
            hideRepositoryName={!!props.hideRepositoryName}
            hideCheckbox={!!props.hideCheckbox}
            hasRepositoryNameError={hasRepositoryNameError}
            hasRepositoryNameValidationError={invalidFields.find((field) => field.key === 'repositoryName')}
            repositoryName={formModel.repositoryName || ''}
            onChange={(e) => {
              const copyOfRepo = { ...formModel };
              copyOfRepo.repositoryName = e.target.value;
              setFormModel(copyOfRepo);
              validateFormModel(copyOfRepo);
            }}
            checked={!!formModel.newRepository}
            onClick={() => {
              const copyOfRepo = { ...formModel };
              copyOfRepo.newRepository = !formModel.newRepository;
              setFormModel(copyOfRepo);
              validateFormModel(copyOfRepo);
            }}
          />

          {(!props.templateVariables || props.templateVariables.length === 0 || props.isFetching) && <LoadingSpinner />}
          <div className="cc-variables">
            {props.templateVariables &&
              !props.isFetching &&
              props.templateVariables.map((value) => {
                const hasError = isInvalidField(value.key);
                return (
                  <TemplateVariableItem key={value.key}>
                    <>
                      <>
                        <Tooltip title={value.description ? value.description : ''} aria-label="add">
                          {value.description ? <InfoCircleIcon /> : <div />}
                        </Tooltip>
                      </>
                      {value.type === 'STRING' && (
                        <TextField
                          required={(value.constraint && value.constraint.required) || false}
                          label={value.name || value.key}
                          variant="outlined"
                          error={hasError}
                          helperText={getErrorText(value.key)}
                          defaultValue={value.value}
                          color="primary"
                          size="small"
                          onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                            handleValueChange(value, e.target.value)
                          }
                        />
                      )}
                      {value.type === 'CHOICE' && (
                        <Autocomplete
                          disableClearable={true}
                          options={value.options as string[]}
                          renderInput={(params) => {
                            return (
                              <TextField
                                required={(value.constraint && value.constraint.required) || false}
                                {...params}
                                label={value.name || value.key}
                                variant="outlined"
                                fullWidth
                              />
                            );
                          }}
                          getOptionLabel={(option) => option}
                          onChange={(event, val) => {
                            handleValueChange(value, val as string);
                          }}
                          defaultValue={value.value as string}
                          size="small"
                        />
                      )}
                    </>
                  </TemplateVariableItem>
                );
              })}
          </div>
        </TemplateVariablesContainer>
      )}
    </div>
  );
};
