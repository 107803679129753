import { AlertPolicy, AlertPolicyDTO } from '../../common/models/interfaces';
import { urls } from '../../common/utils/urls';
import api from '../../common/utils/api';

async function getPolicies(
  domainId: string,
  modelId: string,
  cb?: (policyList: AlertPolicy[]) => void,
  before?: () => void,
) {
  try {
    await before?.();
    const policies = await api.get<AlertPolicy[]>(urls.getAlertPolicies(domainId, modelId));
    cb && (await cb(policies));
  } catch (e) {
    console.log('AlertPolicies-->requests-->getPolicies', e);
  }
}

async function createPolicy(domainId: string, modelId: string, payload: AlertPolicyDTO, cb?: () => void) {
  try {
    await api.post(urls.getAlertPolicies(domainId, modelId), payload);
    await cb?.();
  } catch (e) {
    console.log('AlertPolicies-->requests-->createPolicy', e);
  }
}

async function editPolicy(domainId: string, modelId: string, payload: Omit<AlertPolicy, 'id'>, cb?: () => void) {
  try {
    await api.put(urls.getAlertPolicies(domainId, modelId), payload);
    await cb?.();
  } catch (e) {
    console.log('AlertPolicies-->requests-->createPolicy', e);
  }
}

async function deletePolicy(domainId: string, modelId: string, policyId: string, cb?: () => void, before?: () => void) {
  try {
    before?.();
    await api.del(urls.deleteAlertPolicy(domainId, modelId, policyId));
    await cb?.();
  } catch (e) {
    console.log('AlertPolicies-->requests-->deletePolicy', e);
  }
}

export { getPolicies, createPolicy, deletePolicy, editPolicy };
