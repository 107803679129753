import * as S from './styles';
import { ModelMean, ModelSum, ModelVS } from '../../common/models/model.model';
import { urls } from '../../common/utils/urls';
import MainPredictionChart from '../MainPredictionChart/MainPredictionChart';
import ModelPerformanceContext from '../../pages/ModelPerformance/ModelPerformanceContext';
import NoModelSelected from '../NoModelSelected/NoModelSelected';
import PredictionsSumChart from '../PredictionsSumChart/PredictionsSumChart';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import VSPredictionChart from '../VSPredictionChart/VSPredictionChart';
import api from '../../common/utils/api';

const ModelPredictionTab = () => {
  const { domainId, modelId } = useContext(ModelPerformanceContext);
  const [mean, setMean] = useState<ModelMean>();
  const [vs, setVS] = useState<ModelVS>();
  const [sum, setSum] = useState<ModelSum>();

  const fetchPredictionsMean = async () => {
    try {
      const meanData = await api.get<ModelMean>(urls.getPerdictionsMean(domainId || '', modelId || ''));
      if (meanData) setMean(meanData);
    } catch (e) {
      console.log('ModelPredictionTab-->fetchPredictionsMean', e);
    }
  };

  const fetchPredictionsVSActual = async () => {
    try {
      const vsData = await api.get<ModelVS>(urls.getPerdictionsVSActual(domainId || '', modelId || ''));
      if (vsData) setVS(vsData);
    } catch (e) {
      console.log('ModelPredictionTab-->fetchPredictionsVSActual', e);
    }
  };

  const fetchPredictionsSum = async () => {
    try {
      const sumData = await api.get<ModelSum>(urls.getPerdictionsSum(domainId || '', modelId || ''));
      if (sumData) setSum(sumData);
    } catch (e) {
      console.log('ModelPredictionTab-->fetchPredictionsSum', e);
    }
  };

  const handleFetchPredictionsMean = useCallback(fetchPredictionsMean, [domainId, modelId]);
  const handleFetchPredictionsVSActual = useCallback(fetchPredictionsVSActual, [domainId, modelId]);
  const handleFetchPredictionsSum = useCallback(fetchPredictionsSum, [domainId, modelId]);

  useEffect(() => {
    if (domainId && modelId) {
      handleFetchPredictionsMean();
      handleFetchPredictionsVSActual();
      handleFetchPredictionsSum();
    }
  }, [domainId, modelId, handleFetchPredictionsMean, handleFetchPredictionsVSActual, handleFetchPredictionsSum]);

  if (!modelId) return <NoModelSelected />;

  return (
    <div>
      {mean && <MainPredictionChart data={mean} />}
      <S.ChartGridWrapper>
        {sum && <PredictionsSumChart data={sum} />}
        {vs && <VSPredictionChart data={vs} />}
      </S.ChartGridWrapper>
    </div>
  );
};

export default ModelPredictionTab;
