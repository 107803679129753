import { makeStyles } from '@material-ui/core';
import styled from 'styled-components';

export const UserInfoRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 52px;
  & .principal {
    width: 100%;
  }
`;

export const UserAvatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 16px;
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
`;

export const UserName = styled.span`
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.0015em;
  color: ${(p) => p.theme.colors.primary};
`;

export const UserEmail = styled.span`
  font-family: Didact, Gothic, sans-serif;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.5px;
  color: rgba(5, 33, 32, 0.6);
`;

export const useDialogStyles = makeStyles({
  dialog: {
    width: 768,
  },
});

export const RoleSelectorContainer = styled.div`
  margin: 25px 0;
`;
