import { LinearProgress, MenuItem } from '@material-ui/core';
import { SelectValidator } from 'react-material-ui-form-validator';
import { getTemplatesList } from '../../store/templates/actions';
import { useDispatch } from 'react-redux';
import { useSelector } from '../../store/configure-store';
import React, { useEffect } from 'react';

interface TemplateSelectorPropsT {
  selectedTemplateId: string;
  onChange: (e: any) => void;
}

const TemplateSelector: React.FC<TemplateSelectorPropsT> = ({ selectedTemplateId, onChange }) => {
  const dispatch = useDispatch();
  const templates = useSelector((state) => state.templates.templates);
  useEffect(() => {
    dispatch(getTemplatesList());
  }, [dispatch]);

  if (!templates) {
    return (
      <>
        <LinearProgress />
      </>
    );
  }

  return (
    <SelectValidator
      name="selectedTemplateId"
      validators={['required']}
      errorMessages={['this field is required']}
      fullWidth
      size={'small'}
      variant="outlined"
      displayempty="true"
      SelectProps={{
        displayEmpty: true,
      }}
      style={{ marginTop: '1em' }}
      value={selectedTemplateId}
      onChange={onChange}
    >
      <MenuItem value="">Select a template...</MenuItem>
      {templates.map((template) => (
        <MenuItem key={template.id} value={template.id}>
          {template.name}
        </MenuItem>
      ))}
    </SelectValidator>
  );
};

export default TemplateSelector;
