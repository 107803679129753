import { AddButton } from '../../../components/add-button/add-button';
import { AlertInput } from './alert-input/alert-input';
import {
  AlertTypeInputContainer,
  BudgetFormContainer,
  Controls,
  DividerContainer,
  InfoIconContainer,
  InputContainer,
  LimitAutocompleteContainer,
  NotificationAutocompleteContainer,
  TrashIconContainer,
} from './budget-form.styles';
import { Autocomplete } from '@material-ui/lab';
import { Budget, DetailedWorkspace, Notification, Project } from '../../../common/models/workspace.models';
import {
  Button,
  Card,
  Checkbox,
  Divider,
  FormControlLabel,
  InputAdornment,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { CardContainer, Column, Title, TransparentText } from '../../../common/styles/common.styles';
import { InfoIcon, TrashIcon } from '../../../assets';
import { NotificationLimitAutocomplete } from '../../../common/models/interfaces';
import { NotificationType, PeriodType, ToastType } from '../../../common/models/enums';
import { TextFieldWrapper } from '../../../components/text-field-wrapper/text-field-wrapper';
import { createToasterNotification } from '../../../common/utils/toaster-notification';
import { getWorkspaceById } from '../../../store/workspaces/actions';
import { limitOptions } from '../../../common/utils/limit-options';
import { path } from '../../../routes/path';
import { urls } from '../../../common/utils/urls';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from '../../../store/configure-store';
import React, { ChangeEvent, useEffect, useState } from 'react';
import api from '../../../common/utils/api';
import withResources from '../../../components/withResources/withResources';

const BudgetFormComp: React.FC<{ workspace: DetailedWorkspace; handleWorkspaceFetch: () => void }> = (props) => {
  const selectedResource = useSelector((state) => state.dynamicSelector.selectedResource);
  const workspace = useSelector((state) => state.workspaces.selectedWorkspace);

  const { workspaceId, budgetId } = useParams() as any;

  const [budgetRuleName, setBudgetRuleName] = useState('');
  const [budgetLimit, setBudgetLimit] = useState<number | string>('');
  const [isNotificationSetForEveryProject, setIsNotificationSetForEveryProject] = useState(false);
  const [isHardLimitSetForEveryProject, setIsHardLimitSetForEveryProject] = useState(false);
  const [projects, setProjects] = useState<Project[]>([]);
  const [hardLimitProjects, setHardLimitProjects] = useState<Project[]>([]);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [hardLimit, setHardLimit] = useState<NotificationLimitAutocomplete | null>();
  const [isHardLimitInputVisible, setIsHardLimitInputVisible] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (workspace) {
      const selectedBudget = workspace?.budgets.find((budget) => budget.budgetId === budgetId);
      const hardLimitNotification = selectedBudget?.notifications.find(
        (notification) => notification.notificationType === NotificationType.HARD_LIMIT,
      );

      setBudgetRuleName(selectedBudget?.name ?? '');
      setBudgetLimit(selectedBudget?.budgetLimit ?? '');
      setNotifications(selectedBudget?.notifications ?? []);
      setProjects(selectedBudget?.projectDtos ?? []);
      setHardLimitProjects(hardLimitNotification?.gcpProjects ?? []);
      setHardLimit(limitOptions.find((limitOption) => limitOption.value === hardLimitNotification?.percent) ?? null);
    }
  }, [workspace, budgetId]);

  useEffect(() => {
    if (isHardLimitSetForEveryProject) {
      setHardLimitProjects(workspace?.projects ?? []);
    }
  }, [isHardLimitSetForEveryProject, workspace]);

  const handleBudgetRuleNameChange = (value: string) => {
    setBudgetRuleName(value);
  };

  const handleBudgetLimitChange = (value: string) => {
    setBudgetLimit(value);
  };

  const handleProjectsUpdate = (event: ChangeEvent<unknown>, selectedProjects: Project[]) => {
    setProjects(selectedProjects);
  };

  const handleNotificationTypeUpdate = (selectedNotificationType: NotificationType | null, index: number) => {
    const newNotifications = [...notifications];
    newNotifications[index].notificationType = selectedNotificationType
      ? selectedNotificationType
      : NotificationType.EMPTY;
    if (newNotifications[index].notificationType !== NotificationType.WEBHOOK && newNotifications[index].url) {
      newNotifications[index].url = '';
    }
    setNotifications(newNotifications);
  };

  const handleNotificationLimitUpdate = (limit: number | null, index: number) => {
    const newNotifications = [...notifications];
    newNotifications[index].percent = limit ? limit : 0;
    setNotifications(newNotifications);
  };

  const handleNotificationWebhookUrlUpdate = (url: string | null, index: number) => {
    const newNotifications = [...notifications];
    newNotifications[index].url = url ? url : '';
    setNotifications(newNotifications);
  };

  const handleNotificationDelete = (index: number) => {
    const newNotifications = notifications.filter((_, i) => i !== index);
    setNotifications(newNotifications);
  };

  const handleAddingNewNotification = () => {
    setNotifications((previousNotificationValues) => [
      ...previousNotificationValues,
      {
        notificationType: NotificationType.EMPTY,
        percent: 0,
      },
    ]);
  };

  const handleClearHardLimit = () => {
    setHardLimitProjects([]);
    setHardLimit(null);
    const newNotifications = notifications.filter(
      (notification) => notification.notificationType !== NotificationType.HARD_LIMIT,
    );
    setNotifications(newNotifications);
  };

  const handleHardLimitProjectUpdate = (selectedHardLimitProjects: Project[]) => {
    setHardLimitProjects(selectedHardLimitProjects);
    if (selectedHardLimitProjects.length === 0) {
      handleClearHardLimit();
    }
  };

  const handleRemoveHardLimitFromForm = () => {
    setIsHardLimitInputVisible(false);
    handleClearHardLimit();
  };

  const handleHardLimitUpdate = (limit: NotificationLimitAutocomplete | null) => {
    setHardLimit(limit);
  };

  const prepareNotificationsPayload = (): Notification[] => {
    const newNotifications: Notification[] = [...notifications];
    if (hardLimitProjects.length > 0) {
      const hardLimitNotification: Notification = {
        notificationType: NotificationType.HARD_LIMIT,
        percent: hardLimit?.value ?? 0,
        gcpProjects: hardLimitProjects,
      };
      const hardLimitIndex = newNotifications.findIndex(
        (newNotification) => newNotification.notificationType === NotificationType.HARD_LIMIT,
      );

      if (hardLimitIndex > -1) {
        newNotifications[hardLimitIndex] = hardLimitNotification;
      } else {
        newNotifications.push(hardLimitNotification);
      }
      setNotifications(newNotifications);
      return newNotifications;
    }
    return notifications;
  };

  const handleSave = () => {
    if (budgetId) {
      const payload: Omit<Budget, 'createdAt' | 'createdBy'> = {
        budgetId,
        name: budgetRuleName,
        budgetLimit: budgetLimit ? +budgetLimit : 0,
        notifications: prepareNotificationsPayload(),
        period: PeriodType.MONTHLY,
        projectDtos: isNotificationSetForEveryProject ? workspace?.projects ?? [] : projects,
      };
      api
        .put<Budget>(urls.updateBudgetRule(workspaceId, budgetId), payload)
        .then((response) => {
          dispatch(createToasterNotification('Successfully updated the budget rule', ToastType.SUCCESS));
          navigate(path.editBudgetRule(workspaceId ?? '', response.budgetId));
        })
        .catch((e) => {
          dispatch(createToasterNotification(e));
        });
    } else {
      const payload: Omit<Budget, 'budgetId' | 'createdAt' | 'createdBy'> = {
        name: budgetRuleName,
        budgetLimit: budgetLimit ? +budgetLimit : 0,
        notifications: prepareNotificationsPayload(),
        period: PeriodType.MONTHLY,
        projectDtos: isNotificationSetForEveryProject ? workspace?.projects ?? [] : projects,
      };
      api
        .post<Budget>(urls.createBudgetRule(workspaceId), payload)
        .then((response) => {
          dispatch(createToasterNotification('Successfully created a new budget rule', ToastType.SUCCESS));
          navigate(path.editBudgetRule(workspaceId ?? '', response.budgetId));
        })
        .catch((e) => {
          dispatch(createToasterNotification(e));
        });
    }
  };

  const hasHardLimit = notifications.some(
    (notification) => notification.notificationType === NotificationType.HARD_LIMIT,
  );

  useEffect(() => {
    selectedResource && dispatch(getWorkspaceById(selectedResource.id));
  }, [selectedResource, dispatch]);

  return (
    <CardContainer>
      <Card>
        <BudgetFormContainer>
          <Title marginBottom={8}>Add budget rule</Title>
          <TransparentText marginBottom={15}>
            Create new budget rule by defining trigger trough a budget threshold constraint
          </TransparentText>
          <InputContainer marginBottom={20}>
            <TextFieldWrapper label="Budget rule name" value={budgetRuleName} getValue={handleBudgetRuleNameChange} />
          </InputContainer>
          <TransparentText marginBottom={20}>Trigger</TransparentText>
          <InputContainer marginBottom={40}>
            <TextFieldWrapper
              label="Monthly total budget"
              value={budgetLimit}
              inputProps={{
                startAdornment: <InputAdornment position="start">US$</InputAdornment>,
              }}
              isNumberType
              getValue={handleBudgetLimitChange}
            />
          </InputContainer>
          <InputContainer marginBottom={20}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isNotificationSetForEveryProject}
                  onClick={() =>
                    setIsNotificationSetForEveryProject(
                      (previousIsApplyOnEveryProject) => !previousIsApplyOnEveryProject,
                    )
                  }
                  color="primary"
                />
              }
              label="Apply on every GCP Project within workspace"
            />
          </InputContainer>
          <InputContainer marginBottom={40}>
            <Autocomplete
              options={workspace?.projects ?? []}
              renderInput={(params) => (
                <TextField {...params} label="Applied on project within workspace" variant="outlined" fullWidth />
              )}
              getOptionLabel={(option) => option.name}
              value={isNotificationSetForEveryProject ? workspace?.projects ?? [] : projects ?? []}
              onChange={handleProjectsUpdate}
              disabled={isNotificationSetForEveryProject}
              multiple
              fullWidth
              size="small"
            />
          </InputContainer>
          <Title marginBottom={20}>Alert types</Title>
          {notifications.length > 0 && (
            <InputContainer marginBottom={20}>
              <Column>
                <TransparentText marginBottom={8}>Notifications</TransparentText>
                <TransparentText marginBottom={20} fontSize={12}>
                  Notify by
                </TransparentText>
                {notifications.map((notificationValue, index) => {
                  return (
                    notificationValue.notificationType !== NotificationType.HARD_LIMIT && (
                      <React.Fragment key={`notification-input-${index}`}>
                        <AlertInput
                          index={index}
                          notification={notificationValue}
                          tooltipText="Lorem ipsum dolor sit amet"
                          handleNotificationUpdate={handleNotificationTypeUpdate}
                          handleNotificationLimitUpdate={handleNotificationLimitUpdate}
                          handleNotificationWebhookUrlUpdate={handleNotificationWebhookUrlUpdate}
                          handleDeleteNotification={handleNotificationDelete}
                        />
                        {index < notifications.length - 1 && (
                          <DividerContainer>
                            <Divider light />
                          </DividerContainer>
                        )}
                      </React.Fragment>
                    )
                  );
                })}
              </Column>
            </InputContainer>
          )}
          <InputContainer marginBottom={20}>
            <AddButton label="ADD NOTIFICATION" onClick={handleAddingNewNotification} />
          </InputContainer>
          <TransparentText marginBottom={20}>Hard limit</TransparentText>
          {!isHardLimitInputVisible && !hasHardLimit && (
            <AddButton label="ADD HARD LIMIT" onClick={() => setIsHardLimitInputVisible(true)} />
          )}
          {(isHardLimitInputVisible || hasHardLimit) && (
            <>
              <InputContainer marginBottom={20}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isHardLimitSetForEveryProject}
                      onClick={() =>
                        setIsHardLimitSetForEveryProject(
                          (previousIsHardLimitAppliedOnEveryProject) => !previousIsHardLimitAppliedOnEveryProject,
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Apply on every GCP Project within workspace"
                />
              </InputContainer>
              <InputContainer marginBottom={60}>
                <AlertTypeInputContainer>
                  <NotificationAutocompleteContainer>
                    <Autocomplete
                      options={workspace?.projects ?? []}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Applied on project within workspace"
                          variant="outlined"
                          fullWidth
                        />
                      )}
                      getOptionLabel={(option) => option.name}
                      value={hardLimitProjects ?? []}
                      onChange={(event, value) => handleHardLimitProjectUpdate(value)}
                      disabled={isHardLimitSetForEveryProject}
                      multiple
                      fullWidth
                      size="small"
                    />
                  </NotificationAutocompleteContainer>
                  <LimitAutocompleteContainer>
                    <Autocomplete
                      options={limitOptions}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => <TextField {...params} label="Limit" variant="outlined" fullWidth />}
                      onChange={(event, value) => handleHardLimitUpdate(value)}
                      getOptionSelected={(option) => option.value === hardLimit?.value}
                      value={hardLimit}
                      fullWidth
                      size="small"
                    />
                  </LimitAutocompleteContainer>
                  <InfoIconContainer>
                    <Tooltip title="Lorem ipsum dolor sit amet">
                      <InfoIcon />
                    </Tooltip>
                  </InfoIconContainer>
                  <TrashIconContainer onClick={handleRemoveHardLimitFromForm}>
                    <TrashIcon />
                  </TrashIconContainer>
                </AlertTypeInputContainer>
              </InputContainer>
            </>
          )}
          <Controls>
            <Button onClick={() => navigate(`/workspaces/edit/${workspaceId}`)}>BACK</Button>
            <Button onClick={handleSave}>SAVE</Button>
          </Controls>
        </BudgetFormContainer>
      </Card>
    </CardContainer>
  );
};

export const BudgetForm = withResources(BudgetFormComp);
