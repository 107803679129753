import { Row } from '../../common/styles/common.styles';
import styled from 'styled-components';

export const MonthSelectorContainer = styled(Row)`
  align-items: center;
  justify-content: space-between;
`;

export const DateLabel = styled.div`
  width: 200px;
  text-align: center;
  font-size: 18px;
  line-height: 20px;
  color: ${(p) => p.theme.colors.transparentDarkGreen};
`;

export const ChevronLeftContainer = styled(Row)`
  width: 30px;
  height: 30px;
  align-items: center;
  cursor: pointer;
`;
export const ChevronRightContainer = styled(Row)`
  width: 30px;
  height: 30px;
  align-items: center;
  cursor: pointer;
`;
