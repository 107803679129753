import * as S from './styles';
import { ModelRegressionMetrics } from '../../common/models/model.model';
import React, { Suspense, lazy } from 'react';
const DynamicPlotlyChart = lazy(() => import('../DynamicPlotlyChart'));

interface TRegressionMetricsProps {
  metrics: ModelRegressionMetrics;
}

const RegressionMetrics: React.FC<TRegressionMetricsProps> = ({ metrics }) => {
  return (
    <>
      <S.Title>Main evaluation metric</S.Title>
      <S.ChartWrapper>
        <S.ChartInfo>
          <S.ChartInfoContent>
            {metrics.mainMetric}
            <S.ChartCurrentValue>{parseFloat(metrics.currentValue.toString()).toFixed(2)}</S.ChartCurrentValue>
            <S.ChartChange>{parseFloat(metrics.change.toString()).toFixed(5)}</S.ChartChange>
          </S.ChartInfoContent>
        </S.ChartInfo>
        <div>
          <Suspense fallback={<>Loading...</>}>
            <DynamicPlotlyChart
              data={[
                {
                  x: metrics.metrics.map((metric) => metric.xvalue),
                  y: metrics.metrics.map((metric) => metric.yvalue),
                  type: 'scatter',
                  mode: 'lines+markers',
                  marker: { color: '#56CCF2' },
                },
              ]}
              layout={{
                title: '',
                height: 380,
                autosize: true,
                plot_bgcolor: '#fafafa',
                paper_bgcolor: '#fafafa',
                margin: { t: 0, r: 0, b: 40, l: 40 },
              }}
              config={{ displaylogo: false, modeBarButtons: false }}
            />
          </Suspense>
        </div>
      </S.ChartWrapper>
    </>
  );
};

export default RegressionMetrics;
