import { ActionRow } from './kubeflow.styles';
import { AlertDialog } from '../../components/alert-dialog/alert-dialog';
import { Button, Card } from '@material-ui/core';
import { CardContainer, InformationContainer, Links, PlusIconContainer } from '../../common/styles/common.styles';
import { CellPrimaryValue } from '../workspaces/workspaces.styles';
import { CreateManagedServiceDto, ManagedInstanceDto } from '../../common/models/workspace.models';
import { ManagedServiceDialog } from '../../components/managed-service-dialog/managed-service-dialog';
import { ManagedServiceState, ManagedServiceTypes, ToastType } from '../../common/models/enums';
import { PlusIcon } from '../../assets';
import { Table } from '../../components';
import { TableHeadCell } from '../../components/Table/TableHead';
import { createToasterNotification } from '../../common/utils/toaster-notification';
import { getStatusIcon, getStatusName } from '../mlflow/mlflow';
import { path } from '../../routes/path';
import { urls } from '../../common/utils/urls';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useSelector } from '../../store/configure-store';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import React, { useCallback, useState } from 'react';
import api from '../../common/utils/api';
import withResources from '../../components/withResources/withResources';

const KubeflowComp: React.FC = () => {
  const dispatch = useDispatch();
  const selectedResource = useSelector((state) => state.dynamicSelector.selectedResource);
  const domainId = selectedResource?.domainId || '';
  const [gridData, setGridData] = useState<ManagedInstanceDto[]>([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isAlertDialogForDeletionOpen, setIsAlertDialogForDeletionOpen] = useState(false);
  const [selectedGridRow, setSelectedGridRow] = useState<ManagedInstanceDto | null>(null);
  const navigate = useNavigate();
  const [selectedVersionId, setSelectedVersionId] = useState<string>('');

  const fetchManagedServicesQuery = useQuery(
    `fetch-managed-services-kubeflow`,
    () => {
      return api
        .get<ManagedInstanceDto[]>(urls.getKubeflows(domainId))
        .then((data) => {
          setGridData(data);
          return data;
        })
        .catch((e) => {
          dispatch(createToasterNotification(e));
        });
    },
    { enabled: true, refetchOnWindowFocus: false },
  );

  const headCells: TableHeadCell<ManagedInstanceDto>[] = [
    { id: 'id', label: 'Id' },
    { id: 'name', label: 'Name' },
    { id: 'url', label: 'Url' },
    { id: 'state', label: 'State' },
    { id: 'editable', type: 'editable' },
    { id: 'menu', type: 'menu' },
  ];

  const renderCell = (
    colName: keyof ManagedInstanceDto | string,
    _rowIndex: number,
    item: ManagedInstanceDto,
  ): React.ReactNode => {
    switch (colName) {
      case 'url':
        return (
          <CellPrimaryValue>
            <Links
              className={item.url ? 'withIcon' : 'withIcon disabled'}
              href={item.url}
              underline={'none'}
              target="_blank"
            >
              URL
              <OpenInNewIcon />
            </Links>
          </CellPrimaryValue>
        );

      case 'state':
        return (
          <CellPrimaryValue>
            {
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {getStatusIcon(item[colName] as ManagedServiceState)}
                {getStatusName(item[colName] as ManagedServiceState)}
              </div>
            }
          </CellPrimaryValue>
        );

      default: {
        return <CellPrimaryValue>{item[colName]}</CellPrimaryValue>;
      }
    }
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleFetchManagedServicesQuery = useCallback(fetchManagedServicesQuery.refetch, []);

  const handleOnSave = (createManagedServiceDto: CreateManagedServiceDto): Promise<unknown> => {
    return api
      .post<ManagedInstanceDto>(urls.createKubeflowInstance(domainId, selectedVersionId), createManagedServiceDto)
      .then(() => {
        handleFetchManagedServicesQuery();
        handleCloseDialog();
        dispatch(createToasterNotification('New instance has successfully been created', ToastType.SUCCESS));
        return;
      });
  };

  const confirmDeleteOpen = (id: string) => {
    setIsAlertDialogForDeletionOpen(true);
    const clickedItem = gridData.find((instance) => instance.id === id);
    if (!clickedItem) {
      return;
    }
    setSelectedGridRow(clickedItem);
  };

  const handleCloseAlertDialog = () => {
    setIsAlertDialogForDeletionOpen(false);
    setSelectedGridRow(null);
  };

  const deleteItem = () => {
    if (!selectedGridRow) {
      return;
    }
    api
      .del(urls.deleteKubeflowInstance(domainId, selectedGridRow.id as string))
      .then(() => {
        fetchManagedServicesQuery.refetch();
        handleCloseAlertDialog();
        dispatch(createToasterNotification('Pipeline Instance has successfully been deleted', ToastType.SUCCESS));
      })
      .catch((e) => {
        dispatch(createToasterNotification(e));
      });
  };

  const navigateToManagedServiceAccessPage = (rowId: string, rowName?: string) => {
    navigate(path.managedServiceAccess('kubeflow', rowId), { state: { name: rowName } });
  };

  return (
    <CardContainer>
      <Card>
        <InformationContainer>
          <ActionRow>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setIsDialogOpen(true);
              }}
            >
              <PlusIconContainer>
                <PlusIcon />
              </PlusIconContainer>
              New Pipeline Instance
            </Button>
          </ActionRow>
          <Table
            renderCell={renderCell}
            headCells={headCells}
            data={gridData}
            refresh={() => {
              fetchManagedServicesQuery.refetch();
            }}
            withTopActions={true}
            handleDelete={confirmDeleteOpen}
            tableMenuActions={[
              {
                label: 'IAM',
                onClick: navigateToManagedServiceAccessPage,
                hidden: () => false,
              },
            ]}
          />
        </InformationContainer>
      </Card>
      {isDialogOpen && (
        <ManagedServiceDialog
          title={'New Pipeline Instance'}
          open={isDialogOpen}
          onCancel={handleCloseDialog}
          onSave={handleOnSave}
          serviceType={ManagedServiceTypes.KUBEFLOW}
          setter={setSelectedVersionId}
        />
      )}
      {isAlertDialogForDeletionOpen && (
        <AlertDialog
          isOpen={isAlertDialogForDeletionOpen}
          title="Delete Pipeline Instance"
          description="Are you sure you want to delete this item?"
          cancelButtonText="Cancel"
          okButtonText="Delete"
          onClose={() => {
            setIsAlertDialogForDeletionOpen(false);
            setSelectedGridRow(null);
          }}
          onOk={deleteItem}
        />
      )}
    </CardContainer>
  );
};

export const Kubeflow = withResources(KubeflowComp);
