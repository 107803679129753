import styled from 'styled-components';

const Title = styled.p`
  color: #02394d;
  margin-bottom: 2em;
`;

const MetaProperty = styled.div`
  & + & {
    margin-top: 1em;
  }
`;
const MetaPropertyLabel = styled.p`
  margin: 0;
  color: rgba(5, 33, 32, 0.6);
  font-weight: bold;
  font-family: Lato;
  letter-spacing: 1.5px;
  font-size: 10px;
  text-transform: uppercase;
`;
const MetaPropertyValue = styled.p`
  margin: 0;
  color: #052120;
  font-family: Didact Gothic;
  font-size: 1rem;
`;

const MetaPropertyValueLinkWrapper = styled.div`
  width: 300px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const MetaPropertyValueLink = styled.a`
  margin: 0;
  color: #052120;
  font-family: Didact Gothic;
  font-size: 1rem;
`;

export {
  Title,
  MetaProperty,
  MetaPropertyLabel,
  MetaPropertyValue,
  MetaPropertyValueLink,
  MetaPropertyValueLinkWrapper,
};
