import { generateUrl } from '../../services/request';

export const urls = {
  me: generateUrl('user/v1/users/me'),
  userRole: (userId: string) => generateUrl(`policy/v1/users/${userId}/roles`),
  userTour: () => generateUrl(`user/v1/users/me/tour`),
  domainLevelRoles: (resourceType: string) => generateUrl(`role/v1/roles/${resourceType}`),
  getDomainPrefix: (domainId: string) => generateUrl(`domain/v1/domains/${domainId}/project-prefix`),
  getAlertIncidents: (domainId: string, modelId: string) =>
    generateUrl(`mpm/v1/domains/${domainId}/models/${modelId}/alertIncidents`),
  getPlotTypes: (domainId: string, modelId: string) =>
    generateUrl(`mpm/v1/domains/${domainId}/models/${modelId}/plotTypes`),
  getPlotDates: (domainId: string, modelId: string, plotType: string) =>
    generateUrl(`mpm/v1/domains/${domainId}/models/${modelId}/plotTypes/${plotType}/dates`),
  getPlotChart: (domainId: string, modelId: string, plotType: string, date: string) =>
    generateUrl(`mpm/v1/domains/${domainId}/models/${modelId}/plotTypes/${plotType}/dates/${date}`),
  getHistoryChart: (domainId: string, modelId: string, plotType: string) =>
    generateUrl(`mpm/v1/domains/${domainId}/models/${modelId}/plotTypes/${plotType}`),
  getAlertPolicies: (domainId: string, modelId: string) =>
    generateUrl(`mpm/v1/domains/${domainId}/models/${modelId}/alertPolicies`),
  deleteAlertPolicy: (domainId: string, modelId: string, policyId: string) =>
    generateUrl(`mpm/v1/domains/${domainId}/models/${modelId}/alertPolicies/${policyId}`),
  getModels: (domainId: string) => generateUrl(`mpm/v1/domains/${domainId}/models`),
  getModelMeta: (domainId: string, modelId: string) =>
    generateUrl(`mpm/v1/domains/${domainId}/models/${modelId}/metadata`),
  getRegressionMetrics: (domainId: string, modelId: string, mainMetric: string) =>
    generateUrl(`mpm/v1/domains/${domainId}/models/${modelId}/mainmetric/${mainMetric}`),
  getPerdictionsMean: (domainId: string, modelId: string) =>
    generateUrl(`mpm/v1/domains/${domainId}/models/${modelId}/predictionsMean`),
  getPerdictionsVSActual: (domainId: string, modelId: string) =>
    generateUrl(`mpm/v1/domains/${domainId}/models/${modelId}/predictedVsActual`),
  getPerdictionsSum: (domainId: string, modelId: string) =>
    generateUrl(`mpm/v1/domains/${domainId}/models/${modelId}/predictionsSum`),
  getPredictionsMetrics: (domainId: string, modelId: string) =>
    generateUrl(`mpm/v1/domains/${domainId}/models/${modelId}/predictions`),
  workspacesWithoutRepo: (domainId: string) =>
    generateUrl(`workspace/v1/workspaces/domains/${domainId}?showWithoutRepo=true`),
  workspaces: (domainId: string) => generateUrl(`workspace/v1/workspaces/domains/${domainId}`),
  domains: () => generateUrl(`domain/v1/domains`),
  trialSignUp: () => generateUrl(`domain/v1/signup`),
  workspaceBaseData: (domainId: string) => generateUrl(`workspace/v1/workspaces/domains/${domainId}/base-data`),
  workspaceProject: (workspaceId: string) => generateUrl(`workspace/v1/workspaces/${workspaceId}/projects`),
  fetchWorkspaceMembers: (workspaceId: string) => generateUrl(`workspace/v1/workspaces/${workspaceId}/members`),
  fetchTemplateMembers: (templateId: string) => generateUrl(`template/v1/templates/${templateId}/members`),
  fetchTemplateCategories: () => generateUrl(`template/v1/templates/categories`),
  createOrUpdateWorkspaceMember: (workspaceId: string) => generateUrl(`workspace/v1/workspaces/${workspaceId}/members`),
  deleteWorkspaceMember: (workspaceId: string, authorizationId: string) =>
    generateUrl(`workspace/v1/workspaces/${workspaceId}/members/${authorizationId}`),
  workspace: (workspaceId: string) => generateUrl(`workspace/v1/workspaces/${workspaceId}`),
  deleteWorkspace: (workspaceId: string) => generateUrl(`workspace/v1/workspaces/${workspaceId}`),
  workspaceUsers: (workspaceId: string) => generateUrl(`workspace/v1/workspaces/${workspaceId}/users`),
  project: (domainId: string, projectId: string) =>
    generateUrl(`project/v1/domains/${domainId}/projects/${projectId}/validate`),
  projectIdValidate: (domainId: string, projectId: string) =>
    generateUrl(`project/v1/domains/${domainId}/projects/${projectId}/validate`),
  projectCreate: (domainId: string, projectId: string) =>
    generateUrl(`project/v1/domains/${domainId}/projects/${projectId}/create`),
  projects: (domainId: string) => generateUrl(`project/v1/domains/${domainId}`),
  addUsersToWorkspace: (workspaceId: string) => generateUrl(`workspace/v1/workspaces/${workspaceId}/users`), // TODO remove
  getAlertLogs: (domainId: string) => generateUrl(`budget/v1/domains/${domainId}/alerts`),
  getAlertLogsByWorkspaceId: (workspaceId: string) => generateUrl(`budget/v1/workspaces/${workspaceId}/alerts`),
  getDailySpending: (workspaceId: string) => generateUrl(`report/v1/workspaces/${workspaceId}/spendings`),
  getCumulativeDailySpending: (workspaceId: string, budgetId: string) =>
    generateUrl(`report/v1/workspaces/${workspaceId}/budgets/${budgetId}/cost`),
  createBudgetRule: (workspaceId: string) => generateUrl(`budget/v1/workspaces/${workspaceId}/budgets`),
  updateBudgetRule: (workspaceId: string, budgetId: string) =>
    generateUrl(`budget/v1/workspaces/${workspaceId}/budgets/${budgetId}`),
  deleteBudgetRule: (workspaceId: string, budgetId: string) =>
    generateUrl(`budget/v1/workspaces/${workspaceId}/budgets/${budgetId}`),
  users: () => generateUrl('user/v1/users'), // TODO remove
  getWorkspacesAssignedToUser: (userId: string) => generateUrl(`policy/v1/users/${userId}/workspaces`), // TODO remove
  getTemplatePublic: (templateId: string) => generateUrl(`template/v1/templates/public/${templateId}`),
  getTemplatesPagePublic: () => generateUrl(`template/v1/templates/public/search`),
  getTemplate: (templateId: string) => generateUrl(`template/v1/templates/${templateId}`),
  getTemplates: () => generateUrl(`template/v1/templates`),
  getTerraformVersions: () => generateUrl(`template/v1/templates/terraformVersions`),
  createTemplate: (domainId: string) => generateUrl(`template/v1/templates/domains/${domainId}`),
  getTemplatesPage: (params: { page: number; size: number }) =>
    generateUrl(`template/v1/templates/search?page=${params.page}&size=${params.size}`),
  getTemplateVariables: (templateId: string, version: string) =>
    generateUrl(`template/v1/templates/${templateId}/version/${version}/variables`),
  getTemplateVariablesActual: (templateId: string, version: string, workspaceId: string) =>
    generateUrl(`template/v1/templates/${templateId}/version/${version}/workspaces/${workspaceId}/variables/actual`),
  generateCodeFromTemplate: (templateId: string, workspaceId: string | null) =>
    generateUrl(`template/v1/templates/${templateId}/workspaces/${workspaceId}/generate`),
  validateTemplateVariables: (templateId: string) => generateUrl(`template/v1/templates/${templateId}/validate`),
  getBaseTemplate: () => generateUrl(`template/v1/templates/baseVariables`),
  getRepository: (workspaceId: string | null) => generateUrl(`repository/v1/repositories/${workspaceId}`),
  provision: (workspaceId: string, projectId: string | undefined) =>
    generateUrl(`provisioning/v1/workspaces/${workspaceId}/projects/${projectId}`),
  finishProvisioning: (workspaceId: string, provisioningId: string | undefined) =>
    generateUrl(`provisioning/v1/workspaces/${workspaceId}/provisionings/${provisioningId}/finish`),
  enableProvisioning: (workspaceId: string, projectId: string | undefined) =>
    generateUrl(`provisioning/v1/workspaces/${workspaceId}/projects/${projectId}/enable`),
  provisionLogs: (workspaceId: string, provisionId: string | undefined) =>
    generateUrl(`provisioning/v1/workspaces/${workspaceId}/provisionings/${provisionId}/details`),
  getMyTasks: (workspaceId: string | null) => generateUrl(`task/v1/workspaces/${workspaceId}/tasks`),
  getTaskTypes: () => generateUrl(`task/v1/types`),
  saveTask: (workspaceId: string) => generateUrl(`task/v1/workspaces/${workspaceId}/tasks`),
  dropTask: (workspaceId: string, taskId: string | null) =>
    generateUrl(`task/v1/workspaces/${workspaceId}/tasks/${taskId}/drop`),
  takeTask: (workspaceId: string, taskId: string | null) =>
    generateUrl(`task/v1/workspaces/${workspaceId}/tasks/${taskId}/take`),
  finishTask: (workspaceId: string | undefined, taskId: string | null, taskStatus: string | null) =>
    generateUrl(`task/v1/workspaces/${workspaceId}/tasks/${taskId}/status/${taskStatus}`),
  getTaskByReferenceId: (workspaceId: string, referenceId: string | null) =>
    generateUrl(`task/v1/workspaces/${workspaceId}/references/${referenceId}`),
  changeProjectConfiguration: (workspaceId: string, projectId: string | undefined) =>
    generateUrl(`project/v1/workspaces/${workspaceId}/projects/${projectId}/config`),
  fetchDomainMembers: (domainId: string) => generateUrl(`domain/v1/domains/${domainId}/members`),
  createOrUpdateDomainMember: (domainId: string) => generateUrl(`domain/v1/domains/${domainId}/members`),
  createOrUpdateBlueprintMember: (templateId: string) => generateUrl(`template/v1/templates/${templateId}/members`),
  deleteDomainMember: (domainId: string, authorizationId: string) =>
    generateUrl(`domain/v1/domains/${domainId}/members/${authorizationId}`),
  deleteBlueprintMember: (templateId: string, authorizationId: string) =>
    generateUrl(`template/v1/templates/${templateId}/members/${authorizationId}`),
  getMlflows: (domainId: string) => generateUrl(`mlflow/v1/domains/${domainId}/instances`),
  getMlflowVersions: () => generateUrl(`mlflow/v1/versions`),
  getMlflowPrincipals: (domainId: string, instanceId: string) =>
    generateUrl(`mlflow/v1/domains/${domainId}/instances/${instanceId}/principals`),
  postMlflowPrincipals: (domainId: string, instanceId: string) =>
    generateUrl(`mlflow/v1/domains/${domainId}/instances/${instanceId}/principals`),
  getMlflowVariables: (domainId: string, versionId: string) =>
    generateUrl(`mlflow/v1/domains/${domainId}/versions/${versionId}/variables`),
  createMlflowInstance: (domainId: string, versionId: string) =>
    generateUrl(`mlflow/v1/domains/${domainId}/versions/${versionId}/instances`),
  deleteMlflowInstance: (domainId: string, instanceId: string) =>
    generateUrl(`mlflow/v1/domains/${domainId}/instances/${instanceId}`),
  getKubeflows: (domainId: string) => generateUrl(`kubeflow/v1/domains/${domainId}/instances`),
  getKubeFlowVersions: () => generateUrl(`kubeflow/v1/versions`),
  getKubeflowPrincipals: (domainId: string, instanceId: string) =>
    generateUrl(`kubeflow/v1/domains/${domainId}/instances/${instanceId}/principals`),
  postKubeflowPrincipal: (domainId: string, instanceId: string) =>
    generateUrl(`kubeflow/v1/domains/${domainId}/instances/${instanceId}/principals`),
  getKubeflowVariables: (domainId: string, versionId: string) =>
    generateUrl(`kubeflow/v1/domains/${domainId}/versions/${versionId}/variables`),
  createKubeflowInstance: (domainId: string, versionId: string) =>
    generateUrl(`kubeflow/v1/domains/${domainId}/versions/${versionId}/instances`),
  deleteKubeflowInstance: (domainId: string, instanceId: string) =>
    generateUrl(`kubeflow/v1/domains/${domainId}/instances/${instanceId}`),
  getServingModels: (domainId: string, pipelineId: string) =>
    generateUrl(`serving/v1/domains/${domainId}/pipelines/${pipelineId}/models`),
  resources: () => generateUrl(`resource/v1/resources`),
  getResourceById: (workspaceId: string | null) => generateUrl(`resource/v1/resources/${workspaceId}/type/WORKSPACE`),
  upgradeTemplate: (workspaceId: string | null, templateId: string | null) =>
    generateUrl(`template/v1/templates/${templateId}/workspaces/${workspaceId}/upgrade`),
  getWorkspacesByTemplate: (templateId: string) => generateUrl(`template/v1/templates/${templateId}/workspaces`),
};
