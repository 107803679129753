import { Button, Card } from '@material-ui/core';
import { CardContainer, PlusIconContainer } from '../../common/styles/common.styles';
import { DeleteWorkspaceDto, DetailedWorkspace } from '../../common/models/workspace.models';
import { PlusIcon } from '../../assets';
import { Table } from '../../components';
import { TableHeaderCtaContainer } from './workspaces.styles';
import { TableMenuAction } from '../../components/Table';
import { WorkspaceDeleteDialog } from './dialog/workspace-delete-dialog';
import { deleteWorkspace, getWorkspaces } from '../../store/workspaces/actions';
import { headCells, renderCell } from './table-config/table-config';
import { path } from '../../routes/path';
import { urls } from '../../common/utils/urls';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useSelector } from '../../store/configure-store';
import React, { useCallback, useEffect, useState } from 'react';
import api from '../../common/utils/api';
import withResources from '../../components/withResources/withResources';

const WorkspacesComp: React.FC = () => {
  const selectedResource = useSelector((state) => state.dynamicSelector.selectedResource);
  const [workspace, setWorkspace] = useState<DetailedWorkspace>();
  const workspaces = useSelector((state) => state.workspaces.workspaces);
  const workspacesIsInProgress = useSelector((state) => state.workspaces.workspacesIsInProgress);
  const [isAlertDialogForDeletionOpen, setIsAlertDialogForDeletionOpen] = useState(false);
  const [workspaceId, setWorkspaceId] = useState('');
  const [isFetchingWorkspace, setIsFetchingWorkspace] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isAdmin = true; // TODO remove it later

  const fetchWorkspace = async () => {
    try {
      setIsFetchingWorkspace(true);
      const workspaceData = await api.get<DetailedWorkspace>(urls.workspace(workspaceId as string));
      if (workspaceData) setWorkspace(workspaceData);
      setIsFetchingWorkspace(false);
    } catch (e) {
      console.log('workspaces-->fetchWorkspace');
      setIsFetchingWorkspace(false);
    }
  };

  const handleFetchWorkspace = useCallback(fetchWorkspace, [workspaceId]);

  useEffect(() => {
    if (workspaceId) handleFetchWorkspace();
  }, [handleFetchWorkspace, workspaceId]);

  const handleCreateNewWorkspace = () => {
    navigate(path.createWorkspaceNavigation());
  };

  const handleEditWorkspace = (id: string) => {
    navigate(path.editWorkspace(id));
  };

  const handleOpenAlertDialogForDeletion = (id: string) => {
    if (isAdmin) {
      setWorkspaceId(id);
      setIsAlertDialogForDeletionOpen(true);
    }
  };

  useEffect(() => {
    dispatch(getWorkspaces(selectedResource?.domainId || ''));
  }, [selectedResource, dispatch]);

  const handleDeleteWorkspace = (selectedProjects: string[], deleteRepository: boolean): void => {
    const payload: DeleteWorkspaceDto = {
      projectToBeDeletedInGcp: selectedProjects,
      removeGitRepository: deleteRepository,
    };
    dispatch(deleteWorkspace(workspaceId || null, payload));
    setWorkspaceId('');
    setIsAlertDialogForDeletionOpen(false);
  };

  const tableMenuActions: TableMenuAction[] = [
    {
      label: 'Edit workspace',
      onClick: (id: string) => handleEditWorkspace(id),
    },
  ];

  const adminTableMenuActions: TableMenuAction[] = [
    ...tableMenuActions,
    {
      label: 'Delete workspace',
      onClick: (id: string) => handleOpenAlertDialogForDeletion(id),
    },
  ];

  return (
    <CardContainer>
      <Card>
        <WorkspaceDeleteDialog
          onDelete={handleDeleteWorkspace}
          primaryButtonText={'Delete'}
          workspace={workspace || undefined}
          open={isAlertDialogForDeletionOpen}
          onCancel={() => setIsAlertDialogForDeletionOpen(false)}
          title={'Delete workspace'}
          isLoading={isFetchingWorkspace}
        />
        <Table
          headCells={headCells}
          data={workspaces || []}
          isLoading={workspacesIsInProgress}
          refresh={() => {
            dispatch(getWorkspaces(selectedResource?.domainId || ''));
          }}
          renderCell={renderCell}
          topButton={
            <TableHeaderCtaContainer onClick={handleCreateNewWorkspace}>
              <Button variant="contained" color="primary">
                <PlusIconContainer>
                  <PlusIcon />
                </PlusIconContainer>
                Create new workspace
              </Button>
            </TableHeaderCtaContainer>
          }
          tableMenuActions={isAdmin ? adminTableMenuActions : tableMenuActions}
        />
      </Card>
    </CardContainer>
  );
};

export const Workspaces = withResources(WorkspacesComp);
