import * as UserTypes from './types';
import { IUsersActions, UsersState } from './models';

const initialState: UsersState = {
  data: [],
  isFetching: false,
};

export default (state = initialState, action: IUsersActions): UsersState => {
  switch (action.type) {
    case UserTypes.FETCH_USERS_REQUEST:
      return { ...state, isFetching: true, error: undefined };

    case UserTypes.FETCH_USERS_SUCCESS:
      return { ...state, isFetching: false, data: action.payload };

    case UserTypes.FETCH_USERS_FAILURE:
      return { ...state, isFetching: false, error: action.error, data: [] };
    default:
      return state;
  }
};
