import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { PipelineStep } from '../../common/models/interfaces';
import { makeStyles } from '@material-ui/styles';
import AceEditor from 'react-ace';
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/mode-python';
import 'ace-builds/src-noconflict/theme-solarized_dark';
import stepTypeDeps from './typeDeps.json';

interface TPipelineEditorModalProps {
  isOpen: boolean;
  onCreate: (step: PipelineStep) => void;
  onCancel: () => void;
  selectedStep?: PipelineStep;
}

const useStyles = makeStyles({
  paper: { minWidth: '500px' },
});

const stepTypes = [
  '+ Custom Python component',
  'GCP BigQuery to CSV',
  'GCP BigQuery to GCS',
  'GCP BigQuery to BigQuery table',
  'GCP Cloud Storage download',
  'GCP Cloud Storage upload',
  'GCP ML Engine batch prediction',
  'GCP ML Engine predict',
  'GCP ML Engine train',
  'JSON query with JQ',
  'XGBoost predict',
  'XGBoost train',
];

const initialStep: PipelineStep = {
  id: '',
  name: '',
  python: '',
  type: stepTypes[0],
  parameters: stepTypeDeps[stepTypes[0]] || undefined,
};

const PipelineEditorModal = ({ isOpen, onCancel, onCreate, selectedStep }: TPipelineEditorModalProps) => {
  const classes = useStyles();
  const [step, setStep] = useState<PipelineStep>(!selectedStep ? initialStep : selectedStep);

  useEffect(() => {
    selectedStep && setStep(selectedStep);
    !selectedStep && setStep(initialStep);
  }, [selectedStep]);

  useEffect(() => {
    setStep((prevStateStep) => ({
      ...prevStateStep,
      parameters: stepTypeDeps[prevStateStep.type]
        ? selectedStep && selectedStep.parameters
          ? selectedStep.parameters
          : stepTypeDeps[prevStateStep.type]
        : stepTypeDeps[prevStateStep.type],
    }));
  }, [step.type, selectedStep]);

  return (
    <Dialog open={isOpen} classes={{ paper: classes.paper }}>
      <DialogTitle id="pipeline-editor-modal-title">{selectedStep ? 'Edit step' : 'Create new step'}</DialogTitle>
      <DialogContent>
        <TextField
          onChange={(e) =>
            setStep((prevStepState) => ({
              ...prevStepState,
              name: e.target.value as string,
            }))
          }
          autoFocus
          margin="dense"
          id="name"
          label="Step name"
          type="principal"
          fullWidth
          value={step.name}
        />

        <FormControl style={{ marginTop: '12px' }} size="small" fullWidth variant="outlined">
          <InputLabel htmlFor="step-type">Step type</InputLabel>
          <Select
            style={{ marginBottom: '1em', width: '100%' }}
            labelId="select-step-type"
            value={step.type}
            inputProps={{
              name: 'age',
              id: 'step-type',
            }}
            label="Step type"
            variant="outlined"
            onChange={(e) => {
              setStep((prevStepState) => ({ ...prevStepState, type: e.target.value as string }));
            }}
          >
            {stepTypes.map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </Select>
          {step.type === stepTypes[0] && (
            <AceEditor
              style={{ width: '100%', height: '300px' }}
              mode="python"
              theme="solarized_dark"
              value={step.python}
              onChange={(value) => setStep((prevStepState) => ({ ...prevStepState, python: value }))}
              name="python-code"
              editorProps={{ $blockScrolling: true }}
            />
          )}
          {step?.parameters && (
            <>
              {step?.parameters?.map((dep) => (
                <TextField
                  key={dep.name}
                  onChange={(e) => {
                    setStep((prevStepState) => ({
                      ...prevStepState,
                      parameters: prevStepState.parameters?.map((param) => {
                        if (param.name === dep.name) {
                          return {
                            ...param,
                            default: e.target.value as string,
                          };
                        }
                        return param;
                      }),
                    }));
                  }}
                  margin="dense"
                  id={dep?.name}
                  label={dep?.name}
                  type="principal"
                  fullWidth
                  value={dep?.default || ''}
                />
              ))}
            </>
          )}
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() => {
            onCreate(step.id ? step : { ...step, id: uuidv4() });
          }}
          color="primary"
        >
          {selectedStep ? 'Save' : 'Create'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PipelineEditorModal;
