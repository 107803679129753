import styled from 'styled-components';

export const PageTitleWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 18px;
`;

export const PageActionWrapper = styled.div`
  margin-left: auto;
`;

export const PageTitle = styled.div`
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.0015em;
  color: ${(p) => p.theme.colors.header};
`;

export const RoutePageWrapper = styled.div<{ width: string }>`
  width: ${(p) => p.width};
`;
