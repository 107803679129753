import images from '../../assets';
import styled from 'styled-components';

export const Page = styled.div`
  position: relative;
  background-color: #fafafa;
`;

export const LogoContainer = styled.div`
  margin-left: 24px;
  margin-top: 20px;
`;

export const LandingImage = styled.img.attrs({ src: images.Landing })`
  width: 223.38px;
  height: 250px;
`;

export const GcLogo = styled.img.attrs({ src: images.GcLogo })`
  width: 153.02px;
  height: 24px;
  margin-top: 20px;
`;

export const Panel = styled.div`
  width: 390px;
  right: 120px;
  top: 50%;
  background-color: white;
  box-shadow: 0 8px 40px rgba(2, 57, 77, 0.25);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 40px;
  &.sign-up {
    height: 570px;
  }
`;

export const Title = styled.div`
  font-family: 'Lato, sans-serif';
  font-style: normal;
  font-weight: 300;
  margin-top: 50px;
  letter-spacing: -0.5px;
  font-size: 60px;
  line-height: 72px;

  color: ${(p) => p.theme.colors.primary};
`;

export const Title2 = styled.div`
  font-family: Didact, Gothic, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 34px;
  line-height: 42px;
  letter-spacing: -0.25px;

  color: ${(p) => p.theme.colors.primary};
`;

export const Text = styled.div`
  font-family: Didact, Gothic, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: rgba(5, 33, 32, 0.6);

  &.primary {
    color: ${(p) => p.theme.colors.primary};
  }
`;

export const Href = styled.div`
  font-family: Didact, Gothic, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: rgba(5, 33, 32, 0.6);
  cursor: pointer;

  &.primary {
    color: ${(p) => p.theme.colors.primary};
  }
  &.underline {
    text-decoration: underline;
  }
`;

export const SmallText = styled.div`
  font-family: Didact, Gothic, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: rgba(5, 33, 32, 0.6);

  &.primary {
    color: ${(p) => p.theme.colors.primary};
  }
`;

export const LinearProgressContainer = styled.div<{ isVisible: boolean }>`
  visibility: ${(p) => (p.isVisible ? 'visible' : 'hidden')};
`;

export const ContentContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  padding-left: 25px;
  padding-right: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  min-height: calc(100vh - 155px);
  margin-bottom: 20px;
`;

export const LeftContainer = styled.div`
  min-width: 400px;
  max-width: 650px;
  flex-direction: column;
  margin-right: 130px;
`;

export const RightContainer = styled.div`
  min-width: 400px;
`;

export const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 0px;
  height: 108px;
  background: #ffffff;
`;
