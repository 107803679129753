import { Drawer as MuiDrawer, makeStyles } from '@material-ui/core';
import styled from 'styled-components';
import theme from '../../theme';

export const Root = styled.div`
  display: flex;
`;

export const Main = styled.main`
  margin-top: ${theme.appbarHeight}px;
  padding: 16px 16px 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${(p) => p.theme.colors.page};
  min-height: calc(100vh - 72px);
`;

export const Drawer = styled(MuiDrawer)`
  width: ${theme.drawerWidth}px;
  z-index: 10;
  flex-shrink: 0;
`;

export const DrawerContainer = styled.div`
  overflow: auto;
  justify-content: space-between;
  display: flex;
  height: 100%;
  flex-direction: column;
`;

const useStyles = makeStyles(() => ({
  drawerPaper: {
    width: theme.drawerWidth,
    boxShadow: `0px 2px 4px rgba(0, 0, 0, 0.1), 0px 16px 32px rgba(0, 0, 0, 0.2);`,
    paddingTop: theme.appbarHeight,
  },
}));

export default useStyles;
