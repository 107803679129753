import { LoadingSpinnerContainer } from '../../../components/loading-spinner/loading-spinner.styles';
import styled from 'styled-components';

export const CardRoot = styled.div`
  display: inline-block;
  padding: 10px;
  & .card {
    max-width: 200px;
    padding: 8px;
    background-color: #fcfcfc;
    & .content {
      height: 150px;
      & .title {
      }
      & .description {
        overflow: hidden;
      }
    }
    & .media {
      height: 100px;
      background-size: contain;
      background-color: white;
    }

    & .actions {
      align-items: center;
      flex-direction: column;
    }
  }
`;

export const TemplateSelectContainer = styled.div`
  margin-bottom: 15px;
`;

export const SelectedTemplateContainer = styled.div`
  & .change-template-container {
    align-items: flex-end;
  }
`;

export const TemplateVariablesContainer = styled.div`
  margin-bottom: 25px;
`;

export const ContentContainer = styled.div`
  position: relative;
`;

export const LoadingSpinnerContainerWbg = styled(LoadingSpinnerContainer)`
  background-color: rgb(255 255 255 / 70%);
  position: absolute;
  z-index: 10;
  min-height: auto;
`;

export const NewInstanceRoot = styled.div`
  & .stepper {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }
  & .stepContent {
    padding: 15px;
  }
  & .stepFooter {
    margin-top: 15px;
    margin-bottom: 15px;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: flex-end;
    padding-right: 10px;
    padding-left: 10px;
  }
  & .closeBtn {
    color: gray;
  }
  & .card {
    position: relative;
  }
`;

export const SelectTemplateRoot = styled.div`
  & .select {
    position: absolute;
    z-index: 2;
  }
`;

export const EnvironmentVariablesRoot = styled.div`
  & .variables {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const SelectWorkspaceRoot = styled.div`
  min-height: 400px;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;
  & .center-container {
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
  }
  & .workspace-selector {
    max-width: 600px;
    width: 100%;
    flex: 1;
  }
`;

export const SelectProjectRoot = styled.div`
  min-height: 400px;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;
  & .center-container {
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
  }
  & .workspace-selector {
    max-width: 600px;
    width: 100%;
    flex: 1;
  }
`;

export const WorkspaceFromContainer = styled.div`
  display: flex;
  max-width: 600px;
  flex-direction: column;
  grid-row-gap: 20px;
  margin-top: 20px;
  width: 100%;
`;
