import * as S from './styles';
import { Button, Card, Typography, makeStyles } from '@material-ui/core';
import { CardContainer, CardContent } from '../../common/styles/common.styles';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { TEditBlueprint, TemplateCategory } from '../../common/models/interfaces';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { ToastType } from '../../common/models/enums';
import { createToasterNotification } from '../../common/utils/toaster-notification';
import { getBlueprintById, saveTemplate } from './requests';
import { getTerraformVersions } from '../../store/templates/actions';
import { path } from '../../routes/path';
import { useDispatch } from 'react-redux';
import { useSelector } from '../../store/configure-store';
import BlueprintVersion from '../../components/BlueprintVersion';
import CategoryFilter from '../../components/CategoryFilter';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import withResources from '../../components/withResources/withResources';

const useStyles = makeStyles(
  () => ({
    textInput: {
      marginTop: '1em',
    },
  }),
  { name: 'UploadBlueprint' },
);

function EditBlueprint() {
  const formRef = useRef(null);
  const { templateId } = useParams() as { templateId: string };
  const [formData, setFormData] = useState<TEditBlueprint>();
  const classes = useStyles();
  const dispatch = useDispatch();
  const terraformVersions = useSelector((state) => state.templates.terraformVersions);
  const [selectedCategories, setSelectedCategories] = useState<TemplateCategory[]>();
  const navigate = useNavigate();

  const setValue = (fieldName: string, value: string | boolean | null): void => {
    setFormData((prevFormData) => {
      if (prevFormData) {
        return {
          ...prevFormData,
          [fieldName]: value,
        };
      }
    });
  };

  const handleGetBlueprintById = useCallback(getBlueprintById, [templateId]);

  const getBlueprintByIdCallback = (data: TEditBlueprint) => {
    setFormData({
      name: data.name,
      description: data.description,
      terraformScriptDirectory: data.terraformScriptDirectory,
      versions: data.versions || [],
      terraformEnvDirectory: data.terraformEnvDirectory,
      templateCategories: data.templateCategories,
    });
    return;
  };

  useEffect(() => {
    templateId && handleGetBlueprintById(templateId, { cb: getBlueprintByIdCallback });
  }, [handleGetBlueprintById, templateId]);

  useEffect(() => {
    dispatch(getTerraformVersions());
  }, [dispatch]);

  useEffect(() => {
    selectedCategories &&
      setFormData((prevFormData) => {
        if (prevFormData) {
          return { ...prevFormData, templateCategories: selectedCategories };
        }
      });
  }, [selectedCategories]);

  const versionSetter = (paramName: string, value: string | MaterialUiPickersDate, index: number) => {
    formData &&
      setFormData({
        ...formData,
        versions:
          formData?.versions?.map((versionItem, versionIndex) => {
            if (versionIndex === index) {
              return {
                ...versionItem,
                [paramName]: value,
              };
            }
            return versionItem;
          }) || [],
      });
  };

  if (!formData || !terraformVersions) return null;

  const onSave = () => {
    saveTemplate(formData, templateId, {
      cb: () => {
        navigate(-1);
        dispatch(createToasterNotification(`Template saved successfully!`, ToastType.SUCCESS));
      },
    });
  };

  const addNewVersion = () => {
    const initialVersion = {
      branch: 'master',
      releaseDate: new Date(),
      terraformVersion: terraformVersions.length ? terraformVersions[0] : '',
      version: '',
    };
    setFormData((prevFormData) => {
      if (prevFormData) {
        return {
          ...prevFormData,
          versions: prevFormData.versions ? [initialVersion, ...prevFormData.versions] : [initialVersion],
        };
      }
    });
  };

  const deleteVersion = (versionId?: string | number, isExist?: boolean) => {
    if (formData.versions?.length === 1) {
      dispatch(createToasterNotification(`You can not delete all version!`, ToastType.WARNING));
    } else {
      setFormData({
        ...formData,
        versions: formData?.versions?.filter((version, versionIndex) => {
          if (isExist) {
            return version.id !== versionId;
          } else {
            return versionIndex !== versionId;
          }
        }),
      });
    }
  };

  return (
    <CardContainer>
      <Card>
        <CardContent style={{ position: 'relative' }}>
          <ValidatorForm ref={formRef} onSubmit={onSave}>
            <S.FormWrapper>
              <TextValidator
                name="name"
                variant="outlined"
                label="Blueprint name"
                fullWidth
                color="primary"
                size="small"
                value={formData?.name}
                validators={['required']}
                errorMessages={['This field is required']}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setValue('name', e.target.value as string);
                }}
              />
              <TextValidator
                name="description"
                label="Blueprint description"
                variant="outlined"
                color="primary"
                fullWidth
                classes={{ root: classes.textInput }}
                size="small"
                value={formData.description}
                validators={['required']}
                errorMessages={['This field is required']}
                multiline
                rows={5}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setValue('description', e.target.value as string);
                }}
              />

              <TextValidator
                label="Terraform script directory"
                variant="outlined"
                name="terraformScriptDirectory"
                fullWidth
                color="primary"
                size="small"
                classes={{ root: classes.textInput }}
                validators={['required']}
                errorMessages={['This field is required']}
                value={formData.terraformScriptDirectory}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setValue('terraformScriptDirectory', e.target.value as string);
                }}
              />
              <TextValidator
                label="Terraform environment directory"
                variant="outlined"
                name="terraformScriptDirectory"
                fullWidth
                color="primary"
                size="small"
                classes={{ root: classes.textInput }}
                validators={['required']}
                errorMessages={['This field is required']}
                value={formData.terraformEnvDirectory}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setValue('terraformEnvDirectory', e.target.value as string);
                }}
              />
              <div style={{ marginTop: '15px', marginBottom: '15px' }}>
                <Button onClick={addNewVersion}>+ Add new version</Button>
                {terraformVersions &&
                  formData?.versions?.map((version, index) => (
                    <BlueprintVersion
                      key={index}
                      version={version}
                      index={index}
                      terraformVersions={terraformVersions}
                      setter={versionSetter}
                      remove={deleteVersion}
                    />
                  ))}
              </div>
              <div
                style={{
                  margin: '12px -24px',
                }}
              >
                <Typography
                  style={{
                    paddingLeft: '24px',
                    paddingBottom: '12px',
                  }}
                >
                  Select one or more category:
                </Typography>
                <CategoryFilter
                  selectedCategoriesByDefault={formData.templateCategories}
                  onChangeAction={setSelectedCategories}
                />
              </div>
            </S.FormWrapper>
            <S.Footer>
              <Button component={Link} to={path.blueprintDashboard()}>
                Close
              </Button>
              <Button disabled={!selectedCategories || selectedCategories.length === 0} type="submit">
                Save
              </Button>
            </S.Footer>
          </ValidatorForm>
        </CardContent>
      </Card>
    </CardContainer>
  );
}

export default withResources(EditBlueprint);
