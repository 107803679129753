import * as S from './styles';
import { ActionRow } from '../iam/iam.styles';
import { AlertDialog } from '../../components/alert-dialog/alert-dialog';
import { Breadcrumbs, Button, Card, Link } from '@material-ui/core';
import { BreadcrumbsLink } from '../blueprint-dashboard/blueprint-detail/blue-print-detail.styles';
import { CardContainer, PlusIconContainer } from '../../common/styles/common.styles';
import { ManagedServiceTypes } from '../../common/models/enums';
import { PlusIcon } from '../../assets';
import { Principal } from '../../common/models/interfaces';
import { Table } from '../../components';
import { TableHeadCell } from '../../components/Table/TableHead';
import { ValidationError } from '../../common/utils/api';
import { createToasterNotification } from '../../common/utils/toaster-notification';
import { deletePrincipal, getPrincipals } from './requests';
import { path } from '../../routes/path';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { useSelector } from '../../store/configure-store';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ManagedServiceAccessModal from '../../components/ManagedServiceAccessModal';
import React, { useCallback, useEffect, useState } from 'react';
import withResources from '../../components/withResources/withResources';

const headCells: TableHeadCell<Principal>[] = [
  { id: 'principal', label: 'Principal' },
  { id: 'userType', label: 'Type' },
  { id: 'editable', type: 'editable' },
];

const ManagedServiceAccess = () => {
  const selectedResource = useSelector((state) => state.dynamicSelector.selectedResource);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [principals, setPrincipals] = useState<Principal[]>();
  const params = useParams<{ type: ManagedServiceTypes.KUBEFLOW | ManagedServiceTypes.MLFLOW; serviceId: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteLoading, setDeleteIsLoading] = useState(false);
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const dispatch = useDispatch();
  const [selectedForDelete, setSelectedForDelete] = useState('');
  const location = useLocation();

  const handleGetPrincipals = useCallback(getPrincipals, [params.type, selectedResource]);

  const onError = useCallback(
    (e: ValidationError | unknown) => {
      let message;
      if (e instanceof ValidationError) {
        const principalFieldError = e.error.errors.find((error) => error.field === 'principal');
        message = principalFieldError ? principalFieldError?.message : e;
      }
      dispatch(createToasterNotification(message));
    },
    [dispatch],
  );

  const refreshList = () =>
    params.type &&
    params.serviceId &&
    handleGetPrincipals(
      params.type,
      selectedResource?.domainId || '',
      params.serviceId,
      setPrincipals,
      setIsLoading,
      onError,
    );

  const handleRefreshList = useCallback(refreshList, [handleGetPrincipals, onError, params, selectedResource]);

  useEffect(() => {
    if (selectedResource?.domainId) {
      handleRefreshList();
    }
  }, [handleRefreshList, selectedResource?.domainId]);

  const renderCell = (colName: keyof Principal | string, _rowIndex: number, item: Principal) => {
    if (colName === 'userType') {
      switch (item[colName]) {
        case 'domain':
          return 'Domain';
        case 'serviceAccount':
          return 'Service Account';
        case 'user':
          return 'User';
        case 'group':
          return 'Group';
        default:
          return item[colName];
      }
    }
    return item[colName];
  };

  const resolvePrevPageName = () => {
    switch (params.type) {
      case ManagedServiceTypes.MLFLOW:
        return 'Experiments';
      case ManagedServiceTypes.KUBEFLOW:
        return 'Pipelines';
      default:
        return '';
    }
  };

  const resolvePrevPageURL = () => {
    switch (params.type) {
      case ManagedServiceTypes.MLFLOW:
        return path.mlflow();
      case ManagedServiceTypes.KUBEFLOW:
        return path.kubeflow();
      default:
        return '';
    }
  };

  return (
    <>
      <Breadcrumbs style={{ marginBottom: '1em' }}>
        <Link component={BreadcrumbsLink} to={resolvePrevPageURL()}>
          <ArrowBackIcon style={{ marginRight: '.5em' }} />
          {resolvePrevPageName()}
        </Link>
        <span>IAM</span>
      </Breadcrumbs>
      <CardContainer>
        <Card>
          <ActionRow>
            <div>
              <S.IntanceData>
                Instance name: <b>{location?.state?.name}</b>
              </S.IntanceData>
              <S.IntanceData>
                Instance ID: <i>{params?.serviceId}</i>
              </S.IntanceData>
            </div>
            <Button
              style={{ marginLeft: 'auto' }}
              variant="contained"
              color="primary"
              onClick={() => {
                setIsModalOpen(true);
              }}
            >
              <PlusIconContainer>
                <PlusIcon />
              </PlusIconContainer>
              Add new principal
            </Button>
          </ActionRow>
          <Table
            headCells={headCells}
            data={principals ? principals : []}
            isLoading={isLoading}
            refresh={handleRefreshList}
            renderCell={renderCell}
            handleDelete={(principal: string) => {
              setSelectedForDelete(principal);
              setIsAlertVisible(true);
            }}
          />
        </Card>
        {params.type && params.serviceId && (
          <ManagedServiceAccessModal
            serviceType={params.type}
            open={isModalOpen}
            setOpen={setIsModalOpen}
            serviceId={params.serviceId}
            refreshList={handleRefreshList}
            onError={onError}
          />
        )}
        <AlertDialog
          isOpen={isAlertVisible && !!selectedForDelete.length}
          title="Delete principal"
          description="Are you sure you want to delete this item?"
          cancelButtonText="Cancel"
          okButtonText="Delete"
          onClose={() => {
            setIsAlertVisible(false);
            setSelectedForDelete('');
          }}
          isLoading={isDeleteLoading}
          onOk={() => {
            params.type &&
              params.serviceId &&
              deletePrincipal(
                params.type,
                selectedResource?.domainId || '',
                params.serviceId,
                selectedForDelete,
                setDeleteIsLoading,
                () => {
                  setIsAlertVisible(false);
                  handleRefreshList();
                },
                onError,
              );
          }}
        />
      </CardContainer>
    </>
  );
};

export default withResources(ManagedServiceAccess);
