import * as AuthTypes from './types';
import { AuthState, IAuthActions } from './models';

const initialState: AuthState = {
  data: null,
  isFetching: false,
};

export default (state = initialState, action: IAuthActions): AuthState => {
  switch (action.type) {
    case AuthTypes.GOOGLE_LOGIN_SUCCESS:
      return { ...state, isFetching: false, data: action.payload };

    case AuthTypes.GOOGLE_LOGIN_FAILURE:
    case AuthTypes.GOOGLE_LOGOUT_FAILURE:
      return { ...state, isFetching: false, error: action.error };

    case AuthTypes.GOOGLE_LOGOUT_SUCCESS:
      return { ...state, isFetching: false, data: null };
    default:
      return state;
  }
};
