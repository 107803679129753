import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { ManagedServiceTypes } from '../../common/models/enums';
import { Version } from '../../common/models/interfaces';
import { getVersions } from './requests';
import React, { useCallback, useEffect, useState } from 'react';

interface TManagedServiceVersionSelectorProps {
  formControllStyle?: React.CSSProperties;
  setter: React.Dispatch<React.SetStateAction<string | undefined>>;
  serviceType: ManagedServiceTypes;
}

const ManagedServiceVersionSelector: React.FC<TManagedServiceVersionSelectorProps> = ({
  formControllStyle,
  setter,
  serviceType,
}) => {
  const [versions, setVersions] = useState<Version[]>();
  const [selectedVersionId, setSelectedVersionId] = useState<string>('');
  const handleGetManagedServiceVersions = useCallback(getVersions, []);

  useEffect(() => {
    handleGetManagedServiceVersions(setVersions, serviceType);
  }, [handleGetManagedServiceVersions, serviceType]);

  useEffect(() => {
    if (versions) {
      if (!selectedVersionId) {
        setSelectedVersionId(versions[0].id);
      }
    }
  }, [versions, setter, selectedVersionId]);

  useEffect(() => {
    if (typeof setter === 'function') setter(selectedVersionId);
  }, [selectedVersionId, setter]);

  return (
    <FormControl size="small" variant="outlined" {...(formControllStyle ? { style: formControllStyle } : {})}>
      <InputLabel id="select-version-label">Version</InputLabel>
      <Select
        labelId="select-version-label"
        onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
          console.log('??', event.target.value);
          if (typeof setter === 'function') {
            const newValue = `${event.target.value}`;
            setSelectedVersionId(newValue);
          }
        }}
        label="Version"
        value={selectedVersionId}
        name="version-selector"
      >
        {versions &&
          versions.map((version) => (
            <MenuItem value={version.id} key={version.id}>
              {version.version}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default ManagedServiceVersionSelector;
