import { Cancel, Search } from '../../assets';
import { Chip, WithStyles, withStyles } from '@material-ui/core';
import { InputContainer, Logo, SearchIcon, chipInputStyles } from './styles';
import Input from 'material-ui-chip-input';
import React from 'react';

interface IState {
  hasFocus: boolean;
}

interface IProps extends WithStyles<typeof chipInputStyles> {
  value: string[];
  onChange: (chips: string[]) => void;
}

class ChipInput extends React.Component<IProps, IState> {
  state: IState = { hasFocus: false };
  inputRef: React.Ref<HTMLInputElement> | null = null;

  handleChipAdded = (chip: string) => {
    const { onChange, value } = this.props;
    onChange([...value, chip]);
  };

  handleChipDeleted = (_chip: string, index: number) => {
    const { value, onChange } = this.props;
    const filteredChips = value.filter((_ch, idx) => index !== idx);
    onChange(filteredChips);
    this.focusOnInput();
  };

  focusOnInput = () => {
    if (this.inputRef) {
      (this.inputRef as any as HTMLInputElement).focus();
    }
    this.setState({ hasFocus: true });
  };

  handleInputBlur = () => this.setState((_prevState, { value }) => ({ hasFocus: value.length > 0 }));

  render() {
    const { value, classes } = this.props;
    const { hasFocus } = this.state;

    return (
      <>
        {!hasFocus && <SearchIcon onClick={this.focusOnInput} />}

        <InputContainer
          style={{
            opacity: hasFocus ? 1 : 0,
            pointerEvents: hasFocus ? 'initial' : 'none',
          }}
        >
          <Logo>
            <Search />
          </Logo>
          <Input
            inputRef={(ref) => {
              this.inputRef = ref;
            }}
            onBlur={this.handleInputBlur}
            chipRenderer={(props) => {
              return (
                <Chip
                  key={props.chip}
                  deleteIcon={<Cancel />}
                  onDelete={props.handleDelete}
                  classes={{ root: classes.chip, label: classes.chipText }}
                  label={props.text}
                />
              );
            }}
            disableUnderline={true}
            classes={{
              root: classes.input,
              chipContainer: classes.chipContainer,
            }}
            placeholder="Search option"
            value={value}
            onAdd={this.handleChipAdded}
            onDelete={this.handleChipDeleted}
          />
        </InputContainer>
      </>
    );
  }
}

export default withStyles(chipInputStyles)(ChipInput);
