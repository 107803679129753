import { Autocomplete } from '@material-ui/lab';
import {
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { CellPrimaryValue } from '../../pages/workspaces/workspaces.styles';
import { EnvironmentType, ToastType, ToasterMessage } from '../../common/models/enums';
import { FilterDropdown } from '../filter-dropdown/filter-dropdown';
import { InputContainer, Label, ProjectInformationContainer } from '../../pages/gcp-projects/gcp-projects.styles';
import { Links, PlusIconContainer } from '../../common/styles/common.styles';
import { PlusIcon } from '../../assets';
import { Project, ProjectWithId } from '../../common/models/workspace.models';
import { ProjectIdField } from './project-to-workspace-adder.style';
import { Table } from '../index';
import { TableHeadCell } from '../Table/TableHead';
import { createToasterNotification } from '../../common/utils/toaster-notification';
import { debounce } from '../template-search/template-search';
import { environments } from '../../common/utils/environments';
import { urls } from '../../common/utils/urls';
import { useDispatch } from 'react-redux';
import { useSelector } from '../../store/configure-store';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import React, { useCallback, useEffect, useState } from 'react';
import api, { ErrorItem, ValidationError } from '../../common/utils/api';

interface ProjectToWorkspaceAdderProps {
  hideName?: boolean;
  tableProjects: ProjectWithId[];
  tableOnEdit: (environmentType: EnvironmentType, project: Project) => void;
  tableOnDelete: (projectId: Project) => void;
  workspaceProjects?: Project[] | null;
  selectableProjects: Project[];
  selectedProject?: Project | null;
  onSave?: (updatedProjects: Project | Project[]) => void;
  onSelectProject?: (updatedProjects: Project | Project[] | null) => void;
}

const createItem: Project = {
  createTime: '',
  environmentType: '' as EnvironmentType,
  name: '',
  projectId: '',
  projectNumber: '',
  approvers: [],
  approvalWorkflowEnabled: false,
  provisioningEnabled: false,
  provisioningBranch: '',
  credential: undefined,
};

interface FormModel {
  project?: Project | null;
  environmentType?: EnvironmentType | null;
  projectId?: string | null;
}

export const ProjectToWorkspaceAdder: React.FC<ProjectToWorkspaceAdderProps> = (props) => {
  const [formModel, setFormModel] = useState<FormModel>(
    props.selectedProject
      ? {
          project: props.selectedProject,
          environmentType: props.selectedProject.environmentType,
          projectId: props.selectedProject.projectId,
        }
      : { project: null, environmentType: null, projectId: null },
  );
  const [selectableProjects, setSelectableProjects] = useState<Project[]>([{ ...createItem }]);
  const [projectIdErrors, setProjectIdErrors] = useState<string[]>([]);
  const [projectIdValidationRunning, setProjectIdValidationRunning] = useState<boolean>(false);
  const dispatch = useDispatch();
  const selectedResource = useSelector((state) => state.dynamicSelector.selectedResource);
  const domainId = selectedResource?.domainId || '';
  const [selectedGridRow, setSelectedGridRow] = useState<Project | null>(null);
  const [isAddProjectInProgress, setIsAddProjectInProgress] = useState(false);
  const [prefix, setPrefix] = useState('');

  const projectIdValidateQuery = (model: FormModel) => {
    api
      .post<void>(urls.projectIdValidate(domainId, model.projectId as string))
      .then(() => {
        setProjectIdValidationRunning(false);
        setProjectIdErrors([]);
      })
      .catch((e: ValidationError) => {
        if (e && e.error) {
          const errs: string[] = [];
          e.error.errors.forEach((err: ErrorItem) => errs.push(err.message));
          setProjectIdErrors(errs);
          setProjectIdValidationRunning(false);
        }
      });
  };

  const onSelectProject = (project: Project) => {
    const copy = { ...formModel };
    copy.project = project;
    setFormModel(copy);
  };

  const debounceValidate = debounce((model: FormModel) => {
    setProjectIdValidationRunning(true);
    projectIdValidateQuery(model);
  }, 450);

  const debounceValidateHandler = useCallback(debounceValidate, []);

  const setProjectId = (id: string) => {
    const copy = { ...formModel };
    copy.projectId = id;
    if (!id) {
      setFormModel(copy);
    } else {
      setProjectIdValidationRunning(true);
      debounceValidateHandler(copy);
      setFormModel(copy);
    }
  };

  const onSelectEnvironment = (environmentType: string) => {
    const copy = { ...formModel };
    copy.environmentType = environmentType as EnvironmentType;
    setFormModel(copy);
  };

  const resetFromModel = () => {
    setFormModel({ project: null, environmentType: EnvironmentType.DEVELOP, projectId: null });
  };

  const onAddProjectToWorkspace = async () => {
    if (formModel.project) {
      const copy = { ...formModel.project };
      copy.environmentType = formModel.environmentType as EnvironmentType;
      if (formModel.project.name === '') {
        copy.projectId = formModel.projectId as string;
      }
      const isDuplicate = props.workspaceProjects
        ? props.workspaceProjects?.find((p) => p.projectId === copy?.projectId)
        : false;
      if (!isDuplicate && props.onSave && copy) {
        setIsAddProjectInProgress(true);
        await props.onSave(copy);
        resetFromModel();
        setIsAddProjectInProgress(false);
      } else {
        dispatch(createToasterNotification(ToasterMessage.DUPLICATED_PROJECT, ToastType.WARNING));
      }
    }
  };

  const handlerOnSelectProject = useCallback(
    props.onSelectProject
      ? props.onSelectProject
      : () => {
          // empty
        },
    [],
  );

  useEffect(() => {
    if (
      formModel &&
      formModel.project &&
      formModel.environmentType &&
      ((formModel.project.name === '' && formModel.projectId) || formModel.project.name) &&
      projectIdErrors.length === 0 &&
      !projectIdValidationRunning
    ) {
      const copy = { ...formModel.project };
      copy.environmentType = formModel.environmentType as EnvironmentType;
      if (formModel.project.name === '') {
        copy.projectId = formModel.projectId as string;
      }
      handlerOnSelectProject(copy);
    } else {
      handlerOnSelectProject(null);
    }
  }, [formModel, projectIdErrors, projectIdValidationRunning, handlerOnSelectProject]);

  useEffect(() => {
    if (props.selectableProjects) {
      if (
        (props.selectableProjects.length > 0 && props.selectableProjects[0].projectId !== '') ||
        props.selectableProjects.length === 0
      ) {
        props.selectableProjects.unshift({ ...createItem });
      }
      setSelectableProjects(props.selectableProjects);
    }
  }, [props.selectableProjects]);

  const getPrefix = async () => {
    try {
      const { projectPrefix } = await api.get<{ projectPrefix: string }>(urls.getDomainPrefix(domainId));
      if (projectPrefix) setPrefix(projectPrefix);
    } catch (e) {
      console.log(e);
    }
  };

  const handleGetPrefix = useCallback(getPrefix, [domainId]);

  useEffect(() => {
    if (domainId) {
      handleGetPrefix();
    }
  }, [domainId, handleGetPrefix]);

  const renderCell = (colName: keyof Project | string, _rowIndex: number, item: Project): React.ReactNode => {
    switch (colName) {
      case 'projectId':
        return (
          <CellPrimaryValue>
            {!item.projectNumber && (
              <>
                {prefix}
                {item?.projectId}
              </>
            )}
            {item.projectNumber && (
              <Links
                className={'withIcon'}
                href={`https://console.cloud.google.com/home/dashboard?project=${item.projectId}`}
                underline={'none'}
                target="_blank"
                rel="noopener"
              >
                {item?.projectId}
                <OpenInNewIcon />
              </Links>
            )}
          </CellPrimaryValue>
        );
      case 'environmentType': {
        let formattedEnvironmentType;
        if (item.environmentType === EnvironmentType.UAT) {
          formattedEnvironmentType = item.environmentType;
        } else if (item.environmentType) {
          formattedEnvironmentType =
            item.environmentType?.charAt(0).toUpperCase() + item.environmentType?.slice(1).toLowerCase();
        } else {
          formattedEnvironmentType = 'n/a';
        }
        return (
          <>
            {selectedGridRow?.projectId === item.projectId ? (
              <FormControl variant="outlined" size="small" fullWidth>
                <Select value={item[colName]} onChange={(e) => onEditTableRow(e.target.value as EnvironmentType, item)}>
                  {environments.map((environment, index) => (
                    <MenuItem value={environment.value} key={`${environment.name}-${index}`}>
                      {environment.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <CellPrimaryValue>{formattedEnvironmentType}</CellPrimaryValue>
            )}
          </>
        );
      }
      default: {
        return <CellPrimaryValue>{item[colName]}</CellPrimaryValue>;
      }
    }
  };

  const headCells: TableHeadCell<Project>[] = [
    { id: 'projectId', label: 'ProjectID' },
    { id: 'projectNumber', label: 'Number' },
    { id: 'environmentType', label: 'Environment' },
    { id: 'editable', type: 'editable' },
  ];

  const onEditTableRow = (environmentType: EnvironmentType, project: Project): void => {
    props.tableOnEdit(environmentType, project);
  };

  const onDeleteTableRow = (projectId: string): void => {
    const targetedProject = props.tableProjects.filter((project) => project.projectId === projectId);
    if (!targetedProject) {
      return;
    }
    props.tableOnDelete(targetedProject[0]);
  };

  const enableProjectUpdate = (projectId: string) => {
    const targetedProject = props.tableProjects.filter((project) => project.projectId === projectId);
    if (!targetedProject) {
      return;
    }
    if (selectedGridRow && selectedGridRow.projectId === targetedProject[0].projectId) {
      setSelectedGridRow(null);
    } else {
      setSelectedGridRow(targetedProject[0] as Project);
    }
  };

  return (
    <div className="project-to-workspace">
      <InputContainer>
        {props.onSave && !props.hideName && <Label>Projects</Label>}
        <ProjectInformationContainer className={props.onSave ? '' : 'one-column'}>
          <Autocomplete
            value={formModel.project}
            options={selectableProjects ?? []}
            renderOption={(option) => (
              <Typography className={option.name === '' ? 'create-option' : ''} noWrap>
                {option.name === '' ? '+ NEW PROJECT' : option.name}
              </Typography>
            )}
            renderInput={(params) => (
              <TextField {...params} label="Projects from GCP by name" variant="outlined" fullWidth />
            )}
            getOptionLabel={(option) => (option.name === '' ? 'New project' : option.name)}
            onChange={(event, project) => onSelectProject(project as Project)}
            fullWidth
            size="small"
          />
          <FilterDropdown
            label="Environment type"
            selectedValue={formModel?.environmentType}
            options={environments
              .filter((value) => !props.tableProjects.find((value1) => value1.environmentType === value.value))
              .map((environment) => ({
                itemName: environment.name,
                value: environment.value,
              }))}
            handleChange={onSelectEnvironment}
          />
          {props.onSave && (
            <>
              <Button
                variant="contained"
                color="primary"
                disabled={
                  !formModel.project ||
                  !formModel.environmentType ||
                  (formModel.project.name === '' && !formModel.projectId) ||
                  projectIdErrors.length > 0 ||
                  projectIdValidationRunning ||
                  isAddProjectInProgress
                }
                onClick={onAddProjectToWorkspace}
              >
                <PlusIconContainer>
                  {!isAddProjectInProgress && <PlusIcon />}
                  {isAddProjectInProgress && <CircularProgress size={14} />}
                </PlusIconContainer>
                Add Project
              </Button>
            </>
          )}
          {formModel.project?.name === '' && (
            <div>
              <FormControl style={{ width: '100%' }} error={projectIdErrors.length > 0}>
                <ProjectIdField>
                  <TextField
                    className={'project-id-field'}
                    error={projectIdErrors.length > 0}
                    label="Project id"
                    variant="outlined"
                    color="primary"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">{prefix}</InputAdornment>,
                    }}
                    size="small"
                    value={formModel.projectId}
                    onChange={(e) => setProjectId(e.target.value)}
                  />
                  {projectIdValidationRunning && (
                    <CircularProgress style={{ width: '25px', height: '25px', marginLeft: '5px' }} color="primary" />
                  )}
                </ProjectIdField>
                {projectIdErrors?.map((error: string) => (
                  <FormHelperText key={error} id="component-helper-text">
                    {error}
                  </FormHelperText>
                ))}
              </FormControl>
            </div>
          )}
        </ProjectInformationContainer>
      </InputContainer>
      <Table
        headCells={headCells}
        renderCell={renderCell}
        data={props.tableProjects}
        withTopActions={false}
        handleDelete={onDeleteTableRow}
        handleEdit={enableProjectUpdate}
      />
    </div>
  );
};
