import { Row } from '../../common/styles/common.styles';
import styled from 'styled-components';

export const FilterContainer = styled.div`
  width: 300px;
  margin-right: 25px;
`;

export const MonthSelectorContainer = styled(Row)`
  justify-content: center;
`;
