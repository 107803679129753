import { combineReducers } from '@reduxjs/toolkit';
import { currentUserReducer } from './current-user/reducers/currentUser.reducer';
import { toasterReducer } from './toaster/reducers/toaster.reducers';
import auth from './auth';
import dynamicSelectorReducer from './dynamic-selector/reducer';
import templatesReducer from './templates/reducer';
import users from './users';
import version from './version/reducer';
import workspaces from './workspaces/reducer';

const rootReducer = combineReducers({
  auth,
  users,
  currentUser: currentUserReducer,
  toasters: toasterReducer,
  dynamicSelector: dynamicSelectorReducer,
  templates: templatesReducer,
  workspaces,
  version,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
