import { Grid } from '@material-ui/core';
import { MPMLiftPlot } from '../../../common/models/interfaces';
import React, { Suspense, lazy } from 'react';
const DynamicPlotlyChart = lazy(() => import('../../DynamicPlotlyChart'));

interface TLiftPlotProps {
  chart: MPMLiftPlot;
}

function LiftPlot({ chart }: TLiftPlotProps) {
  const { class_names, lift_thresholds, lift_response, lift_capture, pop_response } = chart;

  if (!chart || !class_names || !lift_thresholds || !lift_response || !lift_capture || !pop_response) return null;

  return (
    <Grid container spacing={2}>
      <Suspense fallback={<>Loading...</>}>
        {class_names.map((class_name, nameIndex) => {
          return (
            <Grid item key={nameIndex} xs={12} lg={6} xl={4}>
              <DynamicPlotlyChart
                data={[
                  {
                    x: lift_thresholds[nameIndex],
                    y: lift_response[nameIndex],
                    mode: 'lines',
                    name: 'Precision',
                  },
                  {
                    x: lift_thresholds[nameIndex],
                    y: lift_capture[nameIndex],
                    mode: 'lines',
                    name: 'Recall',
                  },
                  {
                    x: lift_thresholds[nameIndex],
                    y: Array.from({ length: lift_thresholds[nameIndex].length }, () => pop_response[nameIndex]),
                    mode: 'lines',
                    name: 'Pop. precision',
                    line: {
                      dash: 'dot',
                    },
                  },
                ]}
                layout={{
                  xaxis: {
                    title: 'Percentage taken',
                    constrain: 'domain',
                  },
                  yaxis: {
                    title: 'Precision / Recall rate',
                    scaleanchor: 'x',
                    scaleratio: 1,
                  },
                  height: 380,
                  autosize: true,
                  margin: { t: 40, r: 0, b: 40, l: 40 },
                  showlegend: true,
                  legend: {
                    x: 1,
                    xanchor: 'auto',
                    y: 1,
                  },
                  title: `Eval lift ${class_names.length > 1 ? class_name : ''}`,
                }}
                config={{ displaylogo: false, modeBarButtons: false, responsive: false }}
              />
            </Grid>
          );
        })}
      </Suspense>
    </Grid>
  );
}

export default LiftPlot;
