import { Column, Row } from '../../../../common/styles/common.styles';
import styled from 'styled-components';
import theme from '../../../../theme';

export const WorkspaceDetailsContainer = styled(Column)`
  background: ${(p) => p.theme.colors.white};
  height: 100%;
`;

export const CardContainer = styled(Row)`
  margin-bottom: 16px;
  & .card {
    box-shadow: 0 2.1px 3px rgb(0 0 0 / 10%), 0px 3px 13px -8px rgb(0 0 0 / 22%);
    width: 100%;
    & .card-content {
      display: flex;
      padding-bottom: 16px;
      & .icon-container {
      }
    }
    & .approval-row {
      margin-bottom: 15px;
      & p {
        font-size: 14px;
        color: ${(p) => p.theme.colors.primary};
      }
      & .approval-row-actions {
        justify-content: flex-end;
      }
      & .approval-row-label {
        font-weight: bold;
        margin-right: 15px;
      }
    }
    & .collapse-content {
      border-top: 1px solid rgba(2, 57, 77, 0.38);
    }
  }
`;

export const ArrowContainer = styled.div`
  margin-left: 13px;
  & svg {
    vertical-align: bottom;
  }
`;

export const CardLeftContent = styled(Row)`
  align-items: center;
  & .button-container {
    margin-top: 25px;
  }
  & .title {
    font-size: 20px;
    margin-bottom: 0px;
    margin-left: 15px;
  }
  & .status-circle {
    height: 40px;
    width: 40px;
    background-color: gray;
    border-radius: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    &.pending {
      background-color: rgba(2, 57, 77, 0.38);
    }
    &.running {
      background-color: #56ccf2;
    }
    &.finished {
      background-color: #36d083;
    }
    &.error {
      background-color: #fd8f82;
    }
  }
`;

export const CardRightContent = styled(Row)`
  flex: 1;
`;

export const CardTitleDetailRow = styled(Row)`
  margin-top: 2px;
  margin-left: 7px;
  & p {
    margin: 0px;

    font-size: 14px;
    color: rgba(5, 33, 32, 0.6);
    & span {
      margin-left: 10px;
      color: rgba(5, 33, 32, 0.6);
    }
  }
  & .separator {
    margin-right: 6px;
    margin-left: 6px;
    color: rgba(5, 33, 32, 0.6);
  }
`;

export const Status = styled.div`
  width: auto;
  height: auto;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 10px;
  padding-right: 10px;
  color: white;
  font-size: 14px;
  border-radius: 2px;
  &.running {
    background-color: ${(p) => theme.colors.plain};
  }
  &.finished {
    background-color: ${(p) => theme.colors.success};
  }
`;

export const LogContainer = styled(Row)`
  background: black;
  color: white;
  height: 250px;
  overflow: auto;
`;

export const LogRow = styled(Row)`
  flex-direction: column;
  padding: 15px;
  & p {
    color: #d9d9d9;
    font-family: monospace;
    font-size: 14px;
    display: block;
    margin-top: 0;
  }
`;
