import * as S from './styles';
import { Chip } from '@material-ui/core';
import { TemplateCategory } from '../../common/models/interfaces';
import { urls } from '../../common/utils/urls';
import CheckIcon from '@material-ui/icons/Check';
import React, { useCallback, useEffect, useState } from 'react';
import api from '../../common/utils/api';

interface TCategoryFilterProps {
  onChangeAction: React.Dispatch<React.SetStateAction<TemplateCategory[] | undefined>>;
  selectAllByDefault?: boolean;
  selectedCategoriesByDefault?: TemplateCategory[];
}

const CategoryFilter: React.FC<TCategoryFilterProps> = ({
  onChangeAction,
  selectAllByDefault,
  selectedCategoriesByDefault,
}) => {
  const [categories, setCategories] = useState<TemplateCategory[]>();
  const [selectedCategories, setSelectedCategories] = useState<TemplateCategory[]>();

  const fetchCategories = async () => {
    try {
      const categoriesData = await api.get<TemplateCategory[]>(urls.fetchTemplateCategories());

      if (categoriesData) {
        setCategories(categoriesData);
        selectAllByDefault && setSelectedCategories(categoriesData);
      }
    } catch (e) {
      console.log('CategoryFilter-->fetchCategories', e);
    }
  };

  const handleFetchCategories = useCallback(fetchCategories, [selectAllByDefault]);

  useEffect(() => {
    handleFetchCategories();
  }, [handleFetchCategories]);

  const resolveIcon = (categoryId: string) => {
    const isSelectedCategory = selectedCategories && selectedCategories.find((category) => category.id === categoryId);

    if (selectedCategories && isSelectedCategory) return <CheckIcon />;
    return;
  };

  const onClickCategory = (category: TemplateCategory) => {
    const hasAlreadySelectedCategory =
      selectedCategories && selectedCategories.find((selectedCategory) => selectedCategory.id === category.id);

    if (hasAlreadySelectedCategory && selectedCategories) {
      setSelectedCategories([...selectedCategories.filter((selectedCategory) => selectedCategory.id !== category.id)]);
    } else {
      if (selectedCategories) {
        setSelectedCategories([...selectedCategories, category]);
      } else {
        setSelectedCategories([category]);
      }
    }
  };

  useEffect(() => {
    onChangeAction(selectedCategories);
  }, [selectedCategories, onChangeAction]);

  useEffect(() => {
    setSelectedCategories(selectedCategoriesByDefault);
  }, [selectedCategoriesByDefault]);

  return (
    <S.FilterWrapper>
      {categories &&
        categories.map((category) => (
          <Chip
            key={category.id}
            variant="outlined"
            icon={resolveIcon(category.id)}
            label={category.displayName}
            onClick={() => onClickCategory(category)}
          />
        ))}
    </S.FilterWrapper>
  );
};

export default CategoryFilter;
