import {
  ApprovalWorkflowStatus,
  GitSettings,
  Project,
  ProjectConfigration,
  Provision,
} from '../../common/models/workspace.models';
import { Autocomplete } from '@material-ui/lab';
import { Button, Card, Chip, FormControlLabel, Switch, TextField } from '@material-ui/core';
import { Cancel } from '../../assets';
import { CardContent, Column, HalfCardContainer, InformationContainer, Label } from '../../common/styles/common.styles';
import { ChipContainer, HeadLabel } from './configuration.styles';
import { ToastType } from '../../common/models/enums';
import { createToasterNotification } from '../../common/utils/toaster-notification';
import { getWorkspaceById } from '../../store/workspaces/actions';
import { urls } from '../../common/utils/urls';
import { useDispatch } from 'react-redux';
import { useSelector } from '../../store/configure-store';
import { validateEmails } from '../../common/utils/validations';
import ChipInput from 'material-ui-chip-input';
import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import api from '../../common/utils/api';
import withResources from '../../components/withResources/withResources';

const ConfigurationComp: React.FC = () => {
  const selectedResource = useSelector((state) => state.dynamicSelector.selectedResource);
  const workspace = useSelector((state) => state.workspaces.selectedWorkspace);
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);
  const [approvers, setApprovers] = useState<string[]>([]);
  const [approvalWorkflowEnabled, setApprovalWorkflowEnabled] = useState<boolean>(false);
  const [gitBranch, setGitBranch] = useState<string>('');
  const [hasInvalidEmail, setHasInvalidEmail] = useState<boolean>(false);
  const dispatch = useDispatch();

  const onSelectProject = (project: Project | null) => {
    setSelectedProject(project);
    setApprovers(project?.approvers ? project.approvers : []);
    setApprovalWorkflowEnabled(project?.approvalWorkflowEnabled ? project.approvalWorkflowEnabled : false);
    setGitBranch(project?.provisioningBranch ? project.provisioningBranch : '');
  };

  const handleAddChip = (approver: string) => {
    const approversCopy = approvers.map((x) => x);
    approversCopy.push(approver);
    setApprovers(approversCopy);
  };

  const handleDeleteChip = (approver: string, index: number) => {
    const approversCopy = approvers.map((x) => x);
    approversCopy.splice(index, 1);
    setApprovers(approversCopy);
  };

  const handleChange = (event: SyntheticEvent) => {
    setApprovalWorkflowEnabled(event.target[`checked`]);
  };

  const changeApprovalWorkflow = () => {
    const approvalWorkflowStatus: ApprovalWorkflowStatus = {
      enabled: approvalWorkflowEnabled,
      emails: approvers,
    };

    const gitSettings: GitSettings = {
      provisioningBranch: gitBranch,
    };

    const payload: ProjectConfigration = {
      approvalWorkflowStatus,
      gitSettings,
    };

    api
      .post<Provision[]>(urls.changeProjectConfiguration(workspace?.id || '', selectedProject?.projectId), payload)
      .then(() => {
        dispatch(createToasterNotification('Configuration has been successfully saved', ToastType.SUCCESS));
        workspace?.projects
          .filter((value: Project) => {
            return value.projectId === selectedProject?.projectId;
          })
          .forEach((value) => {
            value.approvers = approvers;
            value.approvalWorkflowEnabled = approvalWorkflowEnabled;
          });
      })
      .catch((e) => {
        dispatch(createToasterNotification(e));
      });
  };

  const handleInvalidApproversCallback = useCallback(() => {
    setHasInvalidEmail(false);
    approvers.forEach((value) => {
      if (!validateEmails(value)) {
        setHasInvalidEmail(true);
      }
    });
  }, [approvers]);

  useEffect(() => {
    if (approvers) {
      handleInvalidApproversCallback();
    }
  }, [approvers, handleInvalidApproversCallback]);

  useEffect(() => {
    selectedResource && dispatch(getWorkspaceById(selectedResource.id));
  }, [selectedResource, dispatch]);

  return (
    <HalfCardContainer>
      <Card>
        <CardContent>
          <InformationContainer>
            <HeadLabel>Provisioning</HeadLabel>
            <Label>Project</Label>
            <Autocomplete
              options={workspace?.projects ?? []}
              renderInput={(params) => <TextField {...params} label="Select project" variant="outlined" fullWidth />}
              getOptionLabel={(option) => option.name}
              onChange={(event, project) => onSelectProject(project ?? null)}
              style={{ width: '100%', maxWidth: 400, marginBottom: '20px' }}
              size="small"
            />
            <Label>Approval</Label>
            <Column>
              <FormControlLabel
                control={
                  <Switch
                    disabled={!selectedProject}
                    checked={approvalWorkflowEnabled}
                    onChange={handleChange}
                    name="Approval workflow"
                    color="primary"
                  />
                }
                label="Approval workflow"
                style={{ marginBottom: '20px' }}
              />
              <ChipContainer>
                <ChipInput
                  disabled={!selectedProject || !approvalWorkflowEnabled}
                  variant={'outlined'}
                  label={'Approvers'}
                  value={approvers}
                  onAdd={(chip) => handleAddChip(chip)}
                  error={hasInvalidEmail}
                  helperText={hasInvalidEmail ? 'Invalid email adress' : ''}
                  onDelete={(chip, index) => handleDeleteChip(chip, index)}
                  style={{ width: '100%', maxWidth: 700, marginBottom: '30px' }}
                  chipRenderer={(chipProps) => {
                    return (
                      <Chip
                        key={chipProps.chip}
                        deleteIcon={<Cancel />}
                        onDelete={chipProps.handleDelete}
                        className={validateEmails(chipProps.text) ? 'chip valid-chip' : 'chip invalid-chip'}
                        label={chipProps.text}
                      />
                    );
                  }}
                />
              </ChipContainer>
            </Column>
            <Column>
              <Label>Git branch</Label>
              <TextField
                style={{ marginBottom: '30px' }}
                disabled={!selectedProject}
                variant="outlined"
                color="primary"
                size="small"
                value={gitBranch}
                onChange={(e) => setGitBranch(e.target.value)}
              />
            </Column>
            <Button
              variant="contained"
              color="primary"
              disabled={
                !gitBranch ||
                !selectedProject ||
                (approvalWorkflowEnabled && (hasInvalidEmail || approvers.length === 0))
              }
              onClick={() => changeApprovalWorkflow()}
            >
              Save changes
            </Button>
          </InformationContainer>
        </CardContent>
      </Card>
    </HalfCardContainer>
  );
};

export const Configuration = withResources(ConfigurationComp);
