import { ApiRequestMeta } from '../../../common/models/interfaces';
import { AppThunk } from '../../configure-store';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Role } from '../models/me.models';
import { User, UserStatus } from '../../users/models';
import { createToasterNotification } from '../../../common/utils/toaster-notification';
import { urls } from '../../../common/utils/urls';
import api from '../../../common/utils/api';
import axios from 'axios';

export interface CurrentUserState {
  loadMeta: ApiRequestMeta;
  me: User;
  roles: Role[];
}

const initialState: CurrentUserState = {
  loadMeta: {
    isLoading: false,
    isError: false,
    error: null,
  },
  me: {
    id: '',
    email: '',
    name: '',
    domain: '',
    picture: '',
    status: UserStatus.EMPTY,
  },
  roles: [],
};

const currentUserSlice = createSlice({
  name: 'currentUser',
  initialState,
  reducers: {
    setApiRequestPending(state) {
      state.loadMeta.isLoading = true;
      state.loadMeta.isError = false;
      state.loadMeta.error = null;
    },
    setApiRequestSuccess(state) {
      state.loadMeta.isLoading = false;
    },
    setApiRequestFailed(state, action: PayloadAction<string>) {
      state.loadMeta.isLoading = false;
      state.loadMeta.isError = true;
      state.loadMeta.error = action.payload;
    },
    setMe(state, action: PayloadAction<User>) {
      state.me = action.payload;
    },
    setRoles(state, action: PayloadAction<Role[]>) {
      state.roles = [...action.payload];
    },
    clearMeState(state) {
      state = initialState;
    },
  },
});

export const {
  setApiRequestPending,
  setApiRequestSuccess,
  setApiRequestFailed,
  setMe,
  setRoles,
  clearMeState,
} = currentUserSlice.actions;

export const fetchMe = (): AppThunk => async (dispatch) => {
  try {
    dispatch(setApiRequestPending());
    const me = await api.get<User>(urls.me).then((data) => data);
    dispatch(setMe(me));
    dispatch(setApiRequestSuccess());
  } catch (e) {
    if (axios.isAxiosError(e)) {
      dispatch(setApiRequestFailed(e.message));
      dispatch(createToasterNotification(e));
    }
  }
};

export const currentUserReducer = currentUserSlice.reducer;
