import { ManagedServiceTypes } from '../../common/models/enums';
import { Principal } from '../../common/models/interfaces';
import { urls } from '../../common/utils/urls';
import api, { ValidationError } from '../../common/utils/api';

function resolveURLByType(type: string, method: string, body?: { principal: string }) {
  switch (type) {
    case ManagedServiceTypes.MLFLOW:
      if (method === 'get') {
        return (domainId: string, instanceId: string) =>
          api[method]<{ principals: Principal[] }>(urls.getMlflowPrincipals(domainId, instanceId));
      } else if (method === 'del') {
        return (domainId: string, instanceId: string) =>
          api[method]<{ principals: Principal[] }>(urls.getMlflowPrincipals(domainId, instanceId), { data: body });
      }
      return () => undefined;
    case ManagedServiceTypes.KUBEFLOW:
      if (method === 'get') {
        return (domainId: string, instanceId: string) =>
          api[method]<{ principals: Principal[] }>(urls.getKubeflowPrincipals(domainId, instanceId));
      } else if (method === 'delete') {
        return (domainId: string, instanceId: string) =>
          api[method]<{ principals: Principal[] }>(urls.getKubeflowPrincipals(domainId, instanceId), body);
      }
      return () => undefined;
    default:
      return () => undefined;
  }
}

async function getPrincipals(
  type: string,
  domainId: string,
  instanceId: string,
  setter?: React.Dispatch<React.SetStateAction<Principal[] | undefined>>,
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>,
  onError?: (e: ValidationError | unknown) => void,
) {
  try {
    if (setLoading) setLoading(true);
    const principals = await resolveURLByType(type, 'get')(domainId, instanceId);
    if (setter && principals) {
      setter(principals.principals.map((principal: Principal) => ({ ...principal, id: principal.principal })));
    }
    if (setLoading) setLoading(false);
  } catch (e) {
    if (setLoading) setLoading(false);
    if (onError) onError(e);
    console.error('Error in getPrincipals!', e);
  }
}

async function deletePrincipal(
  type: string,
  domainId: string,
  instanceId: string,
  principal: string,
  setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>,
  onSucces?: () => void,
  onError?: (e: ValidationError | unknown) => void,
) {
  try {
    setIsLoading && setIsLoading(true);
    await resolveURLByType(type, 'del', { principal })(domainId, instanceId);
    onSucces && onSucces();
    setIsLoading && setIsLoading(false);
  } catch (e) {
    setIsLoading && setIsLoading(false);
    onError && onError(e);
    console.error('Error in deletePrincipal!', e);
  }
}

export { getPrincipals, deletePrincipal };
