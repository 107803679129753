import styled from 'styled-components';

export const CardVerticalRoot = styled.div`
  margin-bottom: 20px;
  & .card {
    display: flex;
    padding: 8px;
    background-color: #fcfcfc;
    box-shadow: none;
    & .card-details {
      display: flex;
      flex-direction: column;
    }
    & .content {
      padding-top: 0px;
      & .description {
        margin-top: 10px;
      }
    }
    & .media {
      height: 188px;
      width: 204px;
      background-size: auto;
      background-color: white;
      position: relative;
    }
  }
`;
