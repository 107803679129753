import { EnvironmentType } from '../models/enums';

export const environments: { name: string; value: EnvironmentType }[] = [
  {
    name: 'Development',
    value: EnvironmentType.DEVELOP,
  },
  {
    name: 'Test',
    value: EnvironmentType.TEST,
  },
  {
    name: 'Staging',
    value: EnvironmentType.STATING,
  },
  {
    name: 'UAT',
    value: EnvironmentType.UAT,
  },
  {
    name: 'Production',
    value: EnvironmentType.PRODUCTION,
  },
];
