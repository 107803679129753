import { FormControl, InputLabel, MenuItem, Select, Typography } from '@material-ui/core';
import { MPMHistoryChartBase } from 'src/common/models/interfaces';
import React, { Suspense, lazy, useEffect, useState } from 'react';
const DynamicPlotlyChart = lazy(() => import('../../DynamicPlotlyChart'));

interface LiftHistoryProps {
  chart: MPMHistoryChartBase[];
}

function LiftHistory({ chart }: LiftHistoryProps) {
  const [thresholds, setThresholds] = useState<number[]>();
  const [selectedThresholdIndex, setSelectedThresholdIndex] = useState<number>();

  useEffect(() => {
    if (chart && chart.length) {
      const thresholdsArrays = chart[0]?.plotDataItems?.lift_thresholds;
      const thresholdsData = thresholdsArrays && thresholdsArrays.length ? thresholdsArrays[0] : null;
      thresholdsData && setThresholds(thresholdsData);
    }
  }, [chart]);

  useEffect(() => {
    if (thresholds && thresholds.length) {
      setSelectedThresholdIndex(0);
    }
  }, [thresholds]);

  if (!chart || !chart?.length) return null;

  const renderThresholds = (threshold: number, index: number) => {
    return (
      <MenuItem value={index} key={index}>
        {parseFloat((threshold * 100).toString()).toFixed()}%
      </MenuItem>
    );
  };

  const dates = chart?.map((chartItem) => chartItem.date);
  const class_names = chart?.[0]?.classNames;
  const finalData: { x: string[]; y: number[]; name: string }[] = [];

  class_names?.forEach((className, classNameIndex) => {
    const lift_responses: number[] = [];
    const lift_captures: number[] = [];

    chart.forEach((chartItem) => {
      if (typeof selectedThresholdIndex === 'number' && Boolean(selectedThresholdIndex.toString())) {
        lift_responses.push(chartItem?.plotDataItems?.lift_response[classNameIndex][selectedThresholdIndex]);
        lift_captures.push(chartItem?.plotDataItems?.lift_capture[classNameIndex][selectedThresholdIndex]);
      }
    });

    finalData.push({
      x: dates,
      y: lift_responses,
      name: `${class_names.length > 1 ? className + ' / ' : ''}Lift response`,
    });
    finalData.push({
      x: dates,
      y: lift_captures,
      name: `${class_names.length > 1 ? className + ' / ' : ''}Lift capture`,
    });
  });

  return (
    <>
      <Typography variant="h6" gutterBottom>
        History
      </Typography>
      {thresholds && (
        <FormControl style={{ width: '150px' }}>
          <InputLabel id="selected-threshold" shrink>
            Threshold
          </InputLabel>
          <Select
            labelId="selected-threshold"
            value={selectedThresholdIndex || 0}
            onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
              setSelectedThresholdIndex(parseInt(e.target.value as string));
            }}
          >
            {thresholds.map(renderThresholds)}
          </Select>
        </FormControl>
      )}
      <Suspense fallback={<div>Loading...</div>}>
        {finalData && (
          <DynamicPlotlyChart
            data={finalData}
            layout={{
              height: 380,
              autosize: true,
              margin: { t: 40, r: 0, b: 40, l: 40 },
              showlegend: true,
            }}
            config={{ displaylogo: false, modeBarButtons: false, responsive: false }}
          />
        )}
      </Suspense>
    </>
  );
}

export default LiftHistory;
