import { Card } from '@material-ui/core';
import { CardContainer } from '../../common/styles/common.styles';
import { DetailedWorkspace, Project } from '../../common/models/workspace.models';
import { EnvironmentType, ToastType } from '../../common/models/enums';
import { InformationContainer } from './gcp-projects.styles';
import { ProjectToWorkspaceAdder } from '../../components/project-to-workspace-adder/project-to-workspace-adder';
import { createToasterNotification } from '../../common/utils/toaster-notification';
import { getWorkspaceById } from '../../store/workspaces/actions';
import { urls } from '../../common/utils/urls';
import { useDispatch } from 'react-redux';
import { useSelector } from '../../store/configure-store';
import React, { useCallback, useEffect, useState } from 'react';
import api from '../../common/utils/api';
import withResources from '../../components/withResources/withResources';

export const GcpProjectsComp: React.FC = () => {
  const workspaceDetails = useSelector((state) => state.workspaces.selectedWorkspace);
  const [projects, setProjects] = useState<Project[]>(workspaceDetails?.projects ?? []);
  const dispatch = useDispatch();
  const selectedResource = useSelector((state) => state.dynamicSelector.selectedResource);
  const domainId = selectedResource?.domainId || '';

  const [selectableProjects, setSelectableProjects] = useState<Project[]>();

  useEffect(() => {
    setProjects(workspaceDetails?.projects ?? []);
  }, [workspaceDetails]);

  const handleFetchSelectableProjects = useCallback(async () => {
    try {
      const projectsData = await api.get<Project[]>(urls.projects(domainId || ''), {
        params: { onlyUnallocated: true },
      });
      if (projectsData) setSelectableProjects(projectsData);
    } catch (e) {
      console.log('gcp-projects-->fetchSelectableProjects', e);
    }
  }, [domainId]);

  useEffect(() => {
    handleFetchSelectableProjects();
  }, [handleFetchSelectableProjects]);

  const projectsWithIds =
    projects.map((project) => ({
      ...project,
      id: project?.projectId,
    })) || [];

  const onUpdateProject = (environmentType: EnvironmentType, item: Project) => {
    const updatedProjects = projects.map((project) => {
      if (project.projectId === item.projectId) {
        return {
          ...project,
          environmentType,
        };
      }
      return project;
    });

    onSave(updatedProjects);
  };

  const handleProjectDelete = (project: Project) => {
    const updatedProjects = projects.filter((pro) => pro.projectId !== project.projectId);
    setProjects(updatedProjects);
    onSave(updatedProjects);
  };

  const onSave = async (updatedProjects: Project | Project[]) => {
    if (!Array.isArray(updatedProjects) && updatedProjects.projectNumber === '') {
      await api
        .post<Project>(urls.projectCreate(domainId, updatedProjects.projectId))
        .then((p: Project) => {
          p.environmentType = updatedProjects.environmentType;
          saveProjectToWorkspace(p);
        })
        .catch((e) => {
          dispatch(createToasterNotification(e));
        });
    } else {
      await saveProjectToWorkspace(updatedProjects);
    }
  };

  const saveProjectToWorkspace = async (updatedProjects: Project | Project[]) => {
    if (workspaceDetails) {
      const payload = {
        id: workspaceDetails?.id,
        projects: !Array.isArray(updatedProjects) ? [...workspaceDetails.projects, updatedProjects] : updatedProjects,
      };

      await api
        .post<DetailedWorkspace>(urls.workspaceProject(selectedResource?.id || ''), payload)
        .then(() => {
          dispatch(createToasterNotification('Successfully updated the workspace', ToastType.SUCCESS));
          handleFetchSelectableProjects();
          dispatch(getWorkspaceById(selectedResource?.id || ''));
        })
        .catch((e) => {
          dispatch(createToasterNotification(e));
        });
    }
  };

  useEffect(() => {
    selectedResource && dispatch(getWorkspaceById(selectedResource.id));
  }, [selectedResource, dispatch]);

  return (
    <CardContainer>
      <Card>
        <InformationContainer>
          <ProjectToWorkspaceAdder
            workspaceProjects={workspaceDetails?.projects ?? []}
            selectableProjects={selectableProjects ?? []}
            onSave={onSave}
            tableProjects={projectsWithIds}
            tableOnEdit={onUpdateProject}
            tableOnDelete={handleProjectDelete}
          />
        </InformationContainer>
      </Card>
    </CardContainer>
  );
};

export const GcpProjects = withResources(GcpProjectsComp);
