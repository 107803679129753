import { Column, Row } from '../../../../common/styles/common.styles';
import { Link } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import styled from 'styled-components';

export const WorkspaceDetailsContainer = styled(Column)`
  background: ${(p) => p.theme.colors.white};
  height: 100%;
`;

export const LogContainer = styled(Row)`
  background: black;
  color: white;
  height: 250px;
  overflow: auto;
`;

export const LogRow = styled(Row)`
  flex-direction: column;
  padding: 15px;
  & p {
    color: #d9d9d9;
    font-family: monospace;
    font-size: 14px;
    display: block;
    margin-top: 0;
  }
`;

export const OutputRow = styled.div`
  border: 1px solid hsl(0deg, 0%, 94%);
  padding: 4px;
  display: grid;
  grid-gap: 12px;
  grid-template-columns: 30% auto;
  align-items: center;
  transition: all 0.4s linear;
  & + & {
    margin-top: 8px;
  }
  &:hover {
    background-color: hsl(0deg, 0%, 96%);
  }
`;

export const OutputLink = styled(Link)`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  && {
    color: #0f698a;
  }
`;
export const OutputLinkIcon = styled(OpenInNewIcon)`
  margin-left: 4px;
`;
