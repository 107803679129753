import { Autocomplete } from '@material-ui/lab';
import { Budget } from '../../common/models/workspace.models';
import { Card, TextField } from '@material-ui/core';
import { CardContainer, CardContent, Margin, Row, Title } from '../../common/styles/common.styles';
import { FilterContainer, MonthSelectorContainer } from './reports.style';
import { MonthSelector } from '../../components/month-selector/month-selector';
import { MonthlyCostReport } from './monthly-cost-report/monthly-cost-report';
import { ReportsQueryParam } from '../../common/models/interfaces';
import { createToasterNotification } from '../../common/utils/toaster-notification';
import { getWorkspaceById } from '../../store/workspaces/actions';
import { setQueryParamForReports } from '../../common/utils/date-formatter';
import { urls } from '../../common/utils/urls';
import { useDispatch } from 'react-redux';
import { useQuery } from 'react-query';
import { useSelector } from '../../store/configure-store';
import React, { useCallback, useEffect, useState } from 'react';
import api from '../../common/utils/api';
import withResources from '../../components/withResources/withResources';

const ReportsComponent: React.FC = () => {
  const selectedResource = useSelector((state) => state.dynamicSelector.selectedResource);
  const workspace = useSelector((state) => state.workspaces.selectedWorkspace);

  const [budget, setBudget] = useState<Budget | null>(null);
  const [queryParam, setQueryParam] = useState<ReportsQueryParam>(setQueryParamForReports(new Date(), 1));
  const dispatch = useDispatch();

  const dailySpendingQuery = useQuery(
    `${selectedResource?.id}-daily-spending-${queryParam.from}-${queryParam.until}`,
    () => {
      return api.get<number[]>(urls.getDailySpending(selectedResource?.id || ''), {
        params: {
          from: queryParam.from,
          until: queryParam.until,
        },
      });
    },
    { enabled: false },
  );

  const cumulativeDailySpendingQuery = useQuery(
    `${selectedResource?.id}-${budget?.budgetId}-cumulative-daily-spending-${queryParam.from}-${queryParam.until}`,
    () => {
      return api.get<number[]>(urls.getCumulativeDailySpending(selectedResource?.id || '', budget?.budgetId ?? ''), {
        params: {
          from: queryParam.from,
          until: queryParam.until,
        },
      });
    },
    { enabled: false },
  );

  const handleDailySpendingFetch = useCallback(dailySpendingQuery.refetch, []);
  const handleCumulativeDailySpendingFetch = useCallback(cumulativeDailySpendingQuery.refetch, []);

  useEffect(() => {
    if (selectedResource && selectedResource.id) {
      setBudget(null);
    }
  }, [selectedResource]);

  useEffect(() => {
    if (queryParam.from && queryParam.until) {
      if (selectedResource?.id && budget?.budgetId) {
        handleCumulativeDailySpendingFetch();
      } else if (selectedResource?.id) {
        handleDailySpendingFetch();
      }
    }
  }, [selectedResource, budget, queryParam, handleCumulativeDailySpendingFetch, handleDailySpendingFetch]);

  useEffect(() => {
    let error;
    if (dailySpendingQuery.error) {
      error = dailySpendingQuery.error;
    } else if (cumulativeDailySpendingQuery.error) {
      error = cumulativeDailySpendingQuery.error;
    }

    if (error) {
      dispatch(createToasterNotification(error));
    }
  }, [dailySpendingQuery.error, cumulativeDailySpendingQuery.error, dispatch]);

  const onSelectBudget = (selectedBudget: Budget | null) => {
    setBudget(selectedBudget);
  };

  const getSelectedDate = (reportsQueryParam: ReportsQueryParam) => {
    setQueryParam(reportsQueryParam);
  };

  const passDataToChart = (): number[] => {
    if (budget?.budgetId) {
      return cumulativeDailySpendingQuery.data ?? [];
    }
    if (selectedResource?.id) {
      return dailySpendingQuery.data ?? [];
    }

    return [];
  };

  useEffect(() => {
    selectedResource && dispatch(getWorkspaceById(selectedResource.id));
  }, [selectedResource, dispatch]);

  return (
    <>
      <CardContainer>
        <Card>
          <CardContent>
            <Margin bottom={25}>
              <Title>Workspace and filters</Title>
            </Margin>
            <Row>
              <FilterContainer>
                <Autocomplete
                  options={selectedResource?.id ? workspace?.budgets ?? [] : []}
                  renderInput={(params) => <TextField {...params} label="Budget" variant="outlined" fullWidth />}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, selectedBudget) => onSelectBudget(selectedBudget)}
                  value={budget}
                  disabled={!selectedResource?.id}
                  fullWidth
                  size="small"
                />
              </FilterContainer>
            </Row>
          </CardContent>
        </Card>
      </CardContainer>
      <CardContainer>
        <Card>
          <CardContent>
            <Margin bottom={25}>
              <Title>Monthly cost report</Title>
            </Margin>
            <MonthSelectorContainer>
              <Margin bottom={50}>
                <MonthSelector onSelectDate={getSelectedDate} />
              </Margin>
            </MonthSelectorContainer>
            <MonthlyCostReport
              data={passDataToChart()}
              budgetLimit={budget?.budgetLimit ?? null}
              isLoading={dailySpendingQuery.isLoading || cumulativeDailySpendingQuery.isLoading}
            />
          </CardContent>
        </Card>
      </CardContainer>
    </>
  );
};

export const Reports = withResources(ReportsComponent);
