import { DefaultTheme } from 'styled-components';

const drawerWidth = 265;
const appbarHeight = 72;

const colors = {
  primary: '#02394D',
  black: '#052120',
  success: '#36D083',
  danger: '#E5002A',
  plain: '#56ccf2',
  othersError: '#E25241',
  warning: '#F7C220',
  header: '#252733',
  page: '#fafafa',
  white: '#fff',
  darkGreen: '#052120',
  transparentDarkGreen: 'rgba(5, 33, 32, 0.6)',
  transparentGrey: 'rgba(5, 33, 32, 0.12)',
};

const theme: DefaultTheme = {
  colors,
  drawerWidth,
  appbarHeight,
};

export default theme;
