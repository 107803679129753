import { Container, UserAvatar, UserEmail, UserName, useUserAccountMenuStyles } from './styles';
import { GoogleLoginPayload } from '../../store/auth/models';
import { Links } from '../../common/styles/common.styles';
import { Menu, MenuProps } from '@material-ui/core';
import { path } from '../../routes/path';
import LogoutButton from '../LogoutButton';
import React from 'react';

interface IProps {
  anchorEl: MenuProps['anchorEl'];
  onClose: MenuProps['onClose'];
  profile: NonNullable<GoogleLoginPayload>['profile'];
}

const UserAccountMenu = ({ anchorEl, onClose, profile }: IProps) => {
  const classes = useUserAccountMenuStyles();

  return (
    <>
      <Menu
        classes={{ paper: classes.menu }}
        anchorEl={anchorEl}
        keepMounted={true}
        open={Boolean(anchorEl)}
        onClose={onClose}
      >
        <Container border={true}>
          <UserAvatar src={profile.imageUrl} />
          <UserName>{profile.name}</UserName>
          <UserEmail>{profile.email}</UserEmail>
        </Container>
        <Container>
          <Links style={{ marginBottom: '12px', textDecoration: 'none' }} href={path.settings()}>
            Settings
          </Links>
          <LogoutButton />
        </Container>
      </Menu>
    </>
  );
};

export default UserAccountMenu;
