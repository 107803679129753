import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const NavigationLinkContainer = styled.div<{ isActive: boolean }>`
  margin: 0 7px;
  border-radius: 4px;
  ${(p) => p.isActive && `background: rgba(2, 57, 77, 0.12)`};
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;
