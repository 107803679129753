import { ChartTitle } from '../../common/styles/common.styles';
import { ModelSum } from '../../common/models/model.model';
import React, { Suspense, lazy } from 'react';
const DynamicPlotlyChart = lazy(() => import('../DynamicPlotlyChart'));

interface TPredictionsSumChartProps {
  data: ModelSum;
}

const PredictionsSumChart: React.FC<TPredictionsSumChartProps> = ({ data }) => {
  return (
    <div>
      <ChartTitle>Predictions sum, Actual sum and Difference</ChartTitle>
      <Suspense fallback={<>Loading...</>}>
        <DynamicPlotlyChart
          data={[
            {
              x: data.actualSum.map((item) => item.xvalue),
              y: data.actualSum.map((item) => item.yvalue),
              type: 'bar',
              name: 'Actual',
              text: data.actualSum.map((item) => item.yvalue),
              textposition: 'auto',
            },
            {
              x: data.actualSum.map((item) => item.xvalue),
              y: data.predSum.map((item) => item.yvalue),
              type: 'bar',
              name: 'Prediction',
              text: data.predSum.map((item) => parseFloat(item.yvalue.toString()).toFixed(2)),
              textposition: 'auto',
            },
            {
              x: data.actualSum.map((item) => item.xvalue),
              y: data.diff.map((item) => Math.abs(item.yvalue)),
              type: 'bar',
              name: 'Difference',
              text: data.diff.map((item) => parseFloat(item.yvalue.toString()).toFixed(2)),
              textposition: 'auto',
            },
          ]}
          layout={{
            title: '',
            height: 380,
            autosize: true,
            margin: { t: 0, r: 0, b: 40, l: 40 },
            barmode: 'group',
            yaxis: {
              range: [0, 10],
            },
            xaxis: {
              range: data.actualSum.map((item) => item.xvalue).slice(0, 2),
            },
          }}
          config={{ displaylogo: false, modeBarButtons: false, responsive: true }}
        />
      </Suspense>
    </div>
  );
};

export default PredictionsSumChart;
