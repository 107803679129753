import * as S from './style';
import { Card, Typography } from '@material-ui/core';
import { CardContainer } from '../../common/styles/common.styles';
import React from 'react';
import RouteWithHeader from '../../containers/route-with-header/route-with-header';
import TourSettings from '../../components/TourSettings';

const Settings = () => {
  return (
    <RouteWithHeader title="Settings">
      <CardContainer>
        <Card>
          {/* Tour settings */}
          <S.SettingSection>
            <S.SectionTitle>Tour settings</S.SectionTitle>
            <Typography>You can change the tour setting here.</Typography>
            <TourSettings />
          </S.SettingSection>
          {/* Tour settings -- END */}
        </Card>
      </CardContainer>
    </RouteWithHeader>
  );
};

export default Settings;
