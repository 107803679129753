import { Row } from '../../common/styles/common.styles';
import styled from 'styled-components';

export const LoadingSpinnerContainer = styled(Row)`
  width: 100%;
  min-height: 400px;
  height: 100%;
  align-items: center;
  justify-content: center;
`;
