import { Checkbox } from '@material-ui/core';
import { DetailRow } from '../../deploy-blueprints/deploy-blueprints.styles';
import { DetailedWorkspace, Project, Repository } from '../../../common/models/workspace.models';
import { Dialog } from '../../../containers';
import { IDialogProps } from '../../../containers/Dialog';
import { Label } from '../../../common/styles/common.styles';
import { LoadingSpinner } from '../../../components/loading-spinner/loading-spinner';
import { LoadingSpinnerContainerWbg } from '../../deploy-blueprints/template-adder/template-adder.styles';
import { Table } from '../../../components';
import { TableHeadCell } from '../../../components/Table/TableHead';
import { urls } from '../../../common/utils/urls';
import { useDialogStyles } from './workspace-delete.dialog.style';
import { useQuery } from 'react-query';
import { useSelector } from '../../../store/configure-store';
import React, { useState } from 'react';
import api from '../../../common/utils/api';

type DialogPropNames = 'open' | 'title' | 'subtitle' | 'onCancel' | 'primaryButtonText';

interface IProps extends Pick<IDialogProps, DialogPropNames> {
  onDelete: (selectedProjects: string[], deleteRepository: boolean) => void;
  workspace: DetailedWorkspace | undefined;
  isLoading?: boolean;
}

export const WorkspaceDeleteDialog = ({ workspace, onDelete, isLoading, ...dialogProps }: IProps) => {
  const classes = useDialogStyles();
  const deleteInProgress = useSelector((state) => state.workspaces.deleteInProgress);
  const [deleteRepository, setDeleteRepository] = useState(false);
  const [selectedProjects, setSelectedProjects] = useState<string[]>([]);

  const repositoryQuery = useQuery(
    `repository-for-delete_${workspace?.id}`,
    () => {
      return api.get<Repository>(urls.getRepository(workspace ? workspace?.id : '')).then((data) => {
        return data;
      });
    },
    { enabled: workspace, refetchOnWindowFocus: false },
  );

  const handleDelete = () => {
    onDelete(selectedProjects, deleteRepository);
  };

  const onTableSelect = (selected: string[]) => {
    setSelectedProjects(selected);
  };

  const headCells: TableHeadCell<Project>[] = [
    { id: 'name', label: 'Project' },
    { id: 'projectId', label: 'ID' },
    { id: 'projectNumber', label: 'Number' },
    { id: 'environmentType', label: 'Environment' },
  ];

  const projectsWithIds = workspace
    ? workspace.projects.map((project) => ({
        ...project,
        id: project?.projectId,
      })) || []
    : [];

  return (
    <>
      <Dialog
        disabled={() => deleteInProgress}
        {...dialogProps}
        onPrimaryAction={() => handleDelete()}
        classes={{ paper: classes.dialog }}
        loading={isLoading}
      >
        {deleteInProgress && (
          <LoadingSpinnerContainerWbg style={{ top: 0, left: 0 }}>
            <LoadingSpinner />
          </LoadingSpinnerContainerWbg>
        )}
        {projectsWithIds && (
          <>
            <Label>Select project(s) to delete</Label>
            <Table
              singleSelect={false}
              withTopActions={false}
              select={true}
              headCells={headCells}
              data={projectsWithIds}
              onSelectionChanged={onTableSelect}
            />
          </>
        )}
        {!repositoryQuery.isFetching && repositoryQuery.data && (
          <>
            <Label style={{ marginTop: '30px' }}>Repository deletion</Label>
            <DetailRow>
              <p>Repository url:</p>
              <span>
                <a target="_blank" rel="noopener noreferrer" href={repositoryQuery.data?.repositoryUrl}>
                  {repositoryQuery.data?.repositoryUrl}
                </a>
              </span>
            </DetailRow>
            <div style={{ marginTop: '25px' }}>
              <Checkbox
                checked={deleteRepository}
                onClick={() => {
                  setDeleteRepository(!deleteRepository);
                }}
              />{' '}
              Delete repository
            </div>
          </>
        )}
      </Dialog>
    </>
  );
};
