import styled from 'styled-components';

const FilterWrapper = styled.div`
  margin: 0 24px;
  display: flex;
  flex-direction: row;
  gap: 14px;
`;

export { FilterWrapper };
