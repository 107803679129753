import { Code, Delete, Edit, Extension } from '@material-ui/icons';
import styled from 'styled-components';

const DeleteIcon = styled(Delete)`
  color: #ff1744;
  &:hover {
    cursor: pointer;
  }
`;

const ExtensionIcon = styled(Extension)`
  color: #2979ff;
  margin-right: 6px;
`;

const EditIcon = styled(Edit)`
  color: #333;
  margin-left: auto;
  margin-right: 12px;
  &:hover {
    cursor: pointer;
  }
`;

const CodeIcon = styled(Code)`
  color: #2979ff;
  margin-right: 6px;
`;

const Description = styled.div`
  text-align: left;
`;

const StepName = styled.span`
  text-align: left;
  font-weight: bold;
`;

export { DeleteIcon, ExtensionIcon, EditIcon, CodeIcon, Description, StepName };
