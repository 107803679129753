import MaterialDeleteIcon from '@material-ui/icons/Delete';
import styled from 'styled-components';

const BlueprintVersion = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
  background: #f9f9f9;
  padding: 15px 12px;
  & + & {
    margin-top: 25px;
  }
  border-radius: 5px;
`;

const ReleaseDateWrapper = styled.div`
  grid-column: span 3;
`;

const DeleteButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #02394d;
  position: absolute;
  right: -15px;
  top: -15px;
`;

const DeleteIcon = styled(MaterialDeleteIcon)`
  color: #ffffff;
`;

export { ReleaseDateWrapper, BlueprintVersion, DeleteButton, DeleteIcon };
