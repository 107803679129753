import { Row } from '../../common/styles/common.styles';
import images from '../../assets';
import styled from 'styled-components';

export const InformationContainer = styled.div`
  padding: 20px;
`;

export const InputContainer = styled.div`
  margin: 30px 0;

  &:first-of-type {
    margin-top: 0;
  }
`;

export const WorkspaceInformationContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
`;

export const ProjectInformationContainer = styled.div`
  display: grid;
  grid-template-columns: 300px 300px 200px;
  grid-column-gap: 20px;
`;

export const ErrorMessage = styled.div<{ isVisible?: boolean }>`
  font-size: 12px;
  line-height: 16px;
  margin-top: 4px;
  color: ${(p) => p.theme.colors.othersError};
  visibility: ${(p) => !p.isVisible && 'hidden'};
`;

export const SaveChangesContainer = styled(Row)`
  justify-content: flex-end;
  padding: 15px;
`;

export const TemplateAdder = styled.div`
  width: 100%;
`;

export const GeneratedRepositoryContainer = styled.div`
  margin-bottom: 25px;
`;

export const DetailRow = styled.div`
  margin-bottom: 5px;
  & p {
    margin: 0;
    font-size: 14px;
    display: inline-block;
  }
  & span {
    margin-left: 10px;
    font-size: 14px;
    display: inline-block;
    & a {
      color: ${(p) => p.theme.colors.primary};
    }
  }
`;

export const CardRoot = styled.div`
  display: inline-block;
  padding: 10px;
  margin-bottom: 10px;
  & .card {
    cursor: pointer;
    max-width: 172px;
    padding: 8px;
    background-color: #fcfcfc;
    border: 1px solid rgba(5, 33, 32, 0.12);
    box-shadow: none;
    height: 345px;
    box-sizing: border-box;
    &:hover {
      box-shadow: 0px 8px 14px rgb(0 0 0 / 7%), 1px 10px 11px -4px rgb(0 0 0 / 18%);
    }
    &.selected {
      box-shadow: 0px 0px 0px 4px #02394d inset;
      &:hover {
        box-shadow: 0px 0px 0px 4px #02394d inset, 0px 8px 14px rgb(0 0 0 / 7%), 1px 10px 11px -4px rgb(0 0 0 / 18%);
      }
    }

    & .content {
      padding: 10px;
      padding-left: 0;
      padding-right: 0;
      padding-top: 0;
      height: 130px;
      margin-bottom: 15px;
      & .title {
        height: 40px;
        margin-top: 5px;
        margin-bottom: 7px;
        overflow: hidden;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
      }
      & .description {
        height: 60px;
        overflow: hidden;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
      }
    }
    & .media {
      height: 137px;
      margin-bottom: 3px;
      border-radius: 4px;
      background-size: contain;
      background-color: white;
      position: relative;
      &.external {
        background-size: initial;
        background-position-y: 0px;
      }
    }

    & .actions {
      align-items: center;
      flex-direction: column;
    }
  }
`;

export const BikeImage = styled.img.attrs({ src: images.Bike })``;
export const NewInstanceImage = styled.img.attrs({ src: images.NewInstance })``;
export const SelectRadioButtonImage = styled.img.attrs({ src: images.SelectDarkRadioButton })``;
export const UnSelectRadioButtonImage = styled.img.attrs({ src: images.UnSelectRadioButton })``;
export const ProvidedByAlizImage = styled.img.attrs({ src: images.ProvidedByAliz })``;
export const ProvidedByAlizPicture = styled.img`
  border-radius: 0px 0px 4px 4px;
  width: 100%;
  position: absolute;
  bottom: 0px;
`;
