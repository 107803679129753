import { createContext } from 'react';

interface ModelPerformanceContext {
  domainId: string | undefined;
  modelId: string | undefined;
}

const initialModelPerformanceContext: ModelPerformanceContext = {
  domainId: undefined,
  modelId: undefined,
};

const ModelPerformanceContext = createContext(initialModelPerformanceContext);

export default ModelPerformanceContext;
