import { ActionRow } from './model-serving.styles';
import { Autocomplete } from '@material-ui/lab';
import { Box, Button, Card, Collapse, TableCell, TextField } from '@material-ui/core';
import { CardContainer, InformationContainer, Links, PlusIconContainer } from '../../common/styles/common.styles';
import { CellPrimaryValue } from '../workspaces/workspaces.styles';
import { ErrorIconLight, FinishedIconLight, PlusIcon, WarnIconLight } from '../../assets';
import { ManagedInstanceDto } from '../../common/models/workspace.models';
import { ServingModelDto, ServingModelVersionDto } from '../../common/models/serving.model';
import { Table } from '../../components';
import { TableHeadCell } from '../../components/Table/TableHead';
import { Title } from '../../routes/config';
import { createToasterNotification } from '../../common/utils/toaster-notification';
import { replaceUnderScoreToFirstCamel } from '../../common/utils/string';
import { urls } from '../../common/utils/urls';
import { useDispatch } from 'react-redux';
import { useQuery } from 'react-query';
import { useSelector } from '../../store/configure-store';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import React, { useCallback, useEffect, useState } from 'react';
import TableRow from '@material-ui/core/TableRow';
import api from '../../common/utils/api';
import withResources from '../../components/withResources/withResources';

interface Mlflow {
  title: Title;
}

const ModelServingComp: React.FC<Mlflow> = (props) => {
  const dispatch = useDispatch();
  const selectedResource = useSelector((state) => state.dynamicSelector.selectedResource);
  const domainId = selectedResource?.domainId || '';
  const [gridData, setGridData] = useState<ServingModelDto[]>([]);
  const [selectedPipeline, setSelectedPipeline] = useState<ManagedInstanceDto | null>(null);

  const renderCell = (colName: ServingModelDto | string, _rowIndex: number, item: ServingModelDto): React.ReactNode => {
    switch (colName) {
      case 'status': {
        return <CellPrimaryValue>{replaceUnderScoreToFirstCamel(item.status)}</CellPrimaryValue>;
      }
      case 'statusIcon': {
        switch (item.status) {
          case 'READY': {
            return <FinishedIconLight />;
          }
          case 'FAILED': {
            return <ErrorIconLight />;
          }
          case 'PENDING': {
            return <WarnIconLight />;
          }
          default: {
            return <CellPrimaryValue>{item[colName as string]}</CellPrimaryValue>;
          }
        }
      }
      case 'apiUrl': {
        return item.apiUrl ? (
          <CellPrimaryValue>
            <Links href={item.apiUrl} underline={'none'} target="_blank">
              {item.apiUrl}
              <OpenInNewIcon />
            </Links>
          </CellPrimaryValue>
        ) : (
          <CellPrimaryValue />
        );
      }
      case 'modelRegistryUrl': {
        return item.modelRegistryUrl ? (
          <CellPrimaryValue>
            <Links href={item.modelRegistryUrl} underline={'none'} target="_blank">
              {item.modelRegistryUrl}
              <OpenInNewIcon />
            </Links>
          </CellPrimaryValue>
        ) : (
          <CellPrimaryValue />
        );
      }
      default: {
        return <CellPrimaryValue>{item[colName as string]}</CellPrimaryValue>;
      }
    }
  };

  const subRenderCell = (
    colName: ServingModelVersionDto | string,
    _rowIndex: number,
    item: ServingModelVersionDto,
  ): React.ReactNode => {
    switch (colName) {
      case 'status': {
        return <CellPrimaryValue>{replaceUnderScoreToFirstCamel(item.status)}</CellPrimaryValue>;
      }
      case 'statusIcon': {
        switch (item.status) {
          case 'READY': {
            return <FinishedIconLight />;
          }
          case 'FAILED': {
            return <ErrorIconLight />;
          }
          case 'PENDING': {
            return <WarnIconLight />;
          }
          default: {
            return <CellPrimaryValue>{item[colName as string]}</CellPrimaryValue>;
          }
        }
      }
      case 'apiUrl': {
        return item.apiUrl ? (
          <CellPrimaryValue>
            <Links href={item.apiUrl} underline={'none'} target="_blank">
              {item.apiUrl}
              <OpenInNewIcon />
            </Links>
          </CellPrimaryValue>
        ) : (
          <CellPrimaryValue />
        );
      }
      case 'modelRegistryUrl': {
        return item.modelRegistryUrl ? (
          <CellPrimaryValue>
            <Links href={item.modelRegistryUrl} underline={'none'} target="_blank">
              {item.modelRegistryUrl}
              <OpenInNewIcon />
            </Links>
          </CellPrimaryValue>
        ) : (
          <CellPrimaryValue />
        );
      }
      default: {
        return <CellPrimaryValue>{item[colName as string]}</CellPrimaryValue>;
      }
    }
  };

  const fetchModelServingQuery = useQuery(
    `fetch-model-serving`,
    () => {
      return api
        .get<ServingModelDto[]>(urls.getServingModels(domainId, selectedPipeline?.id as string))
        .then((data) => {
          setGridData(data);
          return data;
        })
        .catch((e) => {
          dispatch(createToasterNotification(e));
        });
    },
    { enabled: false, refetchOnWindowFocus: false },
  );

  const handleFetchModelServingQuery = useCallback(fetchModelServingQuery.refetch, []);

  const fetchManagedServicesQuery = useQuery(
    `fetch-managed-services-model-serving-kubeflow`,
    () => {
      return api
        .get<ManagedInstanceDto[]>(urls.getKubeflows(domainId))
        .then((data) => {
          if (!selectedPipeline && data) {
            setSelectedPipeline(data[0]);
          }
          return data;
        })
        .catch((e) => {
          dispatch(createToasterNotification(e));
        });
    },
    { enabled: true, refetchOnWindowFocus: false },
  );

  const headCells: TableHeadCell<ServingModelDto>[] = [
    { id: 'collapse', label: '' },
    { id: 'name', label: 'Name' },
    { id: 'statusIcon', label: 'Status' },
    { id: 'status', label: 'Status message' },
    { id: 'apiUrl', label: 'API' },
    { id: 'modelRegistryUrl', label: 'Model registry URL' },
    { id: 'infrastructure', label: 'Serving infrastructure' },
  ];

  const subHeadCells: TableHeadCell<ServingModelVersionDto>[] = [
    { id: 'statusIcon', label: 'Status' },
    { id: 'status', label: 'Status message' },
    { id: 'version', label: 'Version' },
    { id: 'apiUrl', label: 'API' },
    { id: 'modelRegistryUrl', label: 'Model registry URL' },
  ];

  useEffect(() => {
    if (selectedPipeline) {
      handleFetchModelServingQuery();
    }
  }, [selectedPipeline, handleFetchModelServingQuery]);

  const getSubRow = (item: ServingModelDto, isOpen: boolean): JSX.Element => {
    return (
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={headCells.length}>
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table renderCell={subRenderCell} headCells={subHeadCells} data={item.versions} withTopActions={false} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <CardContainer>
      <Card>
        <InformationContainer>
          <ActionRow>
            <Autocomplete
              options={fetchManagedServicesQuery.data ? fetchManagedServicesQuery.data : []}
              renderInput={(params) => <TextField {...params} label="pipelines" variant="outlined" fullWidth />}
              getOptionLabel={(option) => option.name}
              onChange={(event, pipeline) => {
                setSelectedPipeline(pipeline);
              }}
              value={
                selectedPipeline
                  ? selectedPipeline
                  : fetchManagedServicesQuery.data
                  ? fetchManagedServicesQuery.data[0]
                  : null
              }
              size="small"
              style={{ width: '300px' }}
            />
            <Button disabled={true} variant="contained" color="primary" onClick={() => undefined}>
              <PlusIconContainer>
                <PlusIcon />
              </PlusIconContainer>
              Deploy new endpoint
            </Button>
          </ActionRow>
          <Table
            getCollapseRow={getSubRow}
            renderCell={renderCell}
            headCells={headCells}
            data={gridData}
            refresh={() => {
              if (selectedPipeline) {
                fetchModelServingQuery.refetch();
              }
            }}
            withTopActions={true}
          />
        </InformationContainer>
      </Card>
    </CardContainer>
  );
};

export const ModelServing = withResources(ModelServingComp);
