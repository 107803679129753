import Bike from './undraw_bike_ride.png';
import GcLogo from './gc-logo.png';
import Landing from './landing_sm.png';
import NewInstance from './new_instance.svg';
import ProvidedByAliz from './provided_by_aliz.svg';
import ProvidedByUser from './provided_by_user.svg';
import Root from './landing.png';
import SelectDarkRadioButton from './radio_select_circle_dark.svg';
import SelectRadioButton from './radio_select_circle.svg';
import UnSelectRadioButton from './radio_unselect_circle.svg';
import User from './user.png';

export { ReactComponent as Dashboard } from './dashboard.svg';
export { ReactComponent as Overview } from './overview.svg';
export { ReactComponent as Roles } from './roles.svg';
export { ReactComponent as RolesLg } from './roles_lg.svg';
export { ReactComponent as User } from './user.svg';
export { ReactComponent as VMInstances } from './vm_instances.svg';
export { ReactComponent as Support } from './support.svg';
export { ReactComponent as LogoDark } from './aliz_logo_dark.svg';
export { ReactComponent as VerticalMenu } from './vertical_menu.svg';
export { ReactComponent as Refresh } from './refresh.svg';
export { ReactComponent as Search } from './search.svg';
export { ReactComponent as Cancel } from './cancel.svg';
export { ReactComponent as LineChartIcon } from './line-chart.svg';
export { ReactComponent as DesktopIcon } from './desktop.svg';
export { ReactComponent as BullHornIcon } from './bull-horn.svg';
export { ReactComponent as PlusIcon } from './plus-icon.svg';
export { ReactComponent as EditPencilIcon } from './edit-pencil.svg';
export { ReactComponent as TrashIcon } from './trash.svg';
export { ReactComponent as InfoIcon } from './info.svg';
export { ReactComponent as DarkGreenPlusIcon } from './dark-green-plus-icon.svg';
export { ReactComponent as ChevronLeft } from './chevron-left.svg';
export { ReactComponent as ChevronRight } from './chevron-right.svg';
export { ReactComponent as ArrowLeft } from './arrow-left.svg';
export { ReactComponent as Task } from './task.svg';
export { ReactComponent as ArrowDown } from './arrow_down.svg';
export { ReactComponent as ArrowUp } from './arrow_up.svg';
export { ReactComponent as TemplateSample } from './template_sample.svg';
export { ReactComponent as ErrorIcon } from './error_icon.svg';
export { ReactComponent as ErrorIconLight } from './error_icon_light.svg';
export { ReactComponent as FinishedIcon } from './finished_icon.svg';
export { ReactComponent as FinishedIconLight } from './finished_icon_light.svg';
export { ReactComponent as PendingIcon } from './pending_icon.svg';
export { ReactComponent as RunningIcon } from './running_icon.svg';
export { ReactComponent as WarnIcon } from './warn_icon.svg';
export { ReactComponent as WarnIconLight } from './warn_icon_light.svg';
export { ReactComponent as ConfigIcon } from './config.svg';
export { ReactComponent as DraftingCompassIcon } from './drafting-compass.svg';
export { ReactComponent as FileCloudIcon } from './file-cloud.svg';
export { ReactComponent as Task2Icon } from './task2.svg';
export { ReactComponent as WalletIcon } from './wallet.svg';
export { ReactComponent as NewInstance } from './new_instance.svg';
export { ReactComponent as GridIcon } from './grid-icon.svg';
export { ReactComponent as ListIcon } from './list-icon.svg';
export { ReactComponent as BoltIcon } from './bolt.svg';
export { ReactComponent as ExperimentIcon } from './experiment.svg';
export { ReactComponent as PipelineIcon } from './pipeline.svg';
export { ReactComponent as InfoCircleIcon } from './info-circle.svg';
export { ReactComponent as PendingGlassIcon } from './pending.svg';
export { ReactComponent as IssueFoundIcon } from './issue_found.svg';
export { ReactComponent as BoltRunningIcon } from './bolt_running.svg';
export { ReactComponent as ArrowUp2 } from './arrow_up_2.svg';
export { ReactComponent as DesktopWhiteSmIcon } from './desktop_white_sm.svg';
export { ReactComponent as PhoneHoldingIcon } from './phone-holding.svg';
export { ReactComponent as Grid } from './grid.svg';
export { ReactComponent as CloudUploadIcon } from './cloud-upload.svg';
export { ReactComponent as CloudIcon } from './cloud.svg';
export { ReactComponent as PerformanceIcon } from './performance.svg';
export { ReactComponent as NoModelIcon } from './no-model.svg';

export default {
  User,
  Landing,
  Root,
  GcLogo,
  Bike,
  NewInstance,
  SelectDarkRadioButton,
  SelectRadioButton,
  UnSelectRadioButton,
  ProvidedByAliz,
  ProvidedByUser,
};
