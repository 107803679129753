import { TextValidator } from 'react-material-ui-form-validator';
import styled from 'styled-components';

const FormWrapper = styled.div`
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
`;

const TextInput = styled(TextValidator)``;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export { FormWrapper, TextInput, Footer };
