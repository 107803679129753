import { Grid } from '@material-ui/core';
import { MPMKSStatistic } from '../../../common/models/interfaces';
import React, { Suspense, lazy } from 'react';
const DynamicPlotlyChart = lazy(() => import('../../DynamicPlotlyChart'));

interface TKSStatisticProps {
  chart: MPMKSStatistic;
}

function KSStatistic({ chart }: TKSStatisticProps) {
  const { class_names, ks_thresholds, ks_pct1, ks_pct2, ks_max_distance_at, ks_statistic, ks_y1, ks_y2 } = chart;

  if (!class_names || !ks_thresholds || !ks_pct1 || !ks_pct2 || !ks_max_distance_at || !ks_statistic) return null;

  return (
    <Grid container spacing={2}>
      {class_names.map((classNameItem, nameIndex) => {
        return (
          <Grid item key={nameIndex} xs={12} lg={6} xl={4}>
            <Suspense fallback={<div>Loading...</div>}>
              <DynamicPlotlyChart
                data={[
                  {
                    x: ks_thresholds[nameIndex],
                    y: ks_pct1[nameIndex],
                    mode: 'lines',
                    name: `not ${classNameItem}`,
                  },
                  {
                    x: ks_thresholds[nameIndex],
                    y: ks_pct2[nameIndex],
                    mode: 'lines',
                    name: classNameItem,
                  },
                ]}
                layout={{
                  shapes: [
                    {
                      type: 'line',
                      line: { dash: 'dot' },
                      x0: ks_max_distance_at[nameIndex],
                      x1: ks_max_distance_at[nameIndex],
                      y0: ks_y1?.[nameIndex] || 0,
                      y1: ks_y2?.[nameIndex] || 0,
                    },
                  ],
                  xaxis: {
                    title: 'Threshold',
                    constrain: 'domain',
                  },
                  yaxis: {
                    title: 'CDF',
                    scaleanchor: 'x',
                    scaleratio: 1,
                  },
                  height: 380,
                  autosize: true,
                  margin: { t: 40, r: 0, b: 40, l: 40 },
                  showlegend: true,
                  title: `KS Statististic${class_names.length > 1 ? ' (' + classNameItem + ')' : ''}: ${
                    Math.round((ks_statistic[nameIndex] + Number.EPSILON) * 1000) / 1000
                  } at ${Math.round((ks_max_distance_at[nameIndex] + Number.EPSILON) * 1000) / 1000}`,
                }}
                config={{ displaylogo: false, modeBarButtons: false, responsive: false }}
              />
            </Suspense>
          </Grid>
        );
      })}
    </Grid>
  );
}

export default KSStatistic;
