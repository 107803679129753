import { DetailedWorkspace } from '../../common/models/workspace.models';
import {
  GET_WORKSPACES,
  GET_WORKSPACE_BY_ID,
  SET_DELETE_IN_PROGRESS_WORKSPACE,
  SET_SELECTED_WORKSPACE,
  SET_WORKSPACES_IN_PROGRESS,
  TWorkspacesActions,
} from './actions';

export interface InitialWorkspacesState {
  workspaces: [] | null;
  selectedWorkspace: DetailedWorkspace | null;
  deleteInProgress: boolean;
  workspacesIsInProgress: boolean;
}

const initialState: InitialWorkspacesState = {
  workspaces: null,
  selectedWorkspace: null,
  deleteInProgress: false,
  workspacesIsInProgress: false,
};

const workspacesReducer = (state = initialState, action: TWorkspacesActions) => {
  switch (action.type) {
    case GET_WORKSPACE_BY_ID:
      return {
        ...state,
        selectedWorkspace: action.payload,
      };
    case SET_SELECTED_WORKSPACE:
      return {
        ...state,
        selectedWorkspace: action.payload,
      };
    case GET_WORKSPACES:
      return {
        ...state,
        workspaces: action.payload,
      };

    case SET_DELETE_IN_PROGRESS_WORKSPACE:
      return {
        ...state,
        deleteInProgress: action.payload,
      };
    case SET_WORKSPACES_IN_PROGRESS:
      return {
        ...state,
        workspacesIsInProgress: action.payload,
      };
    default:
      return state;
  }
};

export default workspacesReducer;
