import {
  ArrowContainer,
  CardContainer,
  CardLeftContent,
  CardRightContent,
  LogContainer,
  LogRow,
} from './provisioning-details-row.styles';
import { ArrowDown, ArrowUp, ErrorIcon, FinishedIcon, PendingIcon, RunningIcon } from '../../../../assets';
import { Button, Card, CardContent, Collapse, Typography } from '@material-ui/core';
import { Row } from '../../../../common/styles/common.styles';
import { Step } from '../../../../common/models/workspace.models';
import { Task } from '../../../mytasks/models/tasks.model';
import { createToasterNotification } from '../../../../common/utils/toaster-notification';
import { replaceUnderScoreToCamel } from '../../../../common/utils/string';
import { urls } from '../../../../common/utils/urls';
import { useDispatch } from 'react-redux';
import React, { useState } from 'react';
import api from '../../../../common/utils/api';

interface ProvisioningRowProps {
  name: string;
  step: Step;
  isWaitForApprove: boolean | null;
  task: Task | null | void;
  refreshCallback: () => void;
  workspaceId: string;
}

export const ProvisioningDetailsRow: React.FC<ProvisioningRowProps> = (props) => {
  const [workspaceId] = useState<string>(props.workspaceId);
  const [expanded, setExpanded] = React.useState(props.isWaitForApprove !== null && props.isWaitForApprove);

  const dispatch = useDispatch();

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const changeStatus = (status: string) => {
    if (props.task) {
      api
        .post<Task>(urls.finishTask(workspaceId, props.task.id as string, status))
        .then((response: Task) => {
          props.refreshCallback();
        })
        .catch((e) => {
          dispatch(createToasterNotification(e));
        });
    }
  };

  const getStatusIcon = () => {
    switch (props.step.status) {
      case 'STATUS_UNKNOWN':
        return (
          <div className={'status-circle'}>
            <PendingIcon />
          </div>
        );
      case 'QUEUED':
        return (
          <div className={'status-circle pending'}>
            <PendingIcon />
          </div>
        );
      case 'WORKING':
        return (
          <div className={'status-circle running'}>
            <RunningIcon />
          </div>
        );
      case 'SUCCESS':
        return (
          <div className={'status-circle finished'}>
            <FinishedIcon />
          </div>
        );
      case 'FAILURE':
        return (
          <div className={'status-circle error'}>
            <ErrorIcon />
          </div>
        );
      case 'INTERNAL_ERROR':
        return (
          <div className={'status-circle error'}>
            <ErrorIcon />
          </div>
        );
      case 'TIMEOUT':
        return (
          <div className={'status-circle error'}>
            <ErrorIcon />
          </div>
        );
      case 'CANCELLED':
        return (
          <div className={'status-circle error'}>
            <ErrorIcon />
          </div>
        );
      default:
        return (
          <div className={'status-circle'}>
            <PendingIcon />
          </div>
        );
    }
  };

  return (
    <CardContainer>
      <Card className={'card'}>
        <CardContent className={'card-content'}>
          <CardLeftContent>
            {getStatusIcon()}
            <Typography className={'title'} gutterBottom color="primary" variant="body1" component="h1">
              {props.name}
            </Typography>
            <Typography className={'title'} gutterBottom color="primary" variant="body1" component="h1">
              - {replaceUnderScoreToCamel(props.step.status)}
            </Typography>
          </CardLeftContent>
          <CardRightContent>
            <Button color="primary" onClick={handleExpandClick}>
              Details
              <ArrowContainer>{expanded ? <ArrowUp /> : <ArrowDown />}</ArrowContainer>
            </Button>
          </CardRightContent>
        </CardContent>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent className={'collapse-content'}>
            {props.task && (
              <Row className={'approval-row'}>
                {props.task.done && props.isWaitForApprove !== null && (
                  <Row>
                    <p className={'approval-row-label'}>Approver:</p>
                    <p>{props.task.doneBy}</p>
                  </Row>
                )}
                {props.isWaitForApprove !== null && (
                  <Row className={'approval-row-actions'}>
                    <Button disabled={!props.isWaitForApprove} color="primary" onClick={() => changeStatus('REJECTED')}>
                      Reject
                    </Button>
                    <Button disabled={!props.isWaitForApprove} color="primary" onClick={() => changeStatus('DONE')}>
                      Approve Apply
                    </Button>
                  </Row>
                )}
              </Row>
            )}
            <LogContainer>
              <LogRow>
                {props.step.logs.map((log, index) => (
                  <p key={index}>{log}</p>
                ))}
              </LogRow>
            </LogContainer>
          </CardContent>
        </Collapse>
      </Card>
    </CardContainer>
  );
};
