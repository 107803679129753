import * as S from './styles';
import { BlueprintCreationType } from '../../common/models/enums';
import {
  Button,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { CardContainer, CardContent } from '../../common/styles/common.styles';
import { CookieCutterVariables } from '../../components/cookie-cutter-variables/cookie-cutter-variables';
import { GenerateRepository, TemplateVariablesRequest } from '../../common/models/workspace.models';
import { Link, useNavigate } from 'react-router-dom';
import { LoadingSpinner } from '../../components/loading-spinner/loading-spinner';
import { LoadingSpinnerContainerWbg } from '../deploy-blueprints/template-adder/template-adder.styles';
import { NewBlueprint, TemplateCategory } from '../../common/models/interfaces';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { ValidationError } from '../../common/utils/api';
import { createTemplate, getBaseTemplate, getTerraformVersions } from '../../store/templates/actions';
import { path } from '../../routes/path';
import { useDispatch } from 'react-redux';
import { useSelector } from '../../store/configure-store';
import { validateGitRepositoryNameOnUploadBlueprint } from '../../common/utils/validations';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import RepositoryName from '../../components/RepositoryName/RepositoryName';
import TemplateSelector from '../../components/TemplateSelector/TemplateSelector';
import withResources from '../../components/withResources/withResources';

import { BlueprintVersion as BlueprintVersionT } from '../../common/models/interfaces';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import BlueprintVersion from '../../components/BlueprintVersion';
import CategoryFilter from '../../components/CategoryFilter';

const useStyles = makeStyles(
  () => ({
    textInput: {
      marginTop: '1em',
    },
  }),
  { name: 'UploadBlueprint' },
);

const UploadBlueprint: React.FC = () => {
  const formRef = useRef(null);
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [baseData, setBaseData] = useState<GenerateRepository>();
  const terraformVersions = useSelector((state) => state.templates.terraformVersions);
  const initialFormData: NewBlueprint = {
    type: BlueprintCreationType.EMPTY_BLUEPRINT,
    name: '',
    description: '',
    version: [{ version: '', branch: 'master', terraformVersion: '', releaseDate: new Date() }],
    cookieCutterConfigPath: 'cookiecutter.json',
    scriptDirectory: 'terraform',
    envDirecotry: 'environments',
    repositoryName: '',
    newRepository: false,
    provisioningRetry: false,
    selectedTemplateId: '',
    cookieCutterData: null,
  };

  const [formData, setFormData] = useState(initialFormData);
  const baseTemplate = useSelector((state) => state.templates.baseTemplate);
  const isBaseTemplateFetching = useSelector((state) => state.templates.isBaseTemplateFetching);
  const createTemplateInProgress = useSelector((state) => state.templates.createTemplateInProgress);

  const domainId = useSelector((state) => state.dynamicSelector.selectedResource?.domainId);
  const [variablesValidationError, setVariablesValidationError] = useState<ValidationError | null>(null);

  const [hasRepositoryNameError, setHasRepositoryNameError] = useState<boolean>(false);
  const [selectedCategories, setSelectedCategories] = useState<TemplateCategory[]>();

  const validateForm = ({ repositoryName }: { repositoryName: string }) => {
    if (repositoryName) {
      if (validateGitRepositoryNameOnUploadBlueprint(repositoryName)) {
        setHasRepositoryNameError(false);
      } else {
        setHasRepositoryNameError(true);
      }
    }
  };

  const setValue = (
    fieldName: string,
    value: string | boolean | BlueprintCreationType | GenerateRepository | null,
  ): void => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: value,
    }));
  };

  const handleSetValue = useCallback(setValue, []);

  const onChangeTemplateSelector = (e: React.ChangeEvent<HTMLSelectElement>) => {
    handleSetValue('selectedTemplateId', e.target.value);
  };

  const onCreate = () => {
    let finalPayload: Payload = {};

    switch (formData.type) {
      case BlueprintCreationType.EMPTY_BLUEPRINT:
        finalPayload = {
          cookiecutterConfigPath: formData.cookieCutterConfigPath,
          createTemplateMode: formData.type,
          name: formData?.name,
          description: formData?.description,
          newRepository: formData.newRepository,
          repositoryName: formData.repositoryName,
          terraformEnvDirectory: formData.envDirecotry,
          terraformScriptDirectory: formData.scriptDirectory,
          version: formData.version[0],
          provisionWithRetry: formData.provisioningRetry,
          templateCategories: selectedCategories?.map((category) => category.id),
        };
        break;
      case BlueprintCreationType.CLONE_BLUEPRINT:
        finalPayload = {
          createTemplateMode: formData?.type,
          name: formData?.name,
          description: formData?.description,
          version: formData.version[0],
          newRepository: formData.newRepository,
          repositoryName: formData.repositoryName,
          cloneTemplateId: formData.selectedTemplateId,
          templateCategories: selectedCategories?.map((category) => category.id),
        };
        break;
      case BlueprintCreationType.BASE_BLUEPRINT:
        finalPayload = {
          createTemplateMode: formData?.type,
          name: formData?.name,
          description: formData?.description,
          version: formData.version[0],
          newRepository: formData.newRepository,
          repositoryName: formData.repositoryName,
          cookieCutterContent: formData.cookieCutterData?.cookieCutterContent,
          templateCategories: selectedCategories?.map((category) => category.id),
        };
        break;
      default:
    }

    dispatch(createTemplate(finalPayload, navigate, domainId || '', setVariablesValidationError));
  };

  useEffect(() => {
    dispatch(getTerraformVersions());
    if (formData.type === BlueprintCreationType.BASE_BLUEPRINT) {
      dispatch(getBaseTemplate());
    }
  }, [formData.type, dispatch]);

  useEffect(() => {
    if (terraformVersions && Array.isArray(terraformVersions)) {
      handleSetValue('terraformVersion', terraformVersions.at(-1) as string);
    }
  }, [terraformVersions, handleSetValue]);

  useEffect(() => {
    if (terraformVersions) {
      if (!formData.version[0].terraformVersion) {
        setFormData((oldFormState) => ({
          ...oldFormState,
          version: oldFormState.version.map((versionItem, index) => {
            if (index === 0) {
              return { ...versionItem, terraformVersion: terraformVersions.at(-1) as string };
            }
            return versionItem;
          }),
        }));
      }
    }
  }, [terraformVersions, formData.version]);

  const resolveCookieCutterVariables = (variables: any) => {
    const templateVar = {};
    variables.items.forEach((value: any) => {
      templateVar[value.key] = value.value;
    });
    return templateVar;
  };

  useEffect(() => {
    if (baseTemplate) {
      setBaseData({
        newRepository: false,
        cookieCutterContent: resolveCookieCutterVariables(baseTemplate.cookieCutterContent),
      });
    }
  }, [baseTemplate]);

  const versionSetter = (paramName: string, value: string | MaterialUiPickersDate, index: number) => {
    setFormData((oldState) => ({
      ...oldState,
      version: oldState.version.map((versionItem, versionIndex) => {
        if (versionIndex === index) {
          return {
            ...versionItem,
            [paramName]: value,
          };
        }
        return versionItem;
      }),
    }));
  };

  return (
    <CardContainer>
      <Card>
        <CardContent style={{ position: 'relative' }}>
          {createTemplateInProgress && (
            <LoadingSpinnerContainerWbg>
              <LoadingSpinner />
            </LoadingSpinnerContainerWbg>
          )}
          <ValidatorForm ref={formRef} onSubmit={onCreate}>
            <S.FormWrapper>
              <FormHelperText>How would you like to create new blueprint?</FormHelperText>
              <FormControl size="small" fullWidth variant="outlined">
                <Select
                  style={{ marginBottom: '1em', width: '100%' }}
                  labelId="select-blueprint-creation-type"
                  value={formData.type}
                  variant="outlined"
                  onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                    handleSetValue('type', e.target.value as BlueprintCreationType);
                  }}
                >
                  <MenuItem value={BlueprintCreationType.EMPTY_BLUEPRINT}>Create or import an empty blueprint</MenuItem>
                  <MenuItem value={BlueprintCreationType.BASE_BLUEPRINT}>
                    Generate a blueprint from the base blueprint
                  </MenuItem>
                  <MenuItem value={BlueprintCreationType.CLONE_BLUEPRINT}>Clone an existing blueprint</MenuItem>
                </Select>
              </FormControl>

              <TextValidator
                name="name"
                variant="outlined"
                label="Blueprint name"
                fullWidth
                color="primary"
                size="small"
                value={formData.name}
                validators={['required']}
                errorMessages={['This field is required']}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleSetValue('name', e.target.value as string);
                }}
              />

              <TextValidator
                name="description"
                label="Blueprint description"
                variant="outlined"
                color="primary"
                fullWidth
                classes={{ root: classes.textInput }}
                size="small"
                value={formData.description}
                validators={['required']}
                errorMessages={['This field is required']}
                multiline
                rows={5}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleSetValue('description', e.target.value as string);
                }}
              />

              <RepositoryName
                hideCheckbox={false}
                inForm
                hideRepositoryName={false}
                hasRepositoryNameError={hasRepositoryNameError}
                repositoryName={formData.repositoryName}
                onChange={(e) => {
                  handleSetValue('repositoryName', e.target.value);
                  validateForm({ repositoryName: e.target.value });
                }}
                checked={!!formData.newRepository}
                onClick={() => {
                  handleSetValue('newRepository', !formData.newRepository);
                  validateForm({ repositoryName: formData.repositoryName });
                }}
                style={{ maxWidth: 'none', marginLeft: '0', marginTop: '1em' }}
                checkStyle={{ marginBottom: '0em' }}
                inputStyle={{ marginBottom: '0em' }}
              />

              <div style={{ marginTop: '15px', marginBottom: '15px' }}>
                {terraformVersions &&
                  formData.version.map((version, index) => (
                    <BlueprintVersion
                      key={index}
                      version={version}
                      index={index}
                      terraformVersions={terraformVersions}
                      setter={versionSetter}
                    />
                  ))}
              </div>

              {formData.type === BlueprintCreationType.EMPTY_BLUEPRINT && (
                <>
                  <TextValidator
                    label="Cookiecutter config path"
                    variant="outlined"
                    color="primary"
                    size="small"
                    name="cookieCutterConfigPath"
                    fullWidth
                    classes={{ root: classes.textInput }}
                    validators={['required']}
                    errorMessages={['This field is required']}
                    value={formData.cookieCutterConfigPath}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleSetValue('cookieCutterConfigPath', e.target.value as string);
                    }}
                  />
                  <TextValidator
                    label="Terraform script directory"
                    variant="outlined"
                    name="scriptDirectory"
                    fullWidth
                    color="primary"
                    size="small"
                    classes={{ root: classes.textInput }}
                    validators={['required']}
                    errorMessages={['This field is required']}
                    value={formData.scriptDirectory}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleSetValue('scriptDirectory', e.target.value as string);
                    }}
                  />
                  <TextValidator
                    label="Terraform environment directory"
                    variant="outlined"
                    color="primary"
                    name="envDirecotry"
                    size="small"
                    validators={['required']}
                    fullWidth
                    classes={{ root: classes.textInput }}
                    errorMessages={['This field is required']}
                    value={formData.envDirecotry}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleSetValue('envDirecotry', e.target.value as string);
                    }}
                  />
                  <FormControlLabel
                    style={{ marginTop: '1em' }}
                    control={
                      <Checkbox
                        checked={formData.provisioningRetry}
                        onChange={() => {
                          handleSetValue('provisioningRetry', !formData.provisioningRetry);
                        }}
                        name="provision"
                        color="primary"
                      />
                    }
                    label="Use provisioning with retry"
                  />
                </>
              )}

              {formData.type === BlueprintCreationType.CLONE_BLUEPRINT && (
                <TemplateSelector
                  selectedTemplateId={formData.selectedTemplateId}
                  onChange={onChangeTemplateSelector}
                />
              )}

              {formData.type === BlueprintCreationType.BASE_BLUEPRINT && (
                <>
                  {!baseTemplate && isBaseTemplateFetching && <LinearProgress />}
                  {baseTemplate && (
                    <Paper elevation={1} style={{ padding: '1em', marginTop: '1em' }}>
                      <CookieCutterVariables
                        style={{ marginTop: '1em' }}
                        data={baseData}
                        validationError={variablesValidationError}
                        templateVariables={baseTemplate.cookieCutterContent.items}
                        hideCheckbox
                        hideRepositoryName
                        onDataChanged={(data) => {
                          handleSetValue('cookieCutterData', data);
                        }}
                      />
                    </Paper>
                  )}
                </>
              )}

              <div
                style={{
                  margin: '12px -24px',
                }}
              >
                <Typography
                  style={{
                    paddingLeft: '24px',
                    paddingBottom: '12px',
                  }}
                >
                  Select one or more category:
                </Typography>
                <CategoryFilter selectAllByDefault={false} onChangeAction={setSelectedCategories} />
              </div>
            </S.FormWrapper>

            <S.Footer>
              <Button component={Link} to={path.blueprintDashboard()}>
                Close
              </Button>
              <Button
                disabled={createTemplateInProgress || !selectedCategories || !selectedCategories.length}
                type="submit"
              >
                Upload
              </Button>
            </S.Footer>
          </ValidatorForm>
        </CardContent>
      </Card>
    </CardContainer>
  );
};

export interface Payload {
  cookiecutterConfigPath?: string;
  createTemplateMode?: string;
  name?: string;
  description?: string;
  newRepository?: boolean;
  repositoryName?: string;
  terraformEnvDirectory?: string;
  terraformScriptDirectory?: string;
  version?: BlueprintVersionT[] | BlueprintVersionT;
  provisionWithRetry?: boolean;
  cloneTemplateId?: string;
  cookieCutterContent?: TemplateVariablesRequest | null;
  templateCategories?: string[];
  terraformVersion?: string;
}

export default withResources(UploadBlueprint);
