import styled from 'styled-components';

const MainWrapper = styled.div`
  padding: 21px 12px;
`;

const SelectorsSection = styled.div`
  display: flex;
  flex-direction: row;
`;

const ChartsSection = styled.div`
  margin: 20px;
`;

export { MainWrapper, SelectorsSection, ChartsSection };
