import { Grid } from '@material-ui/core';
import { MPMLiftPlot } from '../../../common/models/interfaces';
import React, { Suspense, lazy } from 'react';
const DynamicPlotlyChart = lazy(() => import('../../DynamicPlotlyChart'));

interface TLiftPlotProps {
  chart: MPMLiftPlot;
}

function LiftTable({ chart }: TLiftPlotProps) {
  const {
    class_names,
    lift_thresholds,
    lift_response,
    lift_capture,
    pop_response,
    lift_capture_by_bin,
    lift_response_by_bin,
  } = chart;

  if (
    !chart ||
    !class_names ||
    !lift_thresholds ||
    !lift_response ||
    !lift_capture ||
    !pop_response ||
    !lift_capture_by_bin ||
    !lift_response_by_bin
  )
    return null;

  return (
    <Grid container spacing={2}>
      <Suspense fallback={<>Loading...</>}>
        {class_names.map((class_name, nameIndex) => {
          return (
            <Grid item key={nameIndex} xs={12} lg={6} xl={4}>
              <DynamicPlotlyChart
                data={[
                  {
                    type: 'table',
                    header: {
                      values: ['Base share', 'Recall', 'Precision', 'Recall by bin', 'Precision by bin'],
                      align: 'center',
                      line: { width: 1, color: 'black' },
                    },
                    cells: {
                      values: [
                        lift_thresholds[nameIndex].map((lt) => parseFloat(lt.toString()).toFixed(2)),
                        lift_capture[nameIndex].map((lc) => parseFloat(lc.toString()).toFixed(2)),
                        lift_response[nameIndex].map((ls) => parseFloat(ls.toString()).toFixed(6)),
                        lift_capture_by_bin[nameIndex].map((lcbb) => parseFloat(lcbb.toString()).toFixed(2)),
                        lift_response_by_bin[nameIndex].map((lrbb) => parseFloat(lrbb.toString()).toFixed(2)),
                      ],
                    },
                  },
                ]}
                layout={{
                  autosize: true,
                  height: 650,
                  title: `${class_names.length > 1 ? class_name : ''}`,
                }}
                config={{ displaylogo: false, modeBarButtons: false, responsive: false }}
              />
            </Grid>
          );
        })}
      </Suspense>
    </Grid>
  );
}

export default LiftTable;
