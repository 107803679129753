import { makeStyles } from '@material-ui/core';
import styled from 'styled-components';

export const UserInfoRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 52px;
  & .principal {
    width: 100%;
  }
`;

export const useDialogStyles = makeStyles({
  dialog: {
    width: 768,
  },
});

export const Label = styled.div`
  padding-bottom: 20px;
  font-size: 16px;
  line-height: 24px;
  color: ${(p) => p.theme.colors.transparentDarkGreen};
`;
