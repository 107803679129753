import { Autocomplete } from '@material-ui/lab';
import { AutocompleteContainer, FilterRow } from './my-tasks.styles';
import { Button, Card, FormControlLabel, Switch, TextField } from '@material-ui/core';
import { CardContainer } from '../../common/styles/common.styles';
import { CellPrimaryValue, CellSecondaryValue, TableHeaderCtaContainer } from '../workspaces/workspaces.styles';
import { Repository } from '../../common/models/workspace.models';
import { Table } from '../../components';
import { TableHeadCell } from '../../components/Table/TableHead';
import { Task, TaskType } from './models/tasks.model';
import { ToastType } from '../../common/models/enums';
import { Workspace } from '../workspaces/models/workspaces.models';
import { createToasterNotification } from '../../common/utils/toaster-notification';
import { get12HourFormat, getDate } from '../../common/utils/date-formatter';
import { path } from '../../routes/path';
import { urls } from '../../common/utils/urls';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useSelector } from '../../store/configure-store';
import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import api from '../../common/utils/api';
import withResources from '../../components/withResources/withResources';

const headCells: TableHeadCell<Task>[] = [
  { id: 'taskName', label: 'Task name' },
  { id: 'createdAt', label: 'Date, Time' },
  { id: 'createdByName', label: 'Requested by' },
  { id: 'workspace', label: 'Workspace' },
  { id: 'taskType', label: 'Task type' },
  { id: 'taskStatus', label: 'Task status' },
  { id: 'action', label: ' ' },
  { id: 'menu', type: 'menu' },
];

const MyTasksComp: React.FC = () => {
  const dispatch = useDispatch();
  const selectedResource = useSelector((state) => state.dynamicSelector.selectedResource);
  const [workspaceId] = useState<string | null>(selectedResource ? selectedResource?.id : null);
  const [taskStatuses, setTaskStatuses] = useState<string[]>([]);
  const [selectedTaskStatus, setSelectedTaskStatus] = useState<string | null>(null);
  const [selectedTaskType, setSelectedTaskType] = useState<string | null>(null);
  const [showCompleted, setShowCompleted] = useState<boolean>(false);
  const navigate = useNavigate();

  const taskQuery = useQuery(
    `tasks`,
    () => {
      return api
        .get<Task[]>(urls.getMyTasks(workspaceId), {
          params: {
            taskType: selectedTaskType,
            taskStatus: selectedTaskStatus,
            showCompleted,
            workspaceId,
          },
        })
        .then((data) => data)
        .catch((e) => {
          dispatch(createToasterNotification(e));
        });
    },
    { enabled: workspaceId !== null, refetchOnWindowFocus: false },
  );

  const taskTypeQuery = useQuery(
    `taskType`,
    () => {
      return api
        .get<TaskType[]>(urls.getTaskTypes())
        .then((data) => data)
        .catch((e) => {
          dispatch(createToasterNotification(e));
        });
    },
    { enabled: workspaceId !== null, refetchOnWindowFocus: false },
  );

  const handleFetchTasks = useCallback(taskQuery.refetch, []);

  const takeTask = (rowId: string) => {
    api
      .post<Repository>(urls.takeTask(workspaceId as string, rowId))
      .then(() => {
        handleFetchTasks();
      })
      .catch((e) => {
        dispatch(createToasterNotification(e));
      });
  };

  const dropTask = (rowId: string) => {
    api
      .post<Repository>(urls.dropTask(workspaceId as string, rowId))
      .then(() => {
        handleFetchTasks();
      })
      .catch((e) => {
        dispatch(createToasterNotification(e));
      });
  };

  const isTakeMenuActionHidden = (rowId: string): boolean => {
    if (taskQuery.data) {
      const task = taskQuery.data.find((t) => t.id === rowId);
      return (task?.assignable as boolean) && (task?.isTakenByMe as boolean);
    }
    return false;
  };

  const isDropMenuActionHidden = (rowId: string): boolean => {
    if (taskQuery.data) {
      const task = taskQuery.data.find((t) => t.id === rowId);
      return (task?.assignable as boolean) && (!task?.isTakenByMe as boolean);
    }
    return false;
  };

  const showMenu = (rowId: string): boolean => {
    if (taskQuery.data) {
      const task = taskQuery.data.find((t) => t.id === rowId);
      return (task?.assignable as boolean) && !task?.doneBy;
    }
    return false;
  };

  const taskTypeSelected = (taskType: TaskType | null | undefined): void => {
    if (taskType) {
      setTaskStatuses(taskType.statusList);
      setSelectedTaskType(taskType.type);
      setSelectedTaskStatus(null);
    } else {
      setTaskStatuses([]);
      setSelectedTaskType(null);
      setSelectedTaskStatus(null);
    }
  };

  const taskStatusSelected = (taskStatus: string | null): void => {
    if (taskStatus) {
      setSelectedTaskStatus(taskStatus);
    } else {
      setSelectedTaskStatus(null);
    }
  };

  const taskActionHandler = (task: Task): void => {
    switch (task.taskType) {
      case 'PROVISIONING': {
        navigate(path.provisioningDetails(task.referenceId));
      }
    }
  };

  useEffect(() => {
    if (workspaceId) {
      handleFetchTasks();
    }
  }, [selectedTaskType, handleFetchTasks, workspaceId]);

  useEffect(() => {
    if (workspaceId) {
      handleFetchTasks();
    }
  }, [selectedTaskStatus, handleFetchTasks, workspaceId]);

  useEffect(() => {
    if (workspaceId) {
      handleFetchTasks();
    }
  }, [showCompleted, handleFetchTasks, workspaceId]);

  const renderCell = (colName: string, _rowIndex: number, item: Task): React.ReactNode => {
    switch (colName) {
      case 'action': {
        const visible = (!item.assignable || item.isTakenByMe) && !item.done;
        return (
          visible && (
            <CellPrimaryValue>
              <Button onClick={() => taskActionHandler(item)}>ACTION</Button>
            </CellPrimaryValue>
          )
        );
      }
      case 'workspace': {
        const name = item.workspace?.name as string;
        return <CellPrimaryValue>{name}</CellPrimaryValue>;
      }
      case 'taskType': {
        const taskType = item.taskType as string;
        return (
          <CellPrimaryValue>{taskType.charAt(0).toUpperCase() + taskType.slice(1).toLowerCase()}</CellPrimaryValue>
        );
      }
      case 'taskStatus': {
        const taskStatus = item.taskStatus as string;
        return (
          <CellPrimaryValue>
            {taskStatus?.charAt(0).toUpperCase() + taskStatus?.slice(1).toLowerCase()}
          </CellPrimaryValue>
        );
      }
      case 'createdAt': {
        const d = new Date(item.createdAt as string);
        return (
          <>
            <CellPrimaryValue>{getDate(d)}</CellPrimaryValue>
            <CellSecondaryValue className="time">{get12HourFormat(d)}</CellSecondaryValue>
          </>
        );
      }
    }
    return item[colName];
  };

  // delete later
  const [input, setInput] = useState<string | undefined>(undefined);
  const [input2, setInput2] = useState<string | undefined>(undefined);

  const onSaveTask = () => {
    const task: Task = {
      id: '',
      taskName: 'test task',
      taskType: 'PROVISIONING',
      taskStatus: 'PENDING',
      workspace: {
        id: input,
      } as unknown as Workspace,
      assignedUsers: ['aip.testadmin@demo.doctusoft.com'],
      referenceId: input2,
    };

    api
      .post<Task>(urls.saveTask(workspaceId as string), task)
      .then(() => {
        dispatch(createToasterNotification('Successfully created a new task', ToastType.SUCCESS));
      })
      .catch((e) => {
        dispatch(createToasterNotification(e));
      });
  };

  const handleShowCompletedChange = (event: SyntheticEvent) => {
    setShowCompleted(event.target[`checked`]);
  };

  return (
    <>
      <div style={{ display: 'none' }}>
        <input
          type="text"
          placeholder={'workspaceId'}
          onChange={(e) => {
            setInput(e.target.value);
          }}
        />
        <input
          type="text"
          placeholder={'provisionId'}
          onChange={(e) => {
            setInput2(e.target.value);
          }}
        />
        <Button variant="contained" color="primary" onClick={() => onSaveTask()}>
          Save new task
        </Button>
      </div>
      <CardContainer>
        <Card>
          <Table
            headCells={headCells}
            data={taskQuery.data || []}
            isLoading={taskQuery.isLoading || taskQuery.isFetching}
            refresh={handleFetchTasks}
            renderCell={renderCell}
            showMenu={showMenu}
            tableMenuActions={[
              { label: 'Take task', onClick: takeTask, hidden: isTakeMenuActionHidden },
              { label: 'Drop task', onClick: dropTask, hidden: isDropMenuActionHidden },
            ]}
            topButton={
              <TableHeaderCtaContainer>
                <FilterRow>
                  <FormControlLabel
                    className={'completedSwitch'}
                    control={<Switch onChange={handleShowCompletedChange} name="Show done tasks" color="primary" />}
                    label="Show completed"
                  />
                  <AutocompleteContainer>
                    <Autocomplete
                      options={taskTypeQuery.data || []}
                      renderInput={(params) => <TextField {...params} label="Task type" variant="outlined" fullWidth />}
                      getOptionLabel={(option) =>
                        option.type.charAt(0).toUpperCase() + option.type?.slice(1).toLowerCase()
                      }
                      onChange={(event, taskType) => taskTypeSelected(taskType ?? null)}
                      fullWidth
                      size="small"
                    />
                  </AutocompleteContainer>
                  <AutocompleteContainer>
                    <Autocomplete
                      options={taskStatuses || []}
                      renderInput={(params) => (
                        <TextField {...params} label="Task status" variant="outlined" fullWidth />
                      )}
                      getOptionLabel={(option) => option.charAt(0).toUpperCase() + option.slice(1).toLowerCase()}
                      onChange={(event, taskStatus) => taskStatusSelected(taskStatus ?? null)}
                      fullWidth
                      size="small"
                    />
                  </AutocompleteContainer>
                </FilterRow>
              </TableHeaderCtaContainer>
            }
          />
        </Card>
      </CardContainer>
    </>
  );
};

export const MyTasks = withResources(MyTasksComp);
