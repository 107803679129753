import { Row } from '../../../common/styles/common.styles';
import styled from 'styled-components';

export const CanvasContainer = styled.div`
  position: relative;
  width: 100%;
  height: 500px;
  margin-bottom: 50px;
`;

export const LoadingSpinnerContainer = styled(Row)`
  position: absolute;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;
