import { Autocomplete } from '@material-ui/lab';
import { BlueprintDependency, BlueprintDependencyWorkspace } from '../../common/models/interfaces';
import { TextField, Typography } from '@material-ui/core';
import { urls } from '../../common/utils/urls';
import React, { useCallback, useEffect, useState } from 'react';
import api from '../../common/utils/api';

interface TBlueprintDependencyProps {
  dependency: BlueprintDependency;
  onChangeAction: React.Dispatch<
    React.SetStateAction<
      | Record<string, string>
      | undefined
    >
  >;
  blueprintDependencyData: { [index: string]: string } | undefined;
}

const BlueprintDependencyRow: React.FC<TBlueprintDependencyProps> = ({
  dependency,
  onChangeAction,
  blueprintDependencyData,
}) => {
  const [workspaces, setWorkspaces] = useState<BlueprintDependencyWorkspace[]>();

  const fetchWorkspacesByTemplate = async () => {
    try {
      const workspacesData = await api.get<BlueprintDependencyWorkspace[]>(urls.getWorkspacesByTemplate(dependency.id));
      if (workspacesData) setWorkspaces(workspacesData);
    } catch (e) {
      console.log('BlueprintDependencyRow-->fetchWorkspacesByTemplate', e);
    }
  };

  const handleFetchWorkspacesByTemplate = useCallback(fetchWorkspacesByTemplate, [dependency.id]);

  useEffect(() => {
    handleFetchWorkspacesByTemplate();
  }, [handleFetchWorkspacesByTemplate]);

  useEffect(() => {
    if (onChangeAction && typeof onChangeAction === 'function' && workspaces && workspaces.length > 0) {
      onChangeAction((old: any) => ({
        ...old,
        [dependency.id]: workspaces[0].id,
      }));
    }
  }, [workspaces, onChangeAction, dependency.id]);

  if (!workspaces) return null;

  return (
    <div>
      <Autocomplete
        disableClearable={true}
        options={workspaces || []}
        renderInput={(params) => <TextField {...params} label={dependency.name} variant="outlined" fullWidth />}
        getOptionLabel={(option) => option.name}
        onChange={(event, workspace) => {
          if (onChangeAction && typeof onChangeAction === 'function') {
            onChangeAction((prevState: any) => ({
              ...prevState,
              [dependency.id]: workspace.id,
            }));
          }
        }}
        value={
          blueprintDependencyData && blueprintDependencyData[dependency.id]
            ? workspaces.find((workspace) => workspace.id === blueprintDependencyData[dependency.id])
            : { name: '', id: '' }
        }
        size="small"
      />
      {!workspaces.length && (
        <Typography style={{ fontSize: '0.8em', color: 'tomato', marginTop: '0.5em', marginLeft: '0.5em' }}>
          Please first create a new instance of this blueprint dependency and provision it to the environments!
        </Typography>
      )}
    </div>
  );
};

export default BlueprintDependencyRow;
