export const validateEmails = (value: string) => {
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value.replace(/\s/g, ''));
};

export const validateGitRepositoryName = (value: string) => {
  return /^[a-zA-Z0-9.-]+$/i.test(value.replace(/\s/g, ''));
};

export const validateGitRepositoryNameOnUploadBlueprint = (value: string) => {
  return /^[a-zA-Z0-9.-]+$/i.test(value.replace(/\s/g, ''));
};
