function resolvePlotLabel(plot: string) {
  switch (plot) {
    case 'LIFT_PLOT':
      return 'Lift Plot';
    case 'ROC_CURVE':
      return 'Roc Curve';
    case 'PRECISION_RECALL_CURVE':
      return 'Precision-recall Curve';
    case 'KOLMOGOROV_SMIRNOV_PLOT':
      return 'Kolmogorov-Smirnov Statistic';
    case 'LIFT_TABLE':
      return 'Lift Table';
    default:
      return plot;
  }
}

export { resolvePlotLabel };
