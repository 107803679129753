import styled from 'styled-components';

const Wrapper = styled.div`
  background-color: #fafafa;
  margin-top: 1em;
  margin-bottom: 1em;
  border-radius: 8px;
  padding: 1.5em;
  border: 1px solid #0521201f;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ContentWrapper = styled.div`
  text-align: center;
`;

const NoModelText = styled.p`
  font-size: 1.19rem;
  color: rgba(5, 33, 32, 0.6);
`;

export { Wrapper, NoModelText, ContentWrapper };
