import { CircularProgress } from '@material-ui/core';
import { LoadingSpinnerContainer } from './loading-spinner.styles';
import React from 'react';

export const LoadingSpinner: React.FC = () => {
  return (
    <LoadingSpinnerContainer>
      <CircularProgress color="primary" />
    </LoadingSpinnerContainer>
  );
};
