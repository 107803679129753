import { BaseDataItem, OrderType } from '../';
import { SortIndicator } from './styles';
import { TableHeadCell } from './interfaces';
import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

export interface IProps<T> {
  headCells: TableHeadCell<T>[];
  order: OrderType;
  orderBy: string;
  onRequestSort: (e: React.MouseEvent, property: keyof T) => void;
  select?: boolean;
}

const EnhancedTableHead = <DataItem extends BaseDataItem>(props: IProps<DataItem>) => {
  const { order, orderBy, onRequestSort, headCells, select } = props;
  const createSortHandler = (property: keyof DataItem) => (event: React.MouseEvent) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {select && <TableCell padding="checkbox" />}
        {headCells.map((headCell) => (
          <TableCell key={String(headCell.id)} sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id as keyof DataItem)}
            >
              {headCell.type !== 'menu' && headCell.label}
              {orderBy === headCell.id ? (
                <SortIndicator>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</SortIndicator>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default EnhancedTableHead;
