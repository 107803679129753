import { Column, CommonProps, Row } from '../../../common/styles/common.styles';
import styled from 'styled-components';

export const BudgetFormContainer = styled(Column)`
  padding: 15px;
`;

export const InputContainer = styled(Row)<CommonProps>`
  margin-bottom: ${(p) => (p.marginBottom ? `${p.marginBottom}px` : 0)};
`;

export const AlertTypeInputContainer = styled(Row)<{ isWebhook?: boolean }>`
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${(p) => (p.isWebhook ? '20px' : 0)};
`;

export const NotificationAutocompleteContainer = styled.div`
  width: 50%;
`;

export const LimitAutocompleteContainer = styled.div`
  width: 30%;
`;

export const InfoIconContainer = styled.div`
  cursor: pointer;
`;

export const TrashIconContainer = styled.div`
  cursor: pointer;
`;

export const Controls = styled(Row)`
  justify-content: flex-end;
`;

export const DividerContainer = styled.div`
  height: 10px;
  margin: 25px 0;
`;
