import styled from 'styled-components';

const Title = styled.p`
  color: #02394d;
  margin: 0;
`;

const ChartWrapper = styled.div`
  display: grid;
  padding-bottom: 1em;
  padding-top: 1em;
  grid-template-columns: 200px auto;
  background-color: #fafafa;
  border-radius: 8px;
  margin-top: 1em;
`;

const ChartInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ChartInfoContent = styled.div`
  text-align: center;
`;

const ChartCurrentValue = styled.p`
  font-size: 2.5rem;
  margin: 0;
  margin-top: 0.5em;
`;

const ChartChange = styled.p`
  margin: 0;
  margin-top: 0.5em;
`;

export { Title, ChartWrapper, ChartInfo, ChartInfoContent, ChartCurrentValue, ChartChange };
