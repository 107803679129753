import { Button, Card } from '@material-ui/core';
import { CardContainer } from '../../common/styles/common.styles';
import { PipelineStep } from '../../common/models/interfaces';
import { ToastType } from '../../common/models/enums';
import { createToasterNotification } from '../../common/utils/toaster-notification';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import DeployPipeline from '../../components/DeployPipeline';
import PipelineEditorModal from '../../components/PipelineEditorModal';
import PipelineStepElement from '../../components/PipelineStepElement';
import React, { useEffect, useState } from 'react';
import ReactFlow, { Background, Controls, MiniMap } from 'react-flow-renderer';
import withResources from '../../components/withResources/withResources';

const flowStyles = { height: 450 };

const initialData = [
  { id: '1', name: 'Load', type: '+ Custom Python component', elementOptions: { y: 50, x: 400 } },
  { id: '2', name: 'Train', type: '+ Custom Python component', elementOptions: { y: 150, x: 400 } },
  { id: '3', name: 'Evaluate', type: '+ Custom Python component', elementOptions: { y: 250, x: 400 } },
  { id: '4', name: 'Deploy', type: '+ Custom Python component', elementOptions: { y: 350, x: 400 } },
  {
    id: uuidv4(),
    name: 'line',
    type: 'straight',
    source: '1',
    target: '2',
    animated: true,
  },
  {
    id: uuidv4(),
    name: 'line',
    type: 'straight',
    source: '2',
    target: '3',
    animated: true,
  },
  {
    id: uuidv4(),
    name: 'line',
    type: 'straight',
    source: '3',
    target: '4',
    animated: true,
  },
];

const PipelineEditor = () => {
  const JSONFromLocalStorage = window.localStorage.getItem('pipelineSteps')
    ? JSON.parse(window.localStorage.getItem('pipelineSteps') || '[]')
    : [];

  const initialSteps: PipelineStep[] = JSONFromLocalStorage.length ? JSONFromLocalStorage : initialData;

  const [isEditorModalOpen, setIsEditorModalOpen] = useState(false);
  const dispatch = useDispatch();
  const [elements, setElements] = useState<any[]>([]);
  const [steps, setSteps] = useState<PipelineStep[]>(initialSteps);
  const [selectedStep, setSelectedStep] = useState<PipelineStep>();

  useEffect(() => {
    if (steps) {
      window.localStorage.setItem('pipelineSteps', JSON.stringify(steps));

      const stepsToElements = steps.map((step) => {
        if (step.type === 'straight') {
          return step;
        }

        return {
          id: step?.id,
          style: { width: '230px' },
          type: 'default',
          data: {
            label: <PipelineStepElement setSelectedStep={setSelectedStep} step={step} setSteps={setSteps} />,
          },
          position: { x: step?.elementOptions?.x || 700, y: step?.elementOptions?.y || 100 },
        };
      });

      if (stepsToElements) setElements([...stepsToElements]);
    }
  }, [steps]);

  useEffect(() => {
    selectedStep && setIsEditorModalOpen(true);
  }, [selectedStep]);

  const onCreate = (step: PipelineStep) => {
    if (!selectedStep) {
      setSteps((prevSteps) => [...prevSteps, step]);
    } else {
      setSteps((prevSteps) => {
        const stepsWithoutCurrentStep = prevSteps.filter((prevStep) => prevStep.id !== selectedStep.id);
        return [...stepsWithoutCurrentStep, step];
      });
    }
    onCancel();
  };

  const onCancel = () => {
    setIsEditorModalOpen(false);
    setSelectedStep(undefined);
  };

  return (
    <>
      <PipelineEditorModal
        isOpen={isEditorModalOpen}
        onCreate={onCreate}
        onCancel={onCancel}
        selectedStep={selectedStep}
      />
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '12px' }}>
        <Button
          style={{ marginLeft: '6px' }}
          color="primary"
          variant="outlined"
          onClick={() => {
            setIsEditorModalOpen(true);
          }}
        >
          Create new step
        </Button>
        <div style={{ marginLeft: 'auto' }}>
          <DeployPipeline />
        </div>
      </div>
      <CardContainer>
        <Card>
          {elements && elements.length > 0 && (
            <ReactFlow
              onConnect={(connection) => {
                setSteps((prevSteps) => {
                  return [
                    ...prevSteps,
                    {
                      id: uuidv4(),
                      name: 'line',
                      type: 'straight',
                      source: connection.source || undefined,
                      target: connection.target || undefined,
                      animated: true,
                    },
                  ];
                });
              }}
              onNodeDragStop={(event, node) => {
                setSteps((prevSteps) =>
                  prevSteps.map((step) => {
                    if (step.id === node.id) {
                      return {
                        ...step,
                        elementOptions: {
                          x: node.position.x,
                          y: node.position.y,
                        },
                      };
                    }
                    return step;
                  }),
                );
              }}
              elements={elements}
              style={flowStyles}
            >
              <MiniMap />
              <Controls />
              <Background gap={12} size={0.3} />
            </ReactFlow>
          )}
        </Card>
      </CardContainer>
      {steps.length > 0 && (
        <div style={{ display: 'flex', marginBottom: '21px' }}>
          <Button
            style={{ marginLeft: 'auto' }}
            variant="contained"
            color="primary"
            onClick={() => dispatch(createToasterNotification('Pipeline was saved successfully!', ToastType.SUCCESS))}
          >
            Save Pipeline
          </Button>
        </div>
      )}
    </>
  );
};

export default withResources(PipelineEditor);
