import { DetailedWorkspace } from '../models/workspace.models';
import { Workspace } from '../../pages/workspaces/models/workspaces.models';
import { urls } from './urls';
import { useQuery } from 'react-query';
import { useSelector } from '../../store/configure-store';
import api from './api';

export const WorkspacesWithoutRepoQuery = (additionalQueryKey = '', enabled = true) => {
  const domainId = useSelector((state) => state.dynamicSelector.selectedResource?.domainId);
  return useQuery(
    `workspaces${additionalQueryKey}`,
    () => {
      return api.get<Workspace[]>(urls.workspacesWithoutRepo(domainId || '')).then((data) => data);
    },
    { enabled, refetchOnWindowFocus: false },
  );
};

export const WorkspaceQuery = (workspaceId: string | undefined, additionalQueryKey = '') => {
  const queryKey = workspaceId ? `workspace-${workspaceId}${additionalQueryKey}` : 'new-workspace';
  return useQuery(
    queryKey,
    () => {
      return api.get<DetailedWorkspace>(urls.workspace(workspaceId as string)).then((data) => data);
    },
    { enabled: workspaceId, refetchOnWindowFocus: false },
  );
};
