import { Checkbox, FormControl, FormHelperText, TextField } from '@material-ui/core';
import { RepositoryRow } from '../cookie-cutter-variables/cookie-cutter-variables.style';
import { TextValidator } from 'react-material-ui-form-validator';
import React from 'react';

enum ErrorType {
  REQUIRED = 'REQUIRED',
  INVALID_FORMAT = 'INVALID_FORMAT',
  OTHER = 'OTHER',
}

interface RepositoryNamePropsT {
  hideRepositoryName: boolean;
  hasRepositoryNameError: boolean;
  repositoryName: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  hideCheckbox: boolean;
  checked: boolean;
  style?: React.CSSProperties;
  inputStyle?: React.CSSProperties;
  checkStyle?: React.CSSProperties;
  onClick: () => void;
  inForm?: boolean;
  hasRepositoryNameValidationError?: { key: string; errorType: ErrorType; message: string; invalidValue?: string };
}

const RepositoryName: React.FC<RepositoryNamePropsT> = ({
  hideRepositoryName,
  hasRepositoryNameError,
  hasRepositoryNameValidationError,
  repositoryName,
  onChange,
  hideCheckbox,
  checked,
  onClick,
  style,
  inputStyle = { width: '100%', marginBottom: '45px' },
  checkStyle = { marginBottom: '45px' },
  inForm,
}) => {
  return (
    <RepositoryRow className={hideRepositoryName || hideCheckbox ? 'one-column' : ''} style={style}>
      {!hideRepositoryName && (
        <FormControl className="repository-name" size="small" style={inputStyle} error={hasRepositoryNameError}>
          {!inForm && (
            <TextField
              label="Repository name"
              variant="outlined"
              color="primary"
              size="small"
              error={hasRepositoryNameError}
              value={repositoryName}
              onChange={onChange}
              name="repositoryName"
            />
          )}
          {inForm && (
            <TextValidator
              name="repositoryName"
              label="Repository name"
              variant="outlined"
              color="primary"
              size="small"
              fullWidth
              validators={['required']}
              errorMessages={['This field is required']}
              error={hasRepositoryNameError}
              value={repositoryName}
              onChange={onChange}
            />
          )}
          {hasRepositoryNameError && (
            <FormHelperText error key={'invalidRepositoryName'} id="component-helper-text">
              Invalid repository name. Allowable characters: A-Za-z0-9_.-
            </FormHelperText>
          )}
          {hasRepositoryNameValidationError && (
            <FormHelperText error key={'invalidRepositoryName'} id="component-helper-text">
              {hasRepositoryNameValidationError.message}
            </FormHelperText>
          )}
        </FormControl>
      )}
      {!hideCheckbox && (
        <div className="new-repo-check" style={checkStyle}>
          <Checkbox checked={checked} onClick={onClick} /> New repository
        </div>
      )}
    </RepositoryRow>
  );
};

export default RepositoryName;
