import { Row } from '../../common/styles/common.styles';
import styled from 'styled-components';

export const TableMenuButton = styled.div`
  width: 32px;
  height: 32px;
  float: right;
  cursor: pointer;
`;

export const TableTopActions = styled.div`
  padding: 4px;
  align-items: center;
  display: flex;
  & svg {
    cursor: pointer;
  }
`;

export const RefreshIconWrapper = styled.div`
  margin-left: 20px;
  border-right: 1px solid rgba(5, 33, 32, 0.12);
  padding-right: 19px;
  box-sizing: border-box;
`;

export const EditableContainer = styled(Row)`
  justify-content: flex-end;
`;

export const EditPencilIconContainer = styled.div`
  margin-right: 25px;
  cursor: pointer;
`;

export const TrashIconContainer = styled.div`
  cursor: pointer;
`;
