import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { Skeleton } from '@material-ui/lab';
import { formatMUIDateToString } from '../../common/utils/date-formatter';
import { getPlotDates } from './requests';
import LuxonUtils from '@date-io/luxon';
import ModelPerformanceContext from '../../pages/ModelPerformance/ModelPerformanceContext';
import React, { useCallback, useContext, useEffect, useState } from 'react';

interface TPlotDateSelectorProps {
  selectedPlotType: string;
  setSelectedDate: React.Dispatch<React.SetStateAction<string | undefined>>;
  selectedDate: string | undefined;
  isReferenceDate?: boolean;
  label?: string;
}

function PlotDateSelector({
  selectedPlotType,
  setSelectedDate,
  selectedDate,
  isReferenceDate,
  label,
}: TPlotDateSelectorProps) {
  const { domainId, modelId } = useContext(ModelPerformanceContext);
  const [possibleDates, setPossibleDates] = useState<string[]>();
  const [isLoading, setIsLoading] = useState(false);

  const handleGetPlotDates = useCallback(getPlotDates, [domainId, modelId, selectedPlotType]);

  const getPlotDatesCallback = (dates: string[]) => {
    setPossibleDates(dates);
    setIsLoading(false);
  };

  const shouldDisableDate = (date: MaterialUiPickersDate) => {
    const convertedDateString = formatMUIDateToString(date);
    if (convertedDateString && possibleDates?.includes(convertedDateString)) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (domainId && modelId && selectedPlotType)
      handleGetPlotDates(domainId, modelId, selectedPlotType, {
        beforeActions: () => setIsLoading(true),
        cb: getPlotDatesCallback,
      });
  }, [domainId, modelId, selectedPlotType, handleGetPlotDates]);

  useEffect(() => {
    if (possibleDates && !isReferenceDate) setSelectedDate(possibleDates[0]);
  }, [possibleDates, setSelectedDate, isReferenceDate]);

  if (isLoading)
    return <Skeleton style={{ marginLeft: '12px', borderRadius: '5px' }} variant="rect" width={250} height={40} />;

  return (
    <MuiPickersUtilsProvider utils={LuxonUtils}>
      <KeyboardDatePicker
        disableToolbar
        style={{ margin: 0, marginLeft: '12px' }}
        format="yyyy-MM-dd"
        margin="normal"
        size="small"
        variant="dialog"
        inputVariant="outlined"
        id="date-picker-inline"
        label={label ? label : 'Date'}
        value={selectedDate || null}
        shouldDisableDate={shouldDisableDate}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        onChange={(currentDate: MaterialUiPickersDate) => {
          const newDate = currentDate ? formatMUIDateToString(currentDate) : formatMUIDateToString();
          newDate && setSelectedDate(newDate);
        }}
      />
    </MuiPickersUtilsProvider>
  );
}

export default PlotDateSelector;
