import { AlertLogConfig } from '../models/alert-log.models';
import { CellPrimaryValue, CellSecondaryValue } from '../../workspaces/workspaces.styles';
import { TableHeadCell } from '../../../components/Table/TableHead';
import { get12HourFormat, getDate } from '../../../common/utils/date-formatter';
import React from 'react';

export const headCells: TableHeadCell<AlertLogConfig>[] = [
  { id: 'budgetName', label: 'Rule name' },
  { id: 'alertThresholdPercent', label: 'Threshold' },
  { id: 'alertThresholdValue', label: 'Limit amount' },
  { id: 'alertType', label: 'Alert Type' },
  { id: 'notificationType', label: 'Notification' },
  { id: 'workspaceName', label: 'Workspace' },
  { id: 'createdAt', label: 'Date Time' },
];

export const renderCell = (
  colName: keyof AlertLogConfig | string,
  _rowIndex: number,
  item: AlertLogConfig,
): React.ReactNode => {
  switch (colName) {
    case 'alertThresholdPercent': {
      const value = item[colName];
      let color;

      if (value < 80) {
        color = 'primary';
      } else if (value < 100) {
        color = 'warning';
      } else if (value === 100) {
        color = 'danger';
      } else {
        color = 'black';
      }
      return <CellPrimaryValue color={color}>{value}%</CellPrimaryValue>;
    }
    case 'alertThresholdValue': {
      return <CellPrimaryValue>US$ {item[colName]}</CellPrimaryValue>;
    }
    case 'notificationType': {
      return <CellPrimaryValue>{item[colName].map((notification) => notification).join(', ')}</CellPrimaryValue>;
    }
    case 'createdAt': {
      const d = new Date(item.createdAt);
      return (
        <>
          <CellPrimaryValue>{getDate(d)}</CellPrimaryValue>
          <CellSecondaryValue className="time">{get12HourFormat(d)}</CellSecondaryValue>
        </>
      );
    }
    default: {
      return <CellPrimaryValue>{item[colName]}</CellPrimaryValue>;
    }
  }
};
