import * as S from './styles';
import { ModelMeta as ModelMetaType } from '../../common/models/model.model';
import { addZeroBeforeNumber } from '../../common/utils/number-formatter';
import React from 'react';

interface TModelMetaProps {
  meta: ModelMetaType;
}

const convertDate = (date: string) => {
  return `${new Date(date).getFullYear()}.${addZeroBeforeNumber(new Date(date).getMonth() + 1)}.${addZeroBeforeNumber(
    new Date(date).getDate(),
  )} ${new Date(date).getHours()}:${addZeroBeforeNumber(new Date(date).getMinutes())}:${addZeroBeforeNumber(
    new Date(date).getSeconds(),
  )}`;
};

const ModelMeta: React.FC<TModelMetaProps> = ({ meta }) => {
  return (
    <>
      <S.Title>Model metadata</S.Title>

      <S.MetaProperty>
        <S.MetaPropertyLabel>Model name</S.MetaPropertyLabel>
        <S.MetaPropertyValue>{meta.name}</S.MetaPropertyValue>
      </S.MetaProperty>

      <S.MetaProperty>
        <S.MetaPropertyLabel>Model creator</S.MetaPropertyLabel>
        <S.MetaPropertyValue>{meta.creator}</S.MetaPropertyValue>
      </S.MetaProperty>

      <S.MetaProperty>
        <S.MetaPropertyLabel>Model description</S.MetaPropertyLabel>
        <S.MetaPropertyValue>{meta.description}</S.MetaPropertyValue>
      </S.MetaProperty>

      <S.MetaProperty>
        <S.MetaPropertyLabel>Main evaluation metric</S.MetaPropertyLabel>
        <S.MetaPropertyValue>{meta.evaluationMetric}</S.MetaPropertyValue>
      </S.MetaProperty>

      <S.MetaProperty>
        <S.MetaPropertyLabel>Model periodicity</S.MetaPropertyLabel>
        <S.MetaPropertyValue>{meta.modelPeriodicity}</S.MetaPropertyValue>
      </S.MetaProperty>

      <S.MetaProperty>
        <S.MetaPropertyLabel>Model registry link</S.MetaPropertyLabel>
        <S.MetaPropertyValueLinkWrapper>
          <S.MetaPropertyValueLink href={meta.modelRegistryLink}>{meta.modelRegistryLink}</S.MetaPropertyValueLink>
        </S.MetaPropertyValueLinkWrapper>
      </S.MetaProperty>

      <S.MetaProperty>
        <S.MetaPropertyLabel>Create date</S.MetaPropertyLabel>
        <S.MetaPropertyValue>{convertDate(meta.createdDate)}</S.MetaPropertyValue>
      </S.MetaProperty>

      <S.MetaProperty>
        <S.MetaPropertyLabel>Last update date</S.MetaPropertyLabel>
        <S.MetaPropertyValue>{convertDate(meta.lastUpdatedDate)}</S.MetaPropertyValue>
      </S.MetaProperty>

      <S.MetaProperty>
        <S.MetaPropertyLabel>Version</S.MetaPropertyLabel>
        <S.MetaPropertyValue>{meta.version}</S.MetaPropertyValue>
      </S.MetaProperty>

      <S.MetaProperty>
        <S.MetaPropertyLabel>API Link</S.MetaPropertyLabel>
        <S.MetaPropertyValueLinkWrapper>
          <S.MetaPropertyValueLink href={meta.apiLink} rel="noopener" target="_blank">
            {meta.apiLink}
          </S.MetaPropertyValueLink>
        </S.MetaPropertyValueLinkWrapper>
      </S.MetaProperty>
    </>
  );
};

export default ModelMeta;
