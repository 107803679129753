import { Button, Card, CardContent, Paper, TextField, Tooltip } from '@material-ui/core';
import {
  GenerateRepository,
  ManagedInstanceDto,
  Repository,
  Template,
  TemplateVariables,
  TemplateVariablesItem,
} from '../../../common/models/workspace.models';
import React, { useCallback, useEffect, useState } from 'react';

import { Autocomplete } from '@material-ui/lab';
import { BlueprintDependency } from '../../../common/models/interfaces';
import { CardContainer, Column, HalfCardContainer, Label, Row } from '../../../common/styles/common.styles';
import {
  ContentContainer,
  DependecyRow,
  LoadingSpinnerContainerWbg,
  SelectedTemplateContainer,
  TemplateSelectContainer,
} from './template-adder.styles';
import { CookieCutterVariables } from '../../../components/cookie-cutter-variables/cookie-cutter-variables';
import { InfoCircleIcon } from '../../../assets';
import { InputContainer } from '../deploy-blueprints.styles';
import { LoadingSpinner } from '../../../components/loading-spinner/loading-spinner';
import { ManagedServiceType } from '../../../common/models/enums';
import { SelectedTemplateCard } from '../selected-template-card/selected-template-card';
import { TemplateSearch } from '../../../components/template-search/template-search';
import { createToasterNotification } from '../../../common/utils/toaster-notification';
import { urls } from '../../../common/utils/urls';
import { useDispatch } from 'react-redux';
import { useQuery } from 'react-query';
import { useSelector } from '../../../store/configure-store';
import BlueprintDependencyRow from '../../../components/BlueprintDependencyRow/BlueprintDependencyRow';
import VersionSelector from '../../../components/VersionSelector/VersionSelector';
import api, { ValidationError } from '../../../common/utils/api';

interface TemplateAdderProps {
  selectableTemplates: Template[];
  onSave: (templateId: string, generateRepository?: GenerateRepository) => Promise<Repository>;
  hasRepository: boolean;
  disabled: boolean;
}

export const TemplateAdder: React.FC<TemplateAdderProps> = (props) => {
  const [selectedTemplate, setSelectedTemplate] = useState<Template | null>(null);
  const [templateVariables, setTemplateVariables] = useState({
    items: [] as TemplateVariablesItem[],
    originalContent: '',
  });
  const [saving, setSaving] = useState(false);
  const [initialRepoData, setInitialRepoData] = useState<GenerateRepository | null>({
    newRepository: false,
    cookieCutterContent: {},
  });
  const domainId = useSelector((state) => state.dynamicSelector.selectedResource?.domainId);
  const selectedVersion = useSelector((state) => state.version.selectedVersion);
  const [generateRepository, setGenerateRepository] = useState<GenerateRepository | null>();
  const [selectedPipeline, setSelectedPipeline] = useState<ManagedInstanceDto | null>(null);
  const [selectedMlflow, setSelectedMlflow] = useState<ManagedInstanceDto | null>(null);
  const [hasMlflow, setHasMlflow] = useState<boolean>(false);
  const [hasKubeflow, setHasKubeflow] = useState<boolean>(false);
  const [variablesValidationError, setVariablesValidationError] = useState<ValidationError | null>(null);
  const [hasBlueprintDependency, setHasBlueprintDependency] = useState<boolean>(false);
  const [blueprintDependencies, setBlueprintDependencies] = useState<BlueprintDependency[]>();
  const [blueprintDependencyData, setblueprintDependencyData] = useState<{ [index: string]: string }>();

  const dispatch = useDispatch();

  const onSelectTemplate = (template: Template | null) => {
    if (template) {
      setSelectedTemplate(template);
    } else {
      resetTemplateVariables();
    }
  };

  const resetTemplateVariables = () => {
    setSelectedTemplate(null);
    setTemplateVariables({ items: [] as TemplateVariablesItem[], originalContent: '' });
    setInitialRepoData({
      newRepository: false,
      cookieCutterContent: {},
    });
    setGenerateRepository(null);
  };

  const onSaveTemplate = () => {
    setSaving(true);
    if (generateRepository) {
      generateRepository.managedServiceDependencies = {};
      if (hasMlflow && selectedMlflow) {
        generateRepository.managedServiceDependencies[ManagedServiceType.MLFLOW] = selectedMlflow.id;
      }
      if (hasKubeflow && selectedPipeline) {
        generateRepository.managedServiceDependencies[ManagedServiceType.KUBEFLOW] = selectedPipeline.id;
      }
      if (hasBlueprintDependency) {
        generateRepository.blueprintDependencies = blueprintDependencyData;
      }

      props
        .onSave(selectedTemplate?.id as string, { ...generateRepository, templateVersionId: selectedVersion?.id })
        .then(() => {
          setVariablesValidationError(null);
          setSaving(false);
          resetTemplateVariables();
        })
        .catch((e: ValidationError | unknown) => {
          setSaving(false);
          if (e instanceof ValidationError && e.error.errors) {
            setVariablesValidationError(e);
          } else {
            dispatch(createToasterNotification(e));
          }
        });
    }
  };

  const templateVariablesQuery = useQuery(
    [`templateVariables`, selectedVersion?.id, selectedTemplate],
    (_, version: string, selectedTemplateData: Template) => {
      if (!version || !selectedTemplateData) return;
      return api
        .get<TemplateVariables>(urls.getTemplateVariables(selectedTemplateData?.id as string, version || ''))
        .then((data) => {
          //Init and prepare for the blueprint dependencies
          if (data.blueprintDependencies) {
            setBlueprintDependencies(data.blueprintDependencies);
            const initBlueprintData = {};
            data.blueprintDependencies.forEach((dependency) => {
              initBlueprintData[dependency.id] = null;
            });
            setblueprintDependencyData(initBlueprintData);
          }
          ////////////////////////////////////////////////////////
          setTemplateVariables(data.cookieCutterContent);
          const templateVar = {};
          data.cookieCutterContent.items.forEach((value) => {
            templateVar[value.key] = value.value;
          });
          if (initialRepoData) {
            const copyOfRepo = { ...initialRepoData };
            copyOfRepo.cookieCutterContent = templateVar;
            setInitialRepoData(copyOfRepo);
          }

          setHasMlflow(data.managedServiceDependencies.indexOf(ManagedServiceType.MLFLOW) !== -1);
          setHasKubeflow(data.managedServiceDependencies.indexOf(ManagedServiceType.KUBEFLOW) !== -1);
          setHasBlueprintDependency(data?.blueprintDependencies?.length > 0);

          return data;
        })
        .catch((e) => {
          dispatch(createToasterNotification(e));
        });
    },
    { enabled: false },
  );

  const handleTemplateVariableFetch = useCallback(templateVariablesQuery.refetch, []);

  useEffect(() => {
    if (selectedTemplate || selectedVersion) {
      handleTemplateVariableFetch();
    }
  }, [selectedTemplate, handleTemplateVariableFetch, selectedVersion]);

  const fetchKubeflows = useQuery(
    ['fetchKubeFlows', hasKubeflow],
    (_, hasKubeFlow: boolean) => {
      return api
        .get<ManagedInstanceDto[]>(urls.getKubeflows(domainId || ''), { params: { state: 'DEPLOY_SUCCESS' } })
        .then((data) => {
          if (!selectedPipeline && data && hasKubeFlow) {
            setSelectedPipeline(data[0]);
          }
          return data;
        })
        .catch((e) => {
          dispatch(createToasterNotification(e));
        });
    },
    { enabled: true, refetchOnWindowFocus: false },
  );

  const fetchMlflows = useQuery(
    [`fetch-mlflows-for-variables`, hasMlflow],
    (_, hasMlFlow: boolean) => {
      return api
        .get<ManagedInstanceDto[]>(urls.getMlflows(domainId || ''), { params: { state: 'DEPLOY_SUCCESS' } })
        .then((data) => {
          if (!selectedMlflow && data && hasMlFlow) {
            setSelectedMlflow(data[0]);
          }
          return data;
        })
        .catch((e) => {
          dispatch(createToasterNotification(e));
        });
    },
    { enabled: true, refetchOnWindowFocus: false },
  );

  const hasMissingBluprintDependency = (blueprintDependencyDataParam: any): boolean => {
    const has = blueprintDependencyDataParam
      ? Object.values(blueprintDependencyDataParam).some((item) => item === null)
      : false;

    return has;
  };

  return (
    <ContentContainer>
      {!selectedTemplate && !props.hasRepository && (
        <CardContainer>
          <Card>
            <CardContent style={{ position: 'relative', minHeight: '450px' }}>
              <InputContainer>
                <TemplateSelectContainer>
                  <Label>Blueprint templates</Label>
                  <TemplateSearch
                    buttonText={'Select'}
                    onActionClick={onSelectTemplate}
                    onCardClick={onSelectTemplate}
                  />
                </TemplateSelectContainer>
              </InputContainer>
            </CardContent>
          </Card>
        </CardContainer>
      )}
      {selectedTemplate && (
        <HalfCardContainer>
          <Card>
            <CardContent style={{ position: 'relative', minHeight: '450px' }}>
              {saving && (
                <LoadingSpinnerContainerWbg>
                  <LoadingSpinner />
                </LoadingSpinnerContainerWbg>
              )}
              <SelectedTemplateContainer>
                <>
                  <Row>
                    <Column>
                      <Label>Selected template</Label>
                    </Column>
                    <Column className={'change-template-container'}>
                      <Button size="small" color="primary" onClick={() => onSelectTemplate(null)}>
                        CHANGE TEMPLATE
                      </Button>
                    </Column>
                  </Row>
                  <SelectedTemplateCard template={selectedTemplate} />
                  <Label>Git repository</Label>
                  <div style={{ width: '466px', margin: '0 auto' }}>
                    <VersionSelector
                      styles={{
                        marginBottom: '16px',
                      }}
                      template={selectedTemplate}
                    />
                  </div>
                  <CookieCutterVariables
                    validationError={variablesValidationError}
                    templateVariables={templateVariables.items}
                    data={initialRepoData}
                    isFetching={templateVariablesQuery.isFetching}
                    onDataChanged={(data) => {
                      setGenerateRepository(data);
                    }}
                  />
                  {(hasMlflow || hasKubeflow || hasBlueprintDependency) && (
                    <Paper elevation={1} style={{ padding: '1em', margin: '0 auto' }}>
                      <h2 style={{ margin: 0, marginBottom: '1em', textAlign: 'center' }}>Dependencies</h2>
                      {hasMlflow && (
                        <DependecyRow>
                          <div style={{ alignSelf: 'center' }}>
                            <Tooltip title={'Experiment instance for experiment tracking'} aria-label="add">
                              <InfoCircleIcon />
                            </Tooltip>
                          </div>
                          <Autocomplete
                            disableClearable={true}
                            options={fetchMlflows.data ? fetchMlflows.data : []}
                            renderInput={(params) => (
                              <TextField {...params} label="Experiments" variant="outlined" fullWidth />
                            )}
                            getOptionLabel={(option) => option.name}
                            onChange={(event, mlflow) => {
                              setSelectedMlflow(mlflow);
                            }}
                            value={
                              selectedMlflow
                                ? selectedMlflow
                                : fetchMlflows.data
                                ? fetchMlflows.data[0]
                                : (null as unknown as ManagedInstanceDto)
                            }
                            size="small"
                          />
                        </DependecyRow>
                      )}
                      {hasKubeflow && (
                        <DependecyRow>
                          <div style={{ alignSelf: 'center' }}>
                            <Tooltip title={'Pipeline instance for pipeline handling'} aria-label="add">
                              <InfoCircleIcon />
                            </Tooltip>
                          </div>
                          <Autocomplete
                            disableClearable={true}
                            options={fetchKubeflows.data ? fetchKubeflows.data : []}
                            renderInput={(params) => (
                              <TextField {...params} label="Pipelines" variant="outlined" fullWidth />
                            )}
                            getOptionLabel={(option) => option.name}
                            onChange={(event, pipeline) => {
                              setSelectedPipeline(pipeline);
                            }}
                            value={
                              selectedPipeline
                                ? selectedPipeline
                                : fetchKubeflows.data
                                ? fetchKubeflows.data[0]
                                : (null as unknown as ManagedInstanceDto)
                            }
                            size="small"
                          />
                        </DependecyRow>
                      )}

                      {hasBlueprintDependency &&
                        blueprintDependencies?.map((dependency) => (
                          <DependecyRow key={dependency.id}>
                            <div style={{ alignSelf: 'center' }}>
                              <Tooltip
                                title="Select the workspace where the following blueprint is deployed."
                                aria-label="add"
                              >
                                <InfoCircleIcon />
                              </Tooltip>
                            </div>
                            <BlueprintDependencyRow
                              onChangeAction={setblueprintDependencyData}
                              dependency={dependency}
                              blueprintDependencyData={blueprintDependencyData}
                            />
                          </DependecyRow>
                        ))}
                    </Paper>
                  )}
                </>

                <Button
                  variant="contained"
                  color="primary"
                  disabled={
                    !selectedTemplate ||
                    !generateRepository ||
                    saving ||
                    props.hasRepository ||
                    props.disabled ||
                    (hasKubeflow && !selectedPipeline) ||
                    (hasMlflow && !selectedMlflow) ||
                    (hasBlueprintDependency && hasMissingBluprintDependency(blueprintDependencyData))
                  }
                  style={{ marginTop: '2em' }}
                  onClick={onSaveTemplate}
                >
                  Deploy
                </Button>
              </SelectedTemplateContainer>
            </CardContent>
          </Card>
        </HalfCardContainer>
      )}
    </ContentContainer>
  );
};
