import { Alert } from '@material-ui/lab';
import { Row } from '../../common/styles/common.styles';
import styled from 'styled-components';

export const InformationContainer = styled.div`
  padding: 20px;
`;

export const InputContainer = styled.div`
  margin: 30px 0;

  &:first-of-type {
    margin-top: 0;
  }
`;

export const Label = styled.div`
  padding-bottom: 20px;
  font-size: 16px;
  line-height: 24px;
  color: ${(p) => p.theme.colors.transparentDarkGreen};
`;

export const SaveChangesContainer = styled(Row)`
  justify-content: flex-end;
  padding: 15px;
`;

export const ProjectDetailRow = styled(Row)`
  margin-top: 10px;
  & p {
    margin-right: 45px;
    font-size: 14px;
    color: rgba(5, 33, 32, 0.6);
    & span {
      margin-left: 10px;
      color: rgba(0, 0, 0, 0.87);
    }
  }
`;

export const BuildList = styled(Row)`
  margin-top: 10px;
  display: block;
  & fieldset {
    & span {
      display: block;
    }
  }
  & .refresh-container {
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    & .label {
      padding: 0;
    }
  }
`;

export const InputRow = styled(Row)`
  & button {
    margin-left: 15px;
  }
`;

export const ProvisionDetailsContainer = styled.div`
  & .back-btn {
    cursor: pointer;
    margin-bottom: 20px;
  }
  & .details-title {
    font-size: 15px;
    margin-left: 10px;
  }
`;

export const RefreshContainer = styled(Row)`
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  & .label {
    padding: 0;
  }
`;

export const AlertComponent = styled(Alert)`
  margin-bottom: 20px;
`;
