import { InputProps as StandardInputProps } from '@material-ui/core/Input/Input';
import { TextField } from '@material-ui/core';
import React, { ChangeEvent } from 'react';

export interface TextFieldWrapperProps {
  label: string;
  value: number | string;
  getValue: (value: string) => void;
  isDisabled?: boolean;
  inputProps?: Partial<StandardInputProps>;
  isNumberType?: boolean;
}

export const TextFieldWrapper: React.FC<TextFieldWrapperProps> = (props) => {
  const handleOnChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    props.getValue?.(event.target.value);
  };

  return (
    <TextField
      label={props.label}
      type={props.isNumberType ? 'number' : 'text'}
      value={props.value}
      InputProps={props.inputProps}
      variant="outlined"
      color="primary"
      size="small"
      fullWidth
      disabled={props.isDisabled}
      onChange={handleOnChange}
    />
  );
};
