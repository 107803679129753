import { BlueprintVersion } from '../../../common/models/interfaces';
import { Button, Card, Step, StepLabel, Stepper } from '@material-ui/core';
import { CardContainer } from '../../../common/styles/common.styles';
import {
  DetailedWorkspace,
  GenerateRepository,
  Project,
  Repository,
  Template,
} from '../../../common/models/workspace.models';
import { LoadingSpinner } from '../../../components/loading-spinner/loading-spinner';
import { LoadingSpinnerContainerWbg } from '../../deploy-blueprints/template-adder/template-adder.styles';
import { NewInstanceRoot } from './new-instance.styles';
import { ResourceTypes } from '../../../common/models/resource.model';
import { SelectProject } from './steps/select-project';
import { SelectTemplate } from './steps/select-template';
import { SelectWorkspace } from './steps/select-workspace';
import { SetEnvironmentVariables } from './steps/set-environment-variables';
import { ToastType } from '../../../common/models/enums';
import { Workspace } from '../../workspaces/models/workspaces.models';
import { WorkspaceQuery } from '../../../common/utils/query';
import { createToasterNotification } from '../../../common/utils/toaster-notification';
import { getResources, setResource } from '../../../store/dynamic-selector/actions';
import { path } from '../../../routes/path';
import { urls } from '../../../common/utils/urls';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useSelector } from '../../../store/configure-store';
import React, { useCallback, useEffect, useState } from 'react';
import Tour from '../../../components/Tour';
import api, { ValidationError } from '../../../common/utils/api';
import withResources from '../../../components/withResources/withResources';

export interface NewInstanceProps {
  selectedTemplate?: Template | null;
  onSelectTemplate?: (template: Template) => void;
  onSelectGenerateRepository?: (selectGenerateRepository: GenerateRepository | null) => void;
  selectedGenerateRepository?: GenerateRepository | null;
  selectedWorkspace?: Workspace | null | undefined;
  onSelectWorkspace?: (workspace: Workspace | null) => void;
  selectedProjects?: Project[] | null | undefined;
  onSelectProject?: (project: Project[] | null) => void;
  validationError?: ValidationError | null;
  selectedVersionCache?: BlueprintVersion | null;
  onChangeBlueprintDependency?: React.Dispatch<any>;
}

const NewInstanceComp: React.FC<{
  handleWorkspacesFetch: () => void;
  onWorkspaceSelected: (workspace: Workspace | null) => void;
}> = () => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [isNextDisabled, setIsNextDisabled] = React.useState(true);
  const [selectedTemplate, setSelectedTemplate] = useState<Template | null | undefined>(null);
  const [selectedWorkspace, setSelectedWorkspace] = useState<Workspace | null | undefined>(null);
  const [selectedProjects, setSelectedProjects] = useState<Project[] | null | undefined>(null);
  const [selectedGenerateRepository, setSelectedGenerateRepository] = useState<GenerateRepository | null | undefined>(
    null,
  );
  const navigate = useNavigate();
  const steps = ['Select blueprint', 'Set Environment variables', 'Workspace set up', 'Project set up'];
  const dispatch = useDispatch();
  const [saving, setSaving] = useState(false);
  const selectedVersion = useSelector((state) => state.version.selectedVersion);
  const domainId = useSelector((state) => state.dynamicSelector.selectedResource?.domainId);
  const workspaceQuery = WorkspaceQuery(selectedWorkspace?.id, 'get-details-for-exist' + selectedWorkspace?.id);
  const [variablesValidationError, setVariablesValidationError] = useState<ValidationError | null>(null);
  const [selectedVersionCache, setSelectedVersionCache] = useState<BlueprintVersion | null>();
  const [blueprintDependencyData, setblueprintDependencyData] = useState<any>();

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return <SelectTemplate selectedTemplate={selectedTemplate} onSelectTemplate={onSelectTemplate} />;
      case 1:
        return (
          <>
            <Tour
              type="BLUEPRINT_WIZARD_ENV_VARS"
              steps={[
                {
                  content: 'First you need to choose a version.',
                  target: '.version-selector',
                  disableBeacon: true,
                  placement: 'left-start',
                  showSkipButton: true,
                },
                {
                  content: 'You need to choose a name.',
                  target: '.repository-name',
                  placement: 'left-start',
                  showSkipButton: true,
                },
                {
                  content: 'You can create it as a new repo.',
                  target: '.new-repo-check',
                  placement: 'left-start',
                  showSkipButton: true,
                },
                {
                  content: 'You can define variables here.',
                  target: '.cc-variables',
                  placement: 'left-start',
                  showSkipButton: true,
                },
                {
                  content: 'You can define dependencies here.',
                  target: '.deps',
                  placement: 'left-start',
                  showSkipButton: true,
                },
              ]}
            />
            <SetEnvironmentVariables
              validationError={variablesValidationError}
              selectedGenerateRepository={selectedGenerateRepository}
              selectedTemplate={selectedTemplate}
              onSelectGenerateRepository={onSelectGenerateRepository}
              selectedVersionCache={selectedVersionCache}
              onChangeBlueprintDependency={setblueprintDependencyData}
            />
          </>
        );
      case 2:
        return (
          <>
            <Tour
              type="BLUEPRINT_WIZARD_WORKSPACE"
              steps={[
                {
                  content: 'Select a workspace!',
                  target: '.workspace-selector',
                  disableBeacon: true,
                  showSkipButton: true,
                  placement: 'left-start',
                },
              ]}
            />
            <SelectWorkspace selectedWorkspace={selectedWorkspace} onSelectWorkspace={onSelectWorkspace} />
          </>
        );
      case 3:
        return (
          <>
            <Tour
              type="BLUEPRINT_WIZARD_PROJECT"
              steps={[
                {
                  content: 'Select a project!',
                  target: '.project-to-workspace',
                  disableBeacon: true,
                  showSkipButton: true,
                  placement: 'left-start',
                },
              ]}
            />
            <SelectProject selectedProjects={selectedProjects} onSelectProject={onSelectProject} />
          </>
        );
      default:
        return 'Unknown step';
    }
  };

  const closeWizard = () => {
    navigate(path.blueprintDashboard());
  };

  const hasMissingBluprintDependency = (blueprintDependencyDataParam: any): boolean => {
    const has = blueprintDependencyDataParam
      ? Object.values(blueprintDependencyDataParam).some((item) => item === null)
      : false;

    return has;
  };

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      setSaving(true);
      if (selectedWorkspace != null && selectedWorkspace.id === '') {
        saveWorkspace();
      } else {
        if (workspaceQuery.data) {
          saveProjectsAndRepo(workspaceQuery.data);
        }
      }
    } else if (activeStep === 1) {
      if (!selectedGenerateRepository) {
        return;
      }

      if (hasMissingBluprintDependency(blueprintDependencyData)) {
        return;
      }

      setIsNextDisabled(true);
      setSaving(true);
      api
        .post<Repository>(urls.validateTemplateVariables(selectedTemplate?.id as string), {
          ...selectedGenerateRepository,
          templateVersionId: selectedVersion?.id,
        })
        .then((response: Repository) => {
          setSelectedVersionCache(selectedVersion || null);
          setVariablesValidationError(null);
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          setIsNextDisabled(true);
          setSaving(false);
        })
        .catch((e: ValidationError | unknown) => {
          if (e instanceof ValidationError && e.error.errors) {
            setVariablesValidationError(e);
          } else {
            dispatch(createToasterNotification(e));
          }
          setIsNextDisabled(false);
          setSaving(false);
        });
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setIsNextDisabled(true);
    }
  };

  const saveWorkspace = () => {
    const payload = {
      id: null,
      name: selectedWorkspace?.name,
      description: selectedWorkspace?.description,
    };
    api
      .post<DetailedWorkspace>(urls.workspaceBaseData(domainId || ''), payload)
      .then((workspace) => {
        if (!workspace && selectedWorkspace) {
          if (workspaceQuery.data) {
            saveProjectsAndRepo(workspaceQuery.data);
          }
        } else if (workspace) {
          saveProjectsAndRepo(workspace);
        }
      })
      .catch((e) => {
        setSaving(false);
        dispatch(createToasterNotification(e));
      });
  };

  const saveRepo = async (workspace?: DetailedWorkspace) => {
    const copyOfSelectedRepo = {
      ...(selectedGenerateRepository as GenerateRepository),
      templateVersionId: selectedVersionCache?.id,
    };
    await api
      .put<Repository>(
        urls.generateCodeFromTemplate(
          selectedTemplate?.id as string,
          workspace ? workspace.id : (selectedWorkspace?.id as string),
        ),
        copyOfSelectedRepo as Record<string, unknown>,
      )
      .catch((e) => {
        setSaving(false);
        dispatch(createToasterNotification(e));
      });
  };

  const saveProjectsAndRepo = async (workspace: DetailedWorkspace) => {
    if (selectedProjects && selectedProjects.length > 0) {
      for (const p of selectedProjects) {
        if (p.projectNumber === '') {
          await saveNonExistProject(workspace, p);
        } else {
          await saveProjectToWorkspace(workspace, p);
        }
      }
    }

    await saveRepo(workspace);

    dispatch(
      setResource({
        id: workspace.id,
        name: workspace.name,
        type: ResourceTypes.WORKSPACE,
        domainId,
      }),
    );

    dispatch(getResources());
    //dispatch(setSelectedWorkspaceAction(workspace));
    //props?.onWorkspaceSelected(workspace);
    //props?.handleWorkspacesFetch();
    // just to make sure that every rendering process finished before navigation
    setTimeout(() => {
      navigate(path.deployBlueprints());
      dispatch(createToasterNotification('Instance has successfully been created', ToastType.SUCCESS));
    }, 500);
  };

  const saveNonExistProject = async (workspace: DetailedWorkspace, project: Project) => {
    const projectResp = await api
      .post<Project>(urls.projectCreate(domainId || '', project.projectId))
      .then((p: Project) => {
        return p;
      })
      .catch((e) => {
        setSaving(false);
        dispatch(createToasterNotification(e));
      });
    if (projectResp) {
      projectResp.environmentType = project.environmentType;
      await saveProjectToWorkspace(workspace, projectResp);
    }
  };

  const saveProjectToWorkspace = async (workspace: DetailedWorkspace, project: Project) => {
    const payload = {
      id: workspace?.id,
      projects: workspace.projects ? [...workspace.projects, project] : [project],
    };
    const savedWorkspace = await api
      .post<DetailedWorkspace>(urls.workspaceProject(workspace?.id), payload)
      .catch((e) => {
        dispatch(createToasterNotification(e));
      });
    if (savedWorkspace) {
      workspace.projects = savedWorkspace.projects;
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + -1);
    setIsNextDisabled(false);
  };

  const onSelectTemplate = (template: Template): void => {
    if (template.id !== selectedTemplate?.id) {
      setSelectedGenerateRepository(null);
      setSelectedWorkspace(null);
    }
    setSelectedTemplate(template);
    setIsNextDisabled(false);
  };

  const onSelectGenerateRepository = (request: GenerateRepository | null): void => {
    setSelectedGenerateRepository(request);
    setIsNextDisabled(!request);
  };

  const onSelectWorkspace = (request: Workspace | null): void => {
    setSelectedWorkspace(request);
    setIsNextDisabled(!request);
  };

  const workspaceDetailsQuery = WorkspaceQuery(selectedWorkspace ? selectedWorkspace.id : undefined);

  useEffect(() => {
    if (workspaceDetailsQuery.data) {
      setSelectedProjects(workspaceDetailsQuery.data.projects);
    } else {
      setSelectedProjects([]);
    }
  }, [workspaceDetailsQuery.data]);

  const onSelectProject = (request: Project[] | null): void => {
    setSelectedProjects(request);
    setIsNextDisabled(request == null || request.length === 0);
  };

  const workspaceQueryFetch = useCallback(workspaceQuery.refetch, []);

  useEffect(() => {
    if (selectedWorkspace && selectedWorkspace.id !== '') {
      workspaceQueryFetch();
    }
  }, [selectedWorkspace, workspaceQueryFetch]);

  return (
    <NewInstanceRoot>
      <CardContainer>
        <Card className={'card'}>
          {saving && (
            <LoadingSpinnerContainerWbg>
              <LoadingSpinner />
            </LoadingSpinnerContainerWbg>
          )}
          <Stepper className={'stepper'} activeStep={activeStep} alternativeLabel>
            {steps.map((label) => {
              return (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <div className={'stepContent'}>{getStepContent(activeStep)}</div>
          <div className={'stepFooter'}>
            <Button className={'closeBtn'} onClick={closeWizard}>
              Close
            </Button>
            <Button disabled={activeStep === 0} onClick={handleBack}>
              Back
            </Button>
            <Button disabled={isNextDisabled || workspaceQuery.isFetching} color="primary" onClick={handleNext}>
              {activeStep === steps.length - 1 ? 'Save' : 'Next'}
            </Button>
          </div>
        </Card>
      </CardContainer>
    </NewInstanceRoot>
  );
};

export const NewInstance = withResources(NewInstanceComp);
