import { NewInstanceProps } from '../new-instance';
import { SelectTemplateRoot } from '../new-instance.styles';
import { Step } from 'react-joyride';
import { Template } from '../../../../common/models/workspace.models';
import { TemplateSearch } from '../../../../components/template-search/template-search';
import { path } from '../../../../routes/path';
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import Tour from '../../../../components/Tour';

export const SelectTemplate: React.FC<NewInstanceProps> = (props) => {
  const [selectedTemplate, setSelectedTemplate] = useState<Template | null | undefined>(props.selectedTemplate);
  const navigate = useNavigate();

  const selectTemplate = (template: Template): void => {
    setSelectedTemplate(template);
    if (props.onSelectTemplate) {
      props.onSelectTemplate(template as Template);
    }
  };

  const goToTemplateDetails = (template: Template): void => {
    navigate(path.blueprintDetail(template.id));
  };

  return (
    <>
      <Tour
        type="BLUEPRINT_WIZARD_SELECT_BLUEPRINT"
        steps={
          [
            {
              content: 'First, you need to select a blueprint',
              target: '.tour',
              disableBeacon: true,
              placement: 'left-start',
            },
          ] as Step[]
        }
      />
      <SelectTemplateRoot>
        <TemplateSearch
          buttonText={'details'}
          selectionEnabled={true}
          selectedTemplate={selectedTemplate}
          onActionClick={goToTemplateDetails}
          onCardClick={selectTemplate}
        />
      </SelectTemplateRoot>
    </>
  );
};
