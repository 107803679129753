import { ChevronLeft, ChevronRight } from '../../assets';
import {
  ChevronLeftContainer,
  ChevronRightContainer,
  DateLabel,
  MonthSelectorContainer,
} from './month-selector.styles';
import { ReportsQueryParam } from '../../common/models/interfaces';
import { getMonthAndYear, setQueryParamForReports } from '../../common/utils/date-formatter';
import React, { useState } from 'react';

interface MonthSelectorProps {
  onSelectDate: (reportsQueryParam: ReportsQueryParam) => void;
}

export const MonthSelector: React.FC<MonthSelectorProps> = (props) => {
  const [date, setDate] = useState<Date>(new Date());
  const [label, setLabel] = useState<string>(getMonthAndYear(date));

  const stepDateByMonth = (direction: number) => {
    const newDate = new Date(date.getFullYear(), date.getMonth() + direction);
    setLabel(getMonthAndYear(newDate));
    setDate(newDate);
    props.onSelectDate(setQueryParamForReports(newDate, direction));
  };

  const onBack = () => {
    stepDateByMonth(-1);
  };

  const onNext = () => {
    stepDateByMonth(1);
  };

  return (
    <MonthSelectorContainer>
      <ChevronLeftContainer onClick={onBack}>
        <ChevronLeft />
      </ChevronLeftContainer>
      <DateLabel>{label}</DateLabel>
      <ChevronRightContainer onClick={onNext}>
        <ChevronRight />
      </ChevronRightContainer>
    </MonthSelectorContainer>
  );
};
