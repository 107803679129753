import { AuthState } from '../store/auth/models';
import { appStore } from '../store/configure-store';
import { environmentVariable } from '../common/utils/environment-variable';

interface IRequest {
  auth?: boolean;
  endpoint: string;
  config?: RequestInit;
}

const generateFetchConfig = (
  config: RequestInit | undefined,
  auth: boolean,
  authState: AuthState,
): RequestInit | undefined => {
  if (!auth) {
    return config;
  }

  const token: string = authState.data?.tokenId || '';

  return {
    ...config,
    headers: {
      ...(config && config.headers),
      Authorization: `Bearer ${token}`,
    },
  };
};

export const generateUrl = (endpoint: string) => {
  if (process.env.NODE_ENV !== 'production') {
    return `/api/${endpoint}`;
  }
  return `${environmentVariable.apiUrl}/api/${endpoint}`;
};

const request = async <T>({ endpoint, config, auth = true }: IRequest): Promise<T> => {
  const { auth: authState } = appStore.getState();
  const fetchConfig = generateFetchConfig(config, auth, authState);

  const url = generateUrl(endpoint);
  const result = await fetch(url, fetchConfig);

  if (!result.ok) {
    throw new Error(await result.text());
  }

  const contentType = result.headers.get('content-type');

  let response;

  if (contentType && !contentType.includes('application/json')) {
    response = result.text();
  } else {
    response = result.json();
  }

  return response;
};

export default request;
