import { ValidatorForm } from 'react-material-ui-form-validator';
import GeneralModalContext from './GeneralModalContext';
import React, { useContext } from 'react';

const GeneralModalForm: React.FC = ({ children }) => {
  const { onSubmit } = useContext(GeneralModalContext);
  return <ValidatorForm onSubmit={onSubmit}>{children}</ValidatorForm>;
};

export default GeneralModalForm;
