import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { ModelPlotTypes } from '../../common/models/enums';
import { Skeleton } from '@material-ui/lab';
import { getPlotTypes } from './requests';
import { resolvePlotLabel } from './dictionary';
import ModelPerformanceContext from '../../pages/ModelPerformance/ModelPerformanceContext';
import React, { useCallback, useContext, useEffect, useState } from 'react';

interface TPlotTypeSelectorProps {
  selectedPlotType: ModelPlotTypes;
  setSelectedPlotType: React.Dispatch<React.SetStateAction<ModelPlotTypes>>;
}

function PlotTypeSelector({ selectedPlotType, setSelectedPlotType }: TPlotTypeSelectorProps) {
  const { domainId, modelId } = useContext(ModelPerformanceContext);
  const [plotTypes, setPlotTypes] = useState<ModelPlotTypes[]>();
  const handleGetPlotTypes = useCallback(getPlotTypes, [domainId, modelId]);
  const [isLoading, setIsLoading] = useState(false);

  const getPlotTypesCallback = (plotList: ModelPlotTypes[]) => {
    setPlotTypes(plotList);
    setIsLoading(false);
  };

  useEffect(() => {
    if (domainId && modelId)
      handleGetPlotTypes(domainId, modelId, {
        beforeActions: () => setIsLoading(true),
        cb: getPlotTypesCallback,
      });
  }, [domainId, modelId, handleGetPlotTypes]);

  useEffect(() => {
    plotTypes && plotTypes.length && setSelectedPlotType(plotTypes[0]);
  }, [plotTypes, setSelectedPlotType]);

  if (isLoading) return <Skeleton style={{ borderRadius: '5px' }} variant="rect" width={320} height={40} />;

  return (
    <FormControl size="small" variant="outlined">
      <InputLabel shrink htmlFor="plotType">
        Plot type
      </InputLabel>
      <Select
        style={{ minWidth: '320px' }}
        variant="outlined"
        id="plotType"
        labelId="plot-label"
        onChange={(e) => {
          setSelectedPlotType(e.target.value as ModelPlotTypes);
        }}
        name="model"
        value={selectedPlotType}
        label="Plot type"
      >
        {plotTypes &&
          plotTypes.map((plot) => (
            <MenuItem value={plot} key={plot}>
              {resolvePlotLabel(plot)}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}

export default PlotTypeSelector;
