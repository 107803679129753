import { ManagedServiceTypes } from '../../common/models/enums';
import { urls } from '../../common/utils/urls';
import api, { ValidationError } from '../../common/utils/api';

function resolveSaveURLByType(type: string) {
  switch (type) {
    case ManagedServiceTypes.MLFLOW:
      return (domainId: string, instanceId: string, principal: string) =>
        api.post(urls.postMlflowPrincipals(domainId, instanceId), { principal });
    case ManagedServiceTypes.KUBEFLOW:
      return (domainId: string, instanceId: string, principal: string) =>
        api.post(urls.postKubeflowPrincipal(domainId, instanceId), { principal });
    default:
      return () => undefined;
  }
}

async function savePrincipal(
  type: string,
  domainId: string,
  instanceId: string,
  principal: string,
  setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>,
  onSuccess?: () => void,
  onError?: (e: ValidationError | unknown) => void,
) {
  try {
    setIsLoading && setIsLoading(true);
    await resolveSaveURLByType(type)(domainId, instanceId, principal);
    onSuccess && onSuccess();
    setIsLoading && setIsLoading(false);
  } catch (e) {
    setIsLoading && setIsLoading(false);
    console.error('Error in savePrincipal!', e);
    onError && onError(e);
  }
}

export { savePrincipal };
