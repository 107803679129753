import styled from 'styled-components';

export const AccessDeniedRoot = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin-top: 150px;
  margin-bottom: 150px;
  & .error-text {
    color: ${(p) => p.theme.colors.primary};
    font-size: 3em;
    margin-bottom: 30px;
  }
  & .error-desc-text {
    margin-bottom: 5px;
    margin-top: 2px;
  }
  & .go-back-btn {
    margin-top: 20px;
  }
`;
